var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-register-parecel-step-1"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content padding"},[_c('div',{staticClass:"new-zone-container card-content",staticStyle:{"padding":"1px 1px 1px 1px !important"}},[_c('f7-row',{staticClass:"button-container"},[_c('f7-col',{attrs:{"width":"85"}},[_c('DxForm',{attrs:{"id":_vm.keyName,"form-data":_vm.currentElement,"label-location":"top"}},[_c('DxGroupItem',[_c('DxGroupItem',{attrs:{"col-count":11}},[_c('DxSimpleItem',{attrs:{"name":"provincia","data-field":"provincia","col-span":3,"label":{ text: _vm.$t('properties.province') },"editor-type":"dxSelectBox","editor-options":{
                        dataSource: _vm.provinces,
                        displayExpr: (e) => e ? `(${e.id}) ${e.name}` : '',
                        valueExpr: 'id',
                        searchEnabled: true,
                        searchExpr: ['name', 'id'],
                        searchMode: 'contains',
                        onValueChanged: (e) => _vm.provinceChanged(e),
                        showClearButton: true,
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"municipio","data-field":"municipio","col-span":3,"label":{ text: _vm.$t('properties.municipality') },"editor-type":"dxSelectBox","editor-options":{
                        dataSource: _vm.municipalities,
                        displayExpr: (e) => e ? `(${e.id}) ${e.name}` : '',
                        valueExpr: 'id',
                        searchEnabled: true,
                        searchExpr: ['name', 'id'],
                        searchMode: 'contains',
                        onValueChanged: (e) => _vm.fieldDataChanged('municipio', e),
                        showClearButton: true,
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"agregado","data-field":"agregado","col-span":1,"label":{ text: _vm.$t('properties.aggregate') },"editor-type":"dxNumberBox","editor-options":{
                        min: 0,
                        maxLength: 128,
                        onValueChanged: (e) => _vm.fieldDataChanged('agregado', e),
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"zona","data-field":"zona","col-span":1,"label":{ text: _vm.$t('properties.zone') },"editor-type":"dxNumberBox","editor-options":{
                        min: 0,
                        maxLength: 128,
                        onValueChanged: (e) => _vm.fieldDataChanged('zona', e),
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"poligono","data-field":"poligono","col-span":1,"label":{ text: _vm.$t('properties.polygon') },"editor-type":"dxNumberBox","editor-options":{
                        min: 0,
                        maxLength: 128,
                        onValueChanged: (e) => _vm.fieldDataChanged('poligono', e),
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"parcela","data-field":"parcela","col-span":1,"label":{ text: _vm.$t('properties.parcel') },"editor-type":"dxNumberBox","editor-options":{
                        min: 0,
                        maxLength: 128,
                        onValueChanged: (e) => _vm.fieldDataChanged('parcela', e),
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"recinto","data-field":"recinto","col-span":1,"label":{ text: _vm.$t('properties.enclosure') },"editor-type":"dxNumberBox","editor-options":{
                        min: 0,
                        maxLength: 128,
                        onValueChanged: (e) => _vm.fieldDataChanged('recinto', e),
                      }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1)],1)],1)],1)],1),_vm._v(" "),_c('f7-col',{attrs:{"width":"15"}},[_c('f7-button',{staticClass:"dx-btn-success button-sigpac",attrs:{"raised":"","type":"success"},on:{"click":_vm.searchSigpac}},[_vm._v("\n                "+_vm._s(_vm.$t("mapbox.sigpac.form.buttonSearch"))+"\n              ")])],1)],1)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }