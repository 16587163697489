<template>
  <div class="content-devx">
    <f7-block>{{ $t('Configuration.ObservationsForms.registerTitle') }}</f7-block>
    <f7-block>
      <DxForm
        id="headerTemplateForm-form"
        :show-colon-after-label="false"
      >
        <DxGroupItem :col-count="2">
          <DxSimpleItem
            :data-field="$t('Configuration.ObservationsForms.category')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: templatesCategories,
              value: templateCategory,
              placeholder: $t('Configuration.ObservationsForms.category'),
              onValueChanged: onValueChanged,
            }"
          >
            <DxRequiredRule />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Configuration.ObservationsForms.formName')"
            :editor-options="{
              value: formName,
              placeholder: $t('Configuration.ObservationsForms.formName'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
          >
            <DxRequiredRule :message="$t('Configuration.ObservationsForms.formNameRequired')" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapActions } from 'vuex';

export default {
  name: 'HeaderTemplateForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    templatesCategories: {
      type: Array,
      default: () => [],
    },
    templateCategory: {
      type: String,
      default: '',
    },
    formName: {
      type: String,
      default: '',
    },
  },
  methods: {
    onValueChanged(e) {
      let attributeKey = '';
      switch (e.element.getElementsByTagName('input')[0].getAttribute('name')) {
        case this.$t('Configuration.ObservationsForms.category'):
          attributeKey = 'category';
          break;
        case this.$t('Configuration.ObservationsForms.formName'):
          attributeKey = 'subcategory';
          break;
        default:
          break;
      }
      this.updateCurrentObservationTemplate({ attributeKey, value: e.value });
    },
    ...mapActions('Observations', ['updateCurrentObservationTemplate']),
  },
};
</script>
<style>
@import './HeaderTemplateForm.styles.scss';
</style>
