<template>
    <div class="image-container">
      <img v-if="imageSrc" :src="imageSrc" :style="{ height: imageHeight, width: imageWidth }" :alt="category" />
      <div v-else class="image-container__noimage"></div>
      <div class="image-container__overlay">
        <div>{{ $t("popupSelectObservationsReport.Date") }} : {{ date }}</div>
        <div>{{ category }}</div>
        <div>{{ subcategory }}</div>
      </div>
    </div>
  </template>
  
<script>
export default {
  name: 'ImageText',
  props: {
    imageSrc: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    subcategory: {
      type: String,
      default: '',
    },
    imageHeight: {
      type: String,
      default: '150px',
    },
    imageWidth: {
      type: String,
      default: '175px',
    },
  },
};
</script>
  
<style lang="scss" scoped>
.image-container {
  height: 100%;
  margin: 10px 5px 10px 5px; 
  text-align: left;
  img {
    height: 100%;
    width: 100%;
  }
  &__noimage {
    background-color: #d8d8d8;
    height: 150px;
    width: 175px;
  }
  &__overlay {
    background-color: rgb(255, 255, 255);
    bottom: 0;
    color: black;
    font-size: 12px;
    font-weight: 600;
    opacity: 0.7;
    padding: 5px 0px 16px 5px;
    position: absolute;
    width: 100%;
  }
}
</style>