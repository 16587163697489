import moment from 'moment';
import i18n from '../../js/i18n.config';
import ProductDataFormatter from '../../pages/wizardNutrition/report-formatter/product-data-formatter';
import Api from '../../services/Api';
import { getTimeValues } from '../../js/utils/timeValuesUtils';

function generateTemporalFrame(period) {
  // Copied from: src/components/wizardNutrition/stepper/stepper.js
  if (period === 'MENSUAL') {
    return { id: 0, label: '', value: 'MENSUAL' };
  }
  if (period === 'SEMANAL') {
    return { id: 1, label: '', value: 'SEMANAL' };
  }

  throw new Error(
    `Unexpected period on nutritioStepper.generateTemporalFrame: ${period}`,
  );
}

const state = {
  stepIndex: 0,
  nutritionSteps: [
    {
      label: 'Paso 1',
      status: 1,
      complete: false,
      page: '/utilities/nutrition/nutrition-step-one',
    },
    {
      label: 'Paso 2',
      status: 0,
      complete: false,
      page: '/utilities/nutrition/nutrition-step-two',
    },
    {
      label: 'Paso 3',
      status: 0,
      complete: false,
      page: '/utilities/nutrition/nutrition-step-three',
    },
    {
      label: 'Paso 4',
      status: 0,
      complete: false,
      page: '/utilities/nutrition/nutrition-step-five',
    },
  ],
  currentStep: {},
  stepData: {
    referenceValues: [],
    nutritionalNeedsReference: {
      n: 0,
      p: 0,
      k: 0,
      c: 0,
      m: 0,
      s: 0,
      production: 0,
    },
    nutritionalNeeds: [],
    nutritionalId: '',
    nutritionalBalanceData: [],
    timeValues: [],
    timeValuesIrrigation: [],
    dosages: [],
    dosageProduct: [],
    dosageProductBalance: [],
    productResume: [],
    irrigationTable: [],
    ph: 0,
  },
  enableWatchers: true,
  productsNotDeleted: false,
  isEditing: false,
  editingStepData: {
    initDate: '',
    endDate: '',
    timeValues: [],
    timeValuesIrrigation: [],
  },
  isCopying: false,
  copyingStepData: {
    initDate: '',
    endDate: '',
    timeValues: [],
    timeValuesIrrigation: [],
  },
  loadedReferenceValues: false,
  isNew: true,
};

// to handle state
const getters = {
  nutritionSteps: (stateL) => stateL.nutritionSteps,
  stepData: (stateL) => stateL.stepData,
  currentStep: (stateL) => stateL.currentStep,
  stepIndex: (stateL) => stateL.stepIndex,
};

// to handle actions
const actions = {
  setIsNew({ commit }, value) {
    commit('SET_IS_NEW', value);
  },
  nutritionSteps({ commit }) {
    commit('SET_NUTRITION_STEPS', state.nutritionSteps);
  },
  getCurrentStep({ commit }) {
    commit('SET_CURRENT_STEP', state.currentStep);
  },
  getStepIndex({ commit }) {
    commit('SET_STEP_INDEX', state.stepIndex);
  },
  findSelectedIndex(context) {
    for (let i = 0; context.state.nutritionSteps; i += 1) {
      if (context.state.nutritionSteps[i].status === 0) {
        if (i === 0) {
          return 0;
        }
        return i - 1;
      }
    }
    return 0;
  },
  updateEnableWatchers({ commit }, value) {
    commit('SET_ENABLE_WATCHERS', value);
  },
  updateClasses(context) {
    context.state.nutritionSteps.forEach((step, index) => {
      switch (step.status) {
        case 1:
          step.statusClass = 'dot-activate';
          break;
        default:
        case 0:
          step.statusClass = '';
          break;
      }
      if (index < context.state.stepIndex) {
        step.statusClass += ' dot-complete';
      }
    });
    context.state.nutritionSteps[context.state.stepIndex].statusClass
      += ' dot-current';
  },
  next(context) {
    context.state.stepIndex += 1;

    if (context.state.stepIndex >= context.state.nutritionSteps.length) {
      context.state.stepIndex = context.state.nutritionSteps.length - 1;
    }
    context.state.nutritionSteps[context.state.stepIndex].status = 1;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.nutritionSteps[context.state.stepIndex];
  },
  back(context) {
    context.state.stepIndex -= 1;
    if (context.state.stepIndex < 0) {
      context.state.stepIndex = 0;
    }
    context.state.nutritionSteps[context.state.stepIndex + 1].status = 0;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.nutritionSteps[context.state.stepIndex];
  },
  setStep(context, stepIndex) {
    context.state.stepIndex = stepIndex;
    context.state.nutritionSteps.forEach((step) => {
      step.status = 0;
    });

    context.state.nutritionSteps.forEach((step, index) => {
      if (index <= stepIndex) {
        step.status = 1;
      }
    });
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.nutritionSteps[context.state.stepIndex];
  },
  setStepDataTemporalFrame({ commit }, temporalFrame) {
    commit('SET_STEP_TEMPORAL_FRAME', temporalFrame);
  },
  resetWizard({ commit }) {
    const newStep = {
      endDate: undefined,
      initDate: undefined,
      referenceValues: [],
      nutritionalNeedsReference: {
        n: 0,
        p: 0,
        k: 0,
        c: 0,
        m: 0,
        s: 0,
        production: 0,
      },
      nutritionalNeeds: [],
      nutritionalId: '',
      nutritionalBalanceData: [],
      timeValues: [],
      timeValuesIrrigation: [],
      dosages: [],
      dosageProduct: [],
      dosageProductBalance: [],
      productResume: [],
      irrigationTable: [],
      observation: '',
      ph: 0,
    };

    commit('SET_STEPS_DATA', { ...newStep });
    commit('SET_LOADED_REFERENCE_VALUES', false);
  },
  async updateWizardData({ commit, dispatch }, stepperData) {
    dispatch('Nutrition/setProductsNotInNutritionPlan', [], { root: true });
    const timeValuesTmp = stepperData.contribution_products.map((product) => {
      const { timeValues } = getTimeValues(
        moment(stepperData.init_date, 'YYYY/MM/DD'),
        moment(stepperData.end_date, 'YYYY/MM/DD'),
        stepperData.period,
      );
        
      let counter = 0;
      const periodsObject = {};
    
      // Recorremos las claves del primer objeto en timeValues
      const periodNames = Object.keys(timeValues[0]);

      for (const periodName of periodNames) {
        // Mapeamos cada nombre de período al valor correspondiente en product.periods
        periodsObject[periodName] = product.periods[counter] ?? 0;
        counter++;
      }
      return periodsObject;
    });

    const dosages = timeValuesTmp.map(() => ({ dosages: 0 }));

    // Datos para la tabla de Riego
    let dataSource = [];

    // Solo entramos si tenemos tabla riego y periods_irrigation es distinto de null
    if (stepperData.periods_irrigation !== null) {
      const contributionProducts = stepperData.contribution_products;
      const periodsIrrigation = stepperData.periods_irrigation;
      const periodsIrrigationTime = stepperData.periods_irrigation_time;
      const period = stepperData.period;

      const { timeValues } = getTimeValues(
        moment(stepperData.init_date, 'YYYY/MM/DD'),
        moment(stepperData.end_date, 'YYYY/MM/DD'),
        period,
      );

      // Incorporar los resultados de Object.entries(timeValues[0]) a cada objeto de dataSource
      const timeValuesIrrigation = Object.entries(timeValues[0]).map(([name, _value]) => {
        return { week: name };
      });

      const periodData = timeValuesIrrigation;

      // Generar columnas para los nombres de productos
      contributionProducts.forEach((product, index) => {
        // Asignar valores a las celdas correspondientes
        const periodsProductIrrigation = product.periods_product_irrigation;
        const periodsDose = product.periods_dose;

        periodsProductIrrigation.forEach((value, i) => {
          // Asegurarse de que la fila exista en dataSource
          if (!dataSource[i]) {
            dataSource[i] = {};
          }

          // Asignar valores a las columnas de NºRiegos y l/Riego
          dataSource[i][`periods_product_irrigation_${index}`] = periodsProductIrrigation[i];
          dataSource[i][`periods_dose_${index}`] = periodsDose[i];

          // Agregar los nuevos parámetros caption y name
          dataSource[i][`caption_${index}`] = product.presentation === 'LIQUIDO' ? 'l/Riego' : product.presentation === 'SOLIDO' ? 'kg/Riego' : 'l/Riego';
          dataSource[i][`code_${index}`] = product.product_code;
    

          // Agregar valores de periodData al objeto de datos
          const period = periodData[i];
          Object.keys(period).forEach((key) => {
            const newDataField = period[key];
            dataSource[i][key] = newDataField;
          });
        });
      });

      // Agregar valores de periods_irrigation y periods_irrigation_time a dataSource
      periodsIrrigation.forEach((value, i) => {
        // Asegurarse de que la fila exista en dataSource
        if (!dataSource[i]) {
          dataSource[i] = {};
        }

        // Asignar valores a periods_irrigation y periods_irrigation_time
        dataSource[i]['periods_irrigation'] = value;
        dataSource[i]['periods_irrigation_time'] = periodsIrrigationTime[i];
      });
    }

    const formatter = new ProductDataFormatter(stepperData);
    try {
      await formatter.buildProductResumeData();
    } catch (e) {
      const notFoundNutritionProducts = JSON.parse(e.message);
      dispatch(
        'Nutrition/setProductsNotInNutritionPlan',
        notFoundNutritionProducts,
        { root: true },
      );
    }

    let nutritionalNeeds = {
      n: stepperData.nutritional_needs_reference.n,
      p: stepperData.nutritional_needs_reference.p205,
      k: stepperData.nutritional_needs_reference.k20,
      c: stepperData.nutritional_needs_reference.ca0,
      m: stepperData.nutritional_needs_reference.mg0,
      s: stepperData.nutritional_needs_reference.s03,
      production:
        stepperData.nutritional_needs_reference.expected_production,
    };

    // Is an old plan
    if (stepperData.version === '2.1.8') {
      const { production } = stepperData.nutritional_needs;

      // calculate nutritional needs for 1 of production
      nutritionalNeeds = {
        n: stepperData.nutritional_needs.n / production,
        p: stepperData.nutritional_needs.p205 / production,
        k: stepperData.nutritional_needs.k20 / production,
        c: stepperData.nutritional_needs.ca0 / production,
        m: stepperData.nutritional_needs.mg0 / production,
        s: stepperData.nutritional_needs.s03 / production,
        production: 1,
      };
    }

    formatter.productResume.pop();
    const editStepData = {
      version: stepperData.version,
      expectedProduction: stepperData.nutritional_needs.production,
      ph: stepperData?.ph,
      referenceValues: [
        {
          production: stepperData.reference_values.production,
          n: stepperData.reference_values.n,
          p: stepperData.reference_values.p205,
          k: stepperData.reference_values.k20,
          c: stepperData.reference_values.ca0,
          m: stepperData.reference_values.mg0,
          s: stepperData.reference_values.s03,
        },
      ],
      nutritionalNeedsReference: {
        n: stepperData.nutritional_needs_reference.n,
        p: stepperData.nutritional_needs_reference.p205,
        k: stepperData.nutritional_needs_reference.k20,
        c: stepperData.nutritional_needs_reference.ca0,
        m: stepperData.nutritional_needs_reference.mg0,
        s: stepperData.nutritional_needs_reference.s03,
        production: stepperData.nutritional_needs_reference.expected_production,
      },
      nutritionalNeeds: [
        // {
        //   n: stepperData.nutritional_needs_reference.n,
        //   p: stepperData.nutritional_needs_reference.p205,
        //   k: stepperData.nutritional_needs_reference.k20,
        //   c: stepperData.nutritional_needs_reference.ca0,
        //   m: stepperData.nutritional_needs_reference.mg0,
        //   s: stepperData.nutritional_needs_reference.s03,
        //   production:
        //     stepperData.nutritional_needs_reference.expected_production,
        // },
        nutritionalNeeds,
        {
          n: stepperData.nutritional_needs.n,
          p: stepperData.nutritional_needs.p205,
          k: stepperData.nutritional_needs.k20,
          c: stepperData.nutritional_needs.ca0,
          m: stepperData.nutritional_needs.mg0,
          s: stepperData.nutritional_needs.s03,
          production: stepperData.nutritional_needs.production,
        },
      ],
      nutritionalId: stepperData.id,
      planDescription: stepperData.description,
      observation: stepperData.observation,
      initDate: moment(stepperData.init_date, 'YYYY/MM/DD'),
      endDate: moment(stepperData.end_date, 'YYYY/MM/DD'),
      temporalFrame: generateTemporalFrame(stepperData.period),

      nutritionalBalanceData: [
        {
          uf: i18n.t('nutritionalBalance.necNutritional'),
          n: stepperData.nutritional_needs.n,
          p: stepperData.nutritional_needs.p205,
          k: stepperData.nutritional_needs.k20,
          c: stepperData.nutritional_needs.ca0,
          m: stepperData.nutritional_needs.mg0,
          s: stepperData.nutritional_needs.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.availableGround'),
          n: stepperData.uf_available_soil.n,
          p: stepperData.uf_available_soil.p205,
          k: stepperData.uf_available_soil.k20,
          c: stepperData.uf_available_soil.ca0,
          m: stepperData.uf_available_soil.mg0,
          s: stepperData.uf_available_soil.s03,
        },

        {
          uf: i18n.t('nutritionalBalance.irrigationWater'),
          n: stepperData.uf_irrigation_water.n,
          p: stepperData.uf_irrigation_water.p205,
          k: stepperData.uf_irrigation_water.k20,
          c: stepperData.uf_irrigation_water.ca0,
          m: stepperData.uf_irrigation_water.mg0,
          s: stepperData.uf_irrigation_water.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.harvestScraps'),
          n: stepperData.uf_rest_harvest.n,
          p: stepperData.uf_rest_harvest.p205,
          k: stepperData.uf_rest_harvest.k20,
          c: stepperData.uf_rest_harvest.ca0,
          m: stepperData.uf_rest_harvest.mg0,
          s: stepperData.uf_rest_harvest.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.availableTotal'),
          n:
            stepperData.uf_available_soil.n
            + stepperData.uf_irrigation_water.n
            + stepperData.uf_rest_harvest.n,
          p:
            stepperData.uf_available_soil.p205
            + stepperData.uf_irrigation_water.p205
            + stepperData.uf_rest_harvest.p205,
          k:
            stepperData.uf_available_soil.k20
            + stepperData.uf_irrigation_water.k20
            + stepperData.uf_rest_harvest.k20,
          c:
            stepperData.uf_available_soil.ca0
            + stepperData.uf_irrigation_water.ca0
            + stepperData.uf_rest_harvest.ca0,
          m:
            stepperData.uf_available_soil.mg0
            + stepperData.uf_irrigation_water.mg0
            + stepperData.uf_rest_harvest.mg0,
          s:
            stepperData.uf_available_soil.s03
            + stepperData.uf_irrigation_water.s03
            + stepperData.uf_rest_harvest.s03,
        },
        {
          uf: i18n.t('nutritionalBalance.advanceFertilization'),
          n:
            stepperData.uf_available_soil.n
            + stepperData.uf_irrigation_water.n
            + stepperData.uf_rest_harvest.n
            - stepperData.nutritional_needs.n,
          p:
            stepperData.uf_available_soil.p205
            + stepperData.uf_irrigation_water.p205
            + stepperData.uf_rest_harvest.p205
            - stepperData.nutritional_needs.p205,
          k:
            stepperData.uf_available_soil.k20
            + stepperData.uf_irrigation_water.k20
            + stepperData.uf_rest_harvest.k20
            - stepperData.nutritional_needs.k20,
          c:
            stepperData.uf_available_soil.ca0
            + stepperData.uf_irrigation_water.ca0
            + stepperData.uf_rest_harvest.ca0
            - stepperData.nutritional_needs.ca0,
          m:
            stepperData.uf_available_soil.mg0
            + stepperData.uf_irrigation_water.mg0
            + stepperData.uf_rest_harvest.mg0
            - stepperData.nutritional_needs.mg0,
          s:
            stepperData.uf_available_soil.s03
            + stepperData.uf_irrigation_water.s03
            + stepperData.uf_rest_harvest.s03
            - stepperData.nutritional_needs.s03,
        },
      ],
      timeValues: timeValuesTmp,
      dosages,
      irrigationTable: dataSource,
      dosageProduct: await Promise.all(
        stepperData.contribution_products.map(async (product) => {
          const hasReduction = product.product_reduction_percentage > 0;
          let eficiencies = {
            n: 100,
            p: 100,
            k: 100,
            c: 100,
            m: 100,
            s: 100,
          };

          if (!hasReduction) {
            eficiencies = await Api.getProductEfficiency({
              code: product?.product_code,
              tipology: product?.product_tipology,
              ph: stepperData?.ph || 0,
            })
              .then((xhr) => {
                const data = JSON.parse(xhr.response);

                return {
                  n: data.NT,
                  p: data.P2015_Total,
                  k: data.K2O,
                  c: data.CaO,
                  m: data.MgO,
                  s: data.SO3,
                };
              })
              .catch(() => ({
                n: 0,
                p: 0,
                k: 0,
                c: 100,
                m: 100,
                s: 100,
              }));
          }

          return {
            c: product.ca0,
            id: product.product,
            k: product.k20,
            m: product.mg0,
            n: product.n,
            name: product.product_name,
            p: product.p205,
            s: product.s03,
            price: product.price,
            priceKg: product.priceKg,
            priceL: product.priceL,
            totalCost: product.total_cost,
            caption: product.presentation === 'LIQUIDO' ? 'l/Riego' : product.presentation === 'SOLIDO' ? 'kg/Riego' : 'l/Riego',
            unitCost: product.unit_cost,
            observation: product.observation,
            // product_deleted: !productService.status,
            presentation: product.presentation,
            density: product.density,
            code: product.product_code,
            tipology: product.product_tipology,
            product_deleted: !product.product_status,
            reduction_percentage: product?.product_reduction_percentage || 0,
            eficiencies,
          };
        }),
      ),
      dosageProductBalance: [
        {
          uf: i18n.t('nutritionalBalance.advanceFertilization'),
          n:
            stepperData.uf_available_soil.n
            + stepperData.uf_irrigation_water.n
            + stepperData.uf_rest_harvest.n
            - stepperData.nutritional_needs.n,
          p:
            stepperData.uf_available_soil.p205
            + stepperData.uf_irrigation_water.p205
            + stepperData.uf_rest_harvest.p205
            - stepperData.nutritional_needs.p205,
          k:
            stepperData.uf_available_soil.k20
            + stepperData.uf_irrigation_water.k20
            + stepperData.uf_rest_harvest.k20
            - stepperData.nutritional_needs.k20,
          c:
            stepperData.uf_available_soil.ca0
            + stepperData.uf_irrigation_water.ca0
            + stepperData.uf_rest_harvest.ca0
            - stepperData.nutritional_needs.ca0,
          m:
            stepperData.uf_available_soil.mg0
            + stepperData.uf_irrigation_water.mg0
            + stepperData.uf_rest_harvest.mg0
            - stepperData.nutritional_needs.mg0,
          s:
            stepperData.uf_available_soil.s03
            + stepperData.uf_irrigation_water.s03
            + stepperData.uf_rest_harvest.s03
            - stepperData.nutritional_needs.s03,
        },
        {
          uf: 'UF teóricas',
          n: stepperData?.uf_teoric?.n || 0,
          p: stepperData?.uf_teoric?.p205 || 0,
          k: stepperData?.uf_teoric?.k20 || 0,
          c: stepperData?.uf_teoric?.ca0 || 0,
          m: stepperData?.uf_teoric?.mg0 || 0,
          s: stepperData?.uf_teoric?.s03 || 0,
        },
        {
          uf: 'UF eficientes aportadas',
          n: stepperData?.uf_efficiency_provided?.n || 0,
          p: stepperData?.uf_efficiency_provided?.p205 || 0,
          k: stepperData?.uf_efficiency_provided?.k20 || 0,
          c: stepperData?.uf_efficiency_provided?.ca0 || 0,
          m: stepperData?.uf_efficiency_provided?.mg0 || 0,
          s: stepperData?.uf_efficiency_provided?.s03 || 0,
        },
        {
          uf: i18n.t('dosageProduct.finalBalance'),
          n: stepperData?.final_balance?.n || 0,
          p: stepperData?.final_balance?.p205 || 0,
          k: stepperData?.final_balance?.k20 || 0,
          c: stepperData?.final_balance?.ca0 || 0,
          m: stepperData?.final_balance?.mg0 || 0,
          s: stepperData?.final_balance?.s03 || 0,
        },
      ],
      productResume: formatter.productResume,
    };

    // Este codigo se puede volver a reutilizar en el caso de que se quieran eliminar las dosificaciones al editar
    /**    if (rootState.Nutrition.productsNotInNutritionPlan.length > 0) {
      editStepData.dosageProduct.forEach((dosage, index) => {
        if (!dosage) {
          editStepData.dosages.splice(index, 1);
          editStepData.timeValues.splice(index, 1);
          editStepData.dosageProduct.splice(index, 1);
        }
      });
    } */
    commit('SET_STEPS_DATA', { ...editStepData });

    //   contribution_products: this.stepData.dosageProduct.map((product, index) => {
    //     product.product = product.id;
    //     product.p205 = product.p;
    //     product.k20 = product.k;
    //     product.ca0 = product.c;
    //     product.mg0 = product.m;
    //     product.s03 = product.s;
    //     product.periods = [];
    //     for (const prop in this.stepData.timeValues[index]) {
    //       product.periods.push(this.stepData.timeValues[index][prop]);
    //     }
    //     product.total_cost = this.stepData.productResume[index].costTotal;
    //     product.unit_cost = this.stepData.productResume[index].costUnit;
    //     product.price = this.stepData.productResume[index].price;
    //     return product;
    //   }),
    // }
  },
  setStepDataInitDate({ commit }, initDate) {
    commit('SET_STEP_INIT_DATE', initDate);
  },
  setStepDataEndDate({ commit }, endDate) {
    commit('SET_STEP_END_DATE', endDate);
  },
  setProductsNotDeleted({ commit }, value) {
    commit('setProductsNotDeleted', value);
  },
  setObservation({ commit }, value) {
    commit('SET_OBSERVATION', value);
  },
  setIsEditing({ commit }, value) {
    commit('SET_IS_EDITING', value);
  },
  setEditingStepData({ commit }, data = null) {
    if (data !== null) {
      commit('SET_EDITING_STEP_DATA', data);
    } else {
      commit('SET_EDITING_STEP_DATA', {
        initDate: '',
        endDate: '',
        timeValues: [],
        timeValuesIrrigation: [],
      });
    }
  },
  setIsCopying({ commit }, value) {
    commit('SET_IS_COPYING', value);
  },
  setCopyingStepData({ commit }, data = null) {
    if (data !== null) {
      commit('SET_COPYING_STEP_DATA', data);
    } else {
      commit('SET_COPYING_STEP_DATA', {
        initDate: '',
        endDate: '',
        timeValues: [],
        timeValuesIrrigation: [],
      });
    }
  },
  setLoadedReferenceValues({ commit }, value) {
    commit('SET_LOADED_REFERENCE_VALUES', value);
  },
  resetTimeValuesIrrigation({ commit }) {
    commit('RESET_TIME_VALUES_IRRIGATION');
  },
};

// to handle mutations
const mutations = {
  SET_IS_NEW(stateL, isNew) {
    stateL.isNew = isNew;
  },
  SET_NUTRITION_STEPS(stateL, nutritionSteps) {
    stateL.nutritionSteps = nutritionSteps;
  },
  SET_CURRENT_STEP(stateL, currentStep) {
    stateL.currentStep = currentStep;
  },
  SET_STEP_INDEX(stateL, stepIndex) {
    stateL.stepIndex = stepIndex;
  },
  SET_STEPS_DATA(stateL, stepData) {
    stateL.stepData = stepData;
  },
  SET_ENABLE_WATCHERS(stateL, enableWatchers) {
    stateL.enableWatchers = enableWatchers;
  },
  SET_STEP_TEMPORAL_FRAME(stateL, temporalFrame) {
    stateL.stepData.temporalFrame = temporalFrame;
  },
  SET_STEP_INIT_DATE(stateL, initDate) {
    stateL.stepData.initDate = initDate;
  },
  SET_STEP_END_DATE(stateL, endDate) {
    stateL.stepData.endDate = endDate;
  },
  setProductsNotDeleted(stateL, value) {
    stateL.productsNotDeleted = value;
  },
  SET_OBSERVATION(stateL, value) {
    stateL.stepData.observation = value;
  },
  SET_IS_EDITING(stateL, value) {
    stateL.isEditing = value;
  },
  SET_EDITING_STEP_DATA(stateL, data) {
    stateL.editingStepData = data;
  },
  SET_IS_COPYING(stateL, value) {
    stateL.isCopying = value;
  },
  SET_COPYING_STEP_DATA(stateL, data) {
    stateL.copyingStepData = data;
  },
  SET_LOADED_REFERENCE_VALUES(stateL, value) {
    stateL.loadedReferenceValues = value;
  },
  RESET_TIME_VALUES_IRRIGATION(state) {
    state.stepData.timeValuesIrrigation = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
