<template>
  <div class="vat-forms margin-bottom">
    <f7-row class="align-items-center">
      <f7-col width="5" />
      <f7-col width="25">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatVolume') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              v-model="treatmentPlan.vat_volume"
              :read-only="readOnly"
              @value-changed="vatVolumeValueChanged"
            />
          </div>
          <div class="margin-right margin-left">
            L
          </div>
        </div>
      </f7-col>

      <f7-col width="20">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumberHa') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              :value="getNumberVatsHa"
              :read-only="true"
            />
          </div>
        </div>
      </f7-col>

      <f7-col width="20">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.vatNumber') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              :value="period.vats_number"
              :read-only="readOnly"
              @value-changed="vatsNumberValueChanged"
            />
          </div>
        </div>
      </f7-col>

      <f7-col width="30">
        <div class="dx-field">
          <div class="dx-field-label">
            <span>{{ $t('treatment.treatmentPlan.treatmentCreatePlan.stepTwo.speed') }}</span>
          </div>
          <div class="dx-field-value">
            <DxNumberBox
              v-model="treatmentPlan.forward_speed"
              :read-only="readOnly"
            />
          </div>
          <div class="margin-right margin-left">
            km/h
          </div>
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState } from 'vuex';

export default {
  name: 'TreatmentStepTwoFormVat',
  components: {
    DxSelectBox,
    DxTextBox,
    DxNumberBox,
  },
  props: {
    period: {
      type: Object,
      default: () => {},
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    selectedIndex: Number,
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('treatmentStepper', ['treatmentPlan']),
    getNumberVatsHa() {
      const broth_volume = this.period.products[0]?.broth_volume ?? 0;

      return this.calculateFormula(1, {
        broth_volume,
        vat_volume: this.treatmentPlan.vat_volume,
      });
    },
    getNumberVats() {
      const number_vats_ha = this.getNumberVatsHa;

      return this.calculateFormula(2, {
        number_vats_ha,
        area: this.currentPlantation.area,
      });
    },
  },
  methods: {
    vatsNumberValueChanged(e) {
      const { value } = e;

      this.period.products.map((item) => {
        item.broth_volume = this.calculateFormula(3, {
          number_vats: value,
          area: this.currentPlantation.area,
        });
        return item;
      });

      this.$emit('update-vats-number', { value, index: this.selectedIndex });
    },
    vatVolumeValueChanged(e) {
      this.period.vats_number = this.calculateFormula(2, {
        number_vats_ha: this.getNumberVatsHa,
        area: this.currentPlantation.area,
      });

      this.period.products.map((item) => {
        item.broth_volume = this.calculateFormula(3, {
          number_vats: this.period.vats_number,
          area: this.currentPlantation.area,
        });
        return item;
      });
    },
    calculateFormula(type, data) {
      let result = 0;

      // Nº de cubas/ha = Volumen de caldo (L/ha) / Volumen de cuba (L)
      if (type === 1) {
        result = data.broth_volume / data.vat_volume;
      }

      // Nº de cubas = Nº de cubas/ha * Superficie (ha)
      if (type === 2) {
        result = data.number_vats_ha * data.area;
      }

      // Volumen de caldo (L/ha) = Nº de cubas (cubas/ha) * Volumen de cuba (L)
      if (type === 3) {
        result = parseFloat((data.number_vats / data.area).toFixed(2)) * this.treatmentPlan.vat_volume;
      }

      const fixedResult = Number(result.toFixed(2));

      if (isNaN(fixedResult) || !isFinite(fixedResult)) {
        return 0;
      }
      return fixedResult;
    },
  },
};
</script>

<style src="./TreatmentStepTwoForms.styles.scss" scoped lang="scss"></style>
