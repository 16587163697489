<template>
  <section class="product-resume">
    <div class="content-devx">
      <div class="card-header">
        {{ $t('productResume.productResume') }}
      </div>
      <div class="card-body">
        <section class="area">
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t('productResume.area') }}
            </div>
            <div class="dx-field-value-static">
              <DxNumberBox
                :read-only="true"
                :value="currentPlantation.area"
                format="#0.00"
              />
            </div>
          </div>
        </section>
        <section class="bottom">
          <DxDataGrid
            class="data-grid-product-resume"
            :data-source="treatmentPlan.productResume"
            :word-wrap-enabled="true"
            :show-borders="true"
          >
            <DxEditing
              :allow-updating="true"
              mode="cell"
            />
            <DxColumn
              data-field="productName"
              :caption="$t('productResume.product')"
              :allow-sorting="false"
              alignment="center"
              :allow-editing="false"
            />
            <DxColumn
              data-field="dosage"
              data-type="number"
              :caption="$t('productResume.dosage')"
              :allow-sorting="false"
              alignment="center"
              :allow-editing="false"
              :calculate-display-value="formatNumberDosage"
            />
            <DxColumn
              data-field="caldoMedium"
              data-type="number"
              alignment="center"
              :caption="$t('productResume.caldoMedium')"
              :allow-sorting="false"
              :allow-editing="false"
            />
            <DxColumn
              :caption="$t('productResume.dosageTotalKg')"
              data-field="totalDosage"
              data-type="number"
              alignment="center"
              :allow-sorting="false"
              :allow-editing="false"
              :calculate-display-value="formatNumberTotalDosKG"
            />

            <DxColumn
              :caption="$t('productResume.dosageTotalKgL')"
              data-type="number"
              alignment="center"
              :allow-sorting="false"
              :allow-editing="false"
              :calculate-display-value="calculateDosageTotalKGL"
            />
            <DxColumn
              :caption="$t('productResume.price')"
              data-field="price"
              alignment="center"
              data-type="number"
              :allow-sorting="false"
            />

            <DxColumn
              :caption="$t('productResume.costUnit')"
              data-field="unitCost"
              data-type="number"
              alignment="center"
              :allow-sorting="false"
              :allow-editing="false"
              :calculate-display-value="formatNumberUnitCost"
            />
            <DxColumn
              :caption="$t('productResume.costTotal')"
              data-field="totalCost"
              data-type="number"
              alignment="center"
              :allow-sorting="false"
              :allow-editing="false"
              :calculate-display-value="formatNumberTotalCost"
            />

            <DxSummary css-class="summary-table">
              <DxTotalItem
                column="unitCost"
                summary-type="sum"
                :customize-text="customizeTotal"
              />
              <DxTotalItem
                column="totalCost"
                summary-type="sum"
                :customize-text="customizeTotal"
              />
            </DxSummary>
          </DxDataGrid>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxColumn, DxDataGrid, DxEditing,
  DxPaging, DxScrolling, DxSorting,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';
import DxButton from 'devextreme-vue/button';
import DxSelectBox from 'devextreme-vue/select-box';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState } from 'vuex';

export default {
  name: 'ProductResume',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxSelectBox,
    DxSorting,
    DxScrolling,
    DxPaging,
    DxEditing,
    DxNumberBox,
    DxSummary,
    DxTotalItem,
  },
  props: [],
  computed: {
    ...mapState('treatmentStepper', ['treatmentPlan']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  watch: {
    'treatmentPlan.productResume': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalCost(val);
      },
      deep: true,
    },
  },

  beforeMount() {
    const doseTypeIsHA = this.treatmentPlan.dose_type.toUpperCase() === 'KG-L/HA';

    this.treatmentPlan.productResume = [];
    this.treatmentPlan.products.forEach((item, index) => {
      let caldoMedium = this.treatmentPlan.products[index].dose
        ? (parseFloat(this.treatmentPlan.products[index].dose.max)
        + parseFloat(this.treatmentPlan.products[index].dose.min)) / 2
        : 1000;

      // A veces viene 'dosage' y otras 'dosages' por alguna razón.
      let { dosage, dosages } = this.treatmentPlan.dosages[index];

      // Se escoge la que venga o un 0.
      dosage = dosage || dosages || 0;
      let totalDosage = 0;
      if (doseTypeIsHA) {
        totalDosage = dosage;
        dosage = parseFloat((totalDosage * 1000) / caldoMedium).toFixed(2);
        totalDosage = parseFloat(totalDosage).toFixed(2);
      } else {
        totalDosage = parseFloat((dosage * caldoMedium) / 1000).toFixed(2);
        dosage = parseFloat(dosage).toFixed(2);
      }
      caldoMedium = parseFloat(caldoMedium).toFixed(2);
      this.treatmentPlan.productResume.push({
        productName: item.name,
        dosage,
        caldoMedium,
        totalApplications: this.treatmentPlan.totalApplications,
        totalDosage,
        // Si no viene el precio, se pone un 0.
        price: item.price || 0,
        unitCost: 0,
        totalCost: 0,
        dosage_range: item.dosage_range,
      });
    });
  },
  methods: {
    customizeTotal(total) {
      return parseFloat(total.value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatNumberDosage(row) {
      return this.formatNumber(row.dosage);
    },
    formatNumberUnitCost(row) {
      return this.formatNumber(row.unitCost);
    },
    formatNumberTotalCost(row) {
      return this.formatNumber(row.totalCost);
    },
    formatNumberTotalDosKG(row) {
      return this.formatNumber(row.totalDosage);
    },
    formatNumber(number) {
      return parseFloat(number).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    calculateDosageTotalKGL(row) {
      return parseFloat(row.totalDosage * this.currentPlantation.area).toFixed(2);
    },
    calcTotalCost(element) {
      element.forEach((item) => {
        item.unitCost = parseFloat(item.totalDosage * item.price).toFixed(2);
        item.totalCost = parseFloat(item.unitCost * this.currentPlantation.area).toFixed(2);
      });
    },
  },
};

</script>
<style src="./productResume.scss" scoped lang="scss"></style>
