import Request from './Request';
import Api from './Api';

const plantationService = {
  async getById(id, company) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/plantation/${id}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      company);
    return JSON.parse(xhr.response);
  },
};

export default plantationService;

export { plantationService };
