import Api from './Api';
import db from './DexieDatabase';
import EventBus from '../js/event-bus';

export default {
  /**
   * Descarga el listado completo de registros a la BBDD de dexie
   */
  downloadNewData() {
    return Promise.all([
      Api.getAllRecordsToSync('sync/product?owner=Timac'),
      Api.getAllRecordsToSync('sync/vademecum?owner=Timac'),
      Api.getAllRecordsToSync('sync/ancestors'),
      // Api.getAllRecordsToSync('sync/analysis'),
      // Api.getAllRecordsToSync('sync/fertilization'),
      // Api.getAllRecordsToSync('sync/plantation'),
      // Api.getAllRecordsToSync('sync/treatment_order'),
      // Api.getAllRecordsToSync('sync/treatment_plan'),
    ])
      .then(async (values) => {
        if (values[0] !== 0) {
      
          db.product.bulkPut(values[0]);
          db.vademecum.bulkPut(values[1]);
          db.ancestors.bulkPut(values[2]);
          // db.plantation.bulkPut(values[4]);

        }
        return values;
      }).catch((e) => {
        // Other error such as a string was thrown
        console.error(e);
      });
  },

  /**
   * Descarga el listado de tablas seleccionadas de registros a la BBDD de dexie
   */

  async refreshDexieData() {
    try {
      const tablesToSync = [
        { apiEndpoint: 'sync/product?owner=Timac', dbTable: db.product },
        { apiEndpoint: 'sync/vademecum?owner=Timac', dbTable: db.vademecum },
        { apiEndpoint: 'sync/ancestors', dbTable: db.ancestors },
        // Si es necesario actualizar los demas se agregan aqui
        // Api.getAllRecordsToSync('sync/plantation'),
        // Api.getAllRecordsToSync('sync/analysis'),
        // Api.getAllRecordsToSync('sync/fertilization'),
        // Api.getAllRecordsToSync('sync/treatment_order'),
        // Api.getAllRecordsToSync('sync/treatment_plan'),
      ];
  
      await Promise.all(tablesToSync.map(async ({ apiEndpoint, dbTable }) => {
        const dataToSync = await Api.getAllRecordsToSync(apiEndpoint);
        if (dataToSync.length > 0) {
          await this.clearDatabaseTable(dbTable);
          await dbTable.bulkPut(dataToSync);
        }
      }));
  
      console.log('Database refreshed successfully');
    } catch (error) {
      console.error('Error refreshing database:', error);
    }
  },

  /**
   * Limpiamos la BBDD completa
   */
  // Funcion que utilizamos para limpiar tablas especificas
  async clearDatabaseTable(table) {
    await table.clear();
  },

  clearDatabase() {
    db.product.clear();
    db.vademecum.clear();
    db.plantation.clear();
    db.ancestors.clear();
  },

  /**
   * Descarga el listado completo de registros a la BBDD de dexie
   */
  async completeSyncService() {
    return this.downloadNewData().catch(
      (e) => {
        console.log('Error DEL FRONT:', e);
        const jsonError = JSON.stringify({
          error_code: e,
        });
        EventBus.$emit('UPDATE_SYNC_ERROR_TEXT', jsonError);
        return [0];
      },
    ).finally();
  },

  /**
   * Descarga el listado de tablas seleccionadas de registros a la BBDD de dexie
   */
  async updateDataBaseRecords() {
    return this.refreshDexieData().catch(
      (e) => {
        console.log('Error DEL FRONT:', e);
        const jsonError = JSON.stringify({
          error_code: e,
        });
        EventBus.$emit('UPDATE_SYNC_ERROR_TEXT', jsonError);
        return [0];
      },
    ).finally();
  },

};
