<template>
  <div
    class="content-devx no-padding-bottom"
  >
    <DxDataGrid
      id="VarietyDatabase-datagrid"
      :data-source="customStore"
      :remote-operations="true"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :on-editing-start="onEditingStart"
      @toolbar-preparing="onToolbarPreparing($event)"
      @RowRemoved="callToDeleteVariety"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="VarietyDatabase"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-deleting="true"
        :allow-updating="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxColumn
        data-field="group"
        :width="90"
        :caption="$t('group')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="specie"
        :caption="$t('specie')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="name"
        :caption="$t('variety')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="showDetail"
        />
        <DxButton
          name="edit"
          icon="edit"
          :on-click="editVariety"
        />
        <DxButton
          name="delete"
          icon="trash"
        />
      </DxColumn>
    </DxDataGrid>
    <VarietyPopup
      :groups-availables="groupsAvailables"
      :species-availables="speciesAvailables"
    />
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxButton,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import VarietyPopup from '../varietyPopup/index.vue';
import settings from '../../../../js/commonSettings';

export default {
  name: 'VarietyDatabase',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxEditing,
    DxButton,
    DxTexts,
    VarietyPopup,
  },
  props: {
    customStore: {
      type: Object,
      default: () => [],
    },
    groupsAvailables: {
      type: Array,
      default: () => [],
    },
    speciesAvailables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      isEdit: false,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    isEditing() {
      return this.isEdit === true ? this.$t('DxVariety_edit_Variety') : this.$t('DxVariety_register_Variety');
    },
    ...mapGetters('authentication', ['getPermissions']),
    ...mapState('Varieties', ['varieties']),
  },
  methods: {
    onHidden() {
      this.isEdit = false;
    },
    onEditingStart() {
      this.isEdit = true;
    },
    async showDetail(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.getVarietyDetail(rowSelected.row.data.id);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/viewVarietyDetail/', { reloadCurrent: true });
      }
    },
    async editVariety(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.getVarietyDetail(rowSelected.row.data.id);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.setShowPopup(true);
      }
    },
    async callToDeleteVariety(rowSelected) {
      this.$f7.preloader.show();
      try {
        await this.deleteVariety(rowSelected);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setShowPopup(true);
          },
        },
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('VarietyDatabase');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'VarietyDatabase.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Varieties', ['getVarieties', 'getVarietyDetail', 'createVariety', 'deleteVariety', 'setShowPopup']),
  },
};
</script>
<style lang="scss" scoped>
@import './VarietyDatabase.styles.scss';
</style>
