<template>
  <transition name="fade">
    <div class="report-notify-container dx-overlay dx-overlay-wrapper dx-toast-wrapper">
      <div class="report-notify-notification">
        <div class="spinner-notify-container">
          <div class="spinner-notify" />
        </div>
        <div class="notify-text">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ReportNotify',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.spinner-notify-container {
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
}

.spinner-notify {
  border: 3px solid rgba(133, 133, 133, 0.1);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border-left-color: #c3c4c7;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.report-notify-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.report-notify-notification {
  bottom: 10px;
  background: #337ab7;
  color: white;
  z-index: 999999;
  position: absolute;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  margin: 0 1rem;
  border-radius: 6px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.notify-text {
  font-weight: 600;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
