var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"margin-top-half"},[_c('div',{staticClass:"content-devx"},[_c('div',[_c('DxForm',[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_client'),"caption":_vm.$t('weather_client'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.allClients,
              displayExpr: 'name',
              valueExpr: 'id',
              placeholder: _vm.$t('weather_client_placeholder'),
              onValueChanged: _vm.onSelectedClient,
              searchEnabled:true
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_parameters'),"caption":_vm.$t('weather_parameters'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.parameters,
              placeholder: _vm.$t('registerClima_parameter_placeholder'),
              onValueChanged: _vm.onSelectedParameter,
              displayExpr: _vm.displayTranslatedParameter,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('dateRange'),"is-required":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('f7-input',{staticClass:"range-date-picker",staticStyle:{"width":"200px"},attrs:{"type":"datepicker","placeholder":_vm.$t('PlanningComponent_datepicker_multiple_placeholder'),"clear-button":true,"close-on-select":true,"calendar-params":{
                  closeOnSelect: true,
                  rangePicker: true,
                  dateFormat: {
                    month: 'numeric',
                    day: 'numeric',
                    year: 'numeric',
                  },
                }},on:{"calendar:change":_vm.setSearcherDates}})]},proxy:true}])}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('weather_plantation'),"caption":_vm.$t('weather_plantation'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.allPlantations,
              displayExpr: 'name',
              valueExpr: 'id',
              readOnly:_vm.campaign==='',
              placeholder: _vm.$t('registerClima_parcel_placeholder'),
              onValueChanged: _vm.onSelectedPlantation,
              searchEnabled:true
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"col-count":3}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }