<template>
  <div class="day">
    <p
      class="day-name"
      style="color: rgb(51, 51, 51)"
    >
      {{ name }}
    </p>

    <img
      width="35"
      height="35"
      :src="iconPath"
      :alt="icon + ' Icon'"
    >
    <p
      class="high-temp"
      style="color: rgb(210 68 68)"
    >
      {{ highTemp }}˚
    </p>
    <p
      class="low-temp"
      style="color: rgb(108 115 208)"
    >
      {{ lowTemp }}˚
    </p>
    <div
      id="wind"
      class="wind-water-sun"
    >
      <div style="font-weight: bold">
        <i
          class="f7-icons marginLeft10"
          :style="{ transform: `rotate(${wind.direction}deg)` }"
        >
          arrow_right
        </i>
      </div>
      <div>{{ wind.value }}</div>
    </div>
    <div
      id="water"
      class="wind-water-sun"
    >
      <div>💧</div>
      <div>{{ water }}</div>
    </div>
    <div
      id="sun"
      class="wind-water-sun"
    >
      <div>☀️</div>
      <div>{{ sun }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Day',

  props: [
    'name',
    'icon',
    'highTemp',
    'lowTemp',
    'wind',
    'water',
    'sun',
  ],

  computed: {
    iconPath() {
      return require(`../../../../assets/weather-icons/${this.icon}.png`)
        .default;
    },
  },
};
</script>

<style scoped>
.wind-water-sun {
  padding-top: 5px;
  white-space: nowrap;
  display: flex;
  color: black;
  font-size: 14px;
  justify-content: center;
}
.day {
  /* padding: 5px 5px;
  font-size: 1.5em;
  text-align: center;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd; */

  font-size: 1.5em;
  text-align: center;
  border: 1px solid #f1f1f1;
  border-radius: 0.4rem;
  padding: 10px;
  flex-grow: 1;
}

.day-name {
  font-weight: bold;
  font-size: 0.5em;
}

.day canvas {
  width: 26px;
  height: 26px;
}

.day p {
  margin: 0;
}

.day p:last-child {
  margin-top: -3px;
}

@media screen and (max-width: 600px) {
  .day {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5em;
    padding: 0;
    margin: 0 8%;
  }

  .day .high-temp,
  .day .low-temp {
    min-width: 30px;
  }

  .day-name {
    width: 3em;
  }

  .day canvas {
    /* width: 45px; */
    /* height: 45px; */
  }
}

@media screen and (max-width: 250px) {
  .day {
    margin: 0 5%;
  }

  .day canvas {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 600px) and (max-height: 380px) {
  #daily .day:first-child {
    /* margin: 0; */
  }

  .day p.day-name {
    margin-right: -10px;
  }

  .day p.low-temp {
    margin-top: 0;
    margin-left: -15px;
  }

  #daily .day:first-child {
    /* margin: 0; */
  }
}

@media screen and (max-width: 600px) and (max-height: 300px) {
  .day {
    display: none;
  }

  .day:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5em;
    padding: 0;
    margin: 0 1%;
  }
}

@media (max-width: 320px) {
  .day p.day-name {
    margin-right: -10px;
  }

  .day p.low-temp {
    margin-top: 0;
    margin-left: -15px;
  }
}
</style>
