<template>
  <f7-navbar :sliding="false">
    <f7-link
      panel-toggle="left"
      @click="resizePage"
    />
    <span class="icon-hamburguer" />
    <button
      v-if="pathBack != ''"
      class="button-back"
      @click="backTo"
    >
      <img
        class="ic-back"
        alt="ic-back"
      >
    </button>
    <f7-nav-title
      class="title-head"
    >
      {{ textUpperCase }}
    </f7-nav-title>
    <f7-nav-right sliding>
      <ExportExcel
        v-if="list!=null"
        :list="list"
        :type-export="typeExport"
      />
      <ExtraElement
        v-for="(item, index) in elements"
        :key="index"
        :icon-enable="item.iconEnable"
        :text-enable="item.textEnable"
        :bagde-enable="item.badgeEnable"
        :info="item.info"
      />
      <f7-chip>
        <div style="display:flex;align-items:center">
          {{ user.user.contact.name }} {{ user.user.contact.surname }}
          <f7-link style="padding:0px">
            <span
              id="user"
              class="icon-person margin-horizontal"
              @click="togglePopOver"
            />
          </f7-link>
          <DxPopover
            :width="200"
            :visible="showPopOver"
            target="#user"
            position="top"
          >
            <f7-list class="list no-hairlines">
              <f7-list-item
                link="#"
                popover-close
                :title="$t('Log_Out')"
                @click="closeSession"
              >
                <f7-icon
                  slot="media"
                  class="color-primary"
                  material="exit_to_app"
                />
              </f7-list-item>
            </f7-list>
          </DxPopover>
        </div>
      </f7-chip>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import {
  mapState,
  mapActions,
} from 'vuex';
import { DxPopover } from 'devextreme-vue/popover';
import ExtraElement from './extraElement.vue';
import ExportExcel from './ExportExcel.vue';
import eventBus from '../js/event-bus';

export default {
  components: {
    ExtraElement,
    ExportExcel,
    DxPopover,
  },
  props: {
    elements: { type: Array, default: () => [] },
    list: { type: Array, default: () => null },
    typeExport: { type: String, default: '' },
    pathBack: { type: String, default: '' },
    text: { type: String, default: '' },
  },
  data() {
    return {
      textUpperCase: '',
      showPopOver: false,
    };
  },
  computed: {
    ...mapState('authentication', ['user']),
  },
  beforeMount() {
    this.textUpperCase = this.text.toUpperCase();
  },
  methods: {
    closeSession() {
      this.logout();
    },
    togglePopOver() {
      this.showPopOver = !this.showPopOver;
    },
    backTo() {
      this.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
    },
    resizePage() {
      eventBus.$emit('lateral-panel-toggle');
    },
    ...mapActions('authentication', ['logout']),
  },

};
</script>
<style>
.chip{
  flex-direction: row-reverse;
  padding-right: 0;
  background: transparent;
}
.chip-media + .chip-label{
  margin-right: 20px!important;
}
.chip-label{
  color: var(--fr-up-to-page)!important;
}
.chip-media{
  background: var(--fr-chocolat);
}
.icon-hamburguer {
  background: url('../static/img/ic_hamburguer.svg') no-repeat left center;
  background-size: contain;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 15px;
}
.icon-person {
  background: url('../static/img/ic_user.svg') no-repeat left center;
  background-size: contain;
  width: 22px;
  height: 22px;
}
.ic-back{
  background: url("../static/img/ic_back.svg");
  width: 100%;
  height: 100%;
}
.button-back{
  background-color: #D6B35A;
  border: none;
}

</style>
