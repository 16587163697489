<template>
  <div class="main-register-parecel-step-1">
    <div class="wrapper">
      <div class="card">
        <div
          class="card-content padding"
        >
          <div class="new-zone-container card-content" style="padding: 1px 1px 1px 1px !important;">
            <f7-row class="button-container">
              <f7-col width="85">
                <DxForm
                  :id="keyName"
                  :form-data="currentElement"
                  label-location="top"
                >
                  <DxGroupItem>
                    <DxGroupItem :col-count="11">
                      <DxSimpleItem
                        name="provincia"
                        data-field="provincia"
                        :col-span="3"
                        :label="{ text: $t('properties.province') }"
                        editor-type="dxSelectBox"
                        :editor-options="{
                          dataSource: provinces,
                          displayExpr: (e) => e ? `(${e.id}) ${e.name}` : '',
                          valueExpr: 'id',
                          searchEnabled: true,
                          searchExpr: ['name', 'id'],
                          searchMode: 'contains',
                          onValueChanged: (e) => provinceChanged(e),
                          showClearButton: true,
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                      <DxSimpleItem
                        name="municipio"
                        data-field="municipio"
                        :col-span="3"
                        :label="{ text: $t('properties.municipality') }"
                        editor-type="dxSelectBox"
                        :editor-options="{
                          dataSource: municipalities,
                          displayExpr: (e) => e ? `(${e.id}) ${e.name}` : '',
                          valueExpr: 'id',
                          searchEnabled: true,
                          searchExpr: ['name', 'id'],
                          searchMode: 'contains',
                          onValueChanged: (e) => fieldDataChanged('municipio', e),
                          showClearButton: true,
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                      <DxSimpleItem
                        name="agregado"
                        data-field="agregado"
                        :col-span="1"
                        :label="{ text: $t('properties.aggregate') }"
                        editor-type="dxNumberBox"
                        :editor-options="{
                          min: 0,
                          maxLength: 128,
                          onValueChanged: (e) => fieldDataChanged('agregado', e),
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                      <DxSimpleItem
                        name="zona"
                        data-field="zona"
                        :col-span="1"
                        :label="{ text: $t('properties.zone') }"
                        editor-type="dxNumberBox"
                        :editor-options="{
                          min: 0,
                          maxLength: 128,
                          onValueChanged: (e) => fieldDataChanged('zona', e),
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                      <DxSimpleItem
                        name="poligono"
                        data-field="poligono"
                        :col-span="1"
                        :label="{ text: $t('properties.polygon') }"
                        editor-type="dxNumberBox"
                        :editor-options="{
                          min: 0,
                          maxLength: 128,
                          onValueChanged: (e) => fieldDataChanged('poligono', e),
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                      <DxSimpleItem
                        name="parcela"
                        data-field="parcela"
                        :col-span="1"
                        :label="{ text: $t('properties.parcel') }"
                        editor-type="dxNumberBox"
                        :editor-options="{
                          min: 0,
                          maxLength: 128,
                          onValueChanged: (e) => fieldDataChanged('parcela', e),
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                      <DxSimpleItem
                        name="recinto"
                        data-field="recinto"
                        :col-span="1"
                        :label="{ text: $t('properties.enclosure') }"
                        editor-type="dxNumberBox"
                        :editor-options="{
                          min: 0,
                          maxLength: 128,
                          onValueChanged: (e) => fieldDataChanged('recinto', e),
                        }"
                      >
                        <DxRequiredRule :message="$t('Field_Required')" />
                      </DxSimpleItem>
                    </DxGroupItem>
                  </DxGroupItem>
                </DxForm>
              </f7-col>
              <f7-col width="15">
                <f7-button
                  class="dx-btn-success button-sigpac"
                  raised
                  type="success"
                  @click="searchSigpac"
                >
                  {{ $t("mapbox.sigpac.form.buttonSearch") }}
                </f7-button>
              </f7-col>
            </f7-row>
          </div>
        </div>
      </div>
      <!-- /. Card-->
    </div>
    <!-- /. Wrapper-->
  </div>
</template>

<script>
import Api from '../../../services/Api';
import { PROVINCES } from '../../../js/constants';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

const name = 'SearchFormSigpac';
export default {
  name,
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  data() {
    return {
      keyName: name,
      currentElement: {
        provincia: '',
        municipio: '',
        agregado: '',
        zona: '',
        poligono: '',
        parcela: '',
        recinto: '',
      },
      municipalities: [],
      provinces: PROVINCES,
    };
  },
  methods: {
    /**
     * Función que se ejecuta cuando se modifica el campo provincia del formulario.
     * @param {object} e Evento que ha provocado el cambio.
     */
    async provinceChanged(e) {
      // Nos aseguramos de procesar sólo eventos provenientes de interacciones del usuario en el formulario
      if (!e || !e.event) return;
      try {
        this.$f7.preloader.show();
        this.currentElement.provincia = e.value;
        this.currentElement.municipio = null;
        this.municipalities = [];
        if (this.currentElement.provincia) {
          const municipalitiesRaw = await Api.getMunicipalitiesByProvince(this.currentElement.provincia).then((res) => res.json());
          for (const municipalityId in municipalitiesRaw) {
            if (Object.hasOwnProperty.call(municipalitiesRaw, municipalityId)) {
              this.municipalities.push({
                id: municipalityId,
                name: municipalitiesRaw[municipalityId],
              });
            }
          }
        }
        this.fieldDataChanged('provincia', e);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    /**
     * Función que se ejecuta cuando se modifica un campo del formulario.
     * @param {string} dataField Nombre del campo que ha cambiado.
     * @param {object} e Evento que ha provocado el cambio.
     */
    fieldDataChanged(dataField, e) {
      // Nos aseguramos de procesar sólo eventos provenientes de interacciones del usuario en el formulario
      if (!e || !e.event) return;
      this.$emit('fieldDataChanged', {
        formData: this.currentElement,
        dataField,
        value: e.value,
      });
    },
    searchSigpac() {
      if (!this.validForm()) {
        this.$notifyDX(
            {
              message: this.$t('mapbox.sigpac.form.invalidValues'),
              width: 550,
            },
            'error',
            3000,
          );
        return;
      }
      this.$emit('searchSigpac', this.currentElement);
    },
    /**
     * Función que examina el currentElement y devuelve true si todos los campos están rellenos
     * y tiene valores distintos de null, undefined o cadena vacía.
     * @returns {boolean}
     */
    validForm() {
      for (const key in this.currentElement) {
        if (Object.hasOwnProperty.call(this.currentElement, key)) {
          const element = this.currentElement[key];
          if (typeof element === 'undefined' || element === null || element === '') {
            return false;
          }
        }
      }
      return true;
    },
  },
};
</script>
<style lang="scss" scoped>
.dx-form-group-caption {
  color: black;
}
.content-error-message {
  color: red;
  font-size: 1.2rem;
  font-weight: bold;
}

.button {
  &-container {
    align-items: flex-end
  }
  &-sigpac {
    align-items: center;
    display: flex;
    font-size: 10px;
    height: 35px;
    margin-left: 5px;
    width: 100%; 
  }
}
</style>
