export default class NutritionDataFormatter {
    static invertRowDataSource(element) {
        return {
          n: element.n * -1,
          p: element.p * -1,
          k: element.k * -1,
          c: element.c * -1,
          m: element.m * -1,
          s: element.s * -1,
        };
      }
  
    static convertDataSource(element) {
      return {
        n: element.n,
        p: element.p205,
        k: element.k20,
        c: element.ca0,
        m: element.mg0,
        s: element.s03,
        price: element.price,
      };
    }
  
    static getUfsTotal(rawFertilization) {
      return {
        n: NutritionDataFormatter.sumUfs(rawFertilization, 'n'),
        p: NutritionDataFormatter.sumUfs(rawFertilization, 'p205'),
        k: NutritionDataFormatter.sumUfs(rawFertilization, 'k20'),
        c: NutritionDataFormatter.sumUfs(rawFertilization, 'ca0'),
        m: NutritionDataFormatter.sumUfs(rawFertilization, 'mg0'),
        s: NutritionDataFormatter.sumUfs(rawFertilization, 's03'),
      };
    }
    static sumUfs(rawFertilization, prop) {
      return rawFertilization.uf_available_soil[prop]
        + rawFertilization.uf_irrigation_water[prop]
        + rawFertilization.uf_rest_harvest[prop];
    }
  
    static calculateElementBalance(value, price) {
      return Math.round((value / 100) * price);
    }
  
    static calculateUfContributed(rawFertilization) {
      const ufContributed = {
        n: 0, p: 0, k: 0, c: 0, m: 0, s: 0,
      };
  
      rawFertilization.contribution_products.forEach((item) => {
        const totalPeriods = item.periods.reduce((a, b) => a + b);
        ufContributed.n += NutritionDataFormatter.calculateElementBalance(item.n, totalPeriods);
        ufContributed.p += NutritionDataFormatter.calculateElementBalance(item.p205, totalPeriods);
        ufContributed.k += NutritionDataFormatter.calculateElementBalance(item.k20, totalPeriods);
        ufContributed.c += NutritionDataFormatter.calculateElementBalance(item.ca0, totalPeriods);
        ufContributed.m += NutritionDataFormatter.calculateElementBalance(item.mg0, totalPeriods);
        ufContributed.s += NutritionDataFormatter.calculateElementBalance(item.s03, totalPeriods);
      });
  
      return ufContributed;
    }
  
    static diffRowElement(object1, object2) {
      return {
        n: object1.n + object2.n,
        p: object1.p + object2.p,
        k: object1.k + object2.k,
        c: object1.c + object2.c,
        m: object1.m + object2.m,
        s: object1.s + object2.s,
      };
    }
  }
  