<template>
  <f7-panel
    left
    resizable
    class="panel panel-left not-animated content-menu"
    :visible-breakpoint="1281"
    :collapsed-breakpoint="1281"
  >
    <!-- reveal empuja -->
    <f7-page>
      <div class="content-menu-header justify-content-center">
        <div class="menu-header-item">
          <f7-link
            class="icon-close"
            panel-close
          />
          <div class="text-center padding-vertical">
            <img
              class="image_logo margin-top"
              :src="APP_LOGO"
              alt="logo"
            >
          </div>
        </div>
      </div>
      <f7-list
        accordion-list
        class="content-list-menu"
      >
        <ul>
          <li
            v-for="(item, index) in elements"
            :key="index"
            :class="{
              'accordion-item': true,
            }"
          >
            <!-- TODO Las dos primeras opciones no son accordion por lo tanto hay que quitarles las clases a los <li>-->
            <template
              v-if="isGranted(item) && ($t(item.name) !== '' && $t(item.name) !== 'INFORMATION')"
            >
              <a
                class="item-content item-link"
                @click="() => {
                  parentNavigateTo(item)
                  removeAccordionOpened(item)
                }"
              >
                <img
                  :src="getIconPath + item.icon + '.svg'"
                  alt=""
                  style="width: 18px"
                > &nbsp; &nbsp; &nbsp;
                <div
                  class="item-inner"
                  :class="{ 'client-tab': item.name === 'LeftPanel_title_ParcelSystem' }"
                >
                  <div class="item-title">{{ $t(item.name) }}</div>
                </div>
              </a>
              <f7-accordion-content>
                <f7-list media-list>
                  <f7-list-item
                    v-for="(itemMenu, index2) in item.menus"
                    v-if="isGranted(itemMenu)"
                    :key="index2"
                    class="panel-close close"
                    :class="{highlight:getActualMenuSelected == itemMenu.url}"
                    accordion-list
                    @click="() => {
                      navigateTo(itemMenu.url, itemMenu);
                    }"
                  >
                    <f7-icon
                      v-if="(typeof itemMenu.icon !== `undefined`)"
                      slot="media"
                      :class="`icon-menu ${itemMenu.icon}`"
                      :link="itemMenu.url"
                      width="32"
                      height="32"
                    />
                    <a
                      v-if="(typeof(itemMenu.icon)!=='undefined')"
                    >{{ $t(itemMenu.name) }}</a>
                    <a
                      v-else
                      class="marginLeft40"
                    >{{ capitalizeName(itemMenu.name) }}</a>
                    <br>
                  </f7-list-item>
                </f7-list>
              </f7-accordion-content>
            </template>
            <template
              v-else-if="$t(item.name) === '' && getActualMenuSelected === undefined"
            >
              <f7-list-item
                v-for="(itemMenu, index2) in item.menus"
                :key="index2"
                class="panel-close close highlight"
                :class="{highlight:getActualMenuSelected == itemMenu.url}"
                accordion-list
                @click="navigateTo(itemMenu.url)"
              >
                <f7-icon
                  v-if="(typeof(itemMenu.icon)!=='undefined')"
                  slot="media"
                  :class="`icon-menu ${itemMenu.icon}`"
                  :link="itemMenu.url"
                  width="32"
                  height="32"
                />
                <a
                  v-if="(typeof(itemMenu.icon)!=='undefined')"
                >{{ $t(itemMenu.name) }}</a>
                <a
                  v-else
                >{{ $t(itemMenu.name) }}</a>
                <br>
              </f7-list-item>
            </template>
            <template
              v-else
            >
              <f7-list-item
                v-for="(itemMenu, index2) in item.menus"
                :key="index2"
                class="panel-close close"
                :class="{highlight:getActualMenuSelected == itemMenu.url}"
                accordion-list
                @click="navigateTo(itemMenu.url)"
              >
                <f7-icon
                  v-if="(typeof(itemMenu.icon)!=='undefined')"
                  slot="media"
                  :class="`icon-menu ${itemMenu.icon}`"
                  :link="itemMenu.url"
                  width="32"
                  height="32"
                />
                <a
                  v-if="(typeof(itemMenu.icon)!=='undefined')"
                >{{ $t(itemMenu.name) }}</a>
                <a
                  v-else
                >{{ $t(itemMenu.name) }}</a>
                <br>
              </f7-list-item>
            </template>
          </li>
        </ul>
      </f7-list>

      <div class="left-panel__footer">
        <img
          class="image_logo margin-top"
          :src="APP_TEXT_LOGO"
          style="margin-bottom: -15px"
          alt=""
        >
        <p class="version-app">
          v{{ version }}
        </p>
      </div>
    </f7-page>
  </f7-panel>
</template>

<script>
import {
  mapState, mapActions, mapGetters,
} from 'vuex';
import { APPLICATION_NAME, APP_LOGO, APP_TEXT_LOGO } from '../js/constants';
import store from '../store/index';
import optionButton from '../json/menuFloatingButton.json';

export default {
  name: 'LeftPanel',
  store,
  props: {
    elements: { type: Array, default: () => [] },
    showToDefault: { type: Boolean, default: true },
    version: { type: String, default: '1.0.0' },
  },
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
      APP_TEXT_LOGO,
      alwaysOpen: 0,
      optionButton,
      environment: process.env.NODE_ENV,

    };
  },
  computed: {
    getIconPath() {
      return `${
        this.environment === 'development' ? '../../' : './'
      }static/img/leftmenu/`;
    },
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('Plantation', ['searcherPlantation']),
    ...mapState('authentication', ['user']),
    ...mapGetters('authentication', ['getActualMenuSelected', 'getPermissions']),
    ...mapState('Nutrition', ['displayConfirmExitPopup']),
  },
  methods: {
    setTab(item) {
      if (item.activeTab) {
        this.setCurrentTab(item.activeTab);
      }
    },
    navigateTo(path, item = null) {
      this.resetNutritionPlan();
      this.resetTreatmentPlan();
      const currentPath = this.$f7.views.main.router.currentRoute.path;

      // Helper function to handle popups and route setting
      const handleExitPopup = (popupSetter, routeSetter, tabSetter) => {
        popupSetter(true);
        routeSetter(path);
        tabSetter(item.activeTab);
      };

      if (currentPath.includes('nutrition') && currentPath.includes('step')) {
        handleExitPopup(this.setDisplayConfirmExitPopup, this.setRouteToGo, this.setTabToGo);
        return;
      }

      if (currentPath.includes('treatment') && (currentPath.includes('step') || currentPath.includes('create-order'))) {
        handleExitPopup(this.setDisplayConfirmExitPopupTreatment, this.setRouteToGoInTreatment, this.setTabToGo);
        return;
      }

      if (currentPath.includes('Observation')) {
        handleExitPopup(this.setDisplayConfirmExitPopupObservation, this.setRouteToGoInObservation, this.setTabToGo);
        return;
      }

      if (item) {
        this.setTab(item);
      }

      if (path === '/accounts/loginPage/') {
        this.logoutPressed();
      } else {
        this.setActualMenuSelected(path);
        if (!this.$f7.views.main.router.currentRoute.path.includes('searcher')) {
          this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
        } else if (this.$f7.views.main.router.currentRoute.path.includes('searcher') && !path.includes('searcher')) {
          this.$f7.views.main.router.navigate(path, { reloadCurrent: true });
        }
      }
    },
    parentNavigateTo(item) {
      this.resetNutritionPlan();
      this.resetTreatmentPlan();

      const currentPath = this.$f7.views.main.router.currentRoute.path;

      // Helper function to handle exit popups and navigation
      const handleExitPopup = (popupSetter, routeSetter) => {
        popupSetter(true);
        routeSetter(item.url);
        this.setTabToGo(item.activeTab);
      };

      // Check if the item has no menus and is not the search panel
      if (!item.menus && item.name !== 'LeftPanel_title_search') {
        if (currentPath.includes('nutrition') && currentPath.includes('step')) {
          handleExitPopup(this.setDisplayConfirmExitPopup, this.setRouteToGo);
        } else if (currentPath.includes('treatment') && currentPath.includes('step')) {
          handleExitPopup(this.setDisplayConfirmExitPopupTreatment, this.setRouteToGoInTreatment);
        } else if (currentPath.includes('Observation')) {
          handleExitPopup(this.setDisplayConfirmExitPopupObservation, this.setRouteToGoInObservation);
        } else {
          this.$f7.views.main.router.navigate(item.url, { reloadCurrent: true });
        }
      } else if (item.url) {
        this.$f7.views.main.router.navigate(item.url, { reloadCurrent: true });
      }
    },
    logoutPressed() {
      const self = this;
      self.$f7.panel.close('left', false);
      self.logout();
    },
    isGranted(menu) {
      if (menu.url) {
        if (menu.url.includes('searcher')) {
          if (this.$helpers.isEmptyObject(this.searcherPlantation)) {
            return false;
          }
          return true;
        }
      }
      if (menu.permission === 'ALL') return true;
      return (this.$helpers.havePermission(this.getPermissions[`${menu.permission}`]));
    },
    removeAccordionOpened(item) {
      if (item.name.includes('search') && this.$helpers.isEmptyObject(this.searcherPlantation)) {
        Array.from(document.getElementsByClassName('accordion-item'))[0].children[1].removeAttribute('style');
      }
    },
    resetNutritionPlan() {
      this.setIsEditing(false);
      this.setEditingStepData(null);
      this.setIsCopying(false);
      this.setCopyingStepData(null);
    },
    resetTreatmentPlan() {
      this.setIsCopyingTreatment(false);
    },
    capitalizeName(name) {
      if (this.$t(name) === 'GIS') {
        return 'GIS';
      }

      return this.$t(name).toLowerCase().capitalize();
    },
    ...mapActions('authentication', ['logout', 'setActualMenuSelected']),
    ...mapActions('Utilities', ['setCurrentTab', 'setTabToGo']),
    ...mapActions('Nutrition', ['setDisplayConfirmExitPopup', 'setRouteToGo']),
    ...mapActions('treatment', { setDisplayConfirmExitPopupTreatment: 'setDisplayConfirmExitPopup', setRouteToGoInTreatment: 'setRouteToGo' }),
    ...mapActions('Observations', { setDisplayConfirmExitPopupObservation: 'setDisplayConfirmExitPopup', setRouteToGoInObservation: 'setRouteToGo' }),
    ...mapActions('nutritionStepper', ['setIsEditing', 'setEditingStepData', 'setIsCopying', 'setCopyingStepData']),
    ...mapActions('treatmentStepper', { setIsCopyingTreatment: 'setIsCopying' }),
  },
};
</script>

<style lang="scss">
.content-list-menu{
  ul{
    li{
      &.accordion-item
        a{
          .item-inner{
            justify-content: flex-start!important;
            align-content: center;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            .item-title{
              color: var(--f7-theme-color-shade);
              padding-left: 0;
            }
            &::after{
            }
            &::before{
              color: var(--fr-nutella)!important;
            }
        }
        .client-tab {
            &::before{
              transform: rotate(00);
              -webkit-transform: rotate(0);
              -moz-transform: rotate(0);
              -ms-transform: rotate(0);
              -o-transform: rotate(0);
            }
        }
      }

      &.accordion-item-opened{
        .item-content:not(:last-child){
          border-bottom: 1px solid var(--f7-list-item-border-color);
        }
        .client-tab {
          &::before{
            transform: rotate(90deg) !important;
            -webkit-transform: rotate(90deg) !important;
            -moz-transform: rotate(90deg) !important;
            -ms-transform: rotate(90deg) !important;
            -o-transform: rotate(90deg) !important;
          }
        }
      }
      &.panel-close{
        &:hover {
          background: linear-gradient(270deg, #f8fafd,#ffffff);
          a{
            color: var(--f7-theme-color-shade)!important;
          }
        }
        &.highlight {
          background: linear-gradient(270deg, #f8fafd,#ffffff)!important;
          a{
            color: var(--fr-kinder)!important;
            font-weight: 600;
          }
        }
        .item-content:not(:last-child){
          border-bottom: 1px solid black;
          .item-inner{
            &::after{
              height: 0;
            }
          }
        }
      }
      a{
        color: var(--fr-kinder)!important;
      }
      a:focus,
      a:hover{
        color: var(--fr-kinder)!important;
      }
    }
  }
}

li.accordion-item.accordion-item-opened {
  background: linear-gradient(270deg, #f8fafd,#ffffff)!important;
}

.toolbar_panel {
  bottom: 0px !important;
  padding: 5px 10px;
}
.chip-label {
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;}
.list ul ul{
  padding-left: 0px;
}
.item-content{
  cursor: pointer;
}
.content-menu-header {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  padding: 2px 5px 2px 5px;
}
.content-menu .list ul {
  background: white;

}
.content-menu .list-group-title {
  border-bottom: 1px solid #ececec;
}
.content-menu .page-content {
  background: #f7f7f7;
}

.panel-left.panel-reveal:after {
  background: none!important;
  border-right: 1px solid #ececec;
}

//TODO: Ver porqué se ha alinea a la derecha | Alinear nombre del menú a la izquierda
.content-menu .list .item-inner{
  justify-content: flex-start;
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.item-media {
  margin-left: 0 !important;
  min-width: 24px!important;
}

.icon-menu {
  width: 24px;
  height:24px;
}

.icon-home {
  background-image: url('../static/img/icon_inicio.svg');
}

.icon-close {
  background: url('../static/img/ic_close-menu.svg') no-repeat center center;
  background-size: contain;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 5px;
  top: 5px;
}

.icon-taks {
  background-image: url('../static/img/buscador.svg');
}

.icon-logout {
  background-image: url('../static/img/ic_exitout.svg');
}

div.item-inner a {
  color: #003D3E !important;
}
.image_logo{
  width: 160px;
}
.panel {
  min-width: 100px;
  max-width: 90vw;
}
.fab-buttons-top a {
    transform: translate3d(0, 8px, 0) scale(0.3);
    transform-origin: center bottom;
    background-color: #0071BD !important;
    color: white;
}
.fab > a i + i {
color: white;
}
.fab > a i {
  color: white;
}

.icon-buscador {
  background-image: url('../static/img/buscador.svg');
}

.icon-sistema_parcelario {
  background-image: url('../static/img/sistema_parcelario.svg');
}

.icon-informes {
  background-image: url('../static/img/informes.svg');
}

.icon-analisis {
  background-image: url('../static/img/analisis.svg');
}

.icon-utilidades {
  background-image: url('../static/img/utilidades.svg');
}

.icon-configuracion {
  background-image: url('../static/img/configuracion.svg');
}

</style>
