import Request from './Request';
import Api from './Api';

const keyValuesService = {

  async getByFamily(family) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/keyvalues?family=${family}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth());
    return JSON.parse(xhr.response);
  },

};

export default keyValuesService;

export { keyValuesService };
