<template>
  <f7-page>
    <navbar :text="$t('Utilities.nutrition')" />
    <nutrition-plan-list />
  </f7-page>
</template>
<script>
import navbar from '@/components/NavBar.vue';
import NutritionPlanList from '@/components/wizardNutrition/nutritionPlanList/index.vue';

export default {
  name: 'NutritionPanel',
  components: { navbar, NutritionPlanList },
  props: [],
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};

</script>
<style src="./nutrition-panel.scss" scoped lang="scss"></style>
