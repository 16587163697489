<template>
  <div class="content-devx">
    <div>
      <DxTabPanel
        :selected-index="selectedIndex"
        :data-source="tabs"
        :loop="false"
        :animation-enabled="true"
        :swipe-enabled="true"
        @optionChanged="setSelectedIndex"
      >
        <template #title="{data}">
          <span><i :class="data.icon" />{{ data.name }}</span>
        </template>
        <template #item="{data}">
          <div v-if="selectedIndex === 0">
            <PointTab />
          </div>
          <div v-else-if="selectedIndex === 1">
            <SectorTab />
          </div>
        </template>
      </DxTabPanel>
    </div>
  </div>
</template>
<script>
import DxTabPanel from 'devextreme-vue/tab-panel';

import PointTab from './pointTab/index.vue';
import SectorTab from './sectorTab/index.vue';

export default {
  name: 'ObservationPointForm',
  components: {
    PointTab,
    SectorTab,
    DxTabPanel,
  },
  data() {
    return {
      tabs: [{
        ID: 0,
        name: 'Punto',
        icon: 'dx-icon dx-icon-map',
      }, { ID: 1, name: 'Sector', icon: 'dx-icon dx-icon-globe' }],
      selectedIndex: 0,
    };
  },
  methods: {
    setSelectedIndex(e) {
      if (e.name === 'selectedIndex') {
        this.selectedIndex = e.value;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './ObservationPointForm.styles.scss';
</style>
