import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    allPlans: [],
    plansDetailsById: {}
  },
  mutations: {
    UPDATE_NUTRITION_PLANS(state, value) {
      state.allPlans = value;
    },
    UPDATE_NUTRITION_PLANS_DETAILS(state, value) {
      state.plansDetailsById = value;
    },
    PUSH_NUTRITION_PLAN_DETAILS(state, value) {
      state.plansDetailsById[value.id] = value;
    },
  },
  actions: {
    async fetchAllFertilizationPlans({ commit }) {
      try {
        const xhr = await Api.fetchFertilizationsForAnalysis();
        const plans = JSON.parse(xhr.response).result;

        commit('UPDATE_NUTRITION_PLANS', plans);
        commit('UPDATE_NUTRITION_PLANS_DETAILS', {});

        return Promise.resolve();
      } catch (error) {
        console.error(error);
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchFertilizationPlanById({ commit, state }, planId) {
      if (state.plansDetailsById[planId] != undefined) { Promise.resolve(); return; }
      try {
        const xhr = await Api.fetchFertilizationByIdDetailsForAnalysis(planId);
        const plan = JSON.parse(xhr.response);

        commit('PUSH_NUTRITION_PLAN_DETAILS', plan);

        return Promise.resolve();
      } catch (error) {
        console.error(error);
        return Promise.reject(Helpers.getError(error));
      }
    }
  },

}