<template>
  <DxPopup
    :visible="true"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :width="500"
    height="auto"
    :show-close-button="true"
    @hiding="close()"
  >
    <f7-block class="padding margin-half">
      <f7-row>
        <f7-col>
          <div class="dx-field">
            <div class="margin-bottom-half text-center">
              {{ $t("Searcher.observations.confirmDate") }}
            </div>
          </div>
        </f7-col>
      </f7-row>
    </f7-block>

    <f7-block class="margin-half">
      <f7-row>
        <f7-col>
          <f7-button
            class="
              dx-btn-cancel
              margin-left-block
              btn-width-150
              outlined-gray
              margin-top-block margin-bottom-block
            "
            raised
            type="default"
            styling-mode="contained"
            @click="close()"
          >
            Abandonar
          </f7-button>
        </f7-col>
        <f7-col class="justify-content-end">
          <f7-button
            class="
              dx-btn-cancel
              margin-right-block
              btn-width-150
              margin-top-block margin-bottom-block
              standard-blue-btn
            "
            raised
            type="success"
            styling-mode="contained"
            :disabled="disabledSaveButton"
            @click="save()"
          >
            {{ $t("continue") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </DxPopup>
</template>

<script>
import { DxPopup } from 'devextreme-vue/popup';
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import { DxTextBox } from 'devextreme-vue/text-box';

export default {
  name: 'PopupConfirmedDate',
  components: {
    DxPopup,
    DxDataGrid,
    DxColumn,
    DxTextBox,
  },
  props: {
    product: {
      type: Object,
      default: () => {},
    },
    disabledSaveButton: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
      this.$emit('close');
    },
    onChangeDescription(e) {
      this.annotation = e.value;
    },
  },
};
</script>

<style src="./popupConfirmedDate.styles.scss" scoped lang="scss"></style>
