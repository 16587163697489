<template>
  <f7-page
    @page:beforein="beforein"
  >
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Searcher.observations.observations_title')}`" />
    <f7-row no-gap>
      <f7-col>
        <f7-row>
          <f7-col>
            <ViewMainData
              :company-id="currentPlantation.companyId"
              :scouting-points="scoutingPoints"
              :current-values-main-data="observationsData"
              :hide-btn="false"
            />
          </f7-col>
        </f7-row>
        <f7-row>
          <f7-col><MultimediaData :multimedia="multimedia" /></f7-col>
        </f7-row>
      </f7-col>
      <f7-col>
        <f7-block v-if="!loadedGIS || !showGIS">
          <div class="loadingFalse">
            <div
              v-if="!loadedGIS"
              class="preloader"
            />
          </div>
        </f7-block>
        <div v-else>
          <GISPointsMap
            v-if="currentScoutingPoint.type === 'POINT'"
            :zoom="zoom"
            :name-map="mainMap1"
            :tree-locations="treeLocations"
            :current-point="{latitude: currentScoutingPoint.latitude, longitude: currentScoutingPoint.longitude}"
            :only-read="true"
            :selected-min-height="'450px'"
            :includes-content-devx="true"
          />
          <GISGeoJsonMap
            v-else
            :zoom="zoom"
            :name-map="mainMap2"
            :selected-min-height="'450px'"
            :tree-locations="treeLocations"
          />
        </div>
      </f7-col>
    </f7-row>
    <f7-row>
      <f7-col>
        <TemplateData
          v-if="showTemplateForm && loaded"
          :template-form="templateForm"
        />
      </f7-col>
    </f7-row>
    <f7-block
      class="footer"
    >
      <f7-row>
        <f7-col width="10">
          <f7-button
            class="dx-btn-cancel btnBack"
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            style="margin-bottom: 10px"
            @click="goToObservations"
          >
            {{ $t('Go_Back') }}
          </f7-button>
        </f7-col>
        <f7-col width="80" />
        <f7-col width="10">
          <f7-button
            class="dx-btn-save margin-right"
            raised
            type="success"
            styling-mode="outlined"
            name="ButtonSave"
            @click="callToUpdateObservation"
          >
            {{ $t('Button_save_text') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ViewMainData from '../../../components/searcher/observations/observationForm/viewMainData/index.vue';
import MultimediaData from '../../../components/searcher/observations/observationForm/multimediaData/index.vue';
import TemplateData from '../../../components/searcher/observations/observationForm/templateData/index.vue';
import GISPointsMap from '../../../components/Gis/GISPointsMap/index.vue';
import GISGeoJsonMap from '../../../components/Gis/GISGeoJsonMap/index.vue';

export default {
  name: 'EditObservation',
  components: {
    navbar,
    ViewMainData,
    MultimediaData,
    TemplateData,
    GISPointsMap,
    GISGeoJsonMap,
  },
  data() {
    return {
      zoom: 20,
      mainMap1: 'createObservationPoint',
      mainMap2: 'createObservationParcel',
      loadedGIS: false,
      loaded: false,
    };
  },
  computed: {
    showGIS() {
      return this.currentScoutingPoint !== null;
    },
    ...mapState('SearcherObservations', ['currentScoutingPoint', 'templateForm', 'showTemplateForm', 'scoutingPoints', 'idCurrentObservation', 'multimedia', 'observationsData']),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapGetters('SearcherObservations', ['currentValuesMainData']),
  },
  methods: {
    async beforein() {
      this.$f7.preloader.show();
      try {
        await this.getObservationDetail({ currentPlantation: this.currentPlantation, idCurrentObservation: this.idCurrentObservation });
        const { category } = this.observationsData;
        if (category !== 'Recetario') {
          await this.getScoutingPoints(this.currentPlantation);
          await this.fetchCurrentScoutingPoint({
          companyId: this.currentPlantation.companyId,
          scoutingPointId: this.currentValuesMainData.idGeolocationPoint,
          });
        };
        this.formattingTemplate(this.templateForm);
        this.setEdit(true);
        this.resetCurrentPoint();
        await this.fetchLocationsByPlantation();
        this.loadedGIS = true;
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.loaded = true;
      }
    },
    goToObservations() {
      this.$f7.views.main.router.navigate('/searcher/observations/', { reloadCurrent: true });
    },
    async callToUpdateObservation() {
      this.$f7.preloader.show();
      try {
        await this.updateObservation(this.currentPlantation);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/searcher/observations/', { reloadCurrent: true });
      }
    },
    ...mapActions('SearcherObservations', ['fetchCurrentScoutingPoint', 'getScoutingPoints', 'updateObservation', 'getObservationDetail', 'setEdit', 'formattingTemplate']),
    ...mapActions('Gis', ['resetCurrentPoint']),
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
  },
};
</script>

<style scoped>
.dx-btn-save{
    background: #0071BD;
    color: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: auto;
    line-height: 3;
    box-shadow: none;
  }
  .dx-btn-save:hover {
    box-shadow: 0px 2px 1px rgba(0,0,0,0.07);
  }
  .dx-btn-back{
    background: #FFFFFF;
    color: #74838C;
    border: 1px solid #74838C;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: auto;
    line-height: 3;
    box-shadow: none;
  }
  .dx-btn-back:hover {
    box-shadow: 0px 2px 1px rgba(0,0,0,0.07);
  }
  .footer{
      margin-top: 10px;
  }
</style>
