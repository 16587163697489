var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('f7-col',[_c('span',{staticClass:"dx-field-item-label-text"},[_vm._v(_vm._s(_vm.$t('exclusionDates.list'))+":")]),_vm._v(" "),_c('DxTagBox',{attrs:{"id":"tagBox","show-clear-button":true,"open-on-field-click":false,"items":_vm.datesSelected,"value":_vm.datesSelected,"placeholder":_vm.$t('exclusionDates.list')},on:{"value-changed":(e) => _vm.datesSelected = e.value}})],1),_vm._v(" "),_c('f7-block',{staticStyle:{"margin-top":"20px"}},[_c('DxForm',[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"id":"dateBox","data-field":_vm.$t('exclusionDates.add'),"editor-type":"dxDateBox","editor-options":{   
            displayFormat: 'yyyy-MM-dd',
            dateSerializationFormat: 'yyyy-MM-dd',
            placeholder: _vm.$t('exclusionDates.format'),
            invalidDateMessage: _vm.$t('dates_isInvalid'),
            pickerType: 'calendar',
            disabledDates: _vm.disabledDates,
            min: _vm.min,
            max: _vm.max,
            onValueChanged: (e) => _vm.datesSelected.push(e.value),
          }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }