export default {
  namespaced: true,

  state: {
    itemUnitsWater: [
      {
        meq: 1, label: 'Ca²⁺', mg: 20.04, kg: 120.2, m3: 6000,
      },
      {
        meq: 1, label: 'Mg²⁺', mg: 12.16, kg: 73.0, m3: 6000,
      },
      {
        meq: 1, label: 'Na⁺', mg: 23.00, kg: 138.0, m3: 6000,
      },
      {
        meq: 1, label: 'K⁺', mg: 39.10, kg: 234.6, m3: 6000,
      },
      {
        meq: 1, label: 'H⁺', mg: 1, kg: 6, m3: 6000,
      },
      {
        meq: 1, label: 'Al³⁺', mg: 8.99, kg: 53.9, m3: 6000,
      },
      {
        meq: 1, label: 'Zn²⁺', mg: 32.69, kg: 196.1, m3: 6000,
      },
      {
        meq: 1, label: 'NH₄⁺', mg: 18.042, kg: 108.3, m3: 6000,
      },
      {
        meq: 1, label: 'NO₃⁻', mg: 62.01, kg: 372.1, m3: 6000,
      },
      {
        meq: 1, label: 'PO₄³', mg: 31.66, kg: 190.0, m3: 6000,
      },
      {
        meq: 1, label: 'HPO₄⁼', mg: 47.99, kg: 287.9, m3: 6000,
      },
      {
        meq: 1, label: 'SO₄⁼', mg: 48.50, kg: 291.0, m3: 6000,
      },
      {
        meq: 1, label: 'Fe⁺⁺', mg: 27.93, kg: 167.6, m3: 6000,
      },
      {
        meq: 1, label: 'Mn⁺⁺', mg: 27.47, kg: 164.8, m3: 6000,
      },
      {
        meq: 1, label: 'Cu⁺⁺', mg: 31.77, kg: 190.6, m3: 6000,
      },
      {
        meq: 1, label: 'BO₃⁻⁻⁻', mg: 19.60, kg: 117.6, m3: 6000,
      },
      {
        meq: 1, label: 'Cl⁻', mg: 35.46, kg: 212.8, m3: 6000,
      },
      {
        meq: 1, label: 'CO₃⁼', mg: 30.00, kg: 180.0, m3: 6000,
      },
      {
        meq: 1, label: 'CO₃H⁻', mg: 61.01, kg: 366.1, m3: 6000,
      },
      {
        meq: 1, label: 'B³⁺', mg: 3.50, kg: 21.0, m3: 6000,
      },
      {
        meq: 1, label: 'H₂PO₄⁻', mg: 96.99, kg: 581.9, m3: 6000,
      },
    ],
    itemUnitsGround: [
      { meq: 1, label: 'Ca²⁺', ppm: 200.40 },
      { meq: 1, label: 'Mg²⁺', ppm: 121.60 },
      { meq: 1, label: 'Na⁺', ppm: 229.97 },
      { meq: 1, label: 'K⁺', ppm: 390.96 },
      { meq: 1, label: 'H⁺', ppm: 10.00 },
      { meq: 1, label: 'Al³⁺', ppm: 89.90 },
      { meq: 1, label: 'Zn²⁺', ppm: 326.85 },
      { meq: 1, label: 'NH₄⁺', ppm: 180.42 },
      { meq: 1, label: 'NO₃⁻', ppm: 620.10 },
      { meq: 1, label: 'PO₄³', ppm: 316.57 },
      { meq: 1, label: 'SO₄⁼', ppm: 480.30 },
      { meq: 1, label: 'Fe⁺⁺', ppm: 279.25 },
      { meq: 1, label: 'Mn⁺⁺', ppm: 274.65 },
      { meq: 1, label: 'Cu⁺⁺', ppm: 317.70 },
      { meq: 1, label: 'BO₃⁻⁻⁻', ppm: 588.10 },
      { meq: 1, label: 'Cl⁻', ppm: 354.60 },
      { meq: 1, label: 'CO₃²⁻', ppm: 300.00 },
      { meq: 1, label: 'CO₃H⁻', ppm: 610.10 },
      { meq: 1, label: 'P', ppm: 34.50 },
    ],
    apparentDensity: 1.25,
    soilDepth: 0.2,
    itemUnitsNutritionalSolutions: [
      {
        mol: 1, label: 'NO₃⁻', element: 1, oxide: 4.4,
      },
      {
        mol: 1, label: 'NH₄⁺', element: 1, oxide: 1.3,
      },
      {
        mol: 1, label: 'P', element: 1, oxide: 2.3,
      },
      {
        mol: 1, label: 'K⁺', element: 39.1, oxide: 47.1,
      },
      {
        mol: 1, label: 'Ca²⁺', element: 40.08, oxide: 56.082,
      },
      {
        mol: 1, label: 'Mg²⁺', element: 24.31, oxide: 40.3,
      },
      {
        mol: 1, label: 'S', element: 1, oxide: 2.5,
      },
    ],
    itemUnitsMolecularWeight: [
      {
        eq: 1, label: 'NN por kg ', percent: 6.2, moles: 1,
      },
      {
        eq: 1, label: 'NA por kg', percent: 1.8, moles: 1,
      },
      {
        eq: 1, label: 'N UREICO por kg', percent: 3, moles: 1,
      },
      {
        eq: 1, label: 'H₂PO₄⁻ por kg', percent: 7.1, moles: 1,
      },
      {
        eq: 1, label: 'K por kg', percent: 4.71, moles: 1,
      },
      {
        eq: 1, label: 'Ca²⁺ por kg', percent: 2.8, moles: 1,
      },
      {
        eq: 1, label: 'Mg²⁺ por kg', percent: 2, moles: 0.5,
      },
      {
        eq: 1, label: 'SO₄²⁻ por kg', percent: 4, moles: 0.5,
      },
    ],
  },

  getters: {
    itemUnitsWater: (state) => state.itemUnitsWater,
  },

  actions: {
    setItemUnitsWater(context, value) {
      context.commit('setItemUnitsWater', value);
    },
  },

  mutations: {
    setItemUnitsWater(state, itemUnitsWater) {
      state.itemUnitsWater = itemUnitsWater;
    },
  },
};
