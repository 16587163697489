var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-devx no-padding"},[_c('f7-block',{staticClass:"no-padding"},[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('title_register_plantation')}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.specie_botanic'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.speciesAvailables,
              value: _vm.specieSelected,
              searchEnabled:true,
              onValueChanged: _vm.onChangeSpecieBotanic,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.variety')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.varietiesPaginated,"on-value-changed":_vm.onChangeVariety,"value":_vm.varietySelected,"display-expr":"name","value-expr":"name","search-enabled":true}})]},proxy:true}])})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.unit')}`,"editor-type":"dxSelectBox","editor-options":{
              items: _vm.plantingUnit,
              value: _vm.plantingUnitSelected,
              onValueChanged: _vm.onChangePlantingUnit,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.planting_density'),"editor-type":"dxNumberBox","editor-options":{
              value: _vm.seedDensity,
              onValueChanged: _vm.onChangeSeedDensity,
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.expected_production'),"editor-type":"dxNumberBox","editor-options":{
              value: _vm.expectedProduction,
              onValueChanged: _vm.onChangeExpectedProduction,
              readOnly: false,
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }