<template>
  <div
    class="content-devx"
  >
    <f7-block class="margin-bottom">
      <f7-row>
        <f7-col>
          <f7-block-title>
            {{ isNotEditable ? $t('Searcher.observations.detail_observation_title') : $t('Searcher.observations.edit_observation_title') }}
          </f7-block-title>
        </f7-col>
        <f7-col
          v-if="hideBtn"
          class="justify-content-end"
        >
          <ContextMenu :items="items" />
        </f7-col>
      </f7-row>
    </f7-block>
    <f7-row no-gap>
      <f7-col width="85">
        <DxForm
          id="mainData-form"
          :show-colon-after-label="false"
          :read-only="readOnly"
        >
          <DxGroupItem>
            <DxSimpleItem
              name="date"
              :data-field="$t('Searcher.observations.date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeDate,
                value: currentValuesMainData.date,
                displayFormat: 'dd/MM/yyyy',
                invalidDateMessage: $t('dates_isInvalid'),
                pickerType: 'calendar',
                type: 'date',
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.dateValidation')" />
            </DxSimpleItem>
            <DxSimpleItem
              :visible="showGeolocation"
              name="geolocation"
              :data-field="$t('Searcher.observations.geolocation')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: scoutingPoints,
                onValueChanged: onGeolocationChange,
                value: currentValuesMainData.point,
                displayExpr: 'name',
                valueExpr: 'id',
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.geolocationValidation')" />
            </DxSimpleItem>
            <DxSimpleItem
              name="categoryName"
              :data-field="$t('Searcher.observations.category')"
              :editor-options="{
                value: currentValuesMainData.category,
                readOnly: true,
              }"
            />
            <DxSimpleItem
              :visible="!showLaboratoryData && showGeolocation"
              name="subcategoryName"
              :data-field="$t('Searcher.observations.subcategory')"
              :editor-options="{
                value: currentValuesMainData.subcategoryName,
                readOnly: true,
              }"
            />
            <DxSimpleItem
            :visible="showLaboratoryData && showGeolocation"
              name="laboratoryName"
              :data-field="$t('Searcher.observations.laboratory')"
              :editor-options="{
                value: currentValuesMainData.lab ? currentValuesMainData.lab.name : '',
                readOnly: true,
              }"
            />
            <DxSimpleItem
              :visible="showLaboratoryData && showGeolocation"
              name="analysisTypeName"
              :data-field="$t('Searcher.observations.analysis')"
              :editor-options="{
                value: getAnalyseSubDisplayText(currentValuesMainData.analyse_type),
                readOnly: true,
              }"
            />
          </DxGroupItem>
        </DxForm>
      </f7-col>
      <f7-col width="10">
        <DxForm
          v-if="!readOnly"
          id="form"
          :show-colon-after-label="false"
        >
          <DxSimpleItem
            data-field=""
            :editor-options="{
              disabled: true,
              height: 41
            }"
          />
          <DxButtonItem
            v-if="showGeolocation"
            :button-options="addGeolocationPoint"
            horizontal-alignment="center"
          />
        </DxForm>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>

import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
  DxGroupItem,
  DxButtonItem,
} from 'devextreme-vue/form';
import DxButton from 'devextreme-vue/button';
import { mapActions, mapState } from 'vuex';
import ContextMenu from '@/components/contextMenu/index.vue';

export default {
  name: 'ViewMainData',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxGroupItem,
    DxButtonItem,
    DxButton,
    ContextMenu,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
    scoutingPoints: {
      type: Array,
      default: () => [],
    },
    currentValuesMainData: {
      type: Object,
      default: () => {},
    },
    hideBtn: {
      type: Boolean,
      default: true,
    },
    idCurrentObservation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      isNotEditable: this.hideBtn,
      showDataBeforeSearchOption: false,
      addGeolocationPoint: {
        icon: 'add',
        onClick: () => {
          this.$f7.views.main.router.navigate('/createObservationPoint/', { reloadCurrent: true });
        },
      },
      items: [
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.goToEditObservation,
        },
        {
          icon: 'delete',
          text: this.$t('ContextMenu.Delete'),
          onItemClick: this.callToDeleteObservation,
        },
        {
          icon: 'map',
          text: `${this.$t('viewParcel')} (Google maps)`,
          onItemClick: () => {
            window.open(
              `https://www.google.es/maps/@${this.parcelCentroide.latitude},${this.parcelCentroide.longitude},700m/data=!3m1!1e3!5m2!1e4!1e1?hl=es`, '_blank',
            );
          },
        },
        {
          icon: 'map',
          text: `${this.$t('viewSector')} (Google maps)`,
          onItemClick: () => {
            window.open(
              `https://www.google.es/maps/@${this.sectorCentroide.latitude},${this.sectorCentroide.longitude},700m/data=!3m1!1e3!5m2!1e4!1e1?hl=es`, '_blank',
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapState('Gis', ['sectorCentroide', 'parcelCentroide']),
    showLaboratoryData(){
      return this.currentValuesMainData.category === 'Muestreo';
    },
    showGeolocation() {
     return this.currentValuesMainData.category !== 'Recetario';
    }
  },
  methods: {
    async updateCurrentScoutingPoint(scoutingPointId) {
      if (scoutingPointId && scoutingPointId !== '' && this.currentValuesMainData.category !== 'Recetario') {
        this.$f7.preloader.show();
        try {
          await this.fetchCurrentScoutingPoint({ companyId: this.companyId, scoutingPointId });
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    goToEditObservation() {
      this.$f7.views.main.router.navigate('/editObservation/', {
        reloadCurrent: true,
      });
    },
    async callToDeleteObservation() {
      this.$f7.preloader.show();
      try {
        await this.deleteObservation({ companyId: this.companyId, observationId: this.idCurrentObservation });
        this.$f7.views.main.router.navigate('/searcher/observations/', { reloadCurrent: true });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('Searcher.observations.deleteMuestreoCategory'),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    getAnalyseSubDisplayText(item) {
      if (item && item.sub_family_tm && item.description) {
        return `${item.sub_family_tm} - ${item.description}`;
      } else 
        return '';
    },
    onChangeDate(e) {
      this.setDate(e.value);
    },
    onGeolocationChange(e) {
      this.resetCurrentScoutingPoint();
      this.updateCurrentScoutingPoint(e.value);
      this.setGeolocation(e.value);
    },
    ...mapActions('SearcherObservations', ['resetCurrentScoutingPoint', 'deleteObservation', 'setDate', 'setGeolocation', 'fetchCurrentScoutingPoint']),
  },
};
</script>

<style lang="scss" scoped>
@import './ViewMainData.styles.scss';
</style>
