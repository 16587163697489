<template>
  <section>
    <DxDataGrid
      ref="refDataGrid"
      class="data-grid-balance-value"
      :data-source="treatmentOrder.products"
      :show-borders="true"
    >
      <DxEditing
        :allow-updating="true"
        mode="cell"
      />
      <DxColumn
        data-field="name"
        :caption="$t('treatment.treatmentEditOrder.products.product')"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        :caption="$t('treatment.treatmentEditOrder.products.dosageRange')"
        data-field="dosage_range"
        alignment="right"
        :allow-editing="false"
      />
      <DxColumn

        :caption="$t('treatment.treatmentEditOrder.products.dosageTotalL')"
        data-field="dosageTotalL"
        alignment="right"
        :allow-sorting="false"
        :allow-editing="false"
        :calculate-display-value="calculateDosageTotalL"
      />

      <DxColumn
        :caption="$t('treatment.treatmentEditOrder.products.dosageTotalKg')"
        data-field="dosageTotalKg"
        alignment="right"
        :allow-sorting="false"
        :allow-editing="false"
        :calculate-display-value="calculateDosageTotalKg"
      />
      <DxColumn
        :caption="$t('productResume.dosageTotalKgL')"
        data-type="number"
        :allow-sorting="false"
        :allow-editing="false"
        :calculate-display-value="calculateDosageTotalKGL"
      />
      <DxColumn
        :caption="$t('treatment.treatmentEditOrder.products.price')"
        data-field="price"
        alignment="right"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        :caption="$t('treatment.treatmentEditOrder.products.unitCost')"
        data-field="unitCost"
        alignment="right"
        :allow-sorting="false"
        :allow-editing="false"
      />
      <DxColumn
        :caption="$t('treatment.treatmentEditOrder.products.totalCost')"
        data-field="totalCost"
        alignment="right"
        :allow-sorting="false"
        :allow-editing="false"
        :calculate-display-value="max2Decimals"
      />
    </DxDataGrid>
    <f7-block>
      <f7-row>
        <f7-col />
        <f7-col />
        <f7-col />
        <f7-col />
        <f7-col />
        <f7-col />
        <f7-col class="alignRightBold">
          {{ formatedTotalUnitCost }}
        </f7-col>
        <f7-col class="alignRightBold">
          {{ formatedTotalTotal }}
        </f7-col>
      </f7-row>
    </f7-block>
  </section>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
} from 'devextreme-vue/data-grid';
import numeral from 'numeral';

const numeralFormat = '0,0.00';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,

  },
  props: ['treatmentOrder'],
  data() {
    return {
      totalUnitCost: 0,
      totalTotal: 0,
    };
  },
  computed: {
    formatedTotalUnitCost() {
      return parseFloat(this.totalUnitCost).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },
    formatedTotalTotal() {
      return parseFloat(this.totalTotal).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    },

  },
  async created() {
    this.treatmentOrder.products = this.treatmentOrder.products.map(({
      product, total_dose, unit_cost, total_cost, ...rest
    }) => ({
      ...rest, name: product, dosageTotalKg: total_dose, unitCost: unit_cost, totalCost: total_cost,
    }));
    this.calculateTotals();
  },
  methods: {

    max2Decimals(e) {
      return parseFloat(e.totalCost).toFixed(2);
    },
    calculateTotals() {
      let total = 0;
      let totalUnit = 0;
      this.treatmentOrder.products.forEach((product) => {
        if (typeof product !== 'undefined') {
          total += product.totalCost;
          totalUnit += product.unitCost;
        }
      });
      this.totalUnitCost = totalUnit;
      this.totalTotal = total;
    },
    calculateDosageTotalKg(rowData) {
      rowData.dosageTotalKg = numeral(rowData.dosageTotalKg).format(numeralFormat);
      return numeral(rowData.dosageTotalKg).format(numeralFormat);
    },
    calculateDosageTotalL(rowData) {
      rowData.dosageTotalKg = parseFloat(rowData.dosageTotalKg.toString().replace(/,/g, ''));
      rowData.dosageTotalL = (rowData.dosageTotalKg * 1000) / this.treatmentOrder.volume;
      return numeral(rowData.dosageTotalL).format(numeralFormat);
    },
    calculateDosageTotalKGL(row) {
      return parseFloat(row.dosageTotalKg * this.treatmentOrder.area).toFixed(2);
    },
  },
};
</script>
<style lang="scss" scoped>
@import './treatmentOrderProducts.scss';
</style>
