<template>
  <div
    v-if="loaded"
  >
    <div class="content-devx-margin-block">
      <div
        class="card-header-title title-position-left"
      >
        {{ $t('irrigation_title_datagrid') }}
      </div>
      <DxDataGrid
        id="IrrigationRegister"
        :data-source="dataSource"
        :remote-operations="true"
        :column-min-width="70"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @RowRemoved="callToDeleteIrrigation"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          :storage-key="`${keyName}`"
        />
        <DxEditing
          :allow-deleting="true"
          :allow-updating="true"
          :confirm-delete="true"
          mode="popup"
        >
          <DxTexts
            :confirm-delete-message="$t('Confirm_Delete_Text')"
            :confirm-delete-title="$t('Confirm_Delete_Title')"
          />
        </DxEditing>
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxSelection
          mode="single"
        />
        <DxPaging :page-size="5" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          info-text="{2} items"
          :visible="dataSource.length >= 5"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :highlight-case-sensitive="true"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="date"
          name="date"
          :caption="$t('irrigation_date')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="doses"
          name="doses"
          :caption="$t('irrigation_doses_m3')"
          alignment="center"
          :allow-header-filtering="false"
          format="#,##0.##"
        />
        <DxColumn
          data-field="time"
          name="time"
          :caption="$t('irrigation_time')"
          alignment="center"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="totalVol"
          name="totalVol"
          :caption="$t('irrigation_totalVol_m3')"
          alignment="center"
          :allow-header-filtering="false"
          format="#,##0.##"
        />
        <DxColumn
          data-field="accumulatedVol"
          name="accumulatedVol"
          :caption="$t('irrigation_accumulatedVol_m3')"
          alignment="center"
          :allow-header-filtering="false"
          format="#,##0.##"
        />
        <DxColumn
          :allow-reordering="false"
          type="buttons"
          :fixed="gridSettings.fixed"
          :fixed-position="gridSettings.fixedPosition"
        >
          <DxButton
            name="find"
            icon="find"
            :on-click="callToViewIrrigation"
          />
          <DxButton
            name="edit"
            icon="edit"
            :on-click="callToUpdateIrrigation"
          />
          <DxButton
            name="delete"
            icon="trash"
          />
        </DxColumn>
      </DxDataGrid>
    </div>
    <div class="content-devx-margin-block position-relative">
      <div
        class="card-header-title title-top"
      >
        {{ $t('irrigation_title_datagridRecommendation') }}
      </div>
      <DxDataGrid
        id="IrrigationRecommended"
        :data-source="dataSourceRecommendation"
        :remote-operations="true"
        :column-min-width="70"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="false"
        :column-auto-width="true"
        :row-alternation-enabled="true"
        @RowRemoved="callToDeleteIrrigation"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparingRecommended($event)"
      >
        <DxSelection
          mode="single"
        />
        <DxStateStoring
          :enabled="true"
          type="localStorage"
          :storage-key="`${keyName}`"
        />
        <DxEditing
          :allow-deleting="true"
          :allow-updating="true"
          :confirm-delete="true"
          mode="popup"
        >
          <DxTexts
            :confirm-delete-message="$t('Confirm_Delete_Text')"
            :confirm-delete-title="$t('Confirm_Delete_Title')"
          />
        </DxEditing>
        <DxHeaderFilter :visible="true" />
        <DxExport
          :enabled="false"
        />
        <DxSelection
          mode="single"
        />

        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
          info-text="{2} items"
          :visible="dataSourceRecommendation.length >= 5"
        />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :highlight-case-sensitive="true"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          data-field="date"
          name="date"
          :caption="$t('irrigation_date')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="doses"
          name="doses"
          :caption="$t('irrigation_doses_m3')"
          alignment="center"
          :allow-header-filtering="false"
          format="#,##0.##"
        />
        <DxColumn
          data-field="time"
          name="time"
          :caption="$t('irrigation_time')"
          alignment="center"
          :allow-header-filtering="false"
        />
        <DxColumn
          data-field="totalVol"
          name="totalVol"
          :caption="$t('irrigation_totalVol_m3')"
          alignment="center"
          :allow-header-filtering="false"
          format="#,##0.##"
        />
        <DxColumn
          type="buttons"
          :fixed="gridSettings.fixed"
          :fixed-position="gridSettings.fixedPosition"
        >
          <DxButton
            name="find"
            icon="find"
            :on-click="callToViewIrrigationRecommendation"
          />
          <DxButton
            name="edit"
            icon="edit"
            :on-click="callToUpdateIrrigationRecommendation"
          />
          <DxButton
            name="delete"
            icon="trash"
          />
        </DxColumn>
      </DxDataGrid>
      <PopUpRegisterIrrigationRecommended />
      <PopUpRegisterIrrigation />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxEditing,
  DxButton,
  DxTexts,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import PopUpRegisterIrrigation from './irrigationFormPopup/index.vue';
import PopUpRegisterIrrigationRecommended from './irrigationRecommendedFormPopup/index.vue';
import settings from '../../js/commonSettings';

export default {
  name: 'Irrigation',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxEditing,
    DxButton,
    PopUpRegisterIrrigation,
    PopUpRegisterIrrigationRecommended,
    DxTexts,
  },
  props: {
    keyName: { type: String, default: 'Irrigation' },
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      dataSource: [],
      dataSourceRecommendation: [],
      accumulatedVol: 0,
      enableAddButton: true,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Irrigation', ['dataIrrigation']),
    ...mapState('RegisterPlantation', ['actualPlantation']),

  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchDataIrrigation(this.currentPlantation.id);
      this.checkIfIrrigationIsRainfed();
      for (let i = 0; i < this.dataIrrigation.length; i += 1) {
        if (this.dataIrrigation[i].type === 'RECOMMENDATION') {
          this.dataSourceRecommendation.push({
            date: this.dataIrrigation[i].date,
            doses: this.dataIrrigation[i].doses,
            plantation: this.dataIrrigation[i].plantation,
            company: this.dataIrrigation[i].company,
            time: this.formatTimeRemovingHoursIfNone(this.dataIrrigation[i].time),
            totalVol: this.dataIrrigation[i].total_vol,
            accumulatedVol: this.dataIrrigation[i].accumulated_vol,
            id: this.dataIrrigation[i].id,
          });
        } else {
          this.dataSource.push({
            date: this.dataIrrigation[i].date,
            doses: this.dataIrrigation[i].doses,
            plantation: this.dataIrrigation[i].plantation,
            company: this.dataIrrigation[i].company,
            time: this.formatTimeRemovingHoursIfNone(this.dataIrrigation[i].time),
            totalVol: this.dataIrrigation[i].total_vol,
            accumulatedVol: this.sumatoryVolAccumulated(this.dataIrrigation[i].total_vol),
            id: this.dataIrrigation[i].id,
          });
        }
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(this.$helpers.getError(error));
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    checkIfIrrigationIsRainfed() {
      for (const property of this.actualPlantation.farms[0].properties) {
        if (property.key === 'irrigation_type') {
          if (property.value === 'SECANO') {
            this.enableAddButton = false;
          } else {
            this.enableAddButton = true;
          }
        }
      }
    },
    callToViewIrrigationRecommendation(rowSelected) {
      this.setIsView(true);
      const newObject = {
        date: rowSelected.row.data.date,
        time: rowSelected.row.data.time,
        plantation: rowSelected.row.data.plantation,
        type: 'RECOMMENDATION',
        doses: rowSelected.row.data.doses,
        id: rowSelected.row.data.id,
      };
      this.setActualIrrigationRecommended(newObject);
      this.setIsEdit(true);
      this.setShowPopUpRecommended(true);
    },
    callToViewIrrigation(rowSelected) {
      this.setIsView(true);
      const newObject = {
        date: rowSelected.row.data.date,
        time: rowSelected.row.data.time,
        plantation: rowSelected.row.data.plantation,
        doses: rowSelected.row.data.doses,
        id: rowSelected.row.data.id,
      };
      this.setActualIrrigation(newObject);
      this.setIsEdit(true);
      this.setShowPopUp(true);
    },
    async callToUpdateIrrigation(rowSelected) {
      const newObject = {
        date: rowSelected.row.data.date,
        time: rowSelected.row.data.time,
        plantation: rowSelected.row.data.plantation,
        type: 'REGISTER',
        doses: rowSelected.row.data.doses,
      };
      this.setIrrigationSelectedId(rowSelected.row.data.id);
      this.setActualIrrigation(newObject);
      this.setIsEdit(true);
      this.setShowPopUp(true);
    },
    async callToDeleteIrrigation(rowSelected) {
      try {
        const deleteData = {
          companyId: this.currentPlantation.companyId,
          irrigationId: rowSelected.data.id,
        };
        await this.deleteIrrigation(deleteData);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(this.$helpers.getError(error));
      }
    },
    async callToUpdateIrrigationRecommendation(rowSelected) {
      const newObject = {
        date: rowSelected.row.data.date,
        time: rowSelected.row.data.time,
        plantation: rowSelected.row.data.plantation,
        type: 'RECOMMENDATION',
        doses: rowSelected.row.data.doses,
      };
      this.setIrrigationSelectedId(rowSelected.row.data.id);
      this.setActualIrrigationRecommended(newObject);
      this.setIsEdit(true);
      this.setShowPopUpRecommended(true);
    },
    sumatoryVolAccumulated(volumen) {
      this.accumulatedVol += volumen;
      return this.accumulatedVol;
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Irrigation');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Irrigation.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      if (this.enableAddButton) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            elementAttr: {
              class: 'add-task',
            },
            onClick: () => {
              this.setShowPopUp(true);
            },
          },
        });
      }
    },
    onToolbarPreparingRecommended(e) {
      if (this.enableAddButton) {
        e.toolbarOptions.items.unshift({
          location: 'after',
          widget: 'dxButton',
          options: {
            icon: 'add',
            elementAttr: {
              class: 'add-task',
            },
            onClick: () => {
              this.setShowPopUpRecommended(true);
            },
          },
        });
      }
    },
    formatTimeRemovingHoursIfNone(time) {
      const divided = time.split(':');
      if (divided[0] === '0') {
        return `${divided[1]}:${divided[2]}`;
      }
      return time;
    },
    ...mapActions('Irrigation', ['fetchDataIrrigation', 'setActualIrrigation', 'setId', 'setShowPopUp',
      'setShowPopUpRecommended', 'setIdPlantation', 'setIdCompany', 'setActualIrrigationRecommended',
      'setIsEdit', 'deleteIrrigation', 'setIsView', 'setActualIrrigation', 'setIrrigationSelectedId']),
  },
};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}

.position-relative {
  position: relative;
}

.title-top{
  position: absolute;
  left: 10px;
  top: 15px;
  z-index: 9999;
}
.Activa, .Finalizada, .Pendiente {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
}

.Activa {
  color: #219653 !important;
  background: #DEFCE8 !important;
}
.Finalizada {
  color: #74838C;
  background: #F4F5F7;
}
.Pendiente {
  color: #23465E;
  background: white;
}

::v-deep .dx-datagrid-text-content {
  text-transform: none !important;
}
</style>
