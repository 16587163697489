var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-devx"},[_c('f7-block',[_c('f7-row',{staticClass:"flexbox"},[(_vm.editLandSize)?_c('div',{staticClass:"chip-label"},[_c('DxForm',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('ViewLocation_parcel_column_area'),"editor-options":{
              value: parseFloat(_vm.landSize.toFixed(2)),
              mode: 'number',
              placeholder: _vm.$t('ViewLocation_parcel_column_area'),
              showClearButton: true,
              onValueChanged: _vm.onValueChanged,
            }}})],1)],1):_c('div',{staticClass:"chip-label"},[_vm._v("\n        "+_vm._s(_vm.$t("Monitoring_point_area"))+": "+_vm._s(parseFloat(_vm.landSize.toFixed(2)))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"chip-label"},[_vm._v("\n        "+_vm._s(_vm.$t("Monitoring_point_longitude"))+": "+_vm._s(parseFloat(_vm.lang).toFixed(6))+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"chip-label"},[_vm._v("\n        "+_vm._s(_vm.$t("Monitoring_point_latitude"))+": "+_vm._s(parseFloat(_vm.lat).toFixed(6))+"\n      ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }