<template>
  <f7-block>
    <div class="content-devx">
      <DxDataGrid
        :data-source="dataSource"
        :remote-operations="true"
        :row-alternation-enabled="true"
        :show-borders="true"
      >
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxColumn
          v-if="hasATC"
          data-field="owner_fullname"
          :caption="$t('DxAtc_atc')"
        />
        <DxColumn
          data-field="company_name"
          :caption="$t('DxClients_client')"
        />
        <DxColumn
          data-field="subcategory"
          :caption="$t('Searcher.observations.subcategory')"
        />
        <DxColumn
          data-field="explotation_name"
          :caption="$t('ViewPlantation.view_explotation')"
        />
        <DxColumn
          data-field="parcel_name"
          :caption="$t('ViewPlantation.view_name_parcel')"
        />
        <DxColumn
          data-field="sector_name"
          :caption="$t('sector.register.sectorName')"
        />
        <DxColumn
          data-field="sample_id"
          caption="ID"
        />
        <DxColumn
          data-field="species"
          :caption="$t('Plans.crop')"
        />
        <DxColumn
          data-field="variety"
          :caption="$t('Plans.variety')"
        />
        <DxColumn
          data-field="init_date"
          :caption="$t('ViewCampania_init_date')"
        />
        <DxColumn
          data-field="description"
          :caption="$t('searchEngine_description')"
        />
        <DxColumn
          :buttons="editButtons"
          type="buttons"
          width="80"
          :fixed="gridSettings.fixed"
          :fixed-position="gridSettings.fixedPosition"
        />

        <DxPaging :page-size="gridSettings.pageSize" />
        <DxPager
          :visible="true"
          display-mode="full"
          :allowed-page-sizes="pageSizes"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
          info-text="{2} items"
        />
      </DxDataGrid>
    </div>
    <AnalyticsDetailPopup
      v-if="displayPopup"
      :display-popup="displayPopup"
      :data-source="dataSourcePopup"
      @updateDisplayPopup="handleHidePopup"
    />
  </f7-block>
</template>

<script>
import { mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import commonSettings from '@/js/commonSettings.js';
import AnalyticsDetailPopup from './detailPopup/index.vue';

export default {
  name: 'AnalyticsTable',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxPager,
    DxPaging,
    AnalyticsDetailPopup,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      editButtons: [{
        icon: 'find',
        onClick: this.handleShowPopup,
      }],
      displayPopup: false,
      dataSourcePopup: {},
    };
  },
  computed: {
    gridSettings() {
      return commonSettings;
    },
    hasATC() {
      return this.user?.user?.contact?.admin ?? false;
    },
    ...mapState('authentication', ['user']),
  },
  methods: {
    handleShowPopup(e) {
      this.dataSourcePopup = e.row.data;
      this.displayPopup = true;
    },
    handleHidePopup() {
      this.displayPopup = false;
    },
  },
};
</script>
