<template>
  <f7-block class="no-padding">
    <div
      class="content-devx"
    >
      <f7-block>
        <f7-row>
          <f7-col width="20">
            <f7-block-title class="no-padding no-margin">
              {{ $t('Product_Detail_Title') }}
              <f7-block-title />
            </f7-block-title>
          </f7-col>
          <f7-col width="70" />
          <f7-col
            width="10"
            class="justify-content-end"
          >
          <f7-button
            class="dx-btn-pdf"
            raised
            type="default"
            styling-mode="outlined"
            :external="true"
            @click="downloadPDF(productDetail.productId)"
          >
            <f7-icon
              md="material:picture_as_pdf"
            />
          </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
      <f7-block>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('Filter_Commercial_Name') }}
          </f7-col>
          <f7-col>
            {{ $t('Holder') }}
          </f7-col>
          <f7-col>
            {{ $t('Registration_Number') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold margin-bottom">
          <f7-col>
            {{ productDetail.commercialName }}
          </f7-col>
          <f7-col>
            {{ productDetail.holder }}
          </f7-col>
          <f7-col>
            {{ productDetail.registrationNumber }}
          </f7-col>
        </f7-row>
        <f7-row class="margin-bottom">
          <f7-col>
            {{ $t('Active_Principle') }}
          </f7-col>
          <f7-col>
            {{ $t('Display') }}
          </f7-col>
          <f7-col>
            {{ $t('Expiration_Date') }}
          </f7-col>
        </f7-row>
        <f7-row class="bold">
          <f7-col>
            {{ productDetail.activeProduct }}
          </f7-col>
          <f7-col>
            {{ productDetail.containers.toString() }}
          </f7-col>
          <f7-col>
            {{ productDetail.expirationDate }}
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </f7-block>
</template>
<script>
import { Device } from 'framework7/framework7-lite.esm.bundle';
import productVademecumService from '../../../../../services/productVademecumService';

export default {
  name: 'ProductDetail',
  props: {
    productDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isMobile: Device.cordova,
    };
  },
  methods: {
    async downloadPDF(productId) {
      this.$f7.preloader.show();
      try {
        const pdfContent = await productVademecumService.getPdfUrl(productId);
        if (pdfContent) {
          if (this.isMobile) {
            // Descargar y guardar el PDF en dispositivos móviles usando Cordova
            const pdfBlob = new Blob([pdfContent], { type: 'application/pdf' });
            const fileName = `${this.productDetail.registrationNumber} - ${this.productDetail.commercialName}.pdf`;
            window.resolveLocalFileSystemURL(cordova.file.externalRootDirectory + 'download/', (dirEntry) => {
              dirEntry.getFile(fileName, { create: true }, (fileEntry) => {
                fileEntry.createWriter((fileWriter) => {
                  fileWriter.onwriteend = () => {
                    // Notificar al usuario que el archivo se ha guardado
                    this.$f7.dialog.alert('El archivo se ha guardado en el dispositivo.', 'Descarga con éxito');
                    
                    // Abrir el archivo PDF después de guardarlo
                    cordova.plugins.fileOpener2.open(
                      fileEntry.nativeURL,
                      'application/pdf',
                      {
                        error: (e) => console.error('Error al abrir el archivo:', e),
                        success: () => console.log('Archivo abierto con éxito')
                      }
                    );
                  };
                  fileWriter.onerror = (error) => {
                    console.error('Error al guardar el archivo:', error);
                  };
                  fileWriter.write(pdfBlob);
                });
              });
            });
          } else {
              const pdfBlob = new Blob([pdfContent], { type: 'application/pdf' });
              const pdfBlobUrl = URL.createObjectURL(pdfBlob);
              const downloadLink = document.createElement('a');
              downloadLink.href = pdfBlobUrl;
              downloadLink.download = `${this.productDetail.registrationNumber} - ${this.productDetail.commercialName}.pdf`;
              downloadLink.click();
              downloadLink.remove();
              URL.revokeObjectURL(pdfBlobUrl);
              this.$notifyDX({
                message: this.$t('Descarga con éxito'),
                width: 550,
              }, 'success', 3000);
            }
        } else {
          this.$notifyDX({
            message: this.$t('El contenido PDF no esta disponible.'),
            width: 550,
          }, 'error', 3000);
        }
      } catch (error) {
        this.$notifyDX({
          message: this.$t(`${error}`),
          width: 550,
        }, 'error', 3000);
      } finally {
        this.$f7.preloader.hide();
      }
    },
  }
};
</script>
<style lang="scss" scoped>
@import './ProductDetail.styles.scss';
</style>
