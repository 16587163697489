<template>
  <f7-block class="content-devx-margin-block no-margin-horizontal margin-bottom">
    <f7-row>
      <f7-col width="50">
        <div v-if="!loadedGIS || currentIndexes[`${mainMap}`] === ''">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <GISWMSTimeDimensionMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocations"
            :layer="layer"
            :index="currentIndexes[`${mainMap}`]"
            :center="center"
            :bbox="bbox"
            :height="'540px'"
            :companyId="currentPlantation.companyId"
          />
        </div>
      </f7-col>
      <f7-col width="50">
        <GISLayerOptions :key-id="`${mainMap}`" :enable-date-picker="true" />
        <f7-block v-if="!timelineIndexes">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </f7-block>

        <f7-row v-else>
          <TimelineGraph
            :timeline-indexes="timelineIndexesFiltered"
            :index="currentIndexes.GisTimeDimension"
            :tasks="tasks"
            :observations="observations"
          />
        </f7-row>
        <f7-row v-if="loadedGIS">
          <f7-col width="100">
          <ExclusionDates
            :initial-dates="initialExcludeDates"
            :initial-disabled-dates="disabledDates"
            :min="min"
            :max="max"
            @exclusionDatesChanged="exclusionDatesChanged"
          />
          </f7-col>
        </f7-row>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import GISWMSTimeDimensionMap from '../../../Gis/GISWMSTimeDimensionMap/index.vue';
import GISLayerOptions from '../../../Gis/LayerOptions/index.vue';
import ExclusionDates from '../../../exclusionDates/exclusionDates.vue';
import TimelineGraph from '../timelineGraph/index.vue';
import Api from '../../../../services/Api';
import EventBus from '../../../../js/event-bus';

export default {
  name: 'GisTimeDimension',
  components: {
    GISWMSTimeDimensionMap,
    GISLayerOptions,
    TimelineGraph,
    ExclusionDates,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'GisTimeDimension',
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      center: {},
      bbox: {},
      filteredTasks: null,
      ws: Api.getGeoServerWorkspace(),
      initialExcludeDates: [],
      excludedDates: [],
    };
  },
  computed: {
    /**
     * Retorna un array de fechas en formato Date que contiene las fechas,
     * no disponibles, en base a las fechas con imágenes de índices disponibles
     * @return {Array<Date>} Array de fechas en formato Date
     */
    disabledDates() {
      const disabledDates = [];

      const availableDates = this.datesList.avaiable_dates.map((avaiableDate) => {
        return new Date(avaiableDate);
      });

      // Esto es necesario porque las fechas excluidas pueden ser mayores a las fechas disponibles
      // debido a que en el que el usuario, previamente, pudo haber excluido fechas que luego fueron
      // eliminadas de la lista de fechas disponibles
      this.initialExcludeDates.forEach((date) => {
        if (!availableDates.some(avaiableDate => avaiableDate.toISOString().split('T')[0] === date)) {
          availableDates.push(new Date(date));
        }
      });

      let currentDate = new Date(this.min);
      while (currentDate <= this.max) {
        // Creamos una nueva instancia de Date para asegurar que no haya referencias compartidas
        const currentDateInstance = new Date(currentDate);
        if (!availableDates.some(date => date.toISOString().split('T')[0] === currentDateInstance.toISOString().split('T')[0])) {
          disabledDates.push(currentDateInstance);
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return disabledDates;
    },
    min() {
      if (this.datesList.avaiable_dates.length > 0) {
        return moment(this.datesList.avaiable_dates[0]).toDate();
      }
      return undefined;
    },
    max() {
      if (this.datesList.avaiable_dates.length > 0) {
        return moment(this.datesList.avaiable_dates[this.datesList.avaiable_dates.length - 1]).toDate();
      }
      return undefined;
    },
    timelineIndexesFiltered() {
      if (this.timelineIndexes) {
        return this.timelineIndexes.filter((timelineIndex) => {
          return !this.excludedDates.includes(timelineIndex.date);
        });
      }
      return [];
    },
    ...mapState('Plantation', ['treeLocations', 'currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'currentInitIntervalDates', 'currentEndIntervalDates', 'timelineIndexes', 'currentBoundsWMSLayer', 'datesList']),
    ...mapState('TimeDimension', ['tasks', 'observations']),
    ...mapState('TaskByCompany', ['taskActivity']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      this.$fa.logEvent('buscador_gis_linea_temporal', {
        content_type: 'button',
        description: 'Evento cuando se accede a la línea temporal de un sector',
        sectorId: this.currentPlantation.sectorId,
      });
      this.setTimelineIndexes(null);
      this.setCurrentIndexes({
        key: this.mainMap,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key: this.mainMap,
        newCurrentInitIntervalDate: moment(this.currentPlantation.initDate).format('YYYY-MM-DDT00:00:00'),
      });
      this.setCurrentEndIntervalDates({
        key: this.mainMap,
        newCurrentEndIntervalDate: moment().format('YYYY-MM-DDT00:00:00'),
      });
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
      // Manejamos el error get_index_stats
      try {
        await this.fetchTimelineIndexes({
          location: this.currentPlantation.sectorId,
          initDate: this.currentPlantation.initDate,
          endDate: moment().format('YYYY-MM-DD'),
          companyId: this.currentPlantation.companyId,
        });
      } catch (error) {
        this.$notifyDX({
          message: this.$t('downloadReportError'),
          width: 550,
        }, 'error', 7000);
      }

      await this.getTasksForActivity();
      await Promise.all([this.getTasks(), this.getObservations()]);
      try {
        const xhr = await Api.getElement(
          `/location_gis_dates/${this.currentPlantation.sectorId}/`,
          {},
          this.currentPlantation.companyId
        );
        this.initialExcludeDates = JSON.parse(xhr.response).dates_excluded.map((date) => {
          return moment(date).format('YYYY-MM-DD');
        });
        this.excludedDates = [...this.initialExcludeDates];
      } catch (error) {
        // Si es un error 404 se ignora, ya que es un comportamiento normal cuando no hay fechas excluidas
        if (error.cause !== 404) {
          throw error;
        }
      }

      EventBus.$on('filteringTasks', this.getTasks);
      EventBus.$on('filteringTasks', this.getObservations);

      this.loadedGIS = true;
    } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
            width: 550,
          },
          'warning',
          3000,
        );
    } finally {
      this.$f7.preloader.hide();
    }
  },
  beforeDestroy() {
    EventBus.$off('filteringTasks', this.getTasks);
    EventBus.$off('filteringTasks', this.getObservations);
  },
  methods: {
    async exclusionDatesChanged(dates) {
      this.excludedDates = dates;
      const datesExcluded = this.excludedDates.map((date) => {
        return moment(date).format('YYYY-MM-DDT00:00:00');
      });
      try {
        this.$f7.preloader.show();
        const payload = {
          dates_excluded: datesExcluded,
          location_id: this.currentPlantation.sectorId,
        };
        await Api.createUpdateElement('/location_gis_dates/', payload, this.currentPlantation.companyId, 'POST');
        await this.fetchTimelineIndexes({
          location: this.currentPlantation.sectorId, initDate: this.currentPlantation.initDate, endDate: moment().format('YYYY-MM-DD'), companyId: this.currentPlantation.companyId,
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async getTasks() {
      try {
        this.resetData();

        for (const activity of this.taskActivity) {
          await this.fetchTasks({
            idCompany: this.currentPlantation.companyId,
            idPlantation: this.currentPlantation.id,
            activity,
            initDate: moment(this.currentInitIntervalDates.GisTimeDimension).format('YYYY-MM-DD'),
            endDate: moment(this.currentEndIntervalDates.GisTimeDimension).format('YYYY-MM-DD'),
          });
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    async getObservations() {
      try {
        await this.fetchObservations({
          idCompany: this.currentPlantation.companyId,
          idPlantation: this.currentPlantation.id,
          category: 'Incidencia',
          initDate: moment(this.currentInitIntervalDates.GisTimeDimension).format('YYYY-MM-DD'),
          endDate: moment(this.currentEndIntervalDates.GisTimeDimension).format('YYYY-MM-DD'),
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchTimelineIndexes', 'setTimelineIndexes', 'boundsWMSLayer']),
    ...mapActions('TimeDimension', ['fetchTasks', 'resetData', 'fetchObservations']),
    ...mapActions('TaskByCompany', ['getTasksForActivity']),

  },
};
</script>

<style lang="scss" scoped>
@import './GisTimeDimension.styles.scss';
</style>
