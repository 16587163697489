<template>
  <div class="content-devx content-separatos">
    <DxChart
      style="width: 100%"
      palette="SoftPastel"
      :data-source="chartData"
      @legend-click="onLegendClick"
    >
      <DxSeriesTemplate name-field="productName" />
      <DxTooltip
        :enabled="true"
        :z-index="999999"
        :customize-tooltip="customizeTooltip"
      />
      <DxCommonSeriesSettings
        argument-field="monthWeek"
        value-field="value"
        type="stackedbar"
      />
      <DxLegend
        vertical-alignment="top"
        horizontal-alignment="center"
        item-text-position="right"
      />
      <DxSize :height="210" />
    </DxChart>
  </div>
</template>
<script>
import {
  DxChart,
  DxCommonSeriesSettings,
  DxLegend,
  DxSeriesTemplate,
  DxTooltip,
  DxSize,
} from 'devextreme-vue/chart';

export default {
  name: 'ResumePerProductsChart',
  components: {
    DxChart,
    DxCommonSeriesSettings,
    DxLegend,
    DxSeriesTemplate,
    DxTooltip,
    DxSize,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartData: [],
    };
  },
  mounted() {
    this.getChartData();
  },
  methods: {
    getChartData() {
      const isMensual = this.plan.period === 'MENSUAL';
      this.chartData = [];

      this.plan.contribution_products.forEach((product) => {
        product.periods.forEach((period, index) => {
          this.chartData.push({
            productName: product.product_name,
            monthWeek: isMensual
              ? `${index + 1}º ${this.$t('monthFullName')}`
              : `${index + 1}º ${this.$t('weekFullName')}`,
            value: period,
            index: index + 1,
          });
        });
      });

      // this.chartData = this.chartData.sort((a, b) => a.monthWeek.localeCompare(b.monthWeek));
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(point) {
      const color = point.point.getColor();

      const tableStyle = 'border-spacing: 0;border-collapse: collapse;font-size: 13px;width: 250px;overflow: auto;border-bottom: 1px solid #ccc;';
      const thStyle = 'width: 60px;white-space: nowrap;border: 1px solid #ccc;padding: 0 5px;';
      const tdStyle = 'min-width: 130px;border: 1px solid #ccc;padding: 4px;';

      return {
        html: `
          <div style='text-align: center; margin-bottom: 0.6rem; font-weight: 600'>
            <span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; margin-right: 5px; border-radius: 9999px;"></span>
            <span>${point.argumentText}</span>
          </div>
          <table style="${tableStyle}">
            <tbody>
              <tr>
                <th style="${thStyle}">${this.$t('product')}</th>
                <td style="${tdStyle}">${point.seriesName}</td>
              </tr>
              <tr>
                <th style="${thStyle}">${this.$t('weather_parameter_value')}</th>
                <td style="${tdStyle}">${point.value}</td>
              </tr>
            </tbody>
          </table>
        `,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import './referencePlansDetail.styles.scss';
</style>
