import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import en from './languageEN';
import es from './languageES';

Vue.use(VueI18Next);
const dictionaries = { // TODO: Estos diccionarios deberían de llegar desde el back
  en: en.en,
  es: es.es,
};

i18next.init({
  lng: 'es',
  fallbackLng: 'en',
  whitelist: ['en', 'es'],
  resources: dictionaries,
});
export default new VueI18Next(i18next);
