var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-block',[_c('DxForm',[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"col-span":1,"data-field":_vm.$t('layerOptions_select_index'),"caption":_vm.$t('layerOptions_select_index'),"editor-type":"dxSelectBox","editor-options":{
          items: _vm.indexAvailables,
          value: _vm.currentIndexes[`${_vm.keyId}`],
          placeholder: _vm.$t('layerOptions_index_availables'),
          onValueChanged: _vm.onSelectedIndex,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('DxRegisterCampania_end_date_require')}})],1),_vm._v(" "),_c('DxSimpleItem',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticStyle:{"display":"flex","gap":"1rem"}},[(_vm.enableDatePicker)?_c('f7-input',{staticClass:"recepDate recep-date-picker",attrs:{"type":"datepicker","placeholder":_vm.$t('PlanningComponent_datepicker_multiple_placeholder'),"clear-button":true,"close-on-select":true,"value":_vm.layerOptionsDates,"calendar-params":{
                closeOnSelect: true,
                rangePicker: true,
                dateFormat: {
                  month: 'numeric',
                  day: 'numeric',
                  year: 'numeric',
                },
              }},on:{"calendar:change":_vm.setLayerOptionsDates}}):_vm._e(),_vm._v(" "),_c('div',[_c('ContextMenu',{attrs:{"items":_vm.items}})],1)],1)]},proxy:true}])}),_vm._v(" "),(!_vm.enableDatePicker)?_c('DxSimpleItem',{attrs:{"name":"init-date","data-field":_vm.$t('DxRegisterCampania_fecha_inicio'),"editor-type":"dxDateBox","editor-options":{
          value: _vm.actualInitDate,
          onValueChanged: _vm.onChangeInitDate,
          displayFormat: 'dd/MM/yyyy',
          placeholder: _vm.$t('registerClima_initDate_placeholder'),
          invalidDateMessage: _vm.$t('dates_isInvalid'),
          pickerType: 'calendar',
        }}}):_vm._e(),_vm._v(" "),(!_vm.enableDatePicker)?_c('DxSimpleItem',{attrs:{"name":"end-date","data-field":_vm.$t('DxRegisterCampania_fecha_finalizacion'),"editor-type":"dxDateBox","editor-options":{
          value: _vm.actualEndDate,
          onValueChanged: _vm.onChangeEndDate,
          displayFormat: 'dd/MM/yyyy',
          placeholder: _vm.$t('registerClima_endDate_placeholder'),
          invalidDateMessage: _vm.$t('dates_isInvalid'),
          pickerType: 'calendar',
        }}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }