var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-devx no-padding"},[_c('f7-block',{staticClass:"no-padding"},[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('title_register_plantation')}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.specie_botanic'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.speciesAvailables,
              value: _vm.specieSelected,
              showClearButton: true,
              searchEnabled:true,
              onValueChanged: _vm.onChangeSpecieBotanic,
              searchEnabled:true
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.variety')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.varietiesPaginated,"on-value-changed":_vm.onChangeVariety,"value":_vm.varietySelected,"display-expr":"name","value-expr":"name","search-enabled":true}})]},proxy:true}])})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.distance_between_streets')} (m)`,"editor-type":"dxNumberBox","editor-options":{
              value: _vm.mulUno,
              onValueChanged: _vm.onChangeMulUno,
            },"is-required":true}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.distance_between_feets')} (m)`,"editor-type":"dxNumberBox","editor-options":{
              value: _vm.mulDos,
              onValueChanged: _vm.onChangeMulDos,
            },"is-required":true}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.plants_density')}(${_vm.$t('registerPlantation.unit_plantas_ha')})`,"editor-type":"dxNumberBox","editor-options":{
              value: _vm.calculatePlantsDensity,
              readOnly: true,
              onValueChanged: _vm.onChangePlantsDensity,
            }}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"name":"plants_numbere","editor-type":"dxNumberBox","data-field":_vm.$t('registerPlantation.plants_number'),"editor-options":{
              value: _vm.plantsNumber,
              readOnly: false,
              onValueChanged: _vm.onChangePlantsNumber,
            }}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"editor-type":"dxNumberBox","data-field":`${_vm.$t('registerPlantation.cup_diameter')} (m)`,"editor-options":{
              value: _vm.cupDiameter,
              onValueChanged: _vm.onChangeCupDiameter,
            },"is-required":false}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.plantation_date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangePlantationDate,
              value: _vm.isEdit && _vm.actualPlantation.plantation_date !== null ? _vm.actualPlantation.plantation_date : _vm.plantationDate,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              pickerType: 'calendar',
            }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.expected_production'),"editor-type":"dxNumberBox","editor-options":{
              value: _vm.expectedProduction,
              onValueChanged: _vm.onChangeExpectedProduction,
              readOnly: false,
            }}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }