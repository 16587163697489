var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-devx no-padding-top no-padding-bottom"},[_c('f7-block',{staticClass:"no-padding"},[_c('DxForm',{attrs:{"id":"form","col-count":1,"caption":_vm.$t('title_register_plantation'),"align-item-labels-in-all-groups":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.specie_botanic'),"editor-type":"dxSelectBox","editor-options":{
            items: _vm.speciesAvailables,
            value: _vm.specieSelected,
            showClearButton: true,
            searchEnabled:true,
            onValueChanged: _vm.onChangeSpecieBotanic,
          }}},[_c('DxRequiredRule',{attrs:{"message":""}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.variety')},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('DxSelectBox',{attrs:{"data-source":_vm.varietiesPaginated,"on-value-changed":_vm.onChangeVariety,"value":_vm.varietySelected,"display-expr":"name","value-expr":"name","search-enabled":true}})]},proxy:true}])}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.distance_between_streets')} (m)`,"editor-type":"dxNumberBox","editor-options":{
            value: _vm.mulUno,
            onValueChanged: _vm.onChangeMulUno,
          },"is-required":true}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.distance_between_feets')} (m)`,"editor-type":"dxNumberBox","editor-options":{
            value: _vm.mulDos,
            onValueChanged: _vm.onChangeMulDos,
          },"is-required":true}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":`${_vm.$t('registerPlantation.plants_density')}(${_vm.$t('registerPlantation.unit_plantas_ha')})`,"editor-type":"dxNumberBox","editor-options":{
            value: _vm.calculatePlantsDensity,
            onValueChanged: _vm.onChangePlantDensity,
            readOnly: true,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.plants_number'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.plantsNumber,
            onValueChanged: _vm.onChangePlantsNumber,
            readOnly: false,
          }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerPlantation.expected_production'),"editor-type":"dxNumberBox","editor-options":{
            value: _vm.expectedProduction,
            onValueChanged: _vm.onChangeExpectedProduction,
            readOnly: false,
          }}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }