<template>
  <f7-page>
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Vademecum_Title')}`" />
    <ProductDetail :product-detail="phytosanitaryProductDetail[0].productDetail" />
    <UsesDosesAuthPlague :uses-doses-auth="phytosanitaryProductDetail[0].usesDosesAuth" />
    <f7-block-footer
      class="go-back-button"
    >
      <f7-button
        class="dx-btn-cancel btnBack"
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToVademecum"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import ProductDetail from '../../../components/utilities/vademecum/phytosanitaryProductDetail/productDetail/index.vue';
import UsesDosesAuthPlague from '../../../components/utilities/vademecum/phytosanitaryProductDetail/usesDosesAuthPlague/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'ViewPhytosanitaryProductPlagueDetail',
  components: {
    navbar,
    ProductDetail,
    UsesDosesAuthPlague,
  },
  computed: {
    ...mapState('Vademecum', ['phytosanitaryProductDetail']),
  },
  methods: {
    goToVademecum() {
      this.$f7.views.main.router.navigate('/vademecum/phytosanitary/', { reloadCurrent: true });
    },
  },
};
</script>
