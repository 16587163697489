<template>
    <div class="field">
      <div class="field__label">
        {{ this.$t('nutritionDetail.report.clouds') }}
      </div>
      <div class="field__value">
        <DxNumberBox
          v-model="cloudsData"
          :readOnly=true
        />
      </div>
    </div>
</template>
  
<script>
import DxNumberBox from 'devextreme-vue/number-box';

export default {
  name: 'CloudCoverage',
  components: {
      DxNumberBox,
  },
  props: {
    cloudsData: {
      type: Number,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.field {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 23px 0 8px;

  &__label {
    opacity: 0.75;
    font-size: 12px;
    letter-spacing: -0.01em;
  }

  &__value {
    width: 50px;
    padding-left: 7px;
    height: 40px;
  }
}

@media (max-width: 768px) {
  .field {
    padding: 10px 20px 0px 8px;
  }
}
</style>