<template>
  <f7-page>
    <navbar
      :text="`${$t('Utilities_Title')} > ${$t(
        'LeftPanel_title_referencePlans'
      )} > ${group}`"
    />

    <ListByGroup
      :reference-plans="tableData"
      @remove-plan="removePlan"
    />

    <div class="footer-buttons-page">
      <f7-button
        class="dx-btn-cancel btnBack"
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToGroupList"
      >
        {{ $t('Go_Back') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import navbar from '../../../components/NavBar.vue';
import ListByGroup from '../../../components/utilities/referencePlans/ListByGroup.vue';
import Api from '@/services/Api';

export default {
  name: 'ReferencePlansListByGroup',
  components: {
    navbar,
    ListByGroup,
  },
  data() {
    return {
      group: null,
      tableData: [],
    };
  },
  created() {
    this.group = this.$f7route.params.group;
  },
  async mounted() {
    await this.mockData();
  },
  methods: {
    goToGroupList() {
      this.$f7router.navigate({
        name: 'reference-plans',
      });
    },
    async mockData() {
      const mockData = [];

      const xhr = await Api.getReferencePlans({ category: this.group.toUpperCase() });
      const data = JSON.parse(xhr.response);

      data.forEach((item) => {
        mockData.push({
          id: item.id,
          description: item.description,
          species: item.plantation_specie,
          variety: item.plantation_variety,
          duration: item.duration,
          production: item.production,
          cost: item?.cost ?? 0,
          ufEfficient: {
            n: item.uf_efficiency_provided.n,
            p: item.uf_efficiency_provided.p205,
            k: item.uf_efficiency_provided.k20,
            c: item.uf_efficiency_provided.ca0,
            m: item.uf_efficiency_provided.mg0,
            s: item.uf_efficiency_provided.s03,
          },
        });
      });

      this.tableData = mockData;
    },
    async removePlan(data) {
      await Api.deleteReferencePlan(data.data.data.id).then(() => {
        this.mockData();
      });
    },
  },
};
</script>
