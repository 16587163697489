<template>
  <div class="content-devx">
    <CalendarFilter
      @searchFilter="searchFilter"
      @clearFilteredData="clearFilteredData"
    />

    <f7-button
      id="panel-right-risk-btn"
      fill
      class="btn-blue filter-button-position"
      style="text-transform: none"
      panel-toggle="right"
    >
      {{ $t('attributes') }}
    </f7-button>

    <DxDataGrid
      ref="dataGridplantations"
      :data-source="dataSource"
      :show-borders="true"
      :remote-operations="true"
      :column-auto-width="true"
      :show-colon="false"
      @exporting="onExporting"
      @contentReady="onInit"
    >
      <DxStateStoring
        :enabled="false"
        type="localStorage"
        storage-key="SearchEngine"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping :auto-expand-all="true" />
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
        info-text="{2} items"
      />
      <DxExport :enabled="false" />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        v-if="hasATC"
        data-field="owner_fullname"
        :caption="$t('DxAtc_atc')"
      />
      <DxColumn
        data-field="company_name"
        :caption="$t('DxClients_client')"
      />
      <DxColumn
        data-field="name"
        :caption="$t('searchEngine_description')"
        alignment="left"
      />
      <DxColumn
        data-field="province_name"
        :caption="$t('searchEngine_province')"
        alignment="left"
      />
      <DxColumn
        data-field="explotation_name"
        :caption="$t('searchEngine_farm')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="parcel_name"
        :caption="$t('searchEngine_parcel')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="sector_name"
        :caption="$t('searchEngine_sector')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="status"
        :caption="$t('searchEngine_state')"
        alignment="left"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
        :width="100"
        :filter-values="['ACTIVA']"
        :allow-header-filtering="true"
        sort-order="asc"
        cell-template="cellTemplate"
      />
      <DxColumn
        data-field="species"
        :caption="$t('searchEngine_botanical_species')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('searchEngine_variety')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="showColumn('Protección')"
        data-field="protection"
        :caption="$t('ViewPlantation.view_protection')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="showColumn('Manejo')"
        data-field="management"
        :caption="$t('ViewPlantation.view_management')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="showColumn('Tipo de cultivo')"
        data-field="crop_type"
        :caption="$t('ViewPlantation.view_crop_type')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="showColumn('Sistema de riego')"
        data-field="irrigation_type"
        :caption="$t('sector.register.irrigationType')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        v-if="showColumn('Textura')"
        data-field="ground_texture"
        :caption="$t('sector.register.groundTexture')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="init_date"
        data-type="date"
        format="dd/MM/yyyy"
        :caption="$t('searchEngine_init_date')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="end_date"
        data-type="date"
        format="dd/MM/yyyy"
        :caption="$t('searchEngine_end_date')"
        alignment="right"
        :allow-header-filtering="false"
      />
      <DxColumn
        :width="gridSettings.buttonsWidth"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
        cell-template="buttonsColumnTemplate"
        alignment="center"
      />
      <template #buttonsColumnTemplate="{ data: plantationData }">
        <div class="button-plantation">
          <DxButton
            styling-mode="text"
            class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-search"
            type="success"
            icon="search"
            @click="goToMainMenu(plantationData)"
          />
        </div>
      </template>
      <template #cellTemplate="{ data: dataDateEnd }">
        <div :class="setClassByStatus(dataDateEnd.data)">
          {{ $t(dataDateEnd.data.status) }}
        </div>
      </template>
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import DxButton from 'devextreme-vue/button';
import settings from '../../js/commonSettings';
import customStoreMixins from '../../js/customStore';
import CalendarFilter from './calendarFilter/index.vue';

export default {
  name: 'SearchEngine',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxButton,
    DxPaging,
    CalendarFilter,
  },
  mixins: [customStoreMixins],
  props: {
    goToView: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      moduleName: 'contact/plantation',
      tasksAvailables: [],
      pageSizes: [5, 10, 20],
      filteredDataSource: null,
      actualDate: moment().format('YYYY-MM-DD'),
      format: 'shortDate',
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    hasATC() {
      return this.user?.user?.contact?.admin ?? false;
    },
    ...mapState('SearcherFilters', ['searchFromDate', 'searchToDate']),
    ...mapState('authentication', ['user']),
    ...mapState('Filters', ['searchHome']),
  },
  methods: {
    showColumn(column) {
      return this.searchHome.includes(column);
    },
    onInit() {
      const headerRow = document.querySelectorAll('[role="presentation"] .dx-row.dx-column-lines.dx-header-row')[1];
      if (headerRow) {
        const hiddenCell = headerRow.querySelector('.dx-hidden-cell');

        if (hiddenCell) {
          hiddenCell.classList.remove('dx-hidden-cell');
        }
      }
      Array.from(document.getElementsByClassName('dx-group-cell')).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText).format('DD-MM-YYYY');
    },
    searchFilter() {
      if (!this.searchFromDate || !this.searchToDate) {
        this.$notifyDX(
          {
            message: this.$t('chooseDatesError'),
            width: 450,
          },
          'error',
          2000,
        );
        return;
      }

      this.$emit('search-plantation-by-dates', {
        fromDate: this.searchFromDate,
        toDate: this.searchToDate,
      });
    },
    clearFilteredData() {
      const dataGrid = this.$refs.dataGridplantations.instance;
      dataGrid.clearFilter();

      this.$emit('clear-filtered-data');
    },
    // SET CSS CLASSES TO CELLS
    setClassByStatus(data) {
      const status = this.$t(data.status);

      let colorCell = '';
      switch (status) {
        case this.$t('ACTIVA'):
          colorCell = 'Activa';
          break;
        case this.$t('FINALIZADA'):
          colorCell = 'Finalizada';
          break;
        case this.$t('PENDIENTE'):
          colorCell = 'Pendiente';
          break;
        default:
          break;
      }
      return colorCell;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('SearchEngine');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SearchEngine.xlsx');
          });
      });
      e.cancel = true;
    },
    async goToMainMenu(rowSelected) {
      this.$f7.preloader.show();
      // Se hace una serie de transformaciones porque eran las que se hacían
      // en el store de pagination.js (para contactPlantation) y debemos
      // conservarlas para mantener la compatibilidad con la lógica legacy:
      rowSelected.row.data.companyId = rowSelected.row.data.company_id;
      rowSelected.row.data.sectorId = rowSelected.row.data.sector_id;
      rowSelected.row.data.endDate = rowSelected.row.data.end_date;
      rowSelected.row.data.initDate = rowSelected.row.data.init_date;
      rowSelected.row.data.company = rowSelected.row.data.company_name;
      rowSelected.row.data.province = rowSelected.row.data.province_name;
      rowSelected.row.data.explotation = rowSelected.row.data.explotation_name;
      rowSelected.row.data.parcel = rowSelected.row.data.parcel_name;
      rowSelected.row.data.sector = rowSelected.row.data.sector_name;
      rowSelected.row.data.campaign = rowSelected.row.data.campaign_name;
      const data = {
        companyId: rowSelected.row.data.company_id,
        plantationId: rowSelected.row.data.id,
      };
      this.$fa.logEvent('buscador_lupa', {
        plantationId: rowSelected.row.data.id,
        name: rowSelected.row.data.name,
        content_type: 'button',
        description: 'Evento al seleccionar la lupa de cualquier plantación'
      });
      await this.getCompanyById(rowSelected.row.data.company_id);
      await this.getPlantationById(data);
      this.setCurrentPlantation(rowSelected.row.data);
      this.setSearcherPlantation(rowSelected.row.data);
      this.setOldPlantation(rowSelected.row.data);
      this.setCurrentPlanId('');
      this.setClearFilter('searcher/weatherGraph');
      this.setCurrentTab('home');
      this.$f7.preloader.hide();
      if (this.goToView.includes('home')) {
        this.$f7.views.main.router.navigate('/searcher/', { reloadCurrent: true });
      } else {
        this.$f7.views.main.router.navigate(this.goToView, { reloadCurrent: true });
      }

      this.setAccordionItemOpened();
    },
    setAccordionItemOpened() {
      Array.from(document.getElementsByClassName('accordion-item'))[0].classList.add('accordion-item-opened');
    },
    ...mapActions('Plantation', ['setCurrentPlantation', 'setOldPlantation', 'setSearcherPlantation']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('CalendarInitEndDate', ['setClearFilter']),
    ...mapActions('Utilities', ['setCurrentTab']),
    ...mapActions('Plans', ['setCurrentPlanId']),
    ...mapActions('Explotaciones', ['getCompanyById']),
  },
  watch: {
    extraParams() {
      this.$refs.dataGridplantations.instance.refresh();
    },
  },

};
</script>
<style scoped>
.content-status-cell {
  padding: 0;
}
.Activa, .Finalizada, .Pendiente {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
  text-align:center;
}

.Activa {
  color: #219653 !important;
  background: #DEFCE8 !important;
  border-width: 1px;
    border-style: solid;
    border-color: #219653;
}
.Finalizada {
  color: #74838C;
  background: #F4F5F7;
  border-width: 1px;
    border-style: solid;
    border-color: #74838C;
}
.Pendiente {
  color: #23465E;
  background: white;
  border-width: 1px;
    border-style: solid;
    border-color: #23465E;
}

.filter-button-position {
  color: white;
  font-family: Arial, sans-serif;
  font-size: 13.33px;
  font-weight: 100 !important;
  height: 36px;
  line-height: 38px;
  position: absolute;
  right: 308px;
  width: 108px;
  z-index: 90;
}

.button-plantation ::v-deep .dx-button-content {
  padding: 3px;
}
</style>
