<template>
  <section class="converter-water">
    <div class="content-devx">
      <div class="card-header padding-left-half">
        {{ $t('Utilities.converterWater.title') }}
      </div>
      <div class="card-body main">
        <div class="top margin-vertical padding-horizontal">
          <DxRadioGroup
            ref="rgUnit"
            class="radio-buttons"
            :data-source="itemUnitsWater"
            display-expr="label"
            layout="horizontal"
            @valueChanged="onChangeUnit"
          />
          <div class="dx-field">
            <div class="dx-field-label">
              {{ $t('Utilities.converterWater.m3') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbM3"
                v-model="unitSelected.m3"
                @keyUp="changeM3"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body main">
        <div class="card-header padding-left-half">
          {{ $t('Utilities.converterWater.bottomTitle') }}
        </div>
        <div class="dx-field-section padding-horizontal">
          <div class="dx-field">
            <div class="dx-field-label text-align-right">
              {{ $t('Utilities.converterWater.meq') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbMeq"
                v-model="unitSelected.meq"
                @keyUp="changeMeq"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label text-align-right">
              {{ $t('Utilities.converterWater.mg') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbMg"
                :value="unitSelected.mg"
                @keyUp="changeMg"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label text-align-right">
              {{ $t('Utilities.converterWater.kg') }}
            </div>
            <div class="dx-field-value-static input-zoom">
              <DxNumberBox
                ref="nbKg"
                v-model="unitSelected.kg"
                :read-only="true"
                @keyUp="changeKg"
              />
            </div>
          </div>
          <DxButton
            :text="$t('Utilities.converterWater.reset')"
            type="default"
            @click="cleanData"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DxRadioGroup from 'devextreme-vue/radio-group';
import DxNumberBox from 'devextreme-vue/number-box';
import DxButton from 'devextreme-vue/button';
import { mapState } from 'vuex';

export default {
  name: 'ConverterWater',
  components: {
    DxRadioGroup,
    DxNumberBox,
    DxButton,
  },
  props: [],
  data() {
    return {
      unitSelected: { },
      unitStatic: { },
    };
  },
  computed: {
    ...mapState('Converter', ['itemUnitsWater']),
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.$refs.rgUnit.instance.option('value', this.itemUnitsWater[0]);
      this.unitSelected = { ...this.itemUnitsWater[0] };
      this.unitStatic = { ...this.itemUnitsWater[0] };
    },
    cleanData() {
      this.initData();
    },
    onChangeUnit(e) {
      this.unitSelected = { ...e.value };
      this.unitStatic = { ...e.value };
    },
    changeMeq(e) {
      this.unitSelected.mg = e.event.target.value * this.unitStatic.mg;
      this.unitSelected.kg = (this.unitSelected.mg * this.unitSelected.m3) / 1000;
      this.unitSelected.mg = parseFloat(this.unitSelected.mg.toFixed(2));
      this.unitSelected.kg = parseFloat(this.unitSelected.kg.toFixed(2));
    },
    changeMg(e) {
      this.unitSelected.meq = e.event.target.value / this.unitStatic.mg;
      this.unitSelected.kg = (e.event.target.value * this.unitSelected.m3) / 1000;
      this.unitSelected.kg = parseFloat(this.unitSelected.kg.toFixed(2));
      this.unitSelected.meq = parseFloat(this.unitSelected.meq.toFixed(2));
    },
    changeKg(e) {
      this.unitSelected.mg = (e.event.target.value * 1000) / this.unitSelected.m3;
      this.unitSelected.meq = this.unitSelected.mg / this.unitStatic.mg;
      this.unitSelected.mg = parseFloat(this.unitSelected.mg.toFixed(2));
      this.unitSelected.meq = parseFloat(this.unitSelected.meq.toFixed(2));
    },
    changeM3(e) {
      this.unitSelected.meq = this.unitSelected.mg / this.unitStatic.mg;
      this.unitSelected.mg = this.unitStatic.meq * this.unitStatic.mg;
      this.unitSelected.kg = (this.unitSelected.mg * e.event.target.value) / 1000;
      this.unitSelected.mg = parseFloat(this.unitSelected.mg.toFixed(2));
      this.unitSelected.meq = parseFloat(this.unitSelected.meq.toFixed(2));
      this.unitSelected.kg = parseFloat(this.unitSelected.kg.toFixed(2));
    },
  },
};

</script>
<style src="./converterWater.scss" scoped lang="scss"></style>
