<template>
  <section class="product-resume">
    <div class="content-devx position-relative">
      <div class="card-header-title title-top">
        {{ $t('productResume.productResume') }}
      </div>
      <div
        class="card-body margin-left-block margin-right-block margin-bottom-block"
      >
        <section class="area margin-bottom-block">
          <div class="dx-field justify-content-end">
            <div class="margin-right-block">
              {{ $t('productResume.area') }}
            </div>
            <div>
              <DxNumberBox
                :read-only="true"
                :value="getArea"
                format="#0.00"
              />
            </div>
          </div>
        </section>
        <section class="bottom">
          <DxDataGrid
            class="data-grid-product-resume"
            :data-source="stepData.productResume"
            :word-wrap-enabled="true"
            :show-borders="true"
            :on-editing-start="onEditingStart"
            @cell-prepared="onCellPrepared"
          >
            <DxEditing
              :allow-updating="true"
              mode="cell"
            />
            <DxColumn
              data-field="product"
              :caption="$t('productResume.product')"
              :allow-editing="false"
              :allow-sorting="false"
            />

            <DxColumn
              v-if="!isNew"
              :caption="$t('productResume.dosageUnit')"
              data-field="dosageUnit"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              v-if="isNew"
              caption="Dosis unitaria"
              alignment="center"
            >
              <DxColumn
                caption="kg/ha"
                data-field="dosageUnitKg"
                data-type="number"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
              />
              <DxColumn
                caption="l/ha"
                data-field="dosageUnitL"
                name="dosageUnitL"
                data-type="number"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
              />
            </DxColumn>

            <DxColumn
              v-if="!isNew && showPlantColumn"
              :caption="$t('productResume.dosagePlant')"
              data-field="dosagePlant"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              v-if="isNew && showPlantColumn"
              caption="Dosis por planta"
              alignment="center"
            >
              <DxColumn
                caption="g/planta"
                data-field="dosagePlantKg"
                name="dosagePlantKg"
                data-type="number"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
              />
              <DxColumn
                caption="ml/planta"
                data-field="dosagePlantL"
                name="dosagePlantL"
                data-type="number"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
              />
            </DxColumn>

            <DxColumn
              v-if="!isNew"
              :caption="$t('productResume.dosageTotal')"
              data-field="dosageTotal"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              v-if="isNew"
              caption="Dosis Total"
              alignment="center"
            >
              <DxColumn
                caption="kg"
                data-field="dosageTotalKg"
                data-type="number"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
              />
              <DxColumn
                caption="l"
                data-field="dosageTotalL"
                data-type="number"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
              />
            </DxColumn>

            <DxColumn
              v-if="!isNew"
              :caption="$t('productResume.price')"
              data-field="price"
              alignment="center"
              data-type="number"
              :set-cell-value="setCellValue"
              :allow-sorting="false"
            />
            <DxColumn
              v-if="isNew"
              caption="Precio"
              alignment="center"
            >
              <DxColumn
                caption="€/kg"
                data-field="priceKg"
                alignment="center"
                data-type="number"
                :set-cell-value="setCellValue3dec"
                :allow-sorting="false"
              />
              <DxColumn
                caption="€/l"
                data-field="priceL"
                alignment="center"
                data-type="number"
                :set-cell-value="setCellValue3dec"
                :allow-sorting="false"
              />
            </DxColumn>

            <DxColumn
              :caption="$t('productResume.costUnit')"
              data-field="costUnit"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              v-if="showPlantColumn"
              :caption="$t('nutritionDetail.productResume.costPlant')"
              data-field="costPlant"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxColumn
              :caption="$t('productResume.costTotal')"
              data-field="costTotal"
              data-type="number"
              alignment="center"
              :allow-editing="false"
              :allow-sorting="false"
            />
            <DxSummary css-class="summary-table">
              <DxTotalItem
                column="costUnit"
                summary-type="sum"
                :customize-text="totalItem"
              />
              <DxTotalItem
                v-if="showPlantColumn"
                column="costPlant"
                summary-type="sum"
                :customize-text="totalItem"
              />
              <DxTotalItem
                column="costTotal"
                summary-type="sum"
                :customize-text="totalItem"
              />
            </DxSummary>
          </DxDataGrid>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import {
  DxColumn,
  DxDataGrid,
  DxEditing,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';
import DxNumberBox from 'devextreme-vue/number-box';
import { mapState } from 'vuex';
import plantationService from '@/services/plantationService';

export default {
  name: 'ProductResume',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxNumberBox,
    DxSummary,
    DxTotalItem,
  },
  props: {
    plantationData: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      plantation: {},
      editingCell: null,
    };
  },
  computed: {
    ...mapState('nutritionStepper', [
      'stepData',
      'enableWatchers',
      'isCopying',
      'isNew',
    ]),
    showPlantColumn() {
      return this.plantationData.crop_type !== 'HERBÁCEO';
    },
    getArea() {
      const { value: area } = this.actualPlantation?.farms[0].properties.find(prop => prop.key === "area") || {};
      return this.fixNumberTo(area, 2);
    },
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
  },
  watch: {
    'stepData.productResume': {
      handler(val) {
        if (val.length === 0) {
          return;
        }
        this.calcTotalCost(val);
      },
      deep: true,
    },
  },
  beforeMount() {
    this.stepData.area = this.getArea;
    const plantationCompantId = this.isCopying
      ? this.currentPlantation.plantationCompanyId
      : this.currentPlantation.companyId;
    plantationService
      .getById(this.currentPlantation.id, plantationCompantId)
      .then((response) => {
        this.stepData.productResume = this.stepData.dosageProduct.map(
          (product, index) => {
            const isSolid = product?.presentation === 'SOLIDO';

            const dosageUnit = this.roundValue(this.stepData.dosages[index].dosage, 1);
            const dosageUnitKg = this.roundValue(isSolid ? dosageUnit : dosageUnit * (product?.density || 1), 1);
            const dosageUnitL = isSolid ? '' : dosageUnit;

            let dosagePlant = this.roundValue(
              (dosageUnit * 1000) / this.actualPlantation.plants_density, 1,
            );
            if (Number.isNaN(dosagePlant) || !Number.isFinite(dosagePlant)) {
              dosagePlant = 0;
            }
            const dosagePlantKg = this.roundValue(
              (dosageUnitKg * 1000) / this.actualPlantation.plants_density, 1,
            );
            const dosagePlantL = isSolid ? '' : this.roundValue(
              (dosageUnitL * 1000) / this.actualPlantation.plants_density, 1,
            );

            const dosageTotal = this.roundValue(
              dosageUnit * this.stepData.area, 1,
            );
            const dosageTotalKg = this.roundValue(dosageUnitKg * this.stepData.area, 1);
            const dosageTotalL = isSolid ? '' : this.roundValue(dosageUnitL * this.stepData.area, 1);

            let costPlant = this.roundValue(
              product.costTotal / response.plants, 1,
            );
            if (Number.isNaN(costPlant) || !Number.isFinite(costPlant)) {
              costPlant = 0;
            }

            return {
              product: product.name,
              density: product.density,
              presentation: product.presentation,
              tipology: product.tipology,

              // Dosis
              dosageUnit,
              dosageUnitKg,
              dosageUnitL,
              // Dosis por planta
              dosagePlant,
              dosagePlantKg,
              dosagePlantL,
              // Dosis total
              dosageTotal,
              dosageTotalKg,
              dosageTotalL,

              price: this.stepData.productResume[index]?.price || 0,
              priceKg: this.stepData.productResume[index]?.priceKg || 0,
              priceL: this.stepData.productResume[index]?.priceL || 0,

              costUnit: 0,
              costPlant,
              costTotal: 0,
            };
          },
        );
      });
  },
  methods: {
    onEditingStart(e) {
      this.editingCell = e.column.dataField;
    },
    fixNumberTo(number, digits) {
      return parseFloat(parseFloat(number).toFixed(digits));
    },
    setCellValue3dec(newData, value) {
      newData[this.editingCell] = parseFloat(Math.abs(value).toFixed(3));
    },
    setCellValue(newData, value, currentRowData) {
      newData[this.editingCell] = parseFloat(Math.abs(value).toFixed(2));
    },
    onCellPrepared(e) {
      const isLiquid = e.data?.presentation === 'LIQUIDO';
      const isSolid = e.data?.presentation === 'SOLIDO';

      // Liquid products have both prices(kg and l) enabled but only one can be filled.
      if (isLiquid && e.rowType === 'data' && (e.column.dataField === 'priceKg' || e.column.dataField === 'priceL')) {
        if (e.column.dataField === 'priceKg' && e.data.priceL > 0) {
          e.cellElement.style.backgroundColor = '#D9D9D9';
          e.cellElement.style.border = '1px solid #c0c9ce';
          e.cellElement.style.pointerEvents = 'none';

          e.data.priceKg = 0;
        }

        if (e.column.dataField === 'priceL' && e.data.priceKg > 0) {
          e.cellElement.style.backgroundColor = '#D9D9D9';
          e.cellElement.style.border = '1px solid #c0c9ce';
          e.cellElement.style.pointerEvents = 'none';

          e.data.priceL = 0;
        }
      }

      // Solid products have one price(kg) enabled.
      if (isSolid && e.rowType === 'data' && e.column.dataField === 'priceL') {
        e.cellElement.style.backgroundColor = '#D9D9D9';
        e.cellElement.style.border = '1px solid #c0c9ce';
        e.cellElement.style.pointerEvents = 'none';
      }

      if (e.rowType === 'data' && e.column.dataField !== 'priceKg' && e.column.dataField !== 'priceL') {
        e.cellElement.style.backgroundColor = '#D9D9D9';
        e.cellElement.style.border = '1px solid #c0c9ce';
      }
    },
    calcTotalCost(element) {
      if (!this.enableWatchers) {
        return;
      }
      element.forEach((item) => {
        const selectedPrice = item.priceKg > 0 ? item.priceKg : item.priceL;
        // const selectedDosage = item.presentation === 'SOLIDO' ? item.dosageUnitKg : item.dosageUnitL;
        const selectedDosage = item.priceKg > 0 ? item.dosageUnitKg : item.dosageUnitL;

        let calcCostUnit = this.roundValue(selectedDosage * selectedPrice, 1);
        let calcCostPlant = this.roundValue(
          item.costTotal / this.actualPlantation.plants, 2,
        );
        let calcCostTotal = this.roundValue(
          item.costUnit * this.stepData.area,
        );

        if (Number.isNaN(calcCostUnit) || !Number.isFinite(calcCostUnit)) {
          calcCostUnit = 0;
        }

        if (Number.isNaN(calcCostPlant) || !Number.isFinite(calcCostPlant)) {
          calcCostPlant = 0;
        }

        if (Number.isNaN(calcCostTotal) || !Number.isFinite(calcCostTotal)) {
          calcCostTotal = 0;
        }

        item.costUnit = this.roundValue(calcCostUnit, 1);
        item.costPlant = this.roundValue(calcCostPlant, 2);
        item.costTotal = parseInt(calcCostTotal, 10);
      });
    },
    roundValue(val, decimals = 2) {
      return parseFloat(val.toFixed(decimals));
    },
    totalItem(data) {
      const value = parseFloat(data.value.toFixed(2));
      return `Total: ${value}`;
    },
  },
};
</script>
<style src="./productResume.scss" scoped lang="scss"></style>
