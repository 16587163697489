import db from '../../services/DexieDatabase';

export default {
  async getAll() {
    return db.product.toArray();
  },
  /**
   * Filtramos por cultivo y agente para mostrar el listado de podructos posibles
   * @param {*} cultive
   * @returns
   */
  async getByCultiveAndAgent(cultive = '', agent = '') {
    const products = [];
    const productList = await db.product.toArray();
    if (cultive !== '') {
      productList.forEach((element) => {
        products.push({ id: element.id, name: element.name });
      });
    }
    return [...new Set(products)].sort();
  },
  async getById(id) {
    let product;
    const productList = await db.product.toArray();

    if (id !== '') {
      productList.forEach((element) => {
        if (element.id === id) {
          product = element;
        }
      });
    }

    return product;
  },
};
