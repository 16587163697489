// Import Vue & Framework7
import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';

import DataGrid from 'devextreme/ui/data_grid';

// Styles
import 'framework7/css/framework7.bundle.css';
import '../css/icons.css';
import '../css/app.scss';
import 'jquery-ui/themes/base/all.css';
import '@hispatec/web-vue2/dist/style.css';

// Import DevExtreme
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import * as overlay from 'devextreme/ui/overlay';
import { locale, loadMessages } from 'devextreme/localization';

// Import App Component
import VCalendar from 'v-calendar';
import L from 'leaflet';
// Libreria para dibujar poligonos en el mapa
import 'leaflet.pm';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import notify from 'devextreme/ui/notify';
import esMessages from '@/json/langs/es.json';
import i18n from './i18n.config';
import App from '../components/app.vue';
import store from '../store/index';

// Importamos librerías de apoyo
import Api from '../services/Api';
import Helpers from './helpers';
import FirebaseAnalytics from '../services/FirebaseAnalytics';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

Vue.prototype.$notifyDX = notify;
L.Marker.prototype.options.icon = DefaultIcon;

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

DataGrid.defaultOptions({
  options: {
    allowColumnReordering: false,
  },
});
// Inicializamos las librerías de apoyo
Vue.use(VCalendar);

Object.defineProperty(String.prototype, 'capitalize', {
  value() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
});

overlay.baseZIndex(9999);
loadMessages(esMessages);
locale('es-ES');
// Init App
Vue.prototype.$helpers = Helpers;
Vue.prototype.$fa = FirebaseAnalytics;
Vue.prototype.$api = Api;
// eslint-disable-next-line no-new
new Vue({
  el: '#app',

  // Register App Component
  components: {
    app: App,
  },
  render: (h) => h(App),
  i18n,
  store,
});
