<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('Title_page_analisys_treatment')}`" />
    <div class="margin-top-half">
      <div class="content-devx">
        <f7-block>
          <f7-row>
            <f7-col>
              <DxDataGrid
                :data-source="customStore"
                :remote-operations="true"
                :row-alternation-enabled="true"
                :show-borders="true"
                @toolbar-preparing="onToolbarPreparing($event)"
              >
                <template #periodFilterTemplate>
                  <div>
                    <DxButton
                      class="margin-right-half"
                      :width="200"
                      :text="$t('Utilities.stepperForm.monthly')"
                      type="default"
                      :styling-mode="currentPeriod == 'MENSUAL' ? 'contained' : 'outlined'"
                      @click="selectPeriod('MENSUAL')"
                    />
                    <DxButton
                      class="margin-left-half"
                      :width="200"
                      :text="$t('Utilities.stepperForm.weekly')"
                      type="default"
                      :styling-mode="currentPeriod == 'SEMANAL' ? 'contained' : 'outlined'"
                      @click="selectPeriod('SEMANAL')"
                    />
                  </div>
                </template>

                <DxSearchPanel
                  :visible="true"
                  :width="200"
                  :placeholder="$t('Search_placeholder')"
                />

                <DxColumn
                  data-field="description"
                  :caption="$t('Plans.description')"
                  data-type="string"
                />

                <DxColumn
                  data-field="species"
                  :caption="$t('Plans.crop')"
                  data-type="string"
                />

                <DxColumn
                  data-field="company_name"
                  :caption="$t('client')"
                  data-type="string"
                />

                <DxColumn
                  data-field="explotation"
                  :caption="$t('Explotation')"
                  data-type="string"
                />

                <DxColumn
                  data-field="plantation"
                  :caption="$t('nutritionDetail.report.plantation')"
                  data-type="string"
                />

                <DxColumn
                  data-field="cost"
                  :caption="$t('treatment.treatmentPlan.treatmentCreatePlan.cost')"
                  data-type="string"
                />

                <DxColumn
                  data-field="period"
                  :caption="$t('Searcher.observations.template')"
                  data-type="string"
                />
                <DxColumn
                  width="48px"
                  data-field="id"
                  caption=""
                  cell-template="grid-cell"
                />
                <template #grid-cell="{ data }">
                  <div>
                    <DxButton
                      v-if="isTreatmentselectedTreatmentPlanId(data.text)"
                      name="delete"
                      type="danger"
                      icon="close"
                      @click="deselectTreatmentPlan(data.text)"
                    />
                    <DxButton
                      v-else
                      name="edit"
                      type="success"
                      icon="add"
                      @click="selectTreatmentPlan(data.text)"
                    />
                  </div>
                </template>
                <DxPaging :page-size="gridSettings.pageSize" />
                <DxPager
                  :visible="true"
                  display-mode="full"
                  :allowed-page-sizes="pageSizes"
                  :show-page-size-selector="true"
                  :show-info="true"
                  :show-navigation-buttons="true"
                  info-text="{2} items"
                />
              </DxDataGrid>
            </f7-col>
          </f7-row>
        </f7-block>
      </div>

      <DxLoadPanel
        :visible="loading"
        shading-color="rgba(0,0,0,0.4)"
      />

      <div class="content-devx">
        <div class="card-body">
          <section>
            <DxChart
              v-if="!loading"
              palette="SoftPastel"
              :data-source="chartData"
              @legend-click="onLegendClick"
            >
              <DxSeries
                v-if="selectedTreatmentPlanIds.length > 0"
                argument-field="monthWeek"
                :value-field="'value - ' + `${currentPlanFirst}`"
                :name="firstProduct + ' - ' + `${currentPlanFirst}`"
                type="stackedbar"
                stack="primero"
              />
              <DxSeries
                v-if="selectedTreatmentPlanIds.length >= 2"
                argument-field="monthWeek"
                :value-field="'value - ' + `${currentPlanSecond}`"
                :name="secondProduct + ' - ' + `${currentPlanSecond}`"
                type="stackedbar"
                stack="segundo"
              />
              <DxSeries
                v-if="selectedTreatmentPlanIds.length === 3"
                argument-field="monthWeek"
                :value-field="'value - ' + `${currentPlanThird}`"
                :name="thirdProduct + ' - ' + `${currentPlanThird}`"
                type="stackedbar"
                stack="tercero"
              />
              <DxLegend
                vertical-alignment="bottom"
                horizontal-alignment="center"
                item-text-position="right"
              />
              <DxTooltip
                :enabled="true"
                :z-index="999999"
                :customize-tooltip="customizeTooltip"
              />
              <DxScrollBar :visible="true" />
              <DxZoomAndPan argument-axis="both" />
            </DxChart>
          </section>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

import DxButton from 'devextreme-vue/button';
import {
  DxChart,
  DxLegend,
  DxTooltip,
  DxSeries,
  DxScrollBar,
  DxZoomAndPan,
} from 'devextreme-vue/chart';

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';

import { DxLoadPanel } from 'devextreme-vue/load-panel';
import navbar from '../../components/NavBar.vue';
import settings from '../../js/commonSettings';
import { getTimeValues } from '../../js/utils/timeValuesUtils';

export default {
  name: 'TreatmentPage',
  components: {
    navbar,
    DxChart,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxLegend,
    DxSeries,
    DxPager,
    DxLoadPanel,
    DxTooltip,
    DxScrollBar,
    DxZoomAndPan,
    DxPaging,
  },
  data() {
    return {
      selectedTreatmentPlanIds: [],
      chartData: [],
      pageSizes: [5, 10, 20],
      currentPeriod: 'MENSUAL',
      plansOnDisplay: [],

      currentPlans: [],
      currentPlanFirst: '',
      currentPlanSecond: '',
      currentPlanThird: '',
      firstProduct: '',
      secondProduct: '',
      thirdProduct: '',
      loading: false,
      customStore: null,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('treatment', ['treatmentPlans', 'treatmentPlansDetailById']),
  },
  watch: {
    chartData(newVal) {
      const objNewVal = { ...newVal };

      if (this.selectedTreatmentPlanIds.length === 1) {
        for (const property in objNewVal) {
          if (objNewVal[property][`productName - ${this.currentPlanFirst}`]) {
            this.firstProduct = objNewVal[property][`productName - ${this.currentPlanFirst}`];
            break;
          }
        }
      }

      if (this.selectedTreatmentPlanIds.length === 2) {
        for (const property in objNewVal) {
          if (objNewVal[property][`productName - ${this.currentPlanSecond}`]) {
            this.secondProduct = objNewVal[property][`productName - ${this.currentPlanSecond}`];
            break;
          }
        }
      }

      if (this.selectedTreatmentPlanIds.length === 3) {
        for (const property in objNewVal) {
          if (objNewVal[property][`productName - ${this.currentPlanThird}`]) {
            this.thirdProduct = objNewVal[property][`productName - ${this.currentPlanThird}`];
            break;
          }
        }
      }
    },
  },
  async mounted() {
    this.loading = true;
    try {
      this.customStore = await this.getStore({ type: 'analysisTreatment', data: { period: 'MENSUAL' } });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }

    this.selectPeriod('MENSUAL');

    this.loading = false;
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Análisis > Tratamientos');
    },
    ...mapActions('treatment', ['getTreatmentDataForAnalisis', 'getTreatmentPlanDetails']),
    ...mapActions('Pagination', ['getStore']),
    // FIXME: Maybe move this to a service?
    // COPY from: src/components/wizardTreatment/treatmentDetailPlan/treatmentDetailPlan.js
    sortPlans(a, b) {
      const aSplit = a.monthWeek.split(' - ');
      const bSplit = b.monthWeek.split(' - ');

      if (aSplit[1] === bSplit[1]) {
        return parseInt(aSplit[0]) - parseInt(bSplit[0]);
      }
      return parseInt(aSplit[1]) - parseInt(bSplit[1]);
    },
    async recalculateGraphData() {
      const newChartData = [];

      this.loading = true;
      this.currentPlans = [];
      for (const id of this.selectedTreatmentPlanIds) {
        try {
          await this.getTreatmentPlanDetails({ treatmentId: id });
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          this.loading = false;
        }
        const products = [];

        this.treatmentPlansDetailById[id].periods.forEach((period) => {
          period.products.forEach((product, index) => {
            if (products[index] === undefined) {
              products[index] = { ...product, value_chart: 0 };
            } else {
              products[index].value_chart += product.dose_volume;
            }
          });
        });
        this.treatmentPlansDetailById[id].products = products;
        this.currentPlans.push(this.treatmentPlansDetailById[id]);

        if (this.selectedTreatmentPlanIds.length === 1) {
          this.currentPlanFirst = this.treatmentPlansDetailById[id].description;
          this.currentPlanSecond = '';
          this.currentPlanThird = '';
        }

        if (this.selectedTreatmentPlanIds.length === 2) {
          this.currentPlanFirst = this.treatmentPlansDetailById[this.currentPlans[0].id].description;
          this.currentPlanSecond = this.treatmentPlansDetailById[id].description;
          this.currentPlanThird = '';
        }

        if (this.selectedTreatmentPlanIds.length === 3) {
          this.currentPlanThird = this.treatmentPlansDetailById[id].description;
        }
      }

      this.currentPlans.forEach((plan) => {
        const { timeValues } = getTimeValues(
        moment(plan.init_date), 
        moment(plan.end_date),
        plan.period,
        );

        const keys = Object.keys(timeValues[0]);

        plan.periods.forEach((period, index) => {
          period.products.forEach((product) => {
            if (product.dose_volume > 0) {
              newChartData.push({
                [`productName - ${plan.description}`]: product.product,
                monthWeek: keys[index],
                [`value - ${plan.description}`]: product.dose_volume,
                plan: plan.description,
              });
            }
          });
        });
      });

      this.loading = false;

      this.chartData = newChartData.sort(this.sortPlans);
    },

    async selectPeriod(period) {
      this.currentPeriod = period;
      this.selectedTreatmentPlanIds = [];

      this.customStore = await this.getStore({ type: 'analysisTreatment', data: { period } });

      this.recalculateGraphData();
    },

    // -----------------------
    // Selection Helpers
    // -----------------------
    isTreatmentselectedTreatmentPlanId(treatmentPlanId) {
      for (const id of this.selectedTreatmentPlanIds) {
        if (treatmentPlanId === id) {
          return true;
        }
      }
      return false;
    },

    selectTreatmentPlan(treatmentPlanId) {
      const planFound = this.selectedTreatmentPlanIds.find((plan) => plan.toString() === treatmentPlanId.toString());
      if (this.selectedTreatmentPlanIds.length === 3 && !planFound) {
        this.selectedTreatmentPlanIds.pop();
        this.selectedTreatmentPlanIds.push(treatmentPlanId);
        this.recalculateGraphData();
        return;
      }
      if (this.selectedTreatmentPlanIds.length === 3) return;

      this.selectedTreatmentPlanIds.push(treatmentPlanId);

      this.recalculateGraphData();
    },

    deselectTreatmentPlan(treatmentPlanId) {
      if (this.selectedTreatmentPlanIds.length === 3) {
        this.currentPlanFirst = '';
        this.thirdProduct = '';
        this.firstProduct = this.secondProduct;
      } else if (this.selectedTreatmentPlanIds.length === 2) {
        this.secondProduct = '';
      } else if (this.selectedTreatmentPlanIds.length === 1) {
        this.firstProduct = '';
      }

      const index = this.selectedTreatmentPlanIds.indexOf(treatmentPlanId);
      if (index > -1) {
        this.selectedTreatmentPlanIds.splice(index, 1);
      }
      this.recalculateGraphData();
    },

    clearSelection() {
      this.selectedTreatmentPlanIds = [];
      this.recalculateGraphData();
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    customizeTooltip(pointInfo) {
      /** const product = pointInfo.seriesName.toUpperCase();
      let total = 0;
      pointInfo.point.series.pointsByArgument[pointInfo.argument].forEach((element) => {
        total += element.originalValue;
      }); */
      return {
        html: `<div><div class='tooltip-header'>${
          pointInfo.argumentText
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('product')}: </span>${pointInfo.seriesName}
          </span> </div>
          <span class="bold">${this.$t('weather_parameter_value')} </span> ${pointInfo.valueText} kG-L/HA
          <br>
          <span class="bold"> ${this.$t('treatment.treatmentPlan.title')}:</span> ${pointInfo.point.data.plan}
          <br>
        </span> </div></div></div>`,
      };
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({ location: 'before', template: 'periodFilterTemplate' });
    },
  },
};
</script>

<style>
</style>
