<template>
  <section class="stepper-component">
    <div class="content-devx">
      <div class="card-header">
        {{ title }}
      </div>
      <div class="card-body">
        <section class="steps content-separatos">
          <div
            v-for="(step, index) in nutritionSteps"
            :key="index"
            :class="'step ' + getCompleteClassStatus(step) "
            @click="setCurrentStep(index)"
          >
            <div class="dot-container">
              <span :class="'dot ' + step.statusClass">
                <i
                  v-if="step.statusClass && step.statusClass.includes('complete')"
                  class="dx-icon-check"
                />
              </span>
            </div>
            <span class="dot-label">{{ step.label }}</span>
          </div>
        </section>
        <section
          v-if="stepIndex === 0"
          class="data-form margin-top content-separatos"
        >
          <f7-row class="margin-bottom-block">
            <f7-col>
              <div class="dx-field">
                <div
                  class="mb-2"
                  style="font-weight: 500;"
                >
                  {{ $t('Utilities.stepperForm.planDescription') }}
                  <span style="color: red">*</span>
                </div>
                <div>
                  <DxTextBox v-model="stepData.planDescription" />
                </div>
              </div>
            </f7-col>
            <f7-col v-if="isNew">
              <div class="dx-field">
                <div
                  class="mb-2"
                  style="font-weight: 500;"
                >
                  {{ $t('Utilities.stepperForm.ph') }}
                  <span style="color: red">*</span>
                </div>
                <div>
                  <DxNumberBox
                    :value="stepData.ph"
                    @value-changed="phChanged"
                  />
                </div>
              </div>
            </f7-col>
            <f7-col>
              <div class="dx-field">
                <div
                  class="mb-2"
                  style="font-weight: 500;"
                >
                  {{ $t('Utilities.stepperForm.temporalFrame') }}
                  <span style="color: red">*</span>
                </div>
                <div>
                  <DxSelectBox
                    v-model="temporalFrameId"
                    display-expr="label"
                    value-expr="id"
                    :items="temporalFrameItems"
                    :disabled="isEditing || isCopying"
                    @value-changed="updateTemporalFrame"
                  />
                </div>
              </div>
            </f7-col>
            <!-- <f7-col>
              <div class="dx-field">
                <div
                  class="mb-2"
                  style="font-weight: 500;"
                >
                  {{ $t('Utilities.stepperForm.init') }}
                  <span style="color: red">*</span>
                </div>
                <div>
                  <DxDateBox
                    v-model="stepData.initDate"
                    :display-format="dateboxFormat"
                    :disabled-dates="disabledDates"
                    width="100%"
                    type="date"
                    picker-type="calendar"
                    :accept-custom-value="false"
                    :open-on-field-click="true"
                    @value-changed="initData"
                  >
                    <DxCalendarOptions
                      :max-zoom-level="maxZoomLevel"
                      min-zoom-level="decade"
                      zoom-level="year"
                    />
                  </DxDateBox>
                </div>
              </div>
            </f7-col> -->
            <f7-col>
              <div class="dx-field">
                <div
                  class="mb-2"
                  style="font-weight: 500;"
                >
                  {{ $t('dates') }}
                  <span style="color: red">*</span>
                </div>
                <div>
                  <!-- <DxDateBox
                    v-model="stepData.endDate"
                    :display-format="dateboxFormat"
                    :disabled-dates="disabledDates"
                    picker-type="calendar"
                    type="date"
                    width="100%"
                    :accept-custom-value="false"
                    :open-on-field-click="true"
                    @value-changed="initData"
                  >
                    <DxCalendarOptions
                      :max-zoom-level="maxZoomLevel"
                      min-zoom-level="decade"
                      zoom-level="year"
                    />
                  </DxDateBox> -->
                  <f7-input
                    :key="`input-${temporalFrameId}`"
                    type="datepicker"
                    class="double-date-picker"
                    :placeholder="
                      $t('PlanningComponent_datepicker_multiple_placeholder')
                    "
                    :clear-button="true"
                    :close-on-select="true"
                    :calendar-params="{
                      value: [initDateNut, endDateNut],
                      closeOnSelect: true,
                      rangePicker: true,
                      monthPicker: true,
                      dateFormat: dateboxFormatF7,
                      disabled: disabledDatesf7,
                    }"
                    @calendar:change="calendarChange"
                  />
                </div>
              </div>
            </f7-col>
          </f7-row>
          <div class="bottom" />
        </section>
        <section
          v-if="false"
          class="data-form-label"
        >
          <div class="top">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.planDescription') }}
              </div>
              <div class="dx-field-value">
                {{ stepData.planDescription }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.temporalFrame') }}
              </div>
              <div class="dx-field-value">
                {{ stepData.temporalFrame.label }}
              </div>
            </div>
          </div>
          <div class="bottom">
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.init') }}
              </div>
              <div class="dx-field-value">
                {{ formatInitDate() }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">
                {{ $t('Utilities.stepperForm.end') }}
              </div>
              <div class="dx-field-value">
                {{ formatEndDate() }}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { DxDateBox, DxCalendarOptions } from 'devextreme-vue/date-box';
import DxSelectBox from 'devextreme-vue/select-box';
import DxTextBox from 'devextreme-vue/text-box';
import DxNumberBox from 'devextreme-vue/number-box';
import moment from 'moment';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';

export default {
  name: 'Stepper',
  components: {
    DxNumberBox,
    DxDateBox,
    DxCalendarOptions,
    DxSelectBox,
    DxTextBox,
  },
  props: [],
  data() {
    return {
      title: this.$t('Register_fertilization_plan'),
      stepSelected: {},
      temporalFrameId: 0,
      temporalFrameItems: [
        { id: 0, label: this.$t('Utilities.stepperForm.monthly'), value: 'MENSUAL' },
        { id: 1, label: this.$t('Utilities.stepperForm.weekly'), value: 'SEMANAL' },
      ],
      initDateNut: null,
      endDateNut: null,
    };
  },
  computed: {
    ...mapState('nutritionStepper', ['nutritionSteps', 'stepData', 'currentStep', 'stepIndex', 'enableWatchers', 'isEditing', 'editingStepData', 'isCopying', 'copyingStepData', 'isCopying', 'isNew']),
    ...mapState('Plantation', ['currentPlantation']),
    dateboxFormat() {
      return ['MM/yyyy', 'dd/MM/yyyy'][this.temporalFrameId];
    },
    dateboxFormatF7() {
      return ['mm/yyyy', 'dd/mm/yyyy'][this.temporalFrameId];
    },
    maxZoomLevel() {
      return ['year', 'month'][this.temporalFrameId];
    },
  },
  beforeMount() {
    this.updateClasses();
    this.initData();

    if (!this.stepData.temporalFrame) {
      this.setStepDataTemporalFrame(this.temporalFrameItems[0]);
    } else {
      this.temporalFrameId = this.stepData.temporalFrame.id;
      // Se vuelve a asignar el marco temporal para añadir la traducción correcta.
      this.setStepDataTemporalFrame(this.temporalFrameItems[this.temporalFrameId]);
    }
    if (!this.stepData.initDate) {
      this.setStepDataInitDate(this.currentPlantation.initDate);
      this.setStepDataEndDate(this.currentPlantation.endDate);

      this.initDateNut = this.currentPlantation.initDate;
      this.endDateNut = this.currentPlantation.endDate;
    } else {
      this.initDateNut = this.stepData.initDate;
      this.endDateNut = this.stepData.endDate;
    }
  },
  methods: {
    phChanged(e) {
      const value = parseFloat((parseFloat(e.value) || 0).toFixed(1));
      const ph = Math.min(Math.max(value, 0), 14);
      this.stepData.ph = ph;
    },
    disabledDates(args) {
      const actualDate = moment(args.date, 'YYYY-MM-DD');
      const initDate = moment(this.currentPlantation.initDate, 'YYYY-MM-DD');
      const endDate = moment(this.currentPlantation.endDate, 'YYYY-MM-DD');

      if (args.view === 'month') {
        return !actualDate.isBetween(initDate, endDate, 'days', '[]');
      }

      if (args.view === 'year') {
        return !actualDate.isBetween(initDate, endDate, 'months', '[]');
      }

      if (args.view === 'decade') {
        return !actualDate.isBetween(initDate, endDate, 'years', '[]');
      }

      if (args.view === 'century') {
        const exactYear = parseInt(actualDate.year() / 10) * 10;
        const initDateIsBetween = initDate.year() >= exactYear && initDate.year() <= exactYear + 9;
        const endDateIsBetween = endDate.year() >= exactYear && endDate.year() <= exactYear + 9;

        return !(initDateIsBetween || endDateIsBetween);
      }

      return false;
    },
    formatInitDate() {
      return moment(this.stepData.initDate).format('MM/yyyy');
    },
    formatEndDate() {
      return moment(this.stepData.endDate).format('MM/yyyy');
    },
    setCurrentStep(index) {
      this.setStep(index);
      this.$f7.views.main.router.navigate(this.currentStep.page,
        { reloadCurrent: true });
    },
    initData() {
      if (!this.enableWatchers) {
        return;
      }

      if (this.isEditing) {
        let { timeValues } = getTimeValues(moment(this.stepData.initDate), moment(this.stepData.endDate), this.stepData.temporalFrame.value);
        const newTimeValues = [];

        this.editingStepData.timeValues.forEach((item, index) => {
          newTimeValues[index] = {};
          Object.entries(timeValues[0]).forEach(([key, value]) => {
            const numberOfItems = Object.values(newTimeValues[index] || {}).length ?? 0;
            newTimeValues[index][key] = Object.values(item)[numberOfItems] ?? 0;
          });
        });

        const extraTimeValues = Math.max(0, this.stepData.timeValues.length - this.editingStepData.timeValues.length);

        for (let i = 0; i < extraTimeValues; i++) {
          newTimeValues.push(timeValuePeriods);
        }

        this.stepData.timeValues = newTimeValues;
        return;
      }

      if (this.isCopying) {
        if (this.copyingStepData.timeValues.length === 0) {
          this.stepData.initDate = this.copyingStepData.initDate;
          this.stepData.endDate = this.copyingStepData.endDate;
          this.copyingStepData.timeValues = this.stepData.timeValues;
        }

        let { timeValues } = getTimeValues(moment(this.stepData.initDate), moment(this.stepData.endDate), this.stepData.temporalFrame.value);
        const newTimeValues = [];

        this.copyingStepData.timeValues.forEach((item, index) => {
          newTimeValues[index] = {};
          Object.entries(timeValues[0]).forEach(([key, value]) => {
            const numberOfItems = Object.values(newTimeValues[index] || {}).length ?? 0;
            newTimeValues[index][key] = Object.values(item)[numberOfItems] ?? 0;
          });
        });

        const extraTimeValues = Math.max(0, this.stepData.timeValues.length - this.copyingStepData.timeValues.length);

        for (let i = 0; i < extraTimeValues; i++) {
          newTimeValues.push(timeValuePeriods);
        }

        this.stepData.timeValues = newTimeValues;
        return;
      }

      if (!this.stepData.initDate || !this.stepData.endDate || !this.stepData.temporalFrame.value) return;

      const { timeValues} = getTimeValues(moment(this.stepData.initDate), moment(this.stepData.endDate), this.stepData.temporalFrame.value);
      const newTimeValues = [];
      this.stepData.timeValues.forEach((item, index) => {
        newTimeValues[index] = {};
        Object.entries(timeValues[0]).forEach(([key, value]) => {
          const numberOfItems = Object.values(newTimeValues[index] || {}).length ?? 0;
          newTimeValues[index][key] = Object.values(item)[numberOfItems] ?? 0;
        });
      });

      this.stepData.timeValues = newTimeValues;
      // this.stepData.nutritionalBalanceData = [];
      // this.stepData.timeValues = [];
      // this.stepData.dosages = [];
      // this.stepData.dosageProduct = [];
      // this.stepData.dosageProductBalance = [];
      // this.stepData.productResume = [];
    },
    getCompleteClassStatus(step) {
      if (!step || !step.statusClass) {
        return 'no-pointer-event';
      }
      return !step.statusClass.includes('complete') ? 'no-pointer-event' : '';
    },
    updateTemporalFrame({ value }) {
      this.initData();
      this.setStepDataTemporalFrame(this.temporalFrameItems[value]);
    },
    disabledDatesf7(date) {
      const initDatePlantation = moment(this.currentPlantation.initDate, 'YYYY-MM-DD');
      const endDatePlantation = moment(this.currentPlantation.endDate, 'YYYY-MM-DD');

      if (
        moment(date).isBetween(
          initDatePlantation,
          endDatePlantation,
          'days',
          '[]',
        )
      ) {
        return false;
      }

      return true;
    },
    calendarChange(e) {
      if (this.stepData.irrigationTable.length > 0 && e.length === 2) {
        
     // Llamamos a la función getTimeValues con las fechas y el período temporal
        const { timeValuesIrrigation } = getTimeValues(
          moment(e[0], 'YYYY-MM-DD'),
          moment(e[1], 'YYYY-MM-DD'),
          this.stepData.temporalFrame.id
        );
  
        const existingWeeks = this.stepData.irrigationTable.map(
          (item) => item.week
        );
        // Hacemos el calculo para poner el nombre de la dosis L o KG segun si es Liquido o Solido
        const dosageProduct = this.stepData.dosageProduct = this.stepData.dosageProduct.map(product => {
            return {
              ...product,
              caption: product.presentation === 'LIQUIDO' ? 'l/Riego' : 'kg/Riego'
            };
        });
        // Filtrar el array para eliminar las semanas que no están en timeValues
        this.stepData.irrigationTable = this.stepData.irrigationTable.filter(
          (item) => Object.keys(timeValuesIrrigation[0]).includes(item.week)
        );
        // Datos anteriores lo limpiamos
        this.previousRowValues = {};
        let index = 0  
        for (const week in timeValuesIrrigation[0]) {
          if (!existingWeeks.includes(week)) {
          if (Object.hasOwnProperty.call(timeValuesIrrigation[0], week)) {
            const newTimeValuesIrrigation = {};
            newTimeValuesIrrigation.week = week;

            dosageProduct.forEach((product, indexProduct) => {
              const code = product.code;
              const timeValuesIrrigationProduct = timeValuesIrrigation[0];
              const { periods_dose, periods_product_irrigation } = timeValuesIrrigationProduct[week];
              newTimeValuesIrrigation[`code_${indexProduct}`] = code;
              newTimeValuesIrrigation[`periods_dose_${indexProduct}`] = periods_dose;
              newTimeValuesIrrigation[`periods_product_irrigation_${indexProduct}`] = periods_product_irrigation;
              newTimeValuesIrrigation[`caption_${indexProduct}`] = product.caption;
            });
            newTimeValuesIrrigation.periods_irrigation = 0;
            newTimeValuesIrrigation.periods_irrigation_time = 0;
            this.stepData.irrigationTable.splice(index, 0, newTimeValuesIrrigation);
          }
        }
        index++;
        }


      }
      if (e.length === 0) {
        this.initDateNut = null;
        this.endDateNut = null;
      }

      if (e.length === 2) {
        this.initDateNut = moment(e[0], 'YYYY/MM/DD').format(
          'YYYY-MM-DDT00:00:00',
        );
        this.endDateNut = moment(e[1], 'YYYY/MM/DD').format(
          'YYYY-MM-DDT00:00:00',
        );

        this.stepData.initDate = moment(this.initDateNut, 'YYYY-MM-DD');
        this.stepData.endDate = moment(this.endDateNut, 'YYYY-MM-DD');

        return;
      }

      this.stepData.initDate = null;
      this.stepData.endDate = null;

      if (e.length === 2) {
        this.initData();
      }
    },
    ...mapActions('nutritionStepper', ['next', 'back', 'findSelectedIndex', 'updateClasses', 'setStep',
      'setStepDataTemporalFrame',
      'setStepDataInitDate',
      'setStepDataEndDate',
      'setEditingStepData']),
  },
};

</script>
<style src="./stepper.scss" scoped lang="scss"></style>
