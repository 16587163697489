import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    name: '',
    farm: '',
    code: '',
    level: 0,
    properties: [{ }],
    geo_feature: {},
    parent: '',
    type: [
      'ORGANIZATIVE',
      'AGRARIAN',
    ],
    /* cargo los datos de propiedades en variables
    para luego setear un objeto con todos los datos */
    province: '',
    mobile: '',
    registerNumber: '',
    ownerFarm: '',
    allProvince: [],
    allCities: [],
    registerExplotationId: '',
    postalCode: '',
    provinceFullName: '',
  },
  mutations: {
    UPDATE_TYPE(state, payload) {
      state.type = payload;
    },
    UPDATE_NAME(state, payload) {
      state.name = payload;
    },
    UPDATE_FARM(state, payload) {
      state.farm = payload;
    },
    UPDATE_CODE(state, payload) {
      state.code = payload;
    },
    UPDATE_LEVEL(state, payload) {
      state.level = payload;
    },
    UPDATE_PROPERTIES(state, payload) {
      state.properties = payload;
    },
    UPDATE_GEO_FEATURE(state, payload) {
      state.geo_feature = payload;
    },
    UPDATE_PARENT(state, payload) {
      state.parent = payload;
    },
    // Datos dentro de properties
    UPDATE_REGISTER_NUMBER(state, value) {
      state.registerNumber = value;
    },
    UPDATE_NUMBER_MOBILE(state, value) {
      state.mobile = value;
    },
    UPDATE_PROVINCE(state, value) {
      state.province = value;
    },
    UPDATE_OWNER_FARM(state, value) {
      state.ownerFarm = value;
    },
    UPDATE_ALL_PROVINCE(state, payload) {
      state.allProvince = payload;
    },
    UPDATE_ALL_CITIES(state, payload) {
      state.allCities = payload;
    },
    setRegisterExplotationId(state, payload) {
      state.registerExplotationId = payload;
    },
    setPostalCode(state, payload) {
      state.postalCode = payload;
    },
    UPDATE_PROVINCE_FULL_NAME(state, payload) {
      state.provinceFullName = payload;
    },
  },
  actions: {
    setType(context, value) {
      context.commit('UPDATE_TYPE', value);
    },
    setName(context, value) {
      context.commit('UPDATE_NAME', value);
    },
    setFarm(context, value) {
      context.commit('UPDATE_FARM', value);
    },
    setNumberMobile(context, value) {
      context.commit('UPDATE_NUMBER_MOBILE', value);
    },
    setProvince(context, value) {
      context.commit('UPDATE_PROVINCE', value);
    },
    setOwnerFarm(context, value) {
      context.commit('UPDATE_OWNER_FARM', value);
    },
    setRegisterNumber(context, value) {
      context.commit('UPDATE_REGISTER_NUMBER', value);
    },
    setCode(context, value) {
      context.commit('UPDATE_CODE', value);
    },
    setLevel(context, value) {
      context.commit('UPDATE_LEVEL', value);
    },
    setParent(context, value) {
      context.commit('UPDATE_PARENT', value);
    },
    setProperties(context, value) {
      context.commit('UPDATE_PROPERTIES', value);
    },
    setPostalCode(context, value) {
      context.commit('setPostalCode', value);
    },
    setProvinceFullName(context, value) {
      context.commit('UPDATE_PROVINCE_FULL_NAME', value);
    },
    initStore({ commit }) {
      commit('UPDATE_NAME', '');
      commit('UPDATE_FARM', '');
      commit('UPDATE_PROVINCE', '');
      commit('UPDATE_OWNER_FARM', '');
      commit('UPDATE_NAME', '');
      commit('UPDATE_REGISTER_NUMBER', '');
      commit('UPDATE_NUMBER_MOBILE', '');
      commit('UPDATE_CODE', '');
      commit('UPDATE_PROPERTIES', {});
      commit('setRegisterExplotationId', '');
      commit('setPostalCode', '');
      commit('UPDATE_PROVINCE_FULL_NAME', '');
    },
    async getProvinces({ commit }) {
      try {
        const xhr = await Api.getAllProvince();
        const provinces = JSON.parse(xhr.response).data;
        const dataProvinces = [];
        for (let i = 0; i < provinces.length; i += 1) {
          dataProvinces.push({
            name: provinces[i].name,
            code: provinces[i].code,
          });
        }
        commit(
          'UPDATE_ALL_PROVINCE',
          dataProvinces,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    async getCities({ commit, state }, province = '') {
      try {
        let xhr = '';
        if (province) {
          xhr = await Api.getAllCities(province);
        } else {
          xhr = await Api.getAllCities(state.province);
        }
        const cities = JSON.parse(xhr.response).data;
        const dataCities = [];
        for (let i = 0; i < cities.length; i += 1) {
          dataCities.push({
            name: cities[i].name,
            code: cities[i].city_code,
          });
        }
        commit(
          'UPDATE_ALL_CITIES',
          dataCities,
        );
      } catch (e) {
        const message = Helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
    async createExplotation({ state, commit, dispatch }, companyId) {
      try {
        const newFarm = {
          name: state.name,
          code: `${state.name}${state.registerNumber}`,
          type: state.type[0], // Solo es ORGANIZATIVE cuando es tipo parcela
          level: 0,
          properties: [...state.properties, { key: 'province_name', value: state.provinceFullName }],
        };
        const explotationRegister = await Api.createLocation(companyId, newFarm);
        const explotation = JSON.parse(explotationRegister.response);
        commit(
          'setRegisterExplotationId',
          explotation.id,
        );
        const currentExplotation = {
          bdId: explotation.id,
          name: newFarm.name,
          numberPlot: 0,
          numberSector: 0,
          properties: Helpers.formattingExplotationProperties(newFarm.properties),
        };
        dispatch('Explotaciones/setActualLocation', currentExplotation, { root: true });

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateExplotation({ state, commit, dispatch }, { companyId, explotationId }) {
      try {
        const newFarm = {
          name: state.name,
          code: `${state.name}${state.registerNumber}`,
          type: state.type[0], // Solo es ORGANIZATIVE cuando es tipo parcela
          level: 0,
          properties: [...state.properties, { key: 'province_name', value: state.provinceFullName }],
        };
        const xhr = await Api.updateLocation(companyId, newFarm, explotationId);
        const explotation = JSON.parse(xhr.response);
        commit(
          'setRegisterExplotationId',
          explotation.id,
        );
        const currentExplotation = {
          bdId: explotation.id,
          name: newFarm.name,
          numberPlot: 0,
          numberSector: 0,
          properties: Helpers.formattingExplotationProperties(newFarm.properties),
        };
        dispatch('Explotaciones/setActualLocation', currentExplotation, { root: true });
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
};
