<template>
  <f7-page
    resizable
    @page:beforein="beforein"
  >
    <navbar
      v-if="(!isView || isEdit) && !isFromCampaign"
      :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${actualLocation.name ? actualLocation.name : actualPlantation.explotation} > ${currentParcel.name ? currentParcel.name : actualPlantation.parcel} > ${currentSector.sectorName ? currentSector.sectorName : actualPlantation.farms[0].name} > ${$t('registerPlantation.title_register_plantation')}`"
    />
    <navbar
      v-else
      :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} > ${$t('registerPlantation.title_register_plantation')}`"
    />
    <RegisterPlantationStep1 />
    <RegisterPlantationStep2
      :protection-types="protectionTypes"
      :management-types="managementTypes"
      :crop-types="cropTypes"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t('Button_go_to_back') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="createNewPlantation"
        >
          {{ $t("Button_go_create_new_Campania") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import RegisterPlantationStep1 from '../../../../components/plantation/registerPlantation/registerPlantationStep1/index.vue';
import RegisterPlantationStep2 from '../../../../components/plantation/registerPlantation/registerPlantationStep2/index.vue';
import navbar from '../../../../components/NavBar.vue';

export default {
  name: 'RegisterPlantStep01',
  components: {
    RegisterPlantationStep1,
    RegisterPlantationStep2,
    navbar,
  },
  data() {
    return {
      visible: false,
      date: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterPlantation', ['actualPlantation', 'parcels', 'sectors', 'parcelSelected', 'sectorSelected', 'isEdit']),
    ...mapState('Campania', ['isView', 'actualCampaign', 'viewCampaignId', 'isFromCampaign']),
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Sector', ['currentSector']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('RegisterPlantation', ['protectionTypes', 'managementTypes', 'cropTypes', 'speciesAvailables', 'varieties', 'cropTypeSelected',
      'managementTypeSelected', 'protectionTypeSelected', 'plantationName', 'initDate', 'endDate', 'specieSelected', 'varietySelected', 'plantsNumber',
      'plantDensity', 'seedDensity', 'plantingUnitSelected', 'mulUno', 'mulDos', 'plantationDate', 'isEdit', 'actualPlantation']),
  },
  methods: {
    async goToBack() {
      if (this.isEdit) {
        if (this.$f7.views.main.router.previousRoute.path === '/searcher/' || this.$f7.views.main.router.previousRoute.path === '/searcher/home/') {
          this.$f7.views.main.router.navigate('/searcher/', { reloadCurrent: true });
        } else {
          this.$f7.views.main.router.navigate('/ViewPlantation/', { reloadCurrent: true });
        }
      } else {
        this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
      }
    },
    async beforein() {
      this.$f7.preloader.show();
      try {
        if (!this.isEdit) {
          this.resetSpecieAvailablesAndVariety();
          this.initStore();
        }
        await this.getSelectBoxOptions();
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async createNewPlantation() {
      if (this.parcelSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.parcel_is_required'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }

      if (this.sectorSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.sector_is_required'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }

      if (this.plantationName === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_plantation_name'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.initDate === null || this.initDate === 'Invalid date') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_init_date'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.initDate > this.endDate) {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_endDate_bigger'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.cropTypeSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_cropType'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.managementTypeSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_plantation_management'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.protectionTypeSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_protection'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.endDate === null || this.endDate === 'Invalid date') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_end_date'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.init_date > this.end_date) {
        this.$notifyDX(
          {
            message: this.$t('DxRegisterCampania_fecha_inicio_mayor_validation'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      if (this.specieSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.required_specie_selected'),
            width: 550,
          },
          'error',
          3000,
        );
        return;
      }
      // if (this.varietySelected === '') {
      //   this.$notifyDX(
      //     {
      //       message: this.$t('registerPlantation.required_plantation_variety'),
      //       width: 550,
      //     },
      //     'error',
      //     3000,
      //   );
      //   return;
      // }
      if (this.cropTypeSelected !== '') {
        switch (this.cropTypeSelected) {
          case 'HERBÁCEO':
            if (this.plantingUnitSelected === '') {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_unit'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            // if (this.seedDensity === 0) {
            //   this.$notifyDX(
            //     {
            //       message: this.$t('registerPlantation.required_plants_density'),
            //       width: 550,
            //     },
            //     'error',
            //     3000,
            //   );
            //   return;
            // }
            break;
          case 'LEÑOSO':
            if (this.mulUno === 0) {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_distance_between_streets'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            if (this.mulDos === 0) {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_distance_between_feets'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            if (this.plantDensity === 0) {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_plants_density'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            if (this.cupDiameter === 0) {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_cupDiameter'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            if (this.plantationDate === '') {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_date_plantation'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            break;
          case 'HORTÍCOLA':
          default:
            if (this.mulUno === null || this.mulUno === 0) {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_distance_between_streets'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            if (this.mulDos === null || this.mulDos === 0) {
              this.$notifyDX(
                {
                  message: this.$t('registerPlantation.required_distance_between_feets'),
                  width: 550,
                },
                'error',
                3000,
              );
              return;
            }
            break;
        }
      }
      try {
        const data = {
          clientId: this.actualUser.id,
          plantationId: this.actualPlantation.id,
        };

        this.$f7.preloader.show();

        await this.createPlantation(data);

        if (this.isEdit) {
          this.setCurrentPlantation({
            ...this.currentPlantation,
            name: this.plantationName,
            variety: this.varietySelected,
            initDate: this.initDate,
            endDate: this.endDate,
            species: this.specieSelected,
          });
        }

        this.$f7.preloader.hide();

        await this.plantationRegisterRedirect();

        // this.setShowPopUp(true);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async plantationRegisterRedirect() {
      this.setIsPlantationCreated(true);
      if (this.isEdit) {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.plantation_register_edit'),
            width: 550,
          },
          'success',
          3000,
        );
      } 
       else {
        this.$notifyDX(
          {
            message: this.$t('registerPlantation.plantation_register'),
            width: 550,
          },
          'success',
          3000,
        );
        this.setIsView(true);
      }

      const data = {
        companyId: this.actualUser.id,
        plantationId: this.actualPlantation.id,
      };
      await this.getPlantationById(data);

      this.$f7.views.main.router.navigate('/viewPlantation/', { reloadCurrent: true });
    },
    ...mapActions('Campania', ['setActualPlot', 'setIsView', 'getCampaignsById']),
    ...mapActions('RegisterPlantation', ['setManejo', 'setProtected', 'setTypeCultive', 'getSelectBoxOptions', 'resetSelectBoxStepUno', 'initStore', 'setShowPopUp',
      'getParcelByExplotation', 'createPlantation', 'resetSpecieAvailablesAndVariety', 'setActualPlantation',
      'setParcelSelected', 'setSectorSelected', 'setIsPlantationCreated', 'getPlantationById']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
  },
};
</script>
<style lang="scss">

</style>
