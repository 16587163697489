<template>
  <DxDataGrid
    v-if="loaded"
    id="phytosanitaryProductsPlague-grid"
    :data-source="phytosanitaryProductsPlague"
    :remote-operations="true"
    :column-min-width="90"
    :column-auto-width="true"
    :allow-column-resizing="true"
    :show-borders="true"
    :show-column-lines="true"
    :show-row-lines="true"
    :row-alternation-enabled="true"
    @exporting="onExporting"
    @toolbar-preparing="onToolbarPreparing($event)"
  >
    <DxSelection
      mode="single"
    />
    <DxStateStoring
      :enabled="true"
      type="localStorage"
      storage-key="phytosanitaryProductsPlague"
    />
    <DxExport
      :enabled="false"
    />
    <DxPaging :page-size="gridSettings.pageSIze" />
    <DxPager
      :show-page-size-selector="true"
      :allowed-page-sizes="pageSizes"
      :show-info="true"
      info-text="{2} items"
    />
    <DxHeaderFilter :visible="true" />
    <DxSearchPanel
      :visible="true"
      :width="200"
      :placeholder="$t('Search_placeholder')"
    />
    <DxColumnFixing :enabled="true" />
    <DxColumn
      data-field="activeProduct"
      :caption="$t('Active_Product')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="commercialName"
      :caption="$t('Commercial_Name')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      data-field="holder"
      :caption="$t('Holder')"
      :allow-header-filtering="true"
      alignment="left"
    />
    <DxColumn
      data-field="registrationNumber"
      :caption="$t('Registration_Number')"
      :allow-header-filtering="false"
      alignment="left"
    />
    <DxColumn
      type="buttons"
    >
      <DxButton
        icon="find"
        :on-click="showDetails"
      />
    </DxColumn>
    <template
      #filterTemplate
    >
      <f7-block class="divSelectors">
        <f7-row>
          <f7-col>
            <DxSelectBox
              :items="optionsCultiveFilter"
              :search-enabled="true"
              :placeholder="$t('Select_Crop')"
              :no-data-text="$t('No_Data')"
              :value="filterCrop"
              width="auto"
              @value-changed="onChangeCropFilter"
            />
          </f7-col>
          <f7-col>
            <DxSelectBox
              :items="optionsPlagueFilter"
              :search-enabled="true"
              :placeholder="$t('Select_Agent')"
              :no-data-text="$t('No_Data')"
              :value="filterAgent"
              width="auto"
              @value-changed="onChangeAgentFilter"
            />
          </f7-col>
        </f7-row>
      </f7-block>
    </template>
  </Dxdatagrid>
</template>

<script>

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapState, mapActions } from 'vuex';
import DxSelectBox from 'devextreme-vue/select-box';
import EventBus from '../../../../../js/event-bus';
import settings from '../../../../../js/commonSettings';

export default {
  name: 'PhytosanitaryProductsPlague',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxSelectBox,
  },
  data() {
    return {
      keyName: 'phytosanitaryProductsPlague',
      pageSizes: [5, 10, 20],
      loaded: false,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Vademecum', ['phytosanitaryProductsPlague', 'optionsCultiveFilter', 'optionsPlagueFilter', 'filterCrop', 'filterAgent']),
  },
  async beforeMount() {
    this.setCurrentPage(this.keyName);
    this.$f7.preloader.show();
    try {
      if (this.filterCrop !== null && this.filterAgent !== null) {
        EventBus.$on('refreshDataByFilter_phytosanitaryProductsPlague', await this.getPhytosanitaryProductsPlague());
      }
      await this.getOptionsCultiveFilter();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async showDetails(rowSelected) {
      this.$fa.logEvent('utilidades_vademecum_plaga_lupa', {
        plagueId: rowSelected.row.data.id,
        crop: this.filterCrop,
        content_type: 'button',
        description: 'Evento al entrar en util/vade/plaga y seleccionamos cualquiera para ver el detalle de una plaga'
      });
      this.$f7.preloader.show();
      try {
        await this.getPhytosanitaryProductDetail({ id: rowSelected.row.data.id, crop: this.filterCrop, agent: this.filterAgent });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/viewPhytosanitaryProductPlagueDetail/', { reloadCurrent: true });
      }
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('phytosanitaryProductsPlague');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'phytosanitaryProductsPlague.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'filterTemplate',
      });
    },
    async onChangeCropFilter(e) {
      this.$f7.preloader.show();
      try {
        this.setFilterCrop(e.value);
        await this.getOptionsPlagueFilterVademecum(e.value);
        this.setFilterAgent('');
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async onChangeAgentFilter(e) {
      this.$f7.preloader.show();
      try {
        this.setFilterAgent(e.value);
        if (e.value.length > 0) {
          await this.getPhytosanitaryProductsPlague();
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('Vademecum', ['getPhytosanitaryProductsPlague', 'getPhytosanitaryProductDetail', 'setFilterCrop', 'setFilterAgent', 'getOptionsPlagueFilterVademecum', 'getOptionsCultiveFilter']),
    ...mapActions('Utilities', ['setCurrentPage']),
  },
};
</script>
<style lang="scss" scoped>
@import './PhytosanitaryProductsPlague.styles.scss';
</style>
