<template>
  <div class="content-devx">
    <div
      class="main-title-content"
    >
      {{ $t('productResume.productResume') }}
    </div>
    <div class="list-by-group-reference-plans content-separatos">
      <DxDataGrid
        class="grid-resume-per-products"
        :data-source="tableData"
        :show-borders="true"
        :row-alternation-enabled="true"
        :show-row-lines="true"
        :show-column-lines="true"
        @cellPrepared="onCellPrepared"
      >
        <DxColumn
          v-for="table in tables"
          :key="table.dataField"
          :data-field="table.dataField"
          :caption="table.caption"
          :width="table.width"
          :min-width="table.minWidth"
          :fixed="table.fixed"
          :fixed-position="table.fixedPosition"
          :allow-sorting="false"
        />
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';

export default {
  name: 'ResumePerProducts',
  components: {
    DxDataGrid,
    DxColumn,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      tableData: [],
      tables: [
        {
          dataField: 'product',
          caption: this.$t('productResume.product'),
          minWidth: 200,
          fixed: true,
          fixedPosition: 'left',
        },
        {
          dataField: 'dosageKg',
          caption: 'Dosis Unitaria (kg/ha)',
          minWidth: 100,
        },
        {
          dataField: 'dosageL',
          caption: 'Dosis Unitaria (l/ha)',
          minWidth: 100,
        },
        {
          dataField: 'priceKg',
          caption: 'Precio (€/kg)',
          minWidth: 100,
        },
        {
          dataField: 'priceL',
          caption: 'Precio (€/l)',
          minWidth: 100,
        },
        {
          dataField: 'unitCost',
          caption: 'Coste unitario (€/ha)',
          minWidth: 100,
        },
      ],
    };
  },
  mounted() {
    this.tableData = this.plan.contribution_products.map((product) => {
      const isSolid = product.presentation === 'SOLIDO';
      const density = product.density ?? 1;
      const dosis = product.periods.reduce((acc, period) => acc + period, 0);

      return {
        product: product.product_name,
        dosageKg: parseInt(isSolid ? dosis : dosis * density, 10),
        dosageL: !isSolid ? parseInt(dosis, 10) : null,
        priceKg: parseFloat(product.price_per_kg.toFixed(2)) || 0,
        priceL: parseFloat(product.price_per_liter.toFixed(2)) || 0,
        unitCost: parseFloat(product.unit_cost.toFixed(2)),
      };
    });

    this.tableData.push({
      isLast: true,
      product: 'Total',
      dosageKg: null,
      dosageL: null,
      priceKg: null,
      priceL: null,
      unitCost: this.tableData.reduce(
        (acc, product) => acc + parseFloat(product.unitCost.toFixed(2)), 0,
      ),
    });
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (e.data?.isLast == null) return;

        e.cellElement.style.backgroundColor = 'var(--fr-chocolat)';
        e.cellElement.style.borderTop = '2px solid #ddd';
        e.cellElement.style.fontWeight = '600';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import './referencePlansDetail.styles.scss';
</style>
