import Dexie from 'dexie';
import { DATABASE_NAME } from '../js/constants';

const db = new Dexie(DATABASE_NAME);

db.version(2000).stores({
  plantation: '++id, area, campaign, company_id, company, *coordinates, end_date, explotation, init_date, name, parcel, *plantation, province, sector_id, sector, species, variety',
  laboratory_analysis: '++id, attachment, date, description, interpretation, plantation, sample_type',
  treatment_order: '++id, area, date, description, dose_type, plantation, *products, status, treatment_type, volume',
  treatment_plan: '++id, agent, area, company_name, description, dose_type, end_date, init_date, period, plantation, *products, status',
  product: 'code, name, NT, P2015_Total, K2O, CaO, MgO, SO3, B, Cu, Fe, Mn, Zn, MO, I, Se, tipology, ecologic, status, id, owner',
  vademecum: '++id, owner, code, comercial_name, *agent, *cultive, maker, pdf, provider, composition, *containners, *recommendations, expiration_date, registration_date, version_status, version_date_ini',
  ancestors: 'ancestors, cultive',
  // irrigation: '++id, accumulated_vol, company, date, doses, plantation,time, total_vol, type',
  // fertilization: '++id, area, company_name, *contribution_products, description, end_date, init_date, *nutritional_needs, *nutritional_needs_reference, period, plantation, *reference_values, status, *uf_available_soil, *uf_irrigation_water, *uf_rest_harvest',
});

export default db;
