var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',[_c('navbar',{attrs:{"text":`${_vm.currentPlantation.company} >
      ${_vm.currentPlantation.explotation} >
      ${_vm.currentPlantation.parcel} >
      ${_vm.currentPlantation.sector} >
      ${_vm.currentPlantation.name} >
      ${_vm.$t('Utilities.nutrition')} >
      ${_vm.$t('copy')}`}}),_vm._v(" "),(_vm.loaded)?_c('PlantationsSelector',{attrs:{"left-date-limit":_vm.startDate,"right-date-limit":_vm.endDate,"extra-params":_vm.extraParams},on:{"updatePlantationsSelection":_vm.updatePlantationsSelection}}):_c('div',{staticClass:"content-devx placeholder-reference-plan-copy-table"},[_c('Spinner')],1),_vm._v(" "),_c('div',{staticClass:"footer-buttons-page"},[_c('div',[_c('f7-button',{staticClass:"dx-btn-cancel btnBack",attrs:{"type":"default","text-color":"grey","styling-mode":"outlined"},on:{"click":_vm.goBack}},[_vm._v("\n        "+_vm._s(_vm.$t('Go_Back'))+"\n      ")])],1),_vm._v(" "),_c('div',[_c('f7-button',{staticClass:"dx-btn-cancel btn-width-150 standard-blue-btn",attrs:{"raised":"","type":"success","styling-mode":"contained","disabled":_vm.copying || _vm.plantationsSelection.length === 0},on:{"click":_vm.copyPlans}},[(_vm.copying)?_c('Spinner',{attrs:{"size":"30px","color":"white"}}):_c('span',[_vm._v(_vm._s(_vm.$t('continue')))])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }