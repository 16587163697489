<template>
  <div
    class="content-devx"
  >
    <DxDataGrid
      :data-source="plantations"
      :show-borders="true"
      :remote-operations="true"
      :column-auto-width="true"
      :show-colon="false"
      @exporting="onExporting"
      @contentReady="onInit"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="SearchEngine"
      />
      <DxHeaderFilter :visible="true" />
      <DxGroupPanel :visible="false" />
      <DxGrouping
        :auto-expand-all="true"
      />
      <DxPaging :page-size="gridSettings.pageSize" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxExport
        :enabled="false"
      />
      <DxSearchPanel
        :visible="true"
        :width="300"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="company"
        :group-index="0"
        caption=""
      />
      <DxColumn
        data-field="name"
        :caption="$t('searchEngine_description')"
        alignment="left"
      />
      <DxColumn
        data-field="province"
        :caption="$t('searchEngine_province')"
        alignment="left"
      />
      <DxColumn
        data-field="explotation"
        :caption="$t('searchEngine_farm')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="parcel"
        :caption="$t('searchEngine_parcel')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="sector"
        :caption="$t('searchEngine_sector')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="status"
        :calculate-cell-value="getStatusCampaign"
        :caption="$t('searchEngine_state')"
        alignment="left"
        :header-filter="{ dataSource: dataTaskStatus }"
        cell-template="cellTemplate"
      />
      <DxColumn
        data-field="species"
        :caption="$t('searchEngine_botanical_species')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="variety"
        :caption="$t('searchEngine_variety')"
        alignment="left"
        :allow-header-filtering="true"
      />
      <DxColumn
        data-field="initDate"
        data-type="date"
        :caption="$t('searchEngine_init_date')"
        alignment="right"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
      />
      <DxColumn
        data-field="endDate"
        data-type="date"
        :caption="$t('searchEngine_end_date')"
        alignment="right"
        :allow-header-filtering="false"
        :customize-text="dataFormatted"
      />
      <DxColumn

        alignment="center"
        :allow-header-filtering="false"
        cell-template="radioBtnTemplate"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      />

      <template #radioBtnTemplate="{data: plantation}">
        <div>
          <DxCheckBox
            :value="plantation.data.isPlantationSelected"
            @valueChanged="selectPlantation($event, plantation)"
          />
        </div>
      </template>
      <template #cellTemplate="{ data: dataCampaign }">
        <div :class="dataCampaign.data.status">
          {{ dataCampaign.data.status }}
        </div>
      </template>
      <DxScrolling
        column-rendering-mode="standard"
        :visible="true"
      />
    </DxDataGrid>
  </div>
</template>
<script>
import { DxCheckBox } from 'devextreme-vue/check-box';

import {
  DxDataGrid,
  DxColumn,
  DxSearchPanel,
  DxExport,
  DxGrouping,
  DxGroupPanel,
  DxHeaderFilter,
  DxStateStoring,
  DxScrolling,
  DxPager,
  DxPaging,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import settings from '../../../js/commonSettings';

export default {
  name: 'CopyTreatmentPlanSearchEngine',
  components: {
    DxDataGrid,
    DxColumn,
    DxSearchPanel,
    DxCheckBox,
    DxExport,
    DxGrouping,
    DxGroupPanel,
    DxHeaderFilter,
    DxStateStoring,
    DxScrolling,
    DxPager,
    DxPaging,
  },
  props: {
    plantations: {
      type: Array,
      default: () => [],
    },
    goToView: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tasksAvailables: [],
      pageSizes: [5, 10, 20],
      dataSource: [],
      dataTaskStatus: [{
        text: this.$t('status_campania_active'),
        value: 'Activa',
      }, {
        text: this.$t('status_campania_end'),
        value: 'Finalizada',
      },
      {
        text: this.$t('status_campania_pending'),
        value: 'Pendiente',
      }],
      actualDate: moment().format('YYYY-MM-DD'),
      format: 'shortDate',
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Plantation', ['currentPlantation', 'actualPlantation']),
  },
  methods: {
    onInit() {
      Array.from(document.getElementsByClassName('dx-group-cell')).forEach((el) => {
        el.innerHTML = el.innerHTML.split(':')[1].trim();
      });
    },
    dataFormatted(cellInfo) {
      return moment(cellInfo.valueText, 'DD/MM/YYYY').format('DD-MM-YYYY');
    },
    selectPlantation(event, plantation) {
      if (event.event) {
        this.setCopyingStepData({
          name: plantation.data.name,
          initDate: plantation.data.initDate,
          endDate: plantation.data.endDate,
        });

        const copyPlantation = { ...this.currentPlantation };

        copyPlantation.area = plantation.data.area;
        copyPlantation.campaign = plantation.data.campaign;
        copyPlantation.company = plantation.data.company;
        copyPlantation.plantationCompanyId = copyPlantation.companyId;
        copyPlantation.companyId = plantation.data.companyId;
        copyPlantation.explotation = plantation.data.explotation;
        copyPlantation.parcel = plantation.data.parcel;
        copyPlantation.sector = plantation.data.sector;
        copyPlantation.sectorId = plantation.data.sectorId;
        copyPlantation.id = plantation.data.id;
        copyPlantation.initDate = plantation.data.initDate;
        copyPlantation.endDate = plantation.data.endDate;

        this.setCurrentPlantation(copyPlantation);

        this.updateIsPlantationSelected(plantation.data.id);
      }
    },
    getStatusCampaign(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('status_campania_active');
      } if (endDate < this.actualDate) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('SearchEngine');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'SearchEngine.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Plantation', ['setCurrentPlantation', 'updateIsPlantationSelected', 'setIsPlantationSelected', 'setCurrentPlantation']),
    ...mapActions('treatmentStepper', ['setCopyingStepData']),
  },

};
</script>
<style lang="scss" scoped>
@import './CopyTreatmentPlanSearchEngine.styles.scss';
</style>
