<template>
  <div>
    <f7-col>
      <span class="dx-field-item-label-text">{{ $t('exclusionDates.list') }}:</span>
      <DxTagBox
        id="tagBox"
        :show-clear-button="true"
        :open-on-field-click="false"
        :items="datesSelected"
        :value="datesSelected"
        :placeholder="$t('exclusionDates.list')"
        @value-changed="(e) => datesSelected = e.value"
      />
    </f7-col>
    <f7-block style="margin-top: 20px;">
      <DxForm>
        <DxGroupItem>
          <DxSimpleItem  
            id="dateBox"
            :data-field="$t('exclusionDates.add')"
            editor-type="dxDateBox"
            :editor-options="{   
              displayFormat: 'yyyy-MM-dd',
              dateSerializationFormat: 'yyyy-MM-dd',
              placeholder: $t('exclusionDates.format'),
              invalidDateMessage: $t('dates_isInvalid'),
              pickerType: 'calendar',
              disabledDates: disabledDates,
              min: min,
              max: max,
              onValueChanged: (e) => datesSelected.push(e.value),
            }"
          />
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>

<script>
import DxTagBox from "devextreme-vue/tag-box";
import {
  DxSimpleItem,
  DxForm,
  DxGroupItem,
} from 'devextreme-vue/form';
export default {
  name: "ExclusionDates",
  components: {
    DxTagBox,
    DxSimpleItem,
    DxGroupItem,
    DxForm
  },
  props: {
    initialDates: {
      type: Array,
      default: () => [],
    },
    initialDisabledDates: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Date,
      default: undefined,
    },
    max: {
      type: Date,
      default: undefined,
    },
  },
  data() {
    return {
      datesSelected: [...this.initialDates],
    };
  },
  computed: {
    /**
     * Retorna un array de fechas en formato Date en base al datesSelected y al initialDisabledDates
     * @return {Array<Date>} Array de fechas en formato Date
     */
    disabledDates() {
      const disabledDates = [...this.initialDisabledDates];
      this.datesSelected.forEach((date) => {
        if (!disabledDates.includes(new Date(date))) {
          disabledDates.push(new Date(date));
        }
      });
      return disabledDates;
    },
  },
  watch: {
    datesSelected() {
      // Evitamos los nulos porque son aceptados por DxDatesBox, pero no por el backend
      const datesSelectedFormated = [];
      this.datesSelected.forEach((date) => {
        if (date) {
          datesSelectedFormated.push(date);
        }
      });
      this.$emit("exclusionDatesChanged", datesSelectedFormated);
    },
  },
};
</script>
