export default {
  namespaced: true,
  state: {
    currentFilter: '',
    searchHome: [],
  },
  actions: {
    applyCurrentFilters({ rootState, commit }) {
      const { SearchEngineFilter } = rootState;

      commit('SET_HOME_FILTERS', SearchEngineFilter.selectedAttributes);
    },
    clearCurrentFilters({ commit }) {
      commit('SET_HOME_FILTERS', []);
    },
  },
  mutations: {
    SET_HOME_FILTERS(state, value) {
      state.searchHome = value;
    },
  },
};
