import moment from 'moment';
import {
  treatmentPlanPeriod,
} from '../../services/treatmentPlanService';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';
import { getTimeValues } from '../../js/utils/timeValuesUtils';

const state = {
  stepIndex: 0,
  treatmentSteps: [{
    label: 'Paso 1',
    status: 1,
    complete: false,
    page: '/utilities/treatment/treatment-step-one',
  },
  {
    label: 'Paso 2',
    status: 0,
    complete: false,
    page: '/utilities/treatment/treatment-step-two',
  },
    // {
    //   label: 'Paso 3',
    //   status: 0,
    //   complete: false,
    //   page: '/utilities/treatment/treatment-step-three',
    // },
  ],
  currentStep: {},
  treatmentPlan: {
    id: '',
    plantation: '',
    area: 0,
    description: '',
    observation: '',
    init_date: '',
    end_date: '',
    period: '',
    vat_volume: 0,
    forward_speed: 0,
    status: '',
    periods: [],
    timeValues: [],
    dosages: [],
    products: [],
    totalApplications: [],
    company_name: '',
    default_apply_unit: [],
    dosage_total: [],
  },
  treatmentProducts: [],
  treatmentProduct: {},
  currentTreatmentId: '',
  hasTreatmentError: {},
  isCopying: false,
  copyingStepData: {
    name: '',
    initDate: '',
    endDate: '',
  },
};

// to handle state
const getters = {
  treatmentSteps: (stateL) => stateL.treatmentSteps,
  treatmentPlan: (stateL) => stateL.treatmentPlan,
  currentStep: (stateL) => stateL.currentStep,
  stepIndex: (stateL) => stateL.stepIndex,
  treatmentError: (stateL) => stateL.hasTreatmentError,
};

// to handle actions
const actions = {
  treatmentSteps({
    commit,
  }) {
    commit('SET_TREATMENT_STEPS', state.treatmentSteps);
  },
  getTreatmentPlan({
    commit,
  }) {
    commit('SET_TREATMENT_PLAN', state.treatmentPlan);
  },
  getCurrentStep({
    commit,
  }) {
    commit('SET_CURRENT_STEP', state.currentStep);
  },
  getStepIndex({
    commit,
  }) {
    commit('SET_STEP_INDEX', state.stepIndex);
  },
  findSelectedIndex(context) {
    for (let i = 0; context.state.treatmentSteps; i += 1) {
      if (context.state.treatmentSteps[i].status === 0) {
        if (i === 0) {
          return 0;
        }
        return i - 1;
      }
    }
    return 0;
  },
  updateClasses(context) {
    context.state.treatmentSteps.forEach((step, index) => {
      switch (step.status) {
        case 1:
          step.statusClass = 'dot-activate';
          break;
        default:
        case 0:
          step.statusClass = '';
          break;
      }
      if (index < context.state.stepIndex) {
        step.statusClass += ' dot-complete';
      }
    });
    context.state.treatmentSteps[context.state.stepIndex].statusClass
      += ' dot-current';
  },
  next(context) {
    context.state.stepIndex += 1;

    if (context.state.stepIndex >= context.state.treatmentSteps.length) {
      context.state.stepIndex = context.state.treatmentSteps.length - 1;
    }
    context.state.treatmentSteps[context.state.stepIndex].status = 1;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.treatmentSteps[context.state.stepIndex];
  },
  back(context) {
    context.state.stepIndex -= 1;
    if (context.state.stepIndex < 0) {
      context.state.stepIndex = 0;
    }
    context.state.treatmentSteps[context.state.stepIndex + 1].status = 0;
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.treatmentSteps[context.state.stepIndex];
  },
  setStep(context, stepIndex) {
    context.state.stepIndex = stepIndex;
    context.state.treatmentSteps.forEach((step) => {
      step.status = 0;
    });

    context.state.treatmentSteps.forEach((step, index) => {
      if (index <= stepIndex) {
        step.status = 1;
      }
    });
    context.dispatch('updateClasses');
    context.state.currentStep = context.state.treatmentSteps[context.state.stepIndex];
  },
  initTreatmentPlan({
    commit,
  }) {
    commit('SET_TREATMENT_PLAN', {
      id: '',
      plantation: '',
      area: 0,
      description: '',
      observation: '',
      init_date: '',
      end_date: '',
      period: treatmentPlanPeriod.MONTHLY,
      vat_volume: 0,
      forward_speed: 0,
      status: '',
      periods: [],
      products: [],
      timeValues: [],
      dosages: [],
      totalApplications: [],
      company_name: '',
      default_apply_unit: [],
    });
    commit('SET_TREATMENT_PRODUCT_NOT_FOUND', {
      status: false,
      products: [],
    });
  },
  async updateTreatmentPlan({
    commit,
  }, payload) {
    commit('SET_TREATMENT_PRODUCT_NOT_FOUND', {
      status: false,
      products: [],
    });

    const { timeValues } = getTimeValues(
      moment(payload.init_date, 'YYYY/MM/DD'),
      moment(payload.end_date, 'YYYY/MM/DD'),
      payload.period,
    );

    const allTimeValuesKeys = Object.keys(timeValues[0]);
    const numberOfPeriods = allTimeValuesKeys.length;
    const numberOfProducts = Math.max(...payload.periods.map((item) => item.products.length));
    const timeValuesFinal = new Array(numberOfProducts).fill().map((u) => ({ ...timeValues[0] }));

    for (let i = 0; i < numberOfPeriods; i++) {
      const products = payload.periods[i]?.products ?? [];

      products.forEach(({
        dose_volume,
      }, indexProduct) => timeValuesFinal[indexProduct][allTimeValuesKeys[i]] = dose_volume);
    }

    const dosages = timeValues.map((item) => ({
      dosage: Object.values(item).reduce((total, value) => total + value, 0),
    }));

    const totalApplications = timeValues.map((item) => ({
      total: Object.values(item).filter((value) => value > 0).length,
    }));

    const treatmentProduct = payload.periods[0].products.map(product => 
      Api.getTreatmentProduct(payload.species, product.agent, product.product_id)
        .then(xhrProd => {
          const productService = JSON.parse(xhrProd.response);
          let dosageRange = '';
          if (productService.dose !== null) {
            dosageRange = `${productService.dose.max} - ${productService.dose.min} (${productService.dose.unit})`;
          }
          let noApplications = null;
          if (productService.application_num !== null) {
            noApplications = productService.application_num?.max ? parseFloat(productService.application_num.max).toFixed(2) : null;
          }
          return {
            id: product.product_id,
            name: productService.name,
            dosageRange,
            dose: productService.dose ? {
              min: productService.dose.min,
              max: productService.dose.max,
            } : null,
            noApplications,
            product_deleted: !productService?.status ?? false,
            ...product,
          };
        })
        .catch(error => {
          // Devolver el objeto por defecto en caso de fallar la promesa
          return {
            id: product.product_id,
            name: product.product,
            dosageRange: product.dosage_range,
            dose: null,
            noApplications: null,
            product_deleted: true,
            ...product,
          };
        })
    );
    
    const productsSettled = await Promise.allSettled(treatmentProduct);
    
    // Agrupamos en el mismo array todos los productos del resultado de las promesas con exito/fallidas
    const products = productsSettled.map(item => item.value);

    const vat_volume = payload.vat_volume ? parseFloat(payload.vat_volume) : payload.vat_volume;
    const forward_speed = payload.forward_speed ? parseFloat(payload.forward_speed) : payload.forward_speed;

    commit('SET_TREATMENT_PLAN', {
      id: payload.id,
      plantation: payload.plantation,
      area: payload.area,
      description: payload.description,
      observation: payload.observation,
      init_date: payload.init_date,
      end_date: payload.end_date,
      period: payload.period,
      vat_volume,
      forward_speed,
      status: payload.status,
      periods: payload.periods,
      products,
      timeValues,
      dosages,
      totalApplications,
      company_name: payload.company_name,
      default_apply_unit: payload.default_apply_unit,
      dosage_total: payload.dosage_total,
    });
  },
  async fetchTreatmentProducts({
    commit,
  }, {
    crop,
    agent,
  }) {
    try {
      const xhr = await Api.getTreatmentProducts(crop, agent);
      const treatmentProducts = JSON.parse(xhr.response).data;

      commit('setTreatmentProducts', treatmentProducts);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  async fetchTreatmentProduct({
    commit,
  }, {
    crop,
    agent,
    id,
  }) {
    try {
      const xhr = await Api.getTreatmentProduct(crop, agent, id);
      commit('setTreatmentProduct', JSON.parse(xhr.response));
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  setCurrentTreatmentId({
    commit,
  }, id) {
    commit('setCurrentTreatmentId', id);
  },
  deleteFromTreatmentError({
    commit,
  }, value) {
    commit('deleteFromTreatmentError', value);
  },
  async getTreatmentPlanByIdandCompanyId({
    commit,
  }, {
    companyId,
    treatmentId,
  }) {
    try {
      const xhr = await Api.getTreatmentPlanByIdandCompanyId(
        companyId,
        treatmentId,
      );
      const currentTreatment = JSON.parse(xhr.response);
      commit('getTreatmentPlanByIdandCompanyId', currentTreatment);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  setObservation({
    commit,
  }, value) {
    commit('SET_OBSERVATION', value);
  },
  setIsCopying({ commit }, value) {
    commit('SET_IS_COPYING', value);
  },
  setCopyingStepData({ commit }, data = null) {
    if (data !== null) {
      commit('SET_COPYING_STEP_DATA', data);
    } else {
      commit('SET_COPYING_STEP_DATA', {
        name: '',
        initDate: '',
        endDate: '',
      });
    }
  },
};

// to handle mutations
const mutations = {
  SET_TREATMENT_STEPS(stateL, treatmentSteps) {
    stateL.treatmentSteps = treatmentSteps;
  },
  SET_TREATMENT_PLAN(stateL, treatmentPlan) {
    stateL.treatmentPlan = treatmentPlan;
  },
  SET_CURRENT_STEP(stateL, currentStep) {
    stateL.currentStep = currentStep;
  },
  SET_STEP_INDEX(stateL, stepIndex) {
    stateL.stepIndex = stepIndex;
  },
  setTreatmentProducts(stateL, newTreatmentProducts) {
    stateL.treatmentProducts = newTreatmentProducts;
  },
  setTreatmentProduct(stateL, newTreatmentProduct) {
    stateL.treatmentProduct = newTreatmentProduct;
  },
  setCurrentTreatmentId(stateL, value) {
    stateL.currentTreatmentId = value;
  },
  getTreatmentPlanByIdandCompanyId(stateL, value) {
    stateL.treatmentPlan = value;
  },
  SET_TREATMENT_PRODUCT_NOT_FOUND(statel, value) {
    statel.hasTreatmentError = value;
  },
  deleteFromTreatmentError(state, value) {
    state.hasTreatmentError.products = state.hasTreatmentError.products.filter(
      (product, index) => value !== index,
    );

    if (state.hasTreatmentError.products.length === 0) {
      state.hasTreatmentError.status = false;
    }
  },
  SET_OBSERVATION(stateL, value) {
    stateL.treatmentPlan.observation = value;
  },
  SET_IS_COPYING(stateL, value) {
    stateL.isCopying = value;
  },
  SET_COPYING_STEP_DATA(stateL, data) {
    stateL.copyingStepData = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
