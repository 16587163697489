<template>
  <div class="content-devx">
    <div
      class="main-title-content"
      style="position: absolute; top: 13px; left: 6px; z-index: 200"
    >
      <div class="page-subtitle">
        {{ subTitle }}
      </div>
    </div>
    <div class="list-by-group-reference-plans content-separatos">
      <DxDataGrid
        ref="DataGrid"
        :data-source="dataSource"
        :remote-operations="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        :show-row-lines="true"
        :show-column-lines="true"
        :word-wrap-enabled="true"
        @cellPrepared="onCellPrepared"
      >
        <DxGrouping :auto-expand-all="false" />
        <DxHeaderFilter :visible="true" />
        <DxSorting mode="single" />
        <DxSearchPanel
          :visible="true"
          :width="200"
          :placeholder="$t('Search_placeholder')"
        />
        <DxPaging :page-size="10" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="[5, 10, 20]"
          :show-info="true"
          info-text="{2} items"
        />
        <DxColumn
          v-for="table in tables"
          :key="table.dataField"
          :data-field="table.dataField"
          :caption="table.caption"
          :width="table.width"
          :group-index="table.groupIndex"
          :min-width="table.minWidth"
          :alignment="table.alignment"
          :fixed="table.fixed"
          :fixed-position="table.fixedPosition"
          :cell-template="table.cellTemplate"
          :allow-header-filtering="table.allowHeaderFiltering"
          :group-cell-template="table.groupCellTemplate"
        />

        <template #groupCellTemplateCompany="data">
          <div style="display: flex; justify-content: space-between">
            <span>{{ data.data.value }}</span>
          </div>
        </template>

        <template #statusTemplate="data">
          <div
            :class="getStatusClass(data.data.data.status)"
          >
            {{ data.data.data.status }}
          </div>
        </template>

        <template #checkBoxTemplate="data">
          <DxCheckBox
            v-if="!disabledCheckBox(data.data.data)"
            :value="getPlantantionSelectionValue(data.data.data.id)"
            :disabled="disabledCheckBox(data.data.data)"
            @value-changed="(e) => updatePlantationsSelection(e, data)"
          />
        </template>
      </DxDataGrid>
    </div>
  </div>
</template>
<script>
import {
  DxDataGrid,
  DxColumn,
  DxGrouping,
  DxPaging,
  DxSearchPanel,
  DxHeaderFilter,
  DxPager,
  DxSorting,
} from 'devextreme-vue/data-grid';
import DxCheckBox from 'devextreme-vue/check-box';
import moment from 'moment';
import { DxTooltip } from 'devextreme-vue/tooltip';
import customStoreMixins from '../../../../js/customStore';

export default {
  name: 'PlantationsSelector',
  components: {
    DxDataGrid,
    DxColumn,
    DxGrouping,
    DxPaging,
    DxSearchPanel,
    DxHeaderFilter,
    DxPager,
    DxSorting,
    DxCheckBox,
    DxTooltip,
  },
  mixins: [customStoreMixins],
  props: {
    leftDateLimit: {
      type: String,
      default: null,
    },
    rightDateLimit: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      moduleName: 'contact/plantation',
      tables: [
        {
          dataField: 'company_name',
          caption: this.$t('company'),
          groupCellTemplate: 'groupCellTemplateCompany',
          minWidth: 170,
          fixed: true,
          fixedPosition: 'left',
        },
        {
          dataField: 'name',
          caption: this.$t('searchEngine_description'),
          minWidth: 170,
          fixed: true,
          fixedPosition: 'left',
        },
        {
          dataField: 'province',
          caption: this.$t('Plans.province'),
          minWidth: 140,
        },
        {
          dataField: 'explotation_name',
          caption: this.$t('Explotation'),
          minWidth: 140,
        },
        {
          dataField: 'parcel_name',
          caption: this.$t('searchEngine_parcel'),
          minWidth: 140,
        },
        {
          dataField: 'sector_name',
          caption: this.$t('searchEngine_sector'),
          minWidth: 140,
        },
        {
          dataField: 'crop_type',
          caption: this.$t('ViewPlantation.view_crop_type'),
          minWidth: 140,
        },
        {
          dataField: 'variety',
          caption: this.$t('variety'),
          minWidth: 140,
        },
        {
          dataField: 'status',
          caption: this.$t('status'),
          minWidth: 100,
          fixed: true,
          fixedPosition: 'right',
          cellTemplate: 'statusTemplate',
        },
        {
          dataField: 'init_date',
          caption: this.$t('searchEngine_init_date'),
          minWidth: 110,
          fixed: true,
          fixedPosition: 'right',
          alignment: 'center',
        },
        {
          dataField: 'end_date',
          caption: this.$t('searchEngine_end_date'),
          minWidth: 110,
          fixed: true,
          fixedPosition: 'right',
          alignment: 'center',
        },
        {
          dataField: '',
          caption: '',
          width: 40,
          fixed: true,
          fixedPosition: 'right',
          alignment: 'center',
          cellTemplate: 'checkBoxTemplate',
          allowHeaderFiltering: false,
        },
      ],
      plantationsSelection: [],
    };
  },
  computed: {
    subTitle() {
      return `${moment(this.leftDateLimit).format('DD/MM/YYYY')}  -  ${moment(
        this.rightDateLimit,
      ).format('DD/MM/YYYY')}`;
    },
  },
  methods: {
    getPlantantionSelectionValue(id) {
      const plantationSelected = this.plantationsSelection.find(
        (plantation) => plantation.id === id,
      );
      return plantationSelected ? plantationSelected.selected : false;
    },
    updatePlantationsSelection(e, data) {
      const plantationSelection = {
        id: data.data.data.id,
        selected: e.value,
      }
      const index = this.plantationsSelection.findIndex(plantation => plantation.id === plantationSelection.id);
      if (index !== -1) {
        this.plantationsSelection[index] = { ...this.plantationsSelection[index], selected: plantationSelection.selected };
      } else {
        this.plantationsSelection.push(plantationSelection);
      }
      this.plantationsSelection = this.plantationsSelection.filter(plantation => plantation.selected);
      this.$emit('updatePlantationsSelection', this.plantationsSelection);
    },
    getId(pre, company) {
      return `${pre}_${company.replace(
        /[^a-zA-Z0-9_]/g,
        '',
      )}`;
    },
    onCellPrepared(e) {
      if (e.rowType === 'data') {
        if (!['init_date', 'end_date'].includes(e.column.dataField)) return;

        if (e.column.dataField === 'init_date') {
          if (
            moment(e.data.init_date, 'YYYY-MM-DD').isSameOrBefore(
              moment(this.leftDateLimit),
            )
          ) {
            return;
          }
        }

        if (e.column.dataField === 'end_date') {
          if (
            moment(e.data.end_date, 'YYYY-MM-DD').isSameOrAfter(
              moment(this.rightDateLimit),
            )
          ) {
            return;
          }
        }

        e.cellElement.style.backgroundColor = '#fee2e2';
        e.cellElement.style.color = '#ef4444';
        e.cellElement.style.fontWeight = '600';
      }
    },
    disabledCheckBox(data) {
      if (
        !moment(data.init_date, 'YYYY-MM-DD').isSameOrBefore(
          moment(this.leftDateLimit),
        )
      ) {
        return true;
      }
      if (
        !moment(data.end_date, 'YYYY-MM-DD').isSameOrAfter(
          moment(this.rightDateLimit),
        )
      ) {
        return true;
      }
      return false;
    },
    getStatusClass(status) {
      return `status-column-cell status-column-cell-${status.toLowerCase()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './referencePlansCopy.styles.scss';
</style>
