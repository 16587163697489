<template>
  <f7-page class="main-viewproductdetail">
    <navbar :text="`${$t('Configuration.name')} > ${$t('Page_subtitle_product')}`" />
    <ProductDetail :product-detail="productDetail" />
    <f7-block-footer
      class="go-back-button margin-left margin-top"
    >
      <f7-button
        class="dx-btn-cancel no-margin padding-horizontal"
        raised
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goToProducts"
      >
        {{ $t("Go_Back") }}
      </f7-button>
    </f7-block-footer>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ProductDetail from '../../../components/configuration/products/productDetail/index.vue';

export default {
  name: 'ViewProductDetail',
  components: {
    ProductDetail,
    navbar,
  },
  computed: {
    ...mapState('Product', ['productDetail']),
  },
  methods: {
    goToProducts() {
      this.$f7.views.main.router.navigate('/products/', { reloadCurrent: true });
    },
  },
};
</script>

<style lang="scss">
.main-viewproductdetail{
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}
</style>
