<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('Configuration.name')} > ${$t('Page_subtitle_product')}`" />
    <ProductsDatabase />
  </f7-page>
</template>

<script>
import ProductsDatabase from '../../../components/configuration/products/productsDatabase/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Products',
  components: {
    ProductsDatabase,
    navbar,
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Configuración > Productos');
    },
  },
};
</script>
