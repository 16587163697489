<template>
  <section>
    <div
      class="content-devx margin-half"
      style="padding: 0"
    >
      <div style="padding: 4px 4px 0 4px">
        <div class="main-title-content">
          {{ $t('applicationEfficiencyPerPeriod') }}
        </div>
      </div>

      <div style="padding: 0 4px">
        <div class="content-separatos">
          <DxDataGrid
            class="grid-application-efficiency-per-period"
            :data-source="dataSource"
            :show-borders="true"
            :row-alternation-enabled="true"
            :column-auto-width="true"
            :show-row-lines="true"
            :show-column-lines="true"
          >
            <DxColumn
              data-field="element"
              caption="UF Eficientes"
              :allow-sorting="false"
              :width="130"
              :fixed="true"
              fixed-position="left"
            />
            <DxColumn
              v-for="(period, index) in getPeriods"
              :key="index"
              :data-field="period.dataField"
              :caption="period.name"
              alignment="right"
              :allow-sorting="false"
              :min-width="100"
            />
          </DxDataGrid>
        </div>
      </div>

      <div
        style="width: 100%; height: 1px; background: #e4e6ed; margin: 1rem 0"
      />
      <div style="padding: 0 4px 4px 4px">
        <DxButton
          class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-search"
          :text="chartName"
          :icon="chartIcon"
          @click="toggleChartType">
        </DxButton>
      
        <div class="content-separatos">
          <DxChart
            v-if="chartType === 'line'"
            id="application-efficiency-per-period-chart"
            style="width: 100%; height: 300px"
            :data-source="chartData"
            @legend-click="onLegendClick"
          >
            <DxValueAxis position="left">
              <DxTitle text="UF Eficientes (kg/ha)" />
            </DxValueAxis>
            <DxCommonSeriesSettings
              type="line"
              argument-field="name"
            >
              <DxPoint
                hover-mode="onlyPoint"
                :hover-style="{ size: 10 }"
                :size="0"
              />
            </DxCommonSeriesSettings>
            <DxLegend
              vertical-alignment="bottom"
              horizontal-alignment="center"
              item-text-position="right"
            />
            <DxTooltip
              :enabled="true"
              :z-index="999999"
              :interactive="true"
              :customize-tooltip="customizeTooltip"
            />

            <DxSeries
              value-field="N"
              name="N"
              color="#16a34a"
            />
            <DxSeries
              value-field="P205"
              name="P₂O₅"
              color="#ef444499"
            />
            <DxSeries
              value-field="K20"
              name="K₂O"
              color="#3b82f699"
            />
          </DxChart>
          <DxChart v-else id="application-efficiency-per-period-chart" style="width: 100%;" palette="SoftPastel" :data-source="chartData" @legend-click="onLegendClick">
            <DxTooltip :enabled="true" :z-index="999999" :customize-tooltip="customizeTooltip" />
            <DxSeries
              name="N"
              :value-field="getDynamicValueField('N')"
              color="#16a34a"
            />
            <DxSeries
              name="P₂O₅"
              :value-field="getDynamicValueField('P205')"
              color="#ef4444"
            />
            <DxSeries
              name="K₂O"
              :value-field="getDynamicValueField('K20')"
              color="#3b82f6"
            />
            <DxCommonSeriesSettings argument-field="name" type="stackedbar" />
            <DxLegend vertical-alignment="top" horizontal-alignment="center" item-text-position="right" />
            <DxSize :height="310" />
          </DxChart>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
import moment from 'moment';
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxLegend,
  DxTitle,
  DxTooltip,
  DxValueAxis,
  DxPoint,
  DxSize,
} from 'devextreme-vue/chart';
import DxButton from 'devextreme-vue/button';
import Api from '../../../services/Api';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';

export default {
  name: 'ApplicationEfficiencyPerPeriod',
  components: {
    DxDataGrid,
    DxColumn,
    DxChart,
    DxSeries,
    DxTitle,
    DxValueAxis,
    DxPoint,
    DxCommonSeriesSettings,
    DxLegend,
    DxTooltip,
    DxButton,
    DxSize,
  },
  props: {
    fertilization: {
      type: Object,
      default: () => {},
    },
    isReferencePlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSource: [],
      chartType: 'line',
      chartName: this.$t('nutritionDetail.nutritionDetail.chartLine'),
      chartIcon: 'minus',
    };
  },
  computed: {
    getPeriods() {
      let index = -1;

      const { timeValues } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      return Object.entries(timeValues[0]).map(([name, value]) => {
        index += 1;
        return {
          dataField: index.toString(),
          name,
        };
      });
    },
    chartData() {
      const data = [];

      const names = {
        0: 'N',
        1: 'P205',
        2: 'K20',
      };

      const { timeValues } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      Object.entries(this.dataSource).forEach(([key, value]) => {
        Object.entries(value).forEach(([keyEf, valueEf]) => {
          if (keyEf !== 'element') {
            data.push({
              [names[key]]: parseInt((valueEf ?? 0), 10),
              name: Object.keys(timeValues[0])[keyEf],
            });
          }
        });
      });

      return data;
    },
  },
  async mounted() {
    const ufs = {
      n: {},
      p205: {},
      k20: {},
    };

    const names = {
      n: 'N (kg/ha)',
      p205: 'P₂O₅ (kg/ha)',
      k20: 'K₂O (kg/ha)',
    };

    const FULL_EFFICIENCY = {
      n: 100,
      p: 100,
      k: 100,
      c: 100,
      m: 100,
      s: 100,
    };

    for (const product of this.fertilization.contribution_products) {
      const hasReduction = product.product_reduction_percentage > 0;
      let eficiencies = FULL_EFFICIENCY;

      if (!hasReduction) {
        eficiencies = await Api.getProductEfficiency({
          code: product?.product_code,
          tipology: product?.product_tipology,
          ph: this.fertilization.ph || 0,
        })
          .then((xhr) => {
            const data = JSON.parse(xhr.response);

            return {
              n: data.NT,
              p: data.P2015_Total,
              k: data.K2O,
              c: data.CaO,
              m: data.MgO,
              s: data.SO3,
            };
          })
          .catch(() => ({
            n: 0,
            p: 0,
            k: 0,
            c: 100,
            m: 100,
            s: 100,
          }));
      }

      product.eficiencies = eficiencies;
    }

    this.fertilization.contribution_products.forEach((product) => {
      product.periods.forEach(async (period, index) => {
        if (ufs.n[index] === undefined) {
          ufs.n[index] = 0;
          ufs.p205[index] = 0;
          ufs.k20[index] = 0;
        }

        ufs.n[index]
          += period * (product.n / 100) * ((product.presentation === 'SOLIDO' ? product.eficiencies.n : (product.eficiencies.n * product.density)) / 100);
        ufs.p205[index]
          += period * (product.p205 / 100) * ((product.presentation === 'SOLIDO' ? product.eficiencies.p : (product.eficiencies.p * product.density)) / 100);
        ufs.k20[index]
          += period * (product.k20 / 100) * ((product.presentation === 'SOLIDO' ? product.eficiencies.k : (product.eficiencies.k * product.density)) / 100);
      });
    });

    this.dataSource = Object.entries(ufs).map(([uf, values]) => ({
      element: names[uf],
      ...Object.values(values).map((value) => (value > 0 ? this.fixNumberTo(value, 0) : null)),
    }));
  },
  methods: {
    fixNumberTo(number, digits) {
      return parseFloat(parseFloat(number).toFixed(digits));
    },
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    toggleChartType() {
      const chartTypeMap = {
        line: {
          type: 'bar',
          name: this.$t('nutritionDetail.nutritionDetail.chartBar'),
          icon: 'chart',
        },
        bar: {
          type: 'line',
          name: this.$t('nutritionDetail.nutritionDetail.chartLine'),
          icon: 'minus',
        },
      };

      const newChartInfo = chartTypeMap[this.chartType];
      this.chartType = newChartInfo.type;
      this.chartName = newChartInfo.name;
      this.chartIcon = newChartInfo.icon;
    },
    getDynamicValueField(fieldName) {
      return fieldName;
    },
    customizeTooltip(point) {
      const color = point.point.getColor();

      const tableStyle = 'border-spacing: 0;border-collapse: collapse;font-size: 13px;width: 150px;overflow: auto;border-bottom: 1px solid #ccc;';
      const thStyle = 'width: 60px;white-space: nowrap;border: 1px solid #ccc;padding: 0 5px;';
      const tdStyle = 'width: 90px;border: 1px solid #ccc;padding: 4px;';

      return {
        html: `
          <div style='text-align: center; margin-bottom: 0.6rem; font-weight: 600'>
            <span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; margin-right: 5px; border-radius: 9999px;"></span>
            <span>${point.argumentText}</span>
          </div>
          <table style="${tableStyle}">
            <tbody>
              <tr>
                <th style="${thStyle}">${this.$t('DxElement')}</th>
                <td style="${tdStyle}">${point.seriesName}</td>
              </tr>
              <tr>
                <th style="${thStyle}">${this.$t(
  'weather_parameter_value',
)}</th>
                <td style="${tdStyle}">${point.value}</td>
              </tr>
            </tbody>
          </table>
        `,
      };
    },
  },
};
</script>

<style scoped>
.grid-application-efficiency-per-period::v-deep .dx-header-row td[role="columnheader"] {
  text-transform: none;
}
</style>
