<template>
  <div class="content-devx">
    <f7-block>
      <DxForm
        id="form"
        :col-count="2"
        :caption="$t('Campania_title')"
      >
        <DxGroupItem :col-span="1">
          <DxSimpleItem
            name="activity"
            :data-field="$t('Configuration.Activity')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: activities,
              searchEnabled: true,
              onValueChanged:setActivityChange,
              value:taskDefinitionDetail.activity
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_name_require')" />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem :col-span="1">
          <DxSimpleItem
            name="name"
            :data-field="$t('Configuration.Name')"
            editor-type="dxTextBox"
            :editor-options="{
              onValueChanged:setNameChange,
              value:taskDefinitionDetail.name
            }"
          >
            <DxRequiredRule :message="$t('DxRegisterCampania_name_require')" />
          </DxSimpleItem>
        </DxGroupItem>
      </DxForm>
    </f7-block>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

import { mapActions } from 'vuex';

export default {
  name: 'TaskDefinitionForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,

  },
  props: {
    activities: {
      type: Array,
      default: () => [],
    },
    taskDefinitionDetail: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    setActivityChange(e) {
      this.setActivity(e.value);
    },
    setNameChange(e) {
      this.setName(e.value);
    },
    ...mapActions('TaskDefinition', ['setName', 'setActivity']),

  },
};
</script>
<style lang="scss" scoped>
@import './TaskDefinitionForm.styles.scss';
</style>
