<template>
  <f7-block class="content-devx-margin-block no-margin-horizontal margin-bottom">
    <f7-row>
      <f7-col>
        <div v-if="!loadedGIS || currentIndex === ''">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div
          v-else
          class="margin-top-block"
        >
          <GISWMSMap
            :zoom="zoom"
            :name-map="`${mainMap}`"
            :layer="layer"
            :index="currentIndex"
            :height="'450px'"
            :center="center"
            :bbox="bbox"
            :companyId="currentPlantation.companyId"
          />
        </div>
      </f7-col>
      <f7-col>
        <f7-row>
          <GISLayerOptionsComparator
            v-if="(typeof dateList.avaiable_dates) !== 'undefined'"
            :key-id="`${mainMap}`"
            :default-index="'NDVI'"
            :dates-list="dateList"
            :set-three-cols="false"
            @date-changed="handleDateChange"
          />
        </f7-row>
        <CloudCoverage
          v-if="(typeof dateList.avaiable_dates) !== 'undefined'"
          :cloudsData="cloudsData"
        />
        <f7-block v-if="!histogram && loadedGIS">
          <div class="loadingFalse">
            <div v-if="dataUnavailable">
              <p>{{ $t('No_Images_Data') }}</p>
            </div>
            <div
              v-else
              class="preloader"
            />
          </div>
        </f7-block>
        <f7-row v-else-if="histogram && loadedGIS">
          <HistogramGraph
            v-if="currentIndex !== 'TCI'"
            :histogram="histogram[0].histogram"
            :showing-date="histogram[0].iso_date"
            :index="currentIndex"
            :max="histogram[0].max"
            :min="histogram[0].min"
            :mean="histogram[0].mean"
            :stddev="histogram[0].stddev"
          />
          <HistogramByClass
            v-if="currentIndex !== 'TCI'"
            :histogram="histogram[0].histogram"
            :area="getArea"
            :cloud="cloudsData"
            :showing-date="histogram[0].iso_date"
            :index="currentIndex"
            :max="histogram[0].max"
            :min="histogram[0].min"
            :mean="histogram[0].mean"
            :stddev="histogram[0].stddev"
          />
        </f7-row>
      </f7-col>
    </f7-row>
  </f7-block>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import GISWMSMap from '../../../Gis/GISWMSMap/index.vue';
import GISLayerOptionsComparator from '../../../Gis/LayerOptionsComparator/index.vue';
import HistogramGraph from '../histogramGraph/index.vue';
import HistogramByClass from '../histogramByClass/index.vue';
import Api from '../../../../services/Api';
import CloudCoverage from '../../../Gis/CloudCoverage/CloudCoverage.vue';

export default {
  name: 'GisWMS',
  components: {
    GISWMSMap,
    GISLayerOptionsComparator,
    HistogramGraph,
    HistogramByClass,
    CloudCoverage,
  },
  data() {
    return {
      loadedGIS: false,
      zoom: 17,
      mainMap: 'GisWMS',
      layer: {
        name: 'gis',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: 'NDVI',
        },
      },
      cloudsData: null,
      center: {},
      bbox: {},
      dateList: {},
      dataUnavailable: false,
      ws: Api.getGeoServerWorkspace(),
    };
  },
  computed: {
    getArea() {
      return this.currentPlantation.area;
    },
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Gis', ['currentIndexes', 'currentIndex', 'datesList', 'histogram', 'currentInitIntervalDates', 'currentBoundsWMSLayer', 'histogramValues', 'currentGisWMS']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      await this.logEventAndSetHistogram();
      await this.updateLayer();
      await this.fetchDatesAndHistogram();
      this.filterHistogramByIndex();
    } catch (error) {
      this.handleError(error);
    } finally {
      this.$f7.preloader.hide();
      this.loadedGIS = true;
      await this.fetchCloudData();
    }
  },
  methods: {
    resetConfig(key) {
      this.setCurrentIndexes({
        key,
        newCurrentIndex: 'NDVI',
      });
      this.setCurrentInitIntervalDates({
        key,
        newCurrentInitIntervalDate: '',
      });
      this.setCurrentEndIntervalDates({
        key,
        newCurrentEndIntervalDate: '',
      });
      this.setCurrentAreaCovertClouds({
        key,
        newCurrentAreaCovertCloud: 0
      });
    },
    async logEventAndSetHistogram() {
      this.$fa.logEvent('buscador_gis_indice_radiometrico', {
        content_type: 'button',
        description: 'Evento al entrar en linea temporal de buscador/gis',
        sectorId: this.currentPlantation.sectorId,
      });
      this.setHistogram(null);
    },
    async updateLayer() {
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      await this.boundsWMSLayer(`${this.ws}:${this.currentPlantation.sectorId}`);
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
    },
    async fetchDatesAndHistogram() {
      await this.fetchDatesLayer({layer: this.layer.options.layers.split(':')[1], companyId: this.currentPlantation.companyId});
      this.dateList = this.datesList;
      const date = this.getDateForHistogram();
      await this.fetchHistogram({ location: `${this.currentPlantation.sectorId}`, date, company: this.currentPlantation.companyId });
    },
    handleError(error) {
      console.error('Error:', error);
      const errorMessage = this.$helpers.getFilteredErrorMessage(`${error.message} wms`);
      this.$notifyDX({
        message: this.$t(errorMessage),
        width: 550,
      }, 'warning', 3000);
    },
    async fetchCloudData() {
      try {
        let formattedCloud = null;
        const dateCloud = this.getDateForHistogram();
        const result = await Api.getGeoServerClouds(this.currentPlantation.sectorId, dateCloud);
        formattedCloud = JSON.parse(result.response);
        this.cloudsData = Number(formattedCloud.clouds);
        this.setCurrentGisWMS(this.cloudsData);
      } catch (error) {
        console.error('Error fetching cloud data:', error.cause);   
        this.setCurrentGisWMS(0);
        this.cloudsData = 0;
      }
    },
    getDateForHistogram() {
      const availableDates = this.dateList.avaiable_dates || this.dateList;
      return moment(availableDates[availableDates.length - 1]).format('YYYY-MM-DD');
    },
    async handleDateChange(data) {
      const { date } = data;
      try {
        let formattedCloud = null;
        const dateInit = moment(date, 'YYYY/MM/DD').format('YYYY-MM-DD');
        const result = await Api.getGeoServerClouds(this.currentPlantation.sectorId, dateInit);
        formattedCloud = JSON.parse(result.response);
        this.cloudsData = Number(formattedCloud.clouds);
        this.setCurrentGisWMS(this.cloudsData);
      } catch (error) {
        this.cloudsData = 0;
        this.setCurrentGisWMS(0);
        console.error('Error fetching cloud data:', error);
      }
    },
    goToGisGrid() {
      this.$f7.views.main.router.navigate('/gisGrid/', { reloadCurrent: true });
    },
    filterHistogramByIndex() {
      const histogramDate = this.dateList.avaiable_dates ? this.dateList.avaiable_dates[this.dateList.avaiable_dates.length - 1] : this.dateList[this.dateList.length - 1];
      const histogramInformation = [];

      if (this.histogramValues.length < 1) {
        this.setHistogram(null);
        this.dataUnavailable = true;
        return;
      }

      this.histogramValues.forEach((histogramWithIndex) => {
        if (histogramWithIndex.index.toString().toUpperCase() === this.currentIndex.toString()) {
          histogramInformation.push({
            histogram: histogramWithIndex.histogram, 
            iso_date: histogramDate, 
            max: histogramWithIndex.max,
            min: histogramWithIndex.min,
            mean: histogramWithIndex.mean,
            stddev: histogramWithIndex.stddev,
          });
        }
      });

      this.setHistogram(histogramInformation);
    },

    ...mapActions('Gis', ['setCurrentIndexes', 'setCurrentInitIntervalDates', 'setCurrentEndIntervalDates', 'fetchDatesLayer', 'fetchHistogram', 'setHistogram', 'boundsWMSLayer', 'setCurrentGisWMS']),
  },
};
</script>

<style lang="scss" scoped>
@import './GisWMS.styles.scss';
</style>
