<template>
  <div
    class="content-devx"
  >
    <f7-row>
      <f7-col width="80">
        <f7-block-title>
          {{ $t('sector.detail.title') }}
        </f7-block-title>
      </f7-col>
      <f7-col width="20">
        <div class="justify-content-end">
          <ContextMenu :items="items" />
        </div>
      </f7-col>
    </f7-row>
    <f7-block>
      <f7-row>
        <f7-col>
          {{ $t('parcel.detail.client') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.location') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.parcelName') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentClient }}
        </f7-col>
        <f7-col>
          {{ currentSector.explotation }}
        </f7-col>
        <f7-col>
          {{ currentSector.parcelName }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('sector.detail.sectorName') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.area') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.groundTexture') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentSector.sectorName }}
        </f7-col>
        <f7-col v-if="currentSector.area">
          {{ currentSector.area.toFixed(2) }} ha
        </f7-col>
        <f7-col>
          {{ currentSector.groundTexture }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('sector.detail.irrigationType') }}
        </f7-col>
        <f7-col v-if="hasFlow">
          {{ $t('sector.detail.flow') }}
        </f7-col>
        <f7-col v-if="hasDripperPerPlant">
          {{ $t('sector.detail.dripperPerPlant') }}
        </f7-col>
        <f7-col v-if="hasSprinklersNumber">
          {{ $t('sector.detail.sprinklersNumber') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentSector.irrigationType }}
        </f7-col>
        <f7-col v-if="hasFlow">
          {{ currentSector.flow }} {{ $t('sector.detail.lH') }}
        </f7-col>
        <f7-col v-if="hasDripperPerPlant">
          {{ currentSector.dripperPerPlant }}
        </f7-col>
        <f7-col v-if="hasSprinklersNumber">
          {{ currentSector.sprinklersNumber }}
        </f7-col>
      </f7-row>
      <f7-row
        v-if="currentSector.irrigationType === 'ASPERSIÓN'"
        class="margin-top-half"
      >
        <f7-col>
          {{ $t('sector.detail.separationBetweenLines') }}
        </f7-col>
        <f7-col>
          {{ $t('sector.detail.separationBetweenSprinkler') }}
        </f7-col>
        <f7-col>
          <!-- {{ $t('sector.detail.transformedArea') }} -->
        </f7-col>
      </f7-row>
      <f7-row
        v-if="currentSector.irrigationType === 'ASPERSIÓN'"
        class="bold"
      >
        <f7-col>
          {{ currentSector.separationBetweenLines }} {{ $t('sector.detail.m') }}
        </f7-col>
        <f7-col>
          {{ currentSector.separationBetweenSprinkler }} {{ $t('sector.detail.m') }}
        </f7-col>
        <f7-col>
          <!-- {{ currentSector.transformedArea }} {{ $t('sector.detail.m2') }} -->
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>
import ContextMenu from '@/components/contextMenu/index.vue';
import { mapState } from 'vuex';

export default {
  name: 'SectorDetail',
  components: {
    ContextMenu,
  },
  props: {
    currentSector: {
      type: Object,
      default: () => {},
    },
    currentClient: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      items: [
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.editCurrentSector,
        },
        {
          icon: 'map',
          text: `${this.$t('viewSector')} (Google maps)`,
          onItemClick: () => {
            window.open(
              `https://www.google.es/maps/@${this.sectorCentroide.latitude},${this.sectorCentroide.longitude},700m/data=!3m1!1e3!5m2!1e4!1e1?hl=es`, '_blank',
            );
          },
        },
      ],
    };
  },
  computed: {
    hasFlow() {
      return this.currentSector.irrigationType !== 'SECANO';
    },
    hasDripperPerPlant() {
      return this.currentSector.irrigationType === 'GOTEO';
    },
    hasSprinklersNumber() {
      return this.currentSector.irrigationType === 'ASPERSIÓN';
    },
    ...mapState('Gis', ['sectorCentroide']),
  },
  methods: {
    editCurrentSector() {
      this.$f7.views.main.router.navigate('/registerSectorStep2Page/', { reloadCurrent: true });
    },
  },
};
</script>
<style lang="scss" scoped>
@import './SectorDetail.styles.scss';
</style>
