<template>
  <div>
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
      </div>
      <div
        name="txtDataAccess"
        class="label-text"
      >
        {{ $t("RecoveryPassword_txtDataAccess") }}
      </div>
      <f7-list form>
        <f7-list-input
          name="inpEmail"
          outline
          item-label
          type="email"
          :placeholder="$t('RecoveryPassword_inpEmail_placeholder')"
          clear-button
          max="255"
          required
          validate
          @input="email = $event.target.value"
        />
      </f7-list>
      <!-- Buttons -->
      <f7-block>
        <f7-row>
          <f7-col>
            <f7-button
              name="btnSendEmail"
              class="botonPrimary"
              col
              button
              button-large
              button-raised
              fill
              :disabled="btnDisable"
              @click="recoveryPassword"
            >
              {{ $t("RecoveryPassword_btnSendEmail") }}
            </f7-button>
          </f7-col>
          <f7-col>
            <f7-button
              name="btnGoBack"
              class="botonPrimary"
              col
              button
              button-large
              button-raised
              fill
              @click="goBackToLogin"
            >
              {{ $t("RecoveryPassword_btnGoBack") }}
            </f7-button>
          </f7-col>
        </f7-row>
      </f7-block>
    </f7-block>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { APPLICATION_NAME, APP_LOGO } from '../../js/constants';
import Api from '../../services/Api';

export default {
  name: 'RecoveryPasswordPage',
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
      email: '',
    };
  },
  computed: {
    btnDisable() {
      return !this.email;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
  },

  methods: {
    async recoveryPassword() {
      this.$f7.preloader.show();
      try {
        await Api.recoveryPassword({
          email: this.email,
          url: '',
        });
        this.setMessageChangePassword();
        this.wantToRecoveryPass();
      } catch (reason) {
        const message = this.$helpers.getFilteredErrorMessage(reason);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    goBackToLogin() {
      this.$f7router.back();
    },
    ...mapActions('authentication', ['setWantToRemember', 'wantToRecoveryPass', 'changePassword', 'setMessageChangePassword']),
  },
};
</script>

<style lang="scss" scoped>
    @import "../../assets/styles/accounts/recoveryPassword.scss";
</style>
