<template>
  <div>
    <f7-row no-gap>
      <f7-col width="60">
        <div v-if="!loadedIrrigation">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div v-else>
          <IrrigationComponent />
        </div>
      </f7-col>
      <f7-col width="40">
        <div v-if="!loadedGIS">
          <div class="loadingFalse">
            <div class="preloader" />
          </div>
        </div>
        <div
          v-else
          class="content-devx-margin-block"
        >
          <GISWMSMap
            :zoom="zoom"
            :name-map="mainMap"
            :layer="layer"
            :index="'NDMI'"
            :center="center"
            :height="'450px'"
            :bbox="bbox"
            :companyId="currentPlantation.companyId"
          />
        </div>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import GISWMSMap from '../../Gis/GISWMSMap/index.vue';
import IrrigationComponent from '../../irrigation/index.vue';
import Api from '../../../services/Api';

export default {
  name: 'Irrigation',
  components: {
    GISWMSMap,
    IrrigationComponent,
  },
  data() {
    return {
      loadedGIS: false,
      loadedIrrigation: false,
      zoom: 17,
      mainMap: 'irrigation',
      layer: {
        name: 'irrigation',
        visible: false,
        timeDimension: true,
        baseUrl: Api.getGeoServerProjectWorkSpace(),
        options: {
          layers: '',
          format: 'image/png',
          transparent: true,
          styles: '',
        },
      },
      center: {},
      ws: Api.getGeoServerWorkspace(),
      bbox: {},
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapState('Gis', ['currentBoundsWMSLayer']),
  },
  async beforeMount() {
    this.$fa.setScreenName('Buscador > Riego');
    try {
      this.setIdPlantation(this.currentPlantation.id);
      this.setIdCompany(this.currentPlantation.companyId);
      this.setIdPlantationTask(this.currentPlantation.id);
      this.setIdCompanyTask(this.currentPlantation.companyId);
      this.loadedIrrigation = true;
      this.layer.options.layers = `${this.ws}:${this.currentPlantation.sectorId}`;
      this.center = { ...this.currentPlantation.coordinates };
      await this.fetchLocationsByPlantation();
      await this.boundsWMSLayer(`timacagro:${this.currentPlantation.sectorId}`);
      this.bbox = { ...this.currentBoundsWMSLayer.coverage.latLonBoundingBox };
      this.center = { ...this.currentPlantation.coordinates };
      this.loadedGIS = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(this.$helpers.getFilteredErrorMessage(error.message)),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Irrigation', ['setIdPlantation', 'setIdCompany']),
    ...mapActions('TaskByCompany', ['setIdPlantationTask', 'setIdCompanyTask']),
    ...mapActions('Gis', ['boundsWMSLayer']),
  },
};
</script>

<style lang="scss" scoped>
@import './Irrigation.styles.scss';
</style>
