<template>
  <f7-page>
    <navbar :text="`${$t('Plans.title')} > ${$t('Plans.details')}`" />
    <ModalCopyReferencePlan
      :id="currentPlanId"
      :visible="ShowModal"
      type="nutrition"
      @close="ShowModal = false"
      @copy="copy"
    />
    <template v-if="currentTypePlan === 'fertilization'">
      <NutritionDetailComponent
        v-if="loaded"
        :fertilization="currentPlan"
        :plantation-property="actualPlantation"
        :hide-report-button="false"
        @open-copy-modal="ShowModal = true"
      />
      <f7-row no-gap>
        <f7-col style="align-self: stretch">
          <ElementResumeComponent
            v-if="loaded"
            class="element-resume"
            :fertilization="currentPlan"
          />
        </f7-col>
        <f7-col>
          <ProductResumeChartComponent
            v-if="loaded"
            :fertilization="currentPlan"
          />
        </f7-col>
      </f7-row>
      <ProductResumeComponent
        v-if="loaded"
        :plantationData="actualPlantation"
        :fertilization="currentPlan"
      />

      <IrrigationResumeTable
        v-if="loaded && !showIrrigationTable"
        :fertilization="currentPlan"
      />

      <ApplicationEfficiencyPerPeriod
        v-if="isNew && loaded"
        :fertilization="currentPlan"
      />

      <Observations
        v-if="loaded"
        :fertilization="currentPlan"
      />
    </template>
    <template v-else>
      <TreatmentDetailPlanComponent
        v-if="loaded"
        :id="currentPlanId"
        :hide-back-button="true"
        :called-from-view-plan="true"
        :hide-report-button="false"
      />
    </template>
    <div class="content-navigation">
      <f7-button
        class="dx-btn-cancel padding-left padding-right margin-bottom"
        type="default"
        text-color="gray"
        styling-mode="outlined"
        name="ButtonCancel"
        @click="goToBack()"
      >
        {{ $t("Button_go_to_back") }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import navbar from '../../../components/NavBar.vue';
import NutritionDetailComponent from '../../../components/nutritionDetail/nutritionDetail/index.vue';
import ElementResumeComponent from '../../../components/nutritionDetail/elementResume/index.vue';
import ProductResumeComponent from '../../../components/nutritionDetail/productResume/index.vue';
import IrrigationResumeTable from '../../../components/nutritionDetail/irrigationResumeTable/index.vue';
import TreatmentDetailPlanComponent from '../../../components/wizardTreatment/treatmentDetailPlan/index.vue';
import Observations from '../../../components/nutritionDetail/observations/index.vue';
import ProductResumeChartComponent from '../../../components/nutritionDetail/productResumeChart/index.vue';
import ApplicationEfficiencyPerPeriod from '../../../components/nutritionDetail/ApplicationEfficiencyPerPeriod/index.vue';
import ModalCopyReferencePlan from '../../../components/utilities/referencePlans/detail/ModalCopyReferencePlan.vue';
import Api from '../../../services/Api';

export default {
  name: 'ViewPlanDetail',
  components: {
    navbar,
    NutritionDetailComponent,
    ElementResumeComponent,
    ProductResumeComponent,
    TreatmentDetailPlanComponent,
    Observations,
    ProductResumeChartComponent,
    ApplicationEfficiencyPerPeriod,
    ModalCopyReferencePlan,
    IrrigationResumeTable,
  },
  data() {
    return {
      loaded: false,
      ShowModal: false,
    };
  },
  computed: {
    showIrrigationTable() {
      return (
        this.currentPlan?.periods_irrigation &&
        this.currentPlan.periods_irrigation.every(value => value === 0)
      ) ?? true;
    },
    ...mapState('Plans', ['currentPlanId', 'currentTypePlan', 'currentPlan']),
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['plantations']),
    ...mapState('nutritionStepper', ['isNew']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    // TODO: Mirar esto al hacer copia
    // this.setCurrentFertilizationId('');
    try {
      await this.getPlanById({
        planId: this.currentPlanId,
        typePlan: this.currentTypePlan,
      });

      const newPlan = this.currentPlan?.version === '2.2.0';
      this.setIsNew(newPlan);

      const data = {
        companyId: this.currentPlan.company_id,
        plantationId: this.currentPlan.plantation,
      };

      await this.getPlantationById(data);

      this.setActualUser({ id: this.currentPlan.company_id });

      this.formatPlantation(this.actualPlantation);
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    async copy(date) {
      this.$f7.preloader.show();
      try {
        const planDuration = moment(this.currentPlan.end_date).diff(moment(this.currentPlan.init_date), 'days') || 0;

        const xhr = await Api.getPlantationById(
          this.currentPlan.company_id,
          this.currentPlan.plantation,
        );
        const plantation = JSON.parse(xhr.response);
        this.$f7.preloader.hide();

        this.$f7router.navigate({
          name: 'nutrition-plans-copy',
          params: {
            id: this.currentPlanId,
          },
          query: {
            date,
            duration: planDuration,
            species: plantation.species,
          },
        });
      } catch (error) {
        this.$f7.preloader.hide();
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    goToBack() {
      this.$f7.views.main.router.navigate('/plans/', { reloadCurrent: true });
    },
    formatPlantation(plantation) {
      const currentPlant = {
        id: plantation.id,
        companyId: this.currentPlan.company_id,
        company: plantation.company_name,
        name: plantation.name,
        province: plantation.province,
        explotation: plantation.explotation,
        parcel: plantation.parcel,
        sector: plantation.sector_name,
        campaign: plantation.campaign,
        variety: plantation.variety,
        initDate: plantation.init_date,
        endDate: plantation.end_date,
        species: plantation.species,
        status: plantation.status,
        area: this.currentPlan.area,
        coordinates: plantation.coordinates,
        sectorId: plantation.sectorId,
        sectorIdLeftComparator: false,
        sectorIdRightComparator: false,
        isPlantationSelected: false,
      };

      this.setCurrentPlantation(currentPlant);
    },
    getStatusCampaign(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('status_campania_active');
      }
      if (endDate < this.actualDate) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    ...mapActions('Plans', ['getPlanById']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('Plantation', ['setCurrentPlantation']),
    ...mapActions('fertilizationStore', ['setCurrentFertilizationId']),
    ...mapActions('Explotaciones', ['setActualUser']),
    ...mapActions('nutritionStepper', ['setIsNew']),
  },
};
</script>

<style scoped>
.dx-btn-cancel {
  width: fit-content;
}
.element-resume {
  display: flex;
  height: 100%
}
</style>
