import helpers from '../../js/helpers';
import Api from '../../services/Api';

export default {
  namespaced: true,
  state: {
    allSales: [],
  },
  mutations: {
    UPDATE_SALES(state, value) {
      state.allSales = value;
    },
  },
  actions: {
    async getAllSales({ commit, rootGetters }) {
      try {
        const companyData = rootGetters['Explotaciones/actualUser'];
        const xhr = await Api.getSales(companyData.id);
        const allSales = JSON.parse(xhr.response).data;
        const dataSales = [];
        for (let i = 0; i < allSales.length; i += 1) {
          dataSales.push({
            sale_date: allSales[i].sale_date,
            deal: allSales[i].deal,
            company: allSales[i].company,
            sale_point: allSales[i].sale_point,
            product_code: allSales[i].product_code,
            product_name: allSales[i].product_name,
            quantity: allSales[i].quantity,
            specie_code: allSales[i].specie_code,
            status: allSales[i].status,
            specie: allSales[i].specie === null ? '' : allSales[i].specie,
          });
        }
        commit(
          'UPDATE_SALES',
          dataSales,
        );
      } catch (e) {
        const message = helpers.getFilteredErrorMessage(e);
        if (message.includes('default_') && !message.includes('404')) {
          alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          alert(this.$t(message));
        }
      }
    },
  },
};
