var render = function render(){var _vm=this,_c=_vm._self._c;return _c('f7-page',[_c('navbar',{attrs:{"text":`${_vm.$t('LeftPanel_title_ParcelSystem')} > ${_vm.$t('Page_subtitle_clients')} > ${_vm.actualUser.name} > ${_vm.$t('Location_title')}`}}),_vm._v(" "),_c('RegisterLocation',{attrs:{"title":this.$t('Register_farm'),"current-explotation":{
      name: _vm.name,
      registerNumber: _vm.registerNumber,
      province: _vm.province,
      farm: _vm.farm,
      ownerFarm: _vm.ownerFarm,
      mobile: _vm.mobile,
      postalCode: _vm.postalCode,
    }}}),_vm._v(" "),_c('f7-row',[_c('f7-col',[_c('f7-button',{staticClass:"dx-btn-cancel margin-right",attrs:{"raised":"","type":"default","text-color":"grey","styling-mode":"outlined","name":"ButtonCancel"},on:{"click":function($event){return _vm.goToBack()}}},[_vm._v("\n        "+_vm._s(_vm.$t('Button_go_to_back'))+"\n      ")])],1),_vm._v(" "),_c('f7-col',[_c('f7-button',{staticClass:"dx-btn-success",attrs:{"raised":"","type":"success","styling-mode":"contained"},on:{"click":_vm.registerFarmAndCreatePlot}},[_vm._v("\n        "+_vm._s(_vm.$t("Button_go_create_new_Campania"))+"\n      ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }