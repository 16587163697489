<template>
  <div
    class="content-devx"
  >
    <f7-block-title class="margin-bottom">
      <f7-row>
        <f7-col width="20">
          {{ $t('Configuration.Variety_Detail_Title') }}
        </f7-col>
        <f7-col width="80" />
      </f7-row>
    </f7-block-title>
    <f7-block>
      <f7-row class="margin-bottom">
        <f7-col>
          {{ $t('group') }}
        </f7-col>
        <f7-col>
          {{ $t('variety') }}
        </f7-col>
        <f7-col>
          {{ $t('specie') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold margin-bottom">
        <f7-col>
          {{ varietyDetail.group }}
        </f7-col>
        <f7-col>
          {{ varietyDetail.name }}
        </f7-col>
        <f7-col>
          {{ varietyDetail.specie }}
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

export default {
  name: 'VarietyDetail',
  props: {
    varietyDetail: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './VarietyDetail.styles.scss';
</style>
