import numeral from 'numeral';
import moment from 'moment';
import { getTimeValues } from '../../../js/utils/timeValuesUtils';
import store from '../../../store/index';

const numeralFormat = '0,0.00';

const formatNumber = (number, digits = 2) => {
  if (number) {
    return parseFloat(number.toFixed(digits));
  }
  return number;
};

export default class ProductDataFormatter {
  constructor(fertilizationData) {
    this.fertilization = fertilizationData;
    this.plantation = null;
    this.productResume = null;
    this.stackProduct = [];
  }

  async buildProductResumeData() {
    this.plantation = store.getters['RegisterPlantation/actualPlantation'];
    this.productResume = this.fertilization.contribution_products.map((product) => {
      const isSolid = product?.presentation === 'SOLIDO';

      const dosageUnit = formatNumber(product.periods.reduce((a, b) => a + b, 0));
      const dosageUnitKg = formatNumber(isSolid ? dosageUnit : dosageUnit * (product?.density || 1));
      const dosageUnitL = isSolid ? '' : dosageUnit;

      // const dosagePlant = this.plantation.plants_density > 0 ? formatNumber((dosageUnit / this.plantation.plants_density) * 1000) : 0;
      // const dosagePlantKg = dosagePlant;
      // const dosagePlantL = isSolid ? '' : dosagePlant;

      const dosagePlant = formatNumber(
        (dosageUnit * 1000) / this.plantation.plants_density, 1,
      );
      const dosagePlantKg = formatNumber(
        (dosageUnitKg * 1000) / this.plantation.plants_density, 1,
      );
      const dosagePlantL = isSolid ? '' : formatNumber(
        (dosageUnitL * 1000) / this.plantation.plants_density, 1,
      );

      // const dosageTotal = dosageUnit * this.fertilization.area;
      // const dosageTotalKg = dosageTotal;
      // const dosageTotalL = isSolid ? '' : dosageTotal;

      const dosageTotal = formatNumber(
        dosageUnit * this.fertilization.area,
      );
      const dosageTotalKg = formatNumber(dosageUnitKg * this.fertilization.area);
      const dosageTotalL = isSolid ? '' : formatNumber(dosageUnitL * this.fertilization.area);

      const costPlant = this.plantation.plants > 0 ? formatNumber(product.total_cost / this.plantation.plants, 1) : 0;

      return {
        id: product.product,
        product: product.product_name,
        status: product.product_status,
        density: product.density,
        presentation: product.presentation,
        tipology: product.tipology,

        dosageUnit: formatNumber(dosageUnit, 1),
        dosageUnitKg: formatNumber(dosageUnitKg, 1),
        dosageUnitL: formatNumber(dosageUnitL, 1),

        dosagePlant,
        dosagePlantKg,
        dosagePlantL,

        dosageTotal: formatNumber(dosageTotal, 1),
        dosageTotalKg: formatNumber(dosageTotalKg, 1),
        dosageTotalL: formatNumber(dosageTotalL, 1),

        price: formatNumber(product.price),
        priceKg: formatNumber(product.price_per_kg, 3),
        priceL: formatNumber(product.price_per_liter, 3),

        costUnit: formatNumber(product.unit_cost, 1),
        costPlant,
        costTotal: formatNumber(product.total_cost),
      };
    });

    const { timeValues } = getTimeValues(
      moment(this.fertilization.init_date,'YYYY/MM/DD'),
      moment(this.fertilization.end_date,'YYYY/MM/DD'), 
      this.fertilization.period,
      false,
      true
    );
    
    const notFoundProducts = [];

    this.productResume.forEach((product, i) => {
      this.productResume[i].status = product?.status ?? true;

      this.fertilization.contribution_products[i].periods.forEach((period, indexPeriod) => {
        this.stackProduct.push({
          productName: product.product,
          monthWeek: timeValues[indexPeriod],
          value: period,
        });
      });
    });

    this.productResume.push({
      product: 'Total',
      dosageUnit: '',
      dosagePlant: '',
      dosageTotal: '',
      price: '',
      status: '',
      costUnit: formatNumber(this.sumValue('costUnit')),
      costPlant: formatNumber(this.sumValue('costPlant')),
      costTotal: formatNumber(this.sumValue('costTotal')),
    });

    if (notFoundProducts.length > 0) throw new Error(JSON.stringify(notFoundProducts));
    return this.productResume;
  }
  calculateTotals() {
    this.productResume.push({
      product: this.$t('nutritionDetail.productResume.total'),
      dosageUnit: '',
      dosagePlant: '',
      dosageTotal: '',
      price: '',
      costUnit: numeral(this.sumValue('costUnit')).format(numeralFormat),
      costPlant: numeral(this.sumValue('costPlant')).format(numeralFormat),
      costTotal: numeral(this.sumValue('costTotal')).format(numeralFormat),
    });
  }

  numOr0(number) {
    return number === undefined ? 0 : numeral(number).value();
  }

  sumValue(prop) {
    return this.productResume.reduce(
      (a, b) => ({ [prop]: this.numOr0(a[prop]) + this.numOr0(b[prop]) }),
      { [prop]: this.numOr0(0) },
    )[prop];
  }
}
