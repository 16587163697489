<template>
  <f7-page name="treatment-detail-order">
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('treatment.title')}`" />
    <treatment-detail-order-component :id="id" />
  </f7-page>
</template>
<script>
import { mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import treatmentDetailOrderComponent from '@/components/wizardTreatment/treatmentDetailOrder/index.vue';

export default {
  name: 'TreatmentDetailOrder',
  components: {
    navbar,
    treatmentDetailOrderComponent,
  },
  props: ['id'],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};

</script>
<style src="./treatment-detail-order.scss" scoped lang="scss"></style>
