<template>
  <f7-block class="content-calendar">
    <v-date-picker
      v-model="range"
      is-range
      :masks="format"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="content-calendar__inputs">
          <div>
            <svg
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <input
              :value="inputValue.start"
              v-on="inputEvents.start"
            >
          </div>
          <span class="content-calendar__span">
            <svg
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </span>
          <div>
            <svg
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <input
              :value="inputValue.end"
              v-on="inputEvents.end"
            >
          </div>
        </div>
      </template>
    </v-date-picker>
    <div class="content-calendar__buttons">
      <button
        class="dx-btn-cancel"
        type="button"
        @click="clearFilter"
      >
        {{ $t("CalendarInitEndDate_btnClear") }}
      </button>
      <button
        class="dx-btn-success"
        type="button"
        @click="setFilter"
      >
        {{ $t("CalendarInitEndDate_btnSearch") }}
      </button>
    </div>
  </f7-block>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import EventBus from '../js/event-bus';

export default {
  name: 'CalendarInitEndDate',
  props: {
    keyName: { type: String, default: '' },
  },
  data() {
    return {
      range: {
        start: '',
        end: '',
      },
      format: {
        input: ['YYYY-MM-DD'],
      },
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  beforeMount() {
    if (typeof this.actualFilter[this.keyName] !== 'undefined') {
      this.range.start = moment(this.actualFilter[this.keyName].init).format('YYYY-MM-DD');
      this.range.end = moment(this.actualFilter[this.keyName].end).format('YYYY-MM-DD');
    }
    EventBus.$on('clearFilter', this.clearFilter);
  },
  methods: {
    setFilter() {
      if (this.range.start !== '' && this.range.end !== '') {
        this.setActualFilter({
          keyName: this.keyName,
          init: moment(this.range.start).format('YYYY-MM-DD'),
          end: moment(this.range.end).format('YYYY-MM-DD'),
        });
      }
      this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
    },
    clearFilter() {
      this.range = {
        start: '',
        end: '',
      };
      this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
      this.setClearFilter(this.keyName);
    },
    ...mapActions('CalendarInitEndDate', ['setActualFilter', 'setClearFilter']),
  },
};
</script>

<style lang="scss">
.content-calendar{
  align-items: center;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding-right: 0;
  *, :after, :before {
    border: 0 solid #e2e8f0;
    box-sizing: border-box;
  }
  &__inputs{
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    div{
      flex-grow: 1;
      position: relative;
      svg{
        --text-opacity: 1;
        color: #718096;
        color: rgba(113,128,150,var(--text-opacity));
        display: block;
        height: 100%;
        margin-left: .5rem;
        margin-right: .5rem;
        pointer-events: none;
        position: absolute;
        width: 1rem;
      }
      input{
        --bg-opacity: 1;
        -webkit-rtl-ordering: logical;
        appearance: textfield;
        background-color: #FAF8F2;
        border-radius: .25rem;
        border-width: 1px;
        cursor: text;
        display: inline-block;
        flex-grow: 1;
        font-family: Arial, sans-serif;
        font-size: 13.3333px;
        font-weight: 400;
        height: 34px;
        letter-spacing: normal;
        margin: 0;
        padding: 7px 18px 8px 32px;
        text-align: start;
        text-indent: 0px;
        text-rendering: auto;
        text-shadow: none;
        text-transform: none;
        width: 100%;
        word-spacing: normal;
      }
    }
  }
  &__span{
    flex-shrink: 0;
    margin: .5rem;
    svg{
      width: 1rem;
      path{
        stroke: currentColor;
      }
    }
  }
  &__buttons{
    --text-opacity: 1;
    color: #718096;
    color: rgba(113,128,150,var(--text-opacity));
    display: flex;
    stroke: currentColor;
    button{
      align-items:center;
      display: flex;
      height: 34px;
      justify-content: center;
      margin-left: 6px;
      min-width: 80px;
      overflow: hidden;
      position: relative;
      &.dx-btn-success:focus{
        outline:none!important;
      }
      &.dx-btn-cancel:focus{
        outline:none!important;
      }
    }
  }
}
</style>
