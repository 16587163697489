<template>
  <div
    class="content-devx"
  >
    <DxDataGrid
      id="sectorsDataGrid-datagrid"
      :data-source="sectorsAvailables"
      :remote-operations="true"
      :column-min-width="70"
      :column-auto-width="true"
      :allow-column-resizing="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="sectorsDataGrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="5" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumn
        data-field="name"
        :caption="$t('sector.grid.sectorName')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        :caption="$t('sector.grid.groundTexture')"
        :calculate-cell-value="getGroundTexture"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        :caption="$t('sector.grid.irrigationType')"
        :calculate-cell-value="getIrrigationType"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="area"
        :caption="$t('sector.grid.area')"
        :calculate-display-value="formattingArea"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        type="buttons"
        :fixed="gridSettings.fixed"
        :fixed-position="gridSettings.fixedPosition"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="viewSectorDetail"
        />
      </DxColumn>
      <template #titleRegisteredSectors>
          <span class="block-title">
            {{ $t('sector.grid.registeredSectors') }}
          </span>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import settings from '../../../js/commonSettings';

export default {
  name: 'SectorsDataGrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
  },
  props: {
    sectorsAvailables: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
  },
  methods: {
    async viewSectorDetail(e) {
      this.setCurrentSectorId(e.row.data.id);
      this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
    },
    getGroundTexture(rowData) {
      return this.$helpers.getProperty(rowData.properties, 'ground_texture');
    },
    getIrrigationType(rowData) {
      return this.$helpers.getProperty(rowData.properties, 'irrigation_type');
    },
    formattingArea(rowData) {
      return parseFloat(rowData.area).toFixed(2);
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        template: 'titleRegisteredSectors',
      }, {
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          text: this.$t('sector.grid.createSector'),
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.initCurrentSector();
            this.$f7.views.main.router.navigate('/registerSectorStep2Page/', { reloadCurrent: true });
          },
        },
      });
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Sectors');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Sectors.xlsx');
          });
      });
      e.cancel = true;
    },
    ...mapActions('Sector', ['setCurrentSectorId', 'initCurrentSector']),
  },
};
</script>
<style lang="scss" scoped>
@import './SectorsDataGrid.styles.scss';
</style>
