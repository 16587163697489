import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    id: '',
    plotAvailables: [],
    showPopUp: false,
    actualUser: {},
    showInsertedPopUP: false,
    actualLocation: {},
    treeLocation: {},
  },
  getters: {
    id: (state) => state.id,
    plotAvailables: (state) => state.plotAvailables,
    showPopUp: (state) => state.showPopUp,
    actualUser: (state) => state.actualUser,
    showInsertedPopUP: (state) => state.showInsertedPopUP,
    actualLocation: (state) => state.actualLocation,
    treeLocation: (state) => state.treeLocation,
  },
  mutations: {
    UPDATE_ID(state, payload) {
      state.id = payload;
    },
    UPDATE_PLOTAVAILABLES(state, payload) {
      state.plotAvailables = payload;
    },
    UPDATE_SHOW_POPUP(state, payload) {
      state.showPopUp = payload;
    },
    UPDATE_ACTUAL_USER(state, payload) {
      state.actualUser = payload;
    },
    UPDATE_REGISTER_DATA(state, value) {
      state.actualUser.register_data = value;
    },
    UPDATE_NUMBER_MOBILE(state, value) {
      state.actualUser.mobile = value;
    },
    UPDATE_PROVINCE(state, value) {
      state.actualUser.province = value;
    },
    UPDATE_CITY(state, value) {
      state.actualUser.city = value;
    },
    UPDATE_ATC_PROP(state, value) {
      state.actualUser.atcProp = value;
    },
    UPDATE_SHOW_INSERTED_POPUP(state, payload) {
      state.showInsertedPopUP = payload;
    },
    UPDATE_ACTUAL_LOCATION(state, payload) {
      state.actualLocation = payload;
    },
    UPDATE_TREE_LOCATION(state, payload) {
      state.treeLocation = payload;
    },
  },
  actions: {
    async fetchPlotAvailables({ commit, state }) {
      try {
        const xhr = await Api.getTreeLocation(state.actualUser.id);
        const location = JSON.parse(xhr.response);
        const locationAvailables = [];
        let name = '';
        let numberPlot = 0;
        let numberSector = 0;
        let bdId = '';
        let properties = '';
        let farm = '';
        for (let i = 0; i < location.length; i += 1) {
          name = location[i].name;
          numberPlot = location[i].children.length;
          bdId = location[i].bd_id;
          properties = location[i].properties;
          farm = location[i].farm;
          numberSector = 0;
          for (let j = 0; j < location[i].children.length; j += 1) {
            numberSector += location[i].children[j].children.length;
          }
          locationAvailables.push({
            name,
            numberPlot,
            numberSector,
            bdId,
            properties,
            farm,
          });
        }
        commit('UPDATE_PLOTAVAILABLES', locationAvailables);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchTreeLocation({ commit }, companyId) {
      try {
        const xhr = await Api.getTreeLocation(companyId);
        const location = JSON.parse(xhr.response);
        commit(
          'UPDATE_TREE_LOCATION',
          location,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setID(context, value) {
      context.commit('UPDATE_ID', value);
    },
    setShowPopUp(context, value) {
      context.commit('UPDATE_SHOW_POPUP', value);
    },
    setActualUser(context, value) {
      context.commit('UPDATE_ACTUAL_USER', value);
    },
    setRegisterData(context, value) {
      context.commit('UPDATE_REGISTER_DATA', value);
    },
    setNumberMobile(context, value) {
      context.commit('UPDATE_NUMBER_MOBILE', value);
    },
    setProvince(context, value) {
      context.commit('UPDATE_PROVINCE', value);
    },
    setCity(context, value) {
      context.commit('UPDATE_CITY', value);
    },
    setATCPROP(context, value) {
      context.commit('UPDATE_ATC_PROP', value);
    },
    setShowInsertedPopUP(context, value) {
      context.commit('UPDATE_SHOW_INSERTED_POPUP', value);
    },
    setActualLocation(context, value) {
      context.commit('UPDATE_ACTUAL_LOCATION', value);
    },
    async fetchActualLocation(context, value) {
      try {
        let id = '';
        if (typeof value.id === 'undefined' || value.id === '') { id = value.bdId; } else { id = value.id; }
        const xhr = await Api.getlocationById(context.state.actualUser.id, id);
        const location = JSON.parse(xhr.response);
        location.bdId = location.id;
        const propertiesFormatted = Helpers.formattingExplotationProperties(location.properties);
        location.properties = propertiesFormatted;
        context.commit('UPDATE_ACTUAL_LOCATION', location);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getCompanyById({ commit }, companyId) {
      try {
        const xhr = await Api.getCompanyById(companyId);
        const company = JSON.parse(xhr.response);
        commit(
          'UPDATE_ACTUAL_USER',
          company,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
};
