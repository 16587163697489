<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${$t('parcels')} > ${$t('parcel.register.title')}`" />
    <f7-block v-if="!loadedGPS || !loadedGIS">
      {{ $t("formAction_Alert_EnableGPS") }}
    </f7-block>
    <f7-block v-else>
      <GisGeoJsonCRUDSigpac
        ref="GisGeoJsonCRUDSigpac"
        custom-class="custom-mapbox-css"
        navigation-control-position="top-left"
        home-control-position="top-left"
        scale-control-position="bottom-left"
        geolocate-control-position="top-left"
        language="es"
        pop-up-class="popUpSigpac"
        pop-up-button-id="popupButton"
        home-control-icon="static/icons/gis_mapbox/home.svg"
        sigpac-control-icon="static/icons/gis_mapbox/sigpac.svg"
        measurement-control-icon="static/icons/gis_mapbox/measurement.svg"
        measurement-line-control-icon="static/icons/gis_mapbox/measurement-line.svg"
        measurement-area-control-icon="static/icons/gis_mapbox/measurement-area.svg"
        measurement-close-control-icon="static/icons/gis_mapbox/close.svg"
        remove-last-vertex-control-icon="static/icons/gis_mapbox/remove_last_vertex.svg"
        :pop-up-max-width="popupMaxWidth"
        :center="centerLngLat"
        :style="`min-height: ${gisHeight}`"
        :container="mainMap"
        :access-token="mapBoxAccessToken"
        :zoom="zoom"
        :home-control-title="$t('mapbox.controls.home')"
        :is-style-control-enabled="false"
        :geo-j-s-o-ns="geoJSONs"
        :geo-j-s-o-n-c-r-u-d="geoJSONsCRUD[0]"
        :pop-up-button-label="$t('mapbox.sigpac.popup.buttonLabel')"
        :generate-popup-content="generatePopupContent"
        :api-get-geo-server-collection-sigpac="api.getFetch"
        :geo-server-collection-sigpac-url="api.getGeoServerCollectionSigpacUrl()"
        :url-server-sigpac-w-f-s-geom="api.getGeoServerSigpacWFSGeom()"
        :sigpac-control-title="$t('mapbox.controls.sigpac')"
        :sigpac-fill-options="{ color: '#00ffff', opacity: 0, }"
        :sigpac-line-options="{ color: '#00ffff', width: 1, }"
        :draw-styles="drawCustomStyle"
        :measurement-control-title="$t('mapbox.measurement.controlTitle')"
        :measurement-line-control-title="$t('mapbox.measurement.lineControlTitle')"
        :measurement-area-control-title="$t('mapbox.measurement.areaControlTitle')"
        :measurement-close-control-title="$t('mapbox.measurement.closeControlTitle')"
        :generate-measurement-popup-line-content="generatePopupDistanceContent"
        :generate-measurement-popup-area-content="generatePopupAreaContent"
        @drawCreate="updateGeoJSON"
        @drawDelete="updateGeoJSON"
        @drawUpdate="updateGeoJSON"
        @updateArea="updateArea"
        @updateCentroide="updateCentroide"
        @addGeoJSONToCRUD="addGeoJSONToCRUD"
        @error_remove_last_vertex="errorRemoveLastVertex"
      />
    </f7-block>

    <GISSearchFormSigpac
      @searchSigpac="searchSigpac"
      @fieldDataChanged="fieldDataChanged"
    />

    <Parcel-info
      :land-size="areaToDisplay"
      :lang="lngToDisplay"
      :lat="latToDisplay"
    />

    <RegisterParcelStep1 :current-name="currentParcel.name" />

    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          v-if="loadedGIS && loadedGPS"
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerParcel"
        >
          {{ $t("save") }}
        </f7-button>
      </f7-col>
    </f7-row>
    <PopupRegisterParcelStep2 />
  </f7-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Api from '../../services/Api';
import navbar from '../../components/NavBar.vue';
import { GisGeoJsonCRUDSigpac } from '@hispatec/web-vue2';
import PopupRegisterParcelStep2 from '../../components/parcel/popupRegisterParcelStep2/index.vue';
import ParcelInfo from '../../components/parcel/parcelInfo/index.vue';
import RegisterParcelStep1 from '../../components/parcel/RegisterParcelStep1/index.vue';
import GISSearchFormSigpac from '../../components/Gis/GISSearchFormSigpac/GISSearchFormSigpac.vue';
import geoJsonUtils from '../../js/utils/geoJsonUtils';

export default {
  name: 'RegisterParcelStep2Page',
  components: {
    navbar,
    GisGeoJsonCRUDSigpac,
    PopupRegisterParcelStep2,
    ParcelInfo,
    RegisterParcelStep1,
    GISSearchFormSigpac,
  },
  data() {
    return {
      zoom: 17,
      mainMap: 'mainMapParcel',
      loadedGIS: false,
      loadedGPS: false,
      showMarker: false,
      editing: false,
      gisHeight: '60vh',
      gisHeightHalf: '30vh',
      geoJSONsCRUD: [],
      geoJSONs: [],
      api: Api,
      centerLngLat: null,
      drawCustomStyle: geoJsonUtils.getDrawCustomStyle(),
      popupMaxWidth: '600px',
    };
  },
  computed: {
    areaToDisplay() {
      if (this.editing) {
        return this.area;
      }

      if (typeof this.currentParcel.area !== 'undefined') {
        return this.currentParcel.area;
      }
      return this.area;
    },
    lngToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lng;
      }

      if (typeof this.currentParcel.area !== 'undefined') {
        return this.currentParcel.coordinates.longitude;
      }
      return this.viewCentroide.lng;
    },
    latToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lat;
      }

      if (typeof this.currentParcel.area !== 'undefined') {
        return this.currentParcel.coordinates.latitude;
      }
      return this.viewCentroide.lat;
    },
    ...mapState('RegisterLocation', [
      'name',
      'registerNumber',
      'province',
      'mobile',
      'farm',
      'ownerFarm',
      'registerExplotationId',
    ]),
    ...mapState('GisEditor', ['area', 'centroide', 'currentGeoFeature', 'featureInfoSigpac']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
    ...mapState('Parcel', ['currentParcel', 'currentParcelId']),
    ...mapState('Plantation', ['treeLocations']),
    ...mapGetters('GisEditor', ['viewCentroide']),
    ...mapGetters('authentication', ['mapBoxAccessToken']),
  },
  async beforeMount() {
    let exclusionId = null;
    if (typeof this.currentParcel.id === 'undefined') {
      this.editing = false;
      // Establecer geolocalización
      const navigatorOptions = {
        enableHighAccuracy: true,
        maximumAge: 0,
      };
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          this.centerLngLat = [position.coords.longitude, position.coords.latitude];
          this.loadedGPS = true;
          this.showMarker = true;
        },
        (error) => {
          this.$notifyDX(
            {
              message: `${error.message} - ${this.$t('formAction_Alert_EnableGPS')}`,
              width: 550,
            },
            'error',
            3000,
          );
        },
        navigatorOptions,
      );
    } else {
      exclusionId = this.currentParcel.id;
      this.setCentroide({ lat: this.currentParcel.coordinates.latitude, lng: this.currentParcel.coordinates.longitude });
      this.setArea(this.currentParcel.area);
      geoJsonUtils.treeLocationToGeoJson(
        [this.currentParcel],
        this.geoJSONsCRUD,
        true
      );
      this.setCurrentGeoFeatureRaw(this.geoJSONsCRUD[0]);
      this.editing = true;
      this.loadedGPS = true; // En edición no se necesita la geolocalización
      this.centerLngLat = null;
      this.showMarker = false;
    }
    try {
      await this.fetchAllLocations(exclusionId);
      this.loadedGIS = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  mounted() {
    this.calculateSigpacHeight();

    if (this.sigpacReferenceExists()) {
      delete this.featureInfoSigpac.features[0].properties.SIGPACReference;
    }
  },
  methods: {
    /**
     * @description Muestra un mensaje de error cuando se intenta eliminar el último vértice
     */
    errorRemoveLastVertex(codeError) {
      this.$notifyDX(
        {
          message: this.$t(`mapbox.controls.errorRemoveLastVertex.${codeError}`),
          width: 550,
        },
        'error',
        3000,
      );
    },
    /**
     * @description Obtiene todas las localizaciones de la explotación. Esto nos permitirá
     * que se visualicen en el mapa a forma de información adicional.
     * @param {Number} exclusionId - Id de la localización que se quiere excluir de la lista
     */
    async fetchAllLocations(exclusionId = null) {
      await this.fetchLocationsByCompany(this.actualUser.id);
      const propertiesColor = 
      {
        fill: {
          paint: {
            'fill-color': 'white',
            'fill-opacity': 0.25,
          },
        },
        line: {
          paint: {
            'line-color': 'white',
            'line-width': 3,
          },
        },
      };
      geoJsonUtils.treeLocationToGeoJson(this.treeLocations, this.geoJSONs, false,
        {}, propertiesColor, {});
      // Elimina del this.geoJSONS el elemento que tenga de atributo id el valor de exclusionId
      if (exclusionId !== null) {
        this.geoJSONs = this.geoJSONs.filter((geoJSON) =>!geoJSON.features[0].properties.id.includes(exclusionId));
      }
    },
    /**
     * @description Realiza un zoom hacía el centroide obtenido en base
     * a la información rellenada en el formulario de búsqueda de sigpac.
     * @param {Object} e - Objeto con los datos del formulario de búsqueda sigpac
     */
    async fieldDataChanged(e) {
      try {
        this.$f7.preloader.show();
        let zoom = 9;
        let filterProv = undefined;
        let filterMuni = undefined;
        let filterAgre = undefined;
        let filterZona = undefined;
        let filterPoli = undefined;
        const { provincia, municipio, agregado, zona, poligono } = e.formData;
        if (!provincia) return;
        // Comprobaciones sobre los valores de los campos para que se respete
        // la dependencia de los mismos: provincia -> municipio -> agregado -> zona
        if (provincia || provincia === 0) {
          filterProv = provincia;
        }
        if (municipio || municipio === 0) {
          if (filterProv === undefined) {
            this.$notifyDX(
              {
                message: this.$t('mapbox.sigpac.form.provinceRequired'),
                width: 550,
              },
              'error',
              3000,
            );
            return;
          }
          filterMuni = municipio;
          zoom = 11;
        }
        if (agregado || agregado === 0) {
          if (filterMuni === undefined) {
            this.$notifyDX(
              {
                message: this.$t('mapbox.sigpac.form.municipalityRequired'),
                width: 550,
              },
              'error',
              3000,
            );
            return;
          }
          filterAgre = agregado;
          zoom = 13;
        }
        if (zona || zona === 0) {
          if (filterAgre === undefined) {
            this.$notifyDX(
              {
                message: this.$t('mapbox.sigpac.form.aggregateRequired'),
                width: 550,
              },
              'error',
              3000,
            );
            return;
          }
          filterZona = zona;
          zoom = 15;
        }
        if (poligono || poligono === 0) {
          if (filterZona === undefined) {
            this.$notifyDX(
              {
                message: this.$t('mapbox.sigpac.form.zoneRequired'),
                width: 550,
              },
              'error',
              3000,
            );
            return;
          }
          filterPoli = poligono;
          zoom = 17;
        }

        const queryParamsData = {
          cql_filter_prov: filterProv,
          cql_filter_muni: filterMuni,
          cql_filter_agre: filterAgre,
          cql_filter_zona: filterZona,
          cql_filter_poli: filterPoli,
        }
        const queryParams = Api.getQueryParams(queryParamsData);
        const xhr = await Api.getFetch(`${Api.getGeoServer()}/getcentroidsigpac/?${queryParams}`);
        const centroid = await xhr.json();
        if (centroid.error) {
          this.$notifyDX(
            {
              message: this.$t('mapbox.sigpac.form.noResults'),
              width: 550,
            },
            'error',
            3000,
          );
          return;
        }
        this.$refs.GisGeoJsonCRUDSigpac.flyToCoords(centroid, zoom, { duration: 0 });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    /**
     * @description Busca en el servidor de SIGPAC las parcelas que coincidan con los parámetros de búsqueda
     * @param {Object} searchSigpacFormValues - Objeto con los parámetros de búsqueda
     */
    async searchSigpac(searchSigpacFormValues) {
      this.$f7.preloader.show();
      try {
        const geoJsonData = await this.$refs.GisGeoJsonCRUDSigpac.getGeoJsonSigpac(
          searchSigpacFormValues,
          Api.getGeoServerCollectionSigpac,
        );
        geoJsonData.forEach((featureArray) => {
          if (featureArray[0]) {
            const feature = featureArray[0];
            feature.properties.id = `${feature.properties.dn_oid}`;
            feature.properties.fill = {
              paint: {
                'fill-color': '#ff074a',
                'fill-opacity': 0.3,
              },
            };
            feature.properties.line = {
              paint: {
                'line-color': '#510217',
                'line-width': 2,
              },
            };
          }
        });

        const geoJSON = {
          type: 'FeatureCollection',
          features: geoJsonData.map((featureArray) => featureArray[0]),
        };
        if (geoJSON.features[0]) {
          this.centerLngLat = null;
          this.geoJSONs.unshift(geoJSON);
        } else {
          this.$notifyDX(
            {
              message: this.$t('mapbox.sigpac.form.noResults'),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t(this.$helpers.getError(error)));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    /**
     * @description Genera el contenido del popup de la parcela
     * @param {Object} properties - Objeto con las propiedades SIGPAC de la parcela
     * @returns {String} - Contenido html del popup
     */
    generatePopupContent(properties) {
      const {
        provincia,
        municipio,
        agregado,
        zona,
        poligono,
        parcela,
        recinto,
      } = properties;
      const dnOid = properties.dn_oid;
      return `
        <div style="max-height: ${this.gisHeightHalf}; min-width:${this.popupMaxWidth}">
          <div class="mapBoxTooltipTitle"><b>${this.$t('properties.dnOid')}:</b> ${dnOid}</div>
          <div class="mapBoxTooltip">
            <p>
              <span class="bubble" onclick="navigator.clipboard.writeText('${provincia}')">
                <b>${this.$t('properties.province')}:</b> ${provincia} <span class="copy-icon">📋</span>
              </span>
              <span class="bubble" onclick="navigator.clipboard.writeText('${municipio}')">
                <b>${this.$t('properties.municipality')}:</b> ${municipio} <span class="copy-icon">📋</span>
              </span>
              <span class="bubble" onclick="navigator.clipboard.writeText('${agregado}')">
                <b>${this.$t('properties.aggregate')}:</b> ${agregado} <span class="copy-icon">📋</span>
              </span>
              <span class="bubble" onclick="navigator.clipboard.writeText('${zona}')">
                <b>${this.$t('properties.zone')}:</b> ${zona} <span class="copy-icon">📋</span>
              </span>
            </p>
            <p>
              <span class="bubble" onclick="navigator.clipboard.writeText('${poligono}')">
                <b>${this.$t('properties.polygon')}:</b> ${poligono} <span class="copy-icon">📋</span>
              </span>
              <span class="bubble" onclick="navigator.clipboard.writeText('${parcela}')">
                <b>${this.$t('properties.parcel')}:</b> ${parcela} <span class="copy-icon">📋</span>
              </span>
              <span class="bubble" onclick="navigator.clipboard.writeText('${recinto}')">
                <b>${this.$t('properties.enclosure')}:</b> ${recinto} <span class="copy-icon">📋</span>
              </span>
            </p>
          </div>
        </div>
      `;
    },
    /**
     * @description Genera el contenido del popup de la medición de distancia
     * @param {Number} distance - Distancia medida
     * @param {String} unit - Unidad de medida
     * @returns {String} - Contenido html del popup
     */
    generatePopupDistanceContent(distance, unit) {
      return `
        <div style="max-height: ${this.gisHeightHalf}; min-width:${this.popupMaxWidth}">
          <div class="mapBoxTooltipTitle"><b>${this.$t('mapbox.measurement.distance')}:</b> ${distance} ${unit}</div>
        </div>
      `;
    },
    /**
     * @description Genera el contenido del popup de la medición de área
     * @param {Number} area - Área medida
     * @param {String} unit - Unidad de medida
     * @returns {String} - Contenido html del popup
     */
    generatePopupAreaContent(area, unit) {
      return `
        <div style="max-height: ${this.gisHeightHalf}; min-width:${this.popupMaxWidth}">
          <div class="mapBoxTooltipTitle"><b>${this.$t('mapbox.measurement.area')}:</b> ${area} ${unit}</div>
        </div>
      `;
    },
    /**
     * @description Actualiza el currentGeoFeature en base al geoJSON de la parcela
     * @param {Object} geoJSON - Objeto geoJSON de la parcela
     */
    async updateGeoJSON(geoJSON) {
      if (geoJSON.features.length === 0) {
        // Esto lo hago porque es lógica de negocio que se aplica en EFEMIS
        // cuando se han eliminado todas las parcelas
        const defaultValues = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        };
        geoJSON.features.push(defaultValues);
      }
      this.setCurrentGeoFeatureRaw(geoJSON);
    },
    /**
     * @description Actualiza el área de la parcela
     * @param {Number} newArea - Nueva área de la parcela
     */
    updateArea(newArea) {
      this.setArea(newArea);
    },
    /**
     * @description Actualiza el centroide de la parcela
     * @param {Array} newCentroide - Nueva coordenada (en formato longlat) del centroide de la parcela
     */
    updateCentroide(newCentroide) {
      let centroide = null;
      if (newCentroide) {
        centroide = {
          lat: newCentroide[1],
          lng: newCentroide[0],
        };
      }
      this.setCentroide(centroide);
    },
    /**
     * @description Añade un geoJSON al geoJsonCRUD cuando se selecciona una parcela en el mapa de SIGPAC
     * a través del popup de selección de parcelas de SIGPAC
     * @param {Object} geoJSON - Objeto geoJSON
     */
    addGeoJSONToCRUD(geoJSON) {
      this.$refs.GisGeoJsonCRUDSigpac.addGeoJSONToGeoJSONToCRUD(geoJSON);
    },
    calculateSigpacHeight(offset = 10) {
      // Pasamos 60vh a px ya que en tablet al abrir el teclado se ve mal.
      const px = (window.innerHeight / 100) * 60;
      if (px > 0) {
        this.gisHeight = `${px}px`;
        this.gisHeightHalf = `${px/2 - offset}px`;
      }
    },
    sigpacReferenceExists() {
      return !this.$helpers.isEmptyObject(this.featureInfoSigpac)
        ? this.featureInfoSigpac.features[0].properties.SIGPACReference : false;
    },
    async registerParcel() {
      this.$f7.preloader.show();
      try {
        if (this.area === 0 && this.editing === false) {
          this.$notifyDX(
            {
              message: this.$t('parcel.register.geofeatures.requiredRule'),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (typeof this.currentParcel.name === 'undefined' || this.currentParcel.name === '') {
          this.$notifyDX(
            {
              message: this.$t('parcel.register.name.requiredRule'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          const companyId = this.actualUser.id;
          let geoFeature = null;
          if (this.area === 0) {
            this.setCentroide({ lat: this.currentParcel.coordinates.latitude, lng: this.currentParcel.coordinates.longitude });
            this.setArea(this.currentParcel.area);
            geoFeature = this.currentParcel.geo_feature;
          }

          if (this.currentGeoFeature.features[0].geometry.coordinates.length !== 0) {
            geoFeature = this.currentGeoFeature;
          } else if (!this.$helpers.isEmptyObject(this.featureInfoSigpac)) {
            if (this.featureInfoSigpac.features[0].properties.SIGPACReference) {
              geoFeature = this.featureInfoSigpac;
            }
          } else {
            geoFeature = this.treeLocations[0].children[0].geo_feature;
          }

          if (geoFeature === null) {
            geoFeature = this.treeLocations[0].children[0].geo_feature;
          }

          const newParcel = {
            name: this.currentParcel.name,
            geo_feature: geoFeature,
            type: 'ORGANIZATIVE', // Para parcelas siempre tiene ese valor
            coordinates: {
              latitude: this.centroide.lat,
              longitude: this.centroide.lng,
            },
            code: `${this.currentParcel.name}${Date.now()}`,
            parent:
              typeof this.actualLocation.bdId === 'undefined'
                ? this.registerExplotationId
                : this.actualLocation.bdId,
            properties: [
              {
                key: 'area',
                value: this.area,
              },
            ],
          };

          if (typeof this.currentParcel.id === 'undefined') {
            await this.createParcel({ companyId, newParcel });
            this.$notifyDX(
              {
                message: this.$t('parcel.register.completedSuccessfullyParcelRegister'),
                width: 550,
              },
              'success',
              3000,
            );

            try {
              this.initCurrentSector();
              await this.fetchTreeLocationById({ companyId: this.actualUser.id, locationId: this.currentParcelId });
            } catch (error) {
              this.$notifyDX(
                {
                  message: this.$t(`${error}`),
                  width: 550,
                },
                'error',
                3000,
              );
            } finally {
              this.$f7.views.main.router.navigate('/registerSectorStep2Page/', { reloadCurrent: true });
            }
          } else {
            await this.updateParcel({ companyId, parcelUpdated: newParcel, parcelId: this.currentParcel.id });
            this.$notifyDX(
              {
                message: this.$t('parcel.register.completedSuccessfullyParcelUpdated'),
                width: 550,
              },
              'success',
              3000,
            );
            this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
          }
        }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    goToBack() {
      const title = '';
      const message = this.editing ? this.$t('confirmLeavePage.parcelEdition') : this.$t('confirmLeavePage.generalExploitationCreation');

      this.$f7.dialog.confirm(
        message,
        title,
        () => {
          this.resetCurrentGeoFeature();
          this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', {
            reloadCurrent: true,
          });
        },
      );
    },
    ...mapActions('Parcel', ['createParcel', 'updateParcel', 'setShowPopup']),
    ...mapActions('GisEditor', ['setCentroide', 'resetCentroide', 'setArea', 'resetCurrentGeoFeature', 'setCurrentGeoFeatureRaw']),
    ...mapActions('Sector', ['initCurrentSector']),
    ...mapActions('Plantation', ['fetchTreeLocationById', 'fetchLocationsByCompany']),
  },
};
</script>

<style>
.dx-btn-cancel.margin-right {
  width: 15%;
  margin-left: 2%;
}
.dx-btn-success {
  width: 30%;
  margin-left: 68%;
}
.mapBoxTooltipTitle {
  padding-top: 10px;
  font-size: 1.5em;
}
.mapBoxTooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 5px;
}

.mapBoxTooltip .copy-icon {
  margin-left: 5px;
  font-size: 14px;
}

.mapBoxTooltip .copy-icon:hover {
  color: #007bff;
}

.mapBoxTooltip p {
  margin-bottom: 25px;
}

.bubble {
  background-color: #f0f0f0; /* Color de fondo de la burbuja */
  padding: 10px; /* Espacio dentro de la burbuja */
  border-radius: 15px; /* Bordes redondeados */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra */
  margin: 5px 5px; /* Margen alrededor de la burbuja */
}

.popUpSigpac #popupButton {
  background: var(--ha-butons-color-blue);
  height: auto;
  line-height: 3;
  box-shadow: none;
  border-radius: 4px;
  color: white;
  font-size: 1.25em;
}
</style>
