<template>
  <f7-page name="treatment-create-order">
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('treatment.title')}`" />
    <treatment-create-order-component />
    <PopupConfirmWizardExit />
  </f7-page>
</template>
<script>
import { mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import treatmentCreateOrderComponent from '@/components/wizardTreatment/treatmentCreateOrder/index.vue';
import PopupConfirmWizardExit from '@/components/wizardTreatment/popupConfirmWizardExit/index.vue';

export default {
  name: 'TreatmentCreateOrder',
  components: {
    navbar,
    treatmentCreateOrderComponent,
    PopupConfirmWizardExit,
  },
  props: [],
  data() {
    return {

    };
  },

  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};

</script>
<style src="./treatment-create-order.scss" scoped lang="scss"></style>
