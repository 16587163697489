export default {
  namespaced: true,
  state: {
    locationsTree: [],
    selectedFarmList: [],
    areaList: [],
    currentLevel: 0,
    currentLocation: null,
    currentLocationName: null,
    firstDateSelected: '',
    secondDateSelected: '',
    rangeOfDays: 14,
  },

  getters: {
    locationsTree: (state) => state.locationsTree,
    selectedFarmList: (state) => state.selectedFarmList,
    areaList: (state) => state.areaList,
    currentLevel: (state) => state.currentLevel,
    currentLocation: (state) => state.currentLocation,
    currentLocationName: (state) => state.currentLocationName,
    firstDateSelected: (state) => state.firstDateSelected,
    secondDateSelected: (state) => state.secondDateSelected,
    formattedTree(state) {
      const locationsTreeFormatted = [];
      const setLocationTreeFormat = (idParent, item) => {
        if (item.level > 3) {
          locationsTreeFormatted.push({
            location: `${item.name} -${item.properties.variety_code}- ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
            parent: idParent,
          });
        } else {
          locationsTreeFormatted.push({
            location: `${item.name} - ${item.area.toFixed(2)} ha`,
            bd_id: item.bd_id,
            ID: item.ID,
            HEAD_ID: item.HEAD_ID,
            children: item.children,
            level: item.level,
            name: item.name,
            area: item.area,
            parent: idParent,
          });
        }
      };
      const recursiveAddChildren = (idParent, children) => {
        for (let i = 0; i < children.length; i += 1) {
          setLocationTreeFormat(idParent, children[i]);
          recursiveAddChildren(children[i].bd_id, children[i].children);
        }
      };

      for (const item of state.locationsTree) {
        setLocationTreeFormat(undefined, item);
        recursiveAddChildren(item.bd_id, item.children);
      }
      return locationsTreeFormatted;
    },
  },
  actions: {
    setLocationsTree(context, value) {
      context.commit('setLocationsTree', value);
    },
    setSelectedFarmList(context, value) {
      context.commit('setSelectedFarmList', value);
    },
    setAreaList(context, value) {
      context.commit('setAreaList', value);
    },
    setCurrentLevel(context, value) {
      context.commit('setCurrentLevel', value);
    },
    setCurrentLocation(context, value) {
      context.commit('setCurrentLocation', value);
    },
    setCurrentLocationName(context, value) {
      context.commit('setCurrentLocationName', value);
    },
    setFirstDateSelected(context, value) {
      context.commit('setFirstDateSelected', value);
    },
    setSecondDateSelected(context, value) {
      context.commit('setSecondDateSelected', value);
    },
    setRangeOfDays({ commit }, value) {
      commit('setRangeOfDays', value);
    },
  },
  mutations: {
    setLocationsTree(state, value) {
      state.locationsTree = value;
    },
    setSelectedFarmList(state, value) {
      state.selectedFarmList = value;
    },
    setAreaList(state, value) {
      state.areaList = value;
    },
    setCurrentLevel(state, value) {
      state.currentLevel = value;
    },
    setCurrentLocation(state, value) {
      state.currentLocation = value;
    },
    setCurrentLocationName(state, value) {
      state.currentLocationName = value;
    },
    setFirstDateSelected(state, value) {
      state.firstDateSelected = value;
    },
    setSecondDateSelected(state, value) {
      state.secondDateSelected = value;
    },
    setRangeOfDays(state, value) {
      state.rangeOfDays = value;
    },
  },
};
