<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${currentParcel.name} > ${$t('sectors')} > ${$t('sector.register.title')}`" />
      <GisGeoJsonCRUD
        v-if="loadedGIS"
        ref="GisGeoJsonCRUD"
        custom-class="custom-mapbox-css"
        navigation-control-position="top-left"
        home-control-position="top-left"
        scale-control-position="bottom-left"
        geolocate-control-position="top-left"
        language="es"
        home-control-icon="static/icons/gis_mapbox/home.svg"
        sigpac-control-icon="static/icons/gis_mapbox/sigpac.svg"
        measurement-control-icon="static/icons/gis_mapbox/measurement.svg"
        measurement-line-control-icon="static/icons/gis_mapbox/measurement-line.svg"
        measurement-area-control-icon="static/icons/gis_mapbox/measurement-area.svg"
        measurement-close-control-icon="static/icons/gis_mapbox/close.svg"
        remove-last-vertex-control-icon="static/icons/gis_mapbox/remove_last_vertex.svg"
        :is-draw-control-enabled="drawPolygon"
        :is-remove-last-vertex-control-enabled="drawPolygon"
        :style="`min-height: ${GISEditorHeight}`"
        :container="mainMap"
        :access-token="mapBoxAccessToken"
        :zoom="zoom"
        :home-control-title="$t('mapbox.controls.home')"
        :is-style-control-enabled="false"
        :geo-j-s-o-ns="geoJSONs"
        :geo-j-s-o-n-shape-boundary-geo-json="geoJSONShapeBoundaryGeoJson"
        :geo-j-s-o-n-c-r-u-d="geoJSONCRUD"
        :sigpac-fill-options="{ color: '#00ffff', opacity: 0, }"
        :sigpac-line-options="{ color: '#00ffff', width: 1, }"
        :draw-styles="drawCustomStyle"
        :measurement-control-title="$t('mapbox.measurement.controlTitle')"
        :measurement-line-control-title="$t('mapbox.measurement.lineControlTitle')"
        :measurement-area-control-title="$t('mapbox.measurement.areaControlTitle')"
        :measurement-close-control-title="$t('mapbox.measurement.closeControlTitle')"
        :generate-measurement-popup-line-content="generatePopupDistanceContent"
        :generate-measurement-popup-area-content="generatePopupAreaContent"
        @drawCreate="updateGeoJSON"
        @drawDelete="updateGeoJSON"
        @drawUpdate="updateGeoJSON"
        @updateArea="updateArea"
        @updateCentroide="updateCentroide"
        @error_remove_last_vertex="errorRemoveLastVertex"
      />
    <Parcel-info
      :land-size="areaToDisplay"
      :lang="lngToDisplay"
      :lat="latToDisplay"
      :editLandSize="canEditLandSize"
      @update-land-size="updateLandSize"
    />

    <RegisterSectorStep1
      :ground-textures-availables="groundTexturesAvailables"
      :irrigation-types-availables="irrigationTypesAvailables"
      :current-sector="currentSector"
      :editing="editing"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="registerSector"
        >
          {{ $t("save") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import navbar from '../../components/NavBar.vue';
import { GisGeoJsonCRUD } from '@hispatec/web-vue2';
import ParcelInfo from '../../components/parcel/parcelInfo/index.vue';
import Formatter from './formatter';
import RegisterSectorStep1 from '../../components/sector/registerSectorStep1/index.vue';
import geoJsonUtils from '../../js/utils/geoJsonUtils';

export default {
  name: 'RegisterSectorStep2Page',
  components: {
    navbar,
    GisGeoJsonCRUD,
    ParcelInfo,
    RegisterSectorStep1,
  },
  data() {
    return {
      zoom: 20,
      mainMap: 'registerSectorStep2',
      editing: false,
      groundTexturesAvailables: [],
      irrigationTypesAvailables: [],
      actualArea: 0,
      GISEditorHeight: '50vh',
      geoJSONs: [],
      loadedGIS: false,
      isDrawPolygon: false,
      geoJSONShapeBoundaryGeoJson: [],
      drawCustomStyle: geoJsonUtils.getDrawCustomStyle(),
    };
  },

  computed: {
    geoJSONCRUD() {
      if (this.editing) {
        return this.currentSector.geoFeature;
      }
      const geoJSONCRUD = {
        type: 'FeatureCollection',
        features: [],
      };
      return geoJSONCRUD;
    },
    areaToDisplay() {
      if (this.editing) {
        return this.currentSector.area;
      }

      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.area;
      }
      return this.area;
    },
    lngToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lng;
      }

      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.currentCentroid.lng
      } 
      return this.viewCentroide.lng;
    },
    latToDisplay() {
      if (this.editing) {
        return this.viewCentroide.lat;
      }

      if (typeof this.currentSector.area !== 'undefined') {
        return this.currentSector.currentCentroid.lat;
      }
      return this.viewCentroide.lat;
    },
    drawPolygon() {
     return this.currentSector?.useParcelGeometry === 'Si' ? false : true;
    },
    canEditLandSize() {
      return this.mainMap === 'registerSectorStep2';
    },
    ...mapState('RegisterLocation', [
      'name',
      'registerNumber',
      'province',
      'mobile',
      'farm',
      'ownerFarm',
    ]),
    ...mapState('GisEditor', ['area', 'centroide', 'currentGeoFeature']),
    ...mapState('Sector', ['currentSector', 'currentKeyValues', 'currentSectorId']),
    ...mapState('Parcel', ['currentParcel', 'currentParcelId']),
    ...mapState('Explotaciones', ['actualUser', 'actualLocation']),
    ...mapState('Plantation', ['treeLocations']),
    ...mapGetters('GisEditor', ['viewCentroide']),
    ...mapState('Campania', ['actual_Plot', 'actualCampaign']),
    ...mapGetters('authentication', ['mapBoxAccessToken']),
  },
  async beforeMount() {
    if (typeof this.currentSector.id === 'undefined') {
      this.editing = false;
      this.currentSector.groundTexture = 'FRANCO ARCILLOSO';
      this.resetCentroide();
    } else {
      this.editing = true;
      this.isDrawPolygon = true;
      this.setCentroide({ lat: this.currentSector.currentCentroid.lat, lng: this.currentSector.currentCentroid.lng });
      this.setArea(this.currentSector.area);
      this.actualArea = this.currentSector.area;
      this.setCurrentGeoFeatureRaw(this.geoJSONCRUD);
    }

    try {
      await this.fetchKeyValuesByFamily('GROUND_TEXTURE');
      this.groundTexturesAvailables = this.currentKeyValues;
      await this.fetchKeyValuesByFamily('IRRIGATION_TYPE');
      this.irrigationTypesAvailables = this.currentKeyValues;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
    }
    geoJsonUtils.treeLocationToGeoJson(this.treeLocations, this.geoJSONs, false,
      {
        fill: {
          paint: {
            'fill-color': '#f1f5cb',
            'fill-opacity': 0.5,
          },
        },
        line: {
          paint: {
            'line-color': '#f1f5cb',
            'line-width': 2,
          },
        },
      },
      {
        fill: {
          paint: {
            'fill-color': '#eee',
            'fill-opacity': 0.5,
          },
        },
        line: {
          paint: {
            'line-color': '#eee',
            'line-width': 2,
          },
        },
      },
      {
        fill: {
          paint: {
            'fill-color': '#fafa78',
            'fill-opacity': 0.5,
          },
        },
        line: {
          paint: {
            'line-color': '#fafa78',
            'line-width': 2,
          },
        },
      });
    this.updateGeoJSONShapeBoundaryGeoJson();
    this.loadedGIS = true;
  },
  methods: {
    /**
     * @description Muestra un mensaje de error cuando se intenta eliminar el último vértice
     */
     errorRemoveLastVertex(codeError) {
      this.$notifyDX(
        {
          message: this.$t(`mapbox.controls.errorRemoveLastVertex.${codeError}`),
          width: 550,
        },
        'error',
        3000,
      );
    },
    /**
     * @description Genera el contenido del popup de la medición de distancia
     * @param {Number} distance - Distancia medida
     * @param {String} unit - Unidad de medida
     * @returns {String} - Contenido html del popup
     */
    generatePopupDistanceContent(distance, unit) {
      return `
        <div style="max-height: ${this.gisHeightHalf}; min-width:${this.popupMaxWidth}">
          <div class="mapBoxTooltipTitle"><b>${this.$t('mapbox.measurement.distance')}:</b> ${distance} ${unit}</div>
        </div>
      `;
    },
    /**
     * @description Genera el contenido del popup de la medición de área
     * @param {Number} area - Área medida
     * @param {String} unit - Unidad de medida
     * @returns {String} - Contenido html del popup
     */
    generatePopupAreaContent(area, unit) {
      return `
        <div style="max-height: ${this.gisHeightHalf}; min-width:${this.popupMaxWidth}">
          <div class="mapBoxTooltipTitle"><b>${this.$t('mapbox.measurement.area')}:</b> ${area} ${unit}</div>
        </div>
      `;
    },
    /**
     * @description Actualiza el geoJSONShapeBoundaryGeoJson con las coordenadas de las parcelas.
     * Este geoJSON se utiliza para dibujar el límite de las parcelas en el mapa y evitar que los sectores
     * se dibujen fuera de las parcelas.
     */
    updateGeoJSONShapeBoundaryGeoJson() {
      this.geoJSONShapeBoundaryGeoJson = this.geoJSONs[0];
      if (this.treeLocations.length > 0 && this.treeLocations[0].children.length > 0) {
        for (const location of this.treeLocations[0].children[0].children) {
          if ((this.editing && location.id !== this.currentSector.bd_id) &&
            location.geo_feature.features.length > 0 && location.geo_feature.features[0].geometry.coordinates.length > 0) {
            const coordinates = location.geo_feature.features[0].geometry.coordinates[0];
            this.geoJSONShapeBoundaryGeoJson.features[0].geometry.coordinates.push(coordinates);
          }
        }
      }
    },
    /**
     * @description Actualiza el geoJSON con las coordenadas de las parcelas.
     * @param {Object} geoJSON - GeoJSON con las coordenadas de las parcelas
     */
    updateGeoJSON(geoJSON) {
      if (geoJSON.features.length === 0) {
        // Esto lo hago porque es lógica de negocio que se aplica
        // cuando se han eliminado todas las parcelas
        const defaultValues = {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        };
        geoJSON.features.push(defaultValues);
        this.drawFeature = false;
      }
      this.setCurrentGeoFeatureRaw(geoJSON);
    },
    /**
     * @description Actualiza el área del sector
     * @param {Number} newArea - Nueva área del sector
     */
    updateArea(newArea) {
      this.setArea(newArea);
      this.currentSector.area = newArea;
      this.actualArea = newArea;
    },
    /**
     * @description Actualiza el centroide del sector
     * @param {Array} newCentroide - Nueva coordenada (en formato longlat) del centroide del sector
     */
    updateCentroide(newCentroide) {
      let centroide = null;
       // Verifica si newCentroide es [NaN, NaN], si es así, asigna [0, 0] en su lugar
      if (Array.isArray(newCentroide) && newCentroide.length === 2 && isNaN(newCentroide[0]) && isNaN(newCentroide[1])) {
        centroide = { lat: 0, lng: 0 };
      } else if (newCentroide) {
        centroide = {
          lat: newCentroide[1],
          lng: newCentroide[0],
        };
      }
      this.setCentroide(centroide);
      // actualizamos las coordenadas del sector
      this.setCurrentCentroid(centroide)
    },
    updateLandSize(newValue) {
      // Actualiza el valor
      this.actualArea = newValue;
    },
    async registerSector() {
      if (this.validationForm()) {
        // Esto es para el cuadro de diálogo que crea un sector que ocupa toda la parcela.
        if (this.currentParcel.children?.length === 0 && !this.currentSector.id) {
          this.setShowAutoSector(true);
        }
      } else {
        return;
      }

      if (!this.editing && this.currentSector.useParcelGeometry === 'Si') {
        try {
          this.$f7.preloader.show();

          await this.createAutoSector();
        } catch (e) {
          this.$notifyDX(
            {
              message: `${e}`,
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          this.$f7.preloader.hide();
          this.setShowPopup(true);
        }

        return;
      }

      if (
        typeof this.actualUser.id !== 'undefined'
        && this.actualUser.id !== ''
        && typeof this.currentParcelId !== 'undefined'
        && this.currentParcelId !== ''
      ) {
        if (
          (this.currentGeoFeature.length !== null
          && this.currentGeoFeature.length !== {}
          && this.centroide !== null) || this.editing
        ) {
          this.$f7.preloader.show();
          try {
            let geoFeature = null;

            if (this.area === 0) {
              this.setCentroide({ lat: this.currentSector.currentCentroid.latitude, lng: this.currentSector.currentCentroid.longitude });
              this.setArea(this.currentSector.area);
              geoFeature = this.currentSector.geoFeature;
            } else if (this.currentGeoFeature.features[0].geometry.coordinates.length !== 0) {
              geoFeature = this.currentGeoFeature;
            } else {
              geoFeature = this.currentSector.geoFeature;
            }
            const companyId = this.actualUser.id;
            const newSector = {
              name: this.currentSector.sectorName,
              geo_feature: geoFeature,
              type: 'AGRARIAN', // Para sectores siempre tiene ese valor
              coordinates: {
                latitude: this.centroide.lat,
                longitude: this.centroide.lng,
              },
              code: `${this.currentSector.sectorName}${Date.now()}`,
              parent: this.currentParcel.id,
              properties: Formatter.formatSectorProperties(this.currentSector, this.actualArea),
            };

            if (typeof this.currentSector.id === 'undefined') {
              await this.createSector({ companyId, newSector });
              this.$notifyDX(
                {
                  message: this.$t('sector.register.completedSuccessfullySectorRegister'),
                  width: 550,
                },
                'success',
                3000,
              );
              this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });
            } else {
              await this.updateSector({
                companyId,
                sectorUpdated: newSector,
                sectorId: this.currentSector.id,
              });
              this.updateCurrentSectorKey({ currentSectorKey: 'area', newValue: this.actualArea });
              // Llama a la acción para actualizar 'area' con el nuevo valor
              this.setArea(this.actualArea);
              this.$notifyDX(
                {
                  message: this.$t('sector.register.completedSuccessfullySectorUpdate'),
                  width: 550,
                },
                'success',
                3000,
              );
            }

            // redirect to register plantation form if the user is creating a new sector
            if (!this.editing) {
              this.setActualCampaign({ company: this.actualCampaign.company });
              this.fillDataParcelSectorAndSetParcelSector();

              this.$notifyDX(
                {
                  message: this.$t('sector.register.completedSuccessfullySectorRegister'),
                  width: 550,
                },
                'success',
                3000,
              );

              this.$f7.views.main.router.navigate('/RegisterPlantationStep1/', { reloadCurrent: true });
            } else {
              this.$f7.views.main.router.navigate('/viewParcel/', {
                reloadCurrent: true,
              });
            }
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          } finally {
            this.$f7.preloader.hide();
            this.setShowPopup(true);
          }
        } else {
          this.$notifyDX(
            {
              message: this.$t('sector.register.gisValidation'),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } else {
        this.$notifyDX(
          {
            message: this.$t('sector.register.registerValidation'),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    async fillDataParcelSectorAndSetParcelSector() {
      this.setSectorSelected(this.currentSectorId);
      this.setParcelSelected(this.currentParcelId);
      if (typeof this.actualLocation.id === 'undefined' || this.actualLocation.id === null) {
        this.setActualPlot(this.actualLocation.bdId);
      } else {
        this.setActualPlot(this.actualLocation.id);
      }
      this.setSectorArea(this.currentSector.area);
      this.setIsEdit(false);
      try {
        let data = {
          companyId: this.actualUser.id,
          explotation: this.actual_Plot,
        };
        await this.getParcelByExplotation(data);
        data = {
          companyId: this.actualUser.id,
          parcel: this.currentParcelId,
        };
        await this.getSectorByParcel(data);
        await this.getListExplotation();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    goToBack() {
      const title = '';
      const message = this.editing ? this.$t('confirmLeavePage.sectorEdition') : this.$t('confirmLeavePage.generalExploitationCreation');

      this.$f7.dialog.confirm(
        message,
        title,
        () => {
          this.$f7.views.main.router.navigate('/systemParcelViewClientsPage/', {
            reloadCurrent: true,
          });
        },
      );
    },
    async createAutoSector() {
      const companyId = this.actualUser.id;

      const newSector = {
        name: this.currentSector.sectorName,
        geo_feature: this.currentParcel.geo_feature,
        type: 'AGRARIAN', // Para sectores siempre tiene ese valor
        coordinates: this.currentParcel.coordinates,
        code: `${this.currentSector.sectorName}${Date.now()}`,
        parent: this.currentParcel.id,
        properties: Formatter.formatSectorProperties(this.currentSector, this.actualArea),
      };

      try {
        await this.createSector({ companyId, newSector });
          this.$notifyDX(
            {
              message: this.$t('sector.register.completedSuccessfullySectorRegister'),
              width: 550,
            },
            'success',
            3000,
          );
        this.$f7.views.main.router.navigate('/viewSector/', { reloadCurrent: true });  
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    validationForm() {
      let validationMainForm = false;
      const validationIrrigationForm = false;
      if (this.currentSector.sectorName === '') {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_name'),
            width: 550,
          },
          'error',
          3000,
        );
      } else if (this.area === 0) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.requiredGeometry'),
            width: 550,
          },
          'error',
          3000,
        );
      } else if (this.currentSector.irrigationType === '' || this.currentSector.irrigationType === null) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_irrigationType'),
            width: 550,
          },
          'error',
          3000,
        );
      } else {
        validationMainForm = true;
      }

      // if (this.currentSector.irrigationType !== ''
      // && this.currentSector.irrigationType !== null) {
      //   switch (this.currentSector.irrigationType) {
      //     case 'GOTEO':
      //       validationIrrigationForm = this.validationIrrigationTypeGoteo();
      //       break;
      //     case 'ASPERSIÓN':
      //       validationIrrigationForm = this.validationIrrigationTypeAspersion();
      //       break;
      //     case 'INUNDACIÓN':
      //       validationIrrigationForm = this.validationIrrigationTypeInundacion();
      //       break;
      //     default:
      //       validationIrrigationForm = true;
      //       break;
      //   }
      // }
      return validationMainForm;
    },
    validationIrrigationTypeGoteo() {
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_flowPerPlant'),
            width: 550,
          },
          'error',
          3000,
        );
        return false;
      }
      if (this.currentSector.dripperPerPlant === 0 || this.currentSector.dripperPerPlant === null) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_dripperPerPlant'),
            width: 550,
          },
          'error',
          3000,
        );
        return false;
      }
      return true;
    },
    validationIrrigationTypeAspersion() {
      if (this.currentSector.sprinklersNumber === 0 || this.currentSector.sprinklersNumber === null) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_sprinklersNumber'),
            width: 550,
          },
          'error',
          3000,
        );
        return false;
      }
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_flowPerPlant'),
            width: 550,
          },
          'error',
          3000,
        );
        return false;
      }
      // if (this.currentSector.transformedArea === 0 || this.currentSector.transformedArea === null) {
      //   this.$f7.dialog.alert(this.$t('sector.register.required_transformedArea'));
      //   return false;
      // }
      return true;
    },
    validationIrrigationTypeInundacion() {
      if (this.currentSector.flow === 0 || this.currentSector.flow === null) {
        this.$notifyDX(
          {
            message: this.$t('sector.register.required_flowPerPlant'),
            width: 550,
          },
          'error',
          3000,
        );
        return false;
      }
      return true;
    },
    ...mapActions('Sector', ['createSector', 'updateSector', 'setShowPopup', 'fetchKeyValuesByFamily', 'setShowAutoSector', 'updateCurrentSectorKey', 'setCurrentCentroid']),
    ...mapActions('Campania', ['getPlantationByCompany', 'setActualPlot', 'getListExplotation', 'setShowPopUp', 'setIsView', 'setActualCampaign', 'setCampaignId']),
    ...mapActions('RegisterPlantation', ['setSectorSelected', 'setParcelSelected', 'setSectorArea', 'getSectorByParcel', 'getParcelByExplotation', 'setIsEdit', 'setIsPlantationCreated']),
    ...mapActions('GisEditor', ['resetCentroide', 'setArea', 'setCentroide', 'setCurrentGeoFeatureRaw']),
  },
  watch: {
    /**
     * @description Actualiza el estado de la variable isDrawPolygon y recarga el componente GIS
     */
    drawPolygon() {
      if (this.drawPolygon !== this.isDrawPolygon) {
        this.isDrawPolygon = this.drawPolygon;
        // Esto se tiene que hacer así porque, en el componente gis, la carga de
        // iControls (como el botón de dibujo) no está preparada para cambiar
        // su visualización en tiempo real. Por lo que se debe
        // recargar el componente para que se actualice correctamente.
        this.loadedGIS = false;
        this.$nextTick(() => {
          this.loadedGIS = true;
        });
      }
    },
  },
};
</script>

<style scoped>
.dx-btn-cancel.margin-right {
  width: 15%;
  margin-left: 2%;
}
.dx-btn-success {
  width: 30%;
  margin-left: 68%;
}
</style>
