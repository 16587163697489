<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('copyAnalytics')}`" />
    <AnalyticsTable
      v-if="loaded"
      :data-source="customStore"
    />
  </f7-page>
</template>

<script>
import navbar from '@/components/NavBar';
import { mapActions } from 'vuex';
import AnalyticsTable from '@/components/analisys/analytics/index';

export default {
  name: 'AnalysisAnalyticsPage',
  components: {
    navbar,
    AnalyticsTable,
  },
  data() {
    return {
      customStore: null,
      loaded: false,
    };
  },
  async mounted() {
    try {
      this.$f7.preloader.show();

      this.loaded = false;
      this.customStore = await this.getStore({ type: 'analytics' });

      this.loaded = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Análisis > Analíticas');
    },
    ...mapActions('Plans', ['getAnalysisSamples']),
    ...mapActions('Pagination', ['getStore']),
  },
};
</script>
