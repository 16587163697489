<template>
    <DxPopup
      title=""
      :visible="true"
      :close-on-outside-click="true"
      height="auto"
      width="auto"
      :on-hidden="close"
      :drag-enabled="false"
    >
      <div class="scroll-container">
        <h3 class="title-resume">{{ $t("popupSelectObservationsTaskReport.observations") }}:</h3>
        <div
          v-if="observations.length === 0"
          class="no-data"
        >
          <div v-if="loading">
            <Spinner />
          </div>
          <div v-else>
            {{ $t('noObservationsToSelect') }}
          </div>
        </div>
        <div
          v-if="!loading && observations.length > 0"
        >
          <div class="observations-container">
            <div
              v-for="data in observations"
              :key="data.id"
              class="observations-container__item"
              @click="selectObservations(data)"
            >
              <div v-if="data.selected" class="observations-container__item--selected">
                <svg
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 7.5C0 5.51088 0.790176 3.60322 2.1967 2.1967C3.60322 0.790176 5.51088 0 7.5 0C9.48912 0 11.3968 0.790176 12.8033 2.1967C14.2098 3.60322 15 5.51088 15 7.5C15 9.48912 14.2098 11.3968 12.8033 12.8033C11.3968 14.2098 9.48912 15 7.5 15C5.51088 15 3.60322 14.2098 2.1967 12.8033C0.790176 11.3968 0 9.48912 0 7.5H0ZM7.072 10.71L11.39 5.312L10.61 4.688L6.928 9.289L4.32 7.116L3.68 7.884L7.072 10.711V10.71Z"
                      fill="white"
                    />
                  </svg>
              </div>
              <ImageText
                :imageSrc="data.image"
                :date="data.date"
                :category="data.category"
                :subcategory="data.subcategory"
              />
            </div>
          </div>
        </div>
        <h3 class="title-resume">{{ $t('popupSelectObservationsTaskReport.tasks') }}:</h3>
        <div
          class="separator"
        />
        <div v-if="localTasks.length > 0" class="task-table">
            <!-- Cabecera -->
            <div class="task-table__header">
              <div></div>
              <div class="task-table__header-item">{{ $t('popupSelectObservationsTaskReport.tasks') }}</div>
              <div class="task-table__header-item">{{ $t("popupSelectObservationsTaskReport.initDate") }}</div>
              <div class="task-table__header-item">{{ $t("popupSelectObservationsTaskReport.endDate") }}</div>
            </div>
            <!-- Contenido de la tabla -->
            <div v-for="task in allTasks" :key="task.id" class="task-table__row">
              <div class="task-table__checkbox">
                  <DxCheckBox  v-model="task.selected" :id="'checkbox_' + task.id" @change="toggleCheckboxTask(task)" />
              </div>
              <!-- Nombre de la tarea -->
              <div class="task-table__item">{{ task.name }}</div>
              <!-- Fecha de inicio -->
              <div class="task-table__item">{{ task.init_date }}</div>
              <!-- Fecha de fin -->
              <div class="task-table__item">{{ task.end_date }}</div>
            </div>
          </div>
          <div class="no-data" v-else>
            {{ $t('noTasksToSelect') }}
          </div>
          <f7-row class="container-footer">
            <f7-col>
              <f7-button
                class="dx-btn-cancel margin-left-block btn-width-150 outlined-gray margin-top-block margin-bottom-block"
                raised
                type="default"
                styling-mode="contained"
                @click="close()"
              >
              {{ $t("popupSelectObservationsTaskReport.cancel") }}
              </f7-button>
            </f7-col>
            <f7-col class="justify-content-end">
              <f7-button
                class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
                raised
                type="success"
                styling-mode="contained"
                @click="confirm()"
              >
              {{ $t("popupSelectObservationsTaskReport.confirm") }}
              </f7-button>
            </f7-col>
          </f7-row>
        </div>
    </DxPopup>
  </template>
  <script>
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxPopup} from 'devextreme-vue/popup';
  import { mapState, mapActions } from 'vuex';
  import Api from '../../services/Api';
  import Spinner from '../spinner.vue';
  import ImageText from './imageText.vue';
  
  export default {
    name: 'PopupSelectObservationsTaskReports',
    components: {
      DxPopup,
      DxCheckBox,
      Spinner,
      ImageText
    },
    props: {
      allTasks: {
        type: Array,
        default: () => [],
      },
      compareGis: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        refresh: true,
        observations: [],
        localTasks: [],
        loading: true,
        companyId: '',
        plantationId: ''
      };
    },
    computed: {
      ...mapState('Plantation', ['currentPlantation', 'LeftComparatorPlantationId', 'RightComparatorPlantationId', 'LeftComparatorCompanyId', 'RightComparatorCompanyId']),
      ...mapState('TimeDimension', ['selectedTasks', 'selectedObservations']),
      ...mapState('authentication', ['user']),
    },
    async mounted() {
      this.setSelectedObservations([]);
      this.setSelectedTasks([]);

      this.localTasks = this.allTasks.map((task) => {
          task.selected = false;
          return task;
        });
        // Declaramos array vacio de observaciones
        let observations = [];
      try {
        if (this.compareGis) {
          // Realizamos ambas peticiones en paralelo y esperamos a que ambas completen
          const responses = await Promise.all([
            Api.getObservationsWithImages(this.LeftComparatorCompanyId, this.LeftComparatorPlantationId),
            Api.getObservationsWithImages(this.RightComparatorCompanyId, this.RightComparatorPlantationId),
          ]);

          // Combinamos ambas peticiones
          observations = responses.flatMap(response => JSON.parse(response.response).results);
        } else {
          // Realizar una sola petición si no estamos en el comparador (si entramos desde buscador/Gis)
          const xhr = await Api.getObservationsWithImages(this.currentPlantation.companyId, this.currentPlantation.id);
          observations = JSON.parse(xhr.response).results;
        }
        const observationsColors = {
          Incidencia: '#fbbf24',
          Recetario: '#4cd964',
          Muestreo: '#2dd4bf',
          Otros: '#db2777',
          'Producción y calidad': '#f43f5e',
        };

        this.observations = observations.map((observation) => {
          const image = observation.multimedia.length > 0
            ? Api.getBaseUrl()
                + observation.multimedia[0].attachment
                + this.user.access
            : '';

          const color = observationsColors[observation.category];
          observation.selected = false;
          observation.image = image;
          observation.color = color;
          return observation;
        });
  
        if (this.observations.length === 0) {
          this.refresh = false;
        }
      } catch (error) {
        this.observations = [];
  
        this.$notifyDX(
          {
            message: this.$t(
              this.$helpers.getFilteredErrorMessage(
                this.$helpers.getErrorMSG(error),
              ),
            ),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.loading = false;
      }
    },
    methods: {
      selectObservations(data) {
        this.observations = this.observations.map((item) => {
          if (item.id === data.id) {
            item.selected = !item.selected;
          }
          return item;
        });
      },
      close() {
        this.$emit('close');
      },
      confirm() {
        const selectedObservations = this.observations.filter(item => item.selected);
        const selectedTasks = this.localTasks.filter(item => item.selected);

        if (selectedObservations.length > 0) {
            this.setSelectedObservations(selectedObservations);
        }

        if (selectedTasks.length > 0) {
            this.setSelectedTasks(selectedTasks);
        }
        this.$emit('close');
      },
      toggleCheckboxTask(task) {
        this.localTasks = this.localTasks.map((item) => {
          if (item.id === task.id) {
            item.selected = !item.selected;
          }
          return item;
        });
      },
      ...mapActions('TimeDimension', ['setSelectedTasks', 'setSelectedObservations']),
    },
  };
  </script>
  
<style lang="scss" scoped>
.observations-container {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
    &__item {
      cursor: pointer;
      height: 100%;
      padding: 1px;
      position: relative; 
    &--selected { 
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 10000px;
      display: flex;
      font-size: 12px;
      padding: 1px;
      position: absolute;
      right: 13px;
      top: 17px;
    }
  }
}

.no-data {
  padding: 2rem;
  text-align: center;
}

.title-resume {
  font-size: 20px;
  font-weight: 400;
  padding: 0 10px;
}

.separator {
  background: #e5e7eb;
  height: 1px;
  width: 100%;
}

.container-footer {
  bottom: 0;
  left: 0;
  position: relative;
  text-align: center;
  width: 100%; 
}

.task-table {
  cursor: pointer;
  margin: 10px;
  &__header {
    display: grid;
    font-weight: 500;
    gap: 5px;
    grid-template-columns: minmax(0, 1fr) repeat(3, minmax(0, 6fr));
    padding-bottom: 10px;
  }

  &__row {
    display: grid;
    gap: 5px;
    grid-template-columns: minmax(0, 1fr) repeat(3, minmax(0, 6fr));
    padding-bottom: 8px;
  }
  &__checkbox,
  &__item {
    align-items: center;
    display: flex;
  }

  &__item {
    justify-content: flex-start;
  }
}

/* Estilos para el scrollbar dentro de .scroll-container */
.scroll-container {
  max-height: 500px;
  max-width: 800px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 15px; /* Ancho del scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #0071bd; /* Color del scrollbar */
    border-radius: 5px; /* Borde redondeado */
  }

  &::-moz-scrollbar {
    width: 15px; /* Ancho del scrollbar */
  }

  &::-moz-scrollbar-thumb {
    background-color: #0071bd; /* Color del scrollbar */
    border-radius: 5px; /* Borde redondeado */
  }
}
</style>
  