<template>
  <div>
    <PopupConfirmDateChange
      v-if="showPopupConfirmDateChange"
      @success="changeDate"
      @cancel="cancelChangeDate"
    />
    <div class="content-devx no-padding-bottom">
      <f7-block class="no-padding">
        <DxForm
          id="form"
          :col-count="1"
          :caption="$t('title_register_plantation')"
        >
          <DxGroupItem :col-count="3">
            <DxSimpleItem
              :data-field="$t('ViewPlantation.view_description')"
              name="name"
              :editor-options="{
                value: plantationName,
                showClearButton: true,
                onValueChanged: onChangeName,
                placeholder: plantationNamePlaceholder
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
            <DxSimpleItem
              name="cultive_type"
              :data-field="$t('registerPlantation.cultive_type')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: cropTypes,
                value: cropTypeSelected,
                onValueChanged: onChangeCultiveType,
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
            <DxSimpleItem
              name="protected"
              :data-field="$t('registerPlantation.protected')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: protectionTypes,
                value: protectionTypeSelected,
                onValueChanged: onChangeProtectionType,
              }"
              :is-required="true"
            />
            <DxSimpleItem
              name="manejo"
              :data-field="$t('registerPlantation.manejo')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: managementTypes,
                value: managementTypeSelected,
                onValueChanged: onChangeManagement,
              }"
              :is-required="true"
            />
            <DxSimpleItem
              :data-field="$t('registerPlantation.init_date')"
              name="init_date"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeInitDate,
                displayFormat: 'dd/MM/yyyy',
                invalidDateMessage: $t('dates_isInvalid'),
                inputAttr:inputAttr,
                pickerType: 'calendar',
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
            <DxSimpleItem
              name="end-date"
              :data-field="$t('registerPlantation.end_date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeEndDate,
                displayFormat: 'dd/MM/yyyy',
                invalidDateMessage: $t('dates_isInvalid'),
                inputAttr:inputAttr,
                pickerType: 'calendar',
              }"
            >
              <DxRequiredRule message="" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxForm>
      </f7-block>
    </div>
    <div v-if="cropTypeSelected === 'HORTÍCOLA'">
      <RegisterHorticola
        :species-availables="speciesAvailables"
      />
    </div>
    <div v-if="cropTypeSelected === 'HERBÁCEO'">
      <RegisterHebacea
        :species-availables="speciesAvailables "
      />
    </div>
    <div v-if="cropTypeSelected === 'LEÑOSO'">
      <RegisterLenioso
        :species-availables="speciesAvailables"
      />
    </div>
    <DxPopUPRegisterParcelSectorByPlantation
      :parcels="parcels"
      :sectors="sectors"
    />
  </div>
</template>

<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import RegisterHorticola from '../registerHorticola/index.vue';
import RegisterHebacea from '../registerHerbaceas/index.vue';
import RegisterLenioso from '../registerLeñoso/index.vue';
import DxPopUPRegisterParcelSectorByPlantation from '../popUpSelectParcelSector/index.vue';
import PopupConfirmDateChange from '../popupConfirmDateChange/index.vue';

export default {
  name: 'RegisterPlantationStep2',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    RegisterHorticola,
    RegisterHebacea,
    RegisterLenioso,
    DxPopUPRegisterParcelSectorByPlantation,
    DxRequiredRule,
    PopupConfirmDateChange,
  },
  props: {
    cropTypes: { type: Array, default: () => [] },
    managementTypes: { type: Array, default: () => [] },
    protectionTypes: { type: Array, default: () => [] },
  },
  data() {
    return {
      inputAttr: { inputmode: 'none' },
      dateBoxChanged: null,
      showPopupConfirmDateChange: false,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation', 'parcels', 'sectors', 'isEdit', 'cropTypeSelected', 'speciesAvailables',
      'initDate', 'endDate', 'managementTypeSelected', 'protectionTypeSelected', 'plantationName', 'plantationNamePlaceholder']),
    ...mapState('Campania', ['actualCampaign']),
  },
  beforeMount() {
    if (this.isEdit) {
      this.setPlantationName(this.actualPlantation.name);
      this.setInitDate(this.actualPlantation.init_date);
      this.setEndDate(this.actualPlantation.end_date);
      this.setManagementType(this.actualPlantation.management);
      this.setProtectionType(this.actualPlantation.protection);
      this.setCultiveType(this.actualPlantation.crop_type);

      // setTimeout(() => {
      //   this.setPlantationNamePlaceholder(`${this.actualPlantation.crop_type}-${this.actualPlantation.variety}`);
      // }, 100);
    } else {
      setTimeout(() => {
        this.setPlantationNamePlaceholder('Especie botánica-Variedad');
      }, 100);
    }
  },
  methods: {
    onChangeName(e) {
      this.setPlantationName(e.value);
    },
    onChangeInitDate(e) {
      if (!this.isEdit) {
        this.setInitDate(moment(e.value).format('YYYY-MM-DD'));
        return;
      }


      this.showPopupConfirmDateChange = true;
      this.dateBoxChanged = { name: 'init_date', e };
    },
    onChangeEndDate(e) {
      if (!this.isEdit) {
        this.setEndDate(moment(e.value).format('YYYY-MM-DD'));
        return;
      }

      this.showPopupConfirmDateChange = true;
      this.dateBoxChanged = { name: 'end_date', e };
    },
    changeDate() {
      if (this.dateBoxChanged.name === 'init_date') {
        this.setInitDate(moment(this.dateBoxChanged.e.value).format('YYYY-MM-DD'));
      }

      if (this.dateBoxChanged.name === 'end_date') {
        this.setEndDate(moment(this.dateBoxChanged.e.value).format('YYYY-MM-DD'));
      }

      this.showPopupConfirmDateChange = false;
      this.dateBoxChanged = null;
    },
    cancelChangeDate() {
      this.showPopupConfirmDateChange = false;
      this.dateBoxChanged = null;
    },
    onChangeManagement(e) {
      this.setManagementType(e.value);
    },
    onChangeProtectionType(e) {
      this.setProtectionType(e.value);
    },
    async onChangeCultiveType(e) {
      this.setCultiveType(e.value);
      // this.resetSpecieSelectedAndVarietySelected(); TODO: EN LA EDICION SE RESETEA DESPUES DE DARLE VALOR AL FORMULARIO DINAMICO POR LO QUE SE QUEDA VACIO
      try {
        if (e.value !== '') {
          await this.getSelectBoxSpecies(e.value);

          const changeName = this.plantationName === this.plantationNamePlaceholder;

          this.setPlantationNamePlaceholder('Especie botánica-Variedad');

          if (changeName) {
            this.setPlantationName('');
          }
        }
      } catch (error) {
        const message = this.$helpers.getFilteredErrorMessage(error);
        if (message.includes('default_') && !message.includes('404')) {
          this.$notifyDX(
            {
              message: message.split('_').pop(),
              width: 550,
            },
            'error',
            3000,
          );
        } else if (!message.includes('404')) {
          this.$notifyDX(
            {
              message: this.$t(message),
              width: 550,
            },
            'error',
            3000,
          );
        }
      }
    },
    ...mapActions('RegisterPlantation', ['setPlantationName', 'setProtectionType', 'setManagementType',
      'setInitDate', 'setEndDate', 'setCultiveType', 'getSelectBoxSpecies', 'resetSpecieSelectedAndVarietySelected',
      'initStore', 'setPlantationNamePlaceholder']),
  },
};
</script>
