<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('LeftPanel_title_analisys')} > ${$t('Title_page_weather')}`" />
    <WeatherComponent />
    <f7-row>
      <f7-col />
      <f7-col>
        <f7-button
          class="dx-btn-success margin-top-half"
          raised
          type="success"
          styling-mode="contained"
          @click="addSeriesGraphic"
        >
          {{ $t("Button_add_weather") }}
        </f7-button>
      </f7-col>
    </f7-row>
    <GraphicWeatherComponent
      v-if="loaded"
      :date="date"
    />
  </f7-page>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import WeatherComponent from '../../components/analisys/weather/registerWeather/index.vue';
import GraphicWeatherComponent from '../../components/analisys/weather/graphicWeather/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'WeatherPage',
  components: {
    WeatherComponent,
    GraphicWeatherComponent,
    navbar,
  },
  data() {
    return {
      visible: false,
      date: [],
      loaded: false,
    };
  },
  computed: {
    ...mapState('weather', ['initDate', 'endDate', 'plantationSelected', 'parameterSelected', 'dataDate', 'clientSelected']),
  },
  beforeMount() {
    this.initStoreRegister();
    this.initStoreGraph();
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Análisis > Clima');
    },
    async addSeriesGraphic() {
      this.loaded = false;
      if (this.clientSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('DxRegisterCampania_name_require'),
            width: 550,
          },
          'info',
          3000,
        );
      } else if (this.parameterSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('DxRegisterCampania_parameter_required'),
            width: 550,
          },
          'info',
          3000,
        );
      } else if (this.initDate === '' || this.initDate === 'Invalid date' || this.endDate === '' || this.endDate === 'Invalid date') {
        this.$notifyDX(
          {
            message: this.$t('DxRegisterDateRange_validation'),
            width: 550,
          },
          'info',
          3000,
        );
      } else if (moment(this.endDate).diff(moment(this.initDate), 'days') > 30) {
        this.$notifyDX(
          {
            message: this.$t('error_range_date'),
            width: 550,
          },
          'info',
          3000,
        );
      } else if (this.initDate > this.endDate) {
        this.$notifyDX(
          {
            message: this.$t('DxRegisterCampania_fecha_inicio_mayor_validation'),
            width: 550,
          },
          'info',
          3000,
        );
      } else if (this.plantationSelected === '') {
        this.$notifyDX(
          {
            message: this.$t('DxRegisterCampania_plantation_require'),
            width: 550,
          },
          'info',
          3000,
        );
      } else {
        try {
          this.$f7.preloader.show();
          await this.getWeatherParameter();
          this.date = this.dataDate;
          this.loaded = true;
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },

    ...mapActions('weather', ['getWeatherParameter', 'initStoreRegister', 'initStoreGraph']),
  },
};
</script>
<style scoped>
@import './weather.styles.scss';
.dx-btn-success{
  width: auto;
}
</style>
