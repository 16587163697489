<template>
  <div style="margin-top: 20px; padding: 12px">
    <div class="label-input">
      {{ $t("attributes") }}
    </div>
    <DxTagBox
      :data-source="attributeList"
      :multiline="true"
      placeholder="Tipo"
      tag-template="tag"
      :value="selectedAttributes"
      :on-value-changed="attributeChanged"
      :show-selection-controls="true"
    >
      <template #tag="{ data }">
        <div class="blueTag">
          <b>{{ data }}</b>
          <div class="dx-tag-remove-button xButton" />
        </div>
      </template>
    </DxTagBox>
  </div>
</template>
<script>
import { DxTagBox } from 'devextreme-vue/tag-box';
import { mapActions, mapState } from 'vuex';

export default {
  components: { DxTagBox },
  data() {
    return {
      attributeList: [
        'Tipo de cultivo',
        'Textura',
        'Manejo',
        'Sistema de riego',
        'Protección',
      ],
    };
  },
  computed: {
    ...mapState('SearchEngineFilter', ['selectedAttributes']),
  },
  methods: {
    ...mapActions('SearchEngineFilter', ['setSelectedAttribute']),
    attributeChanged(e) {
      this.setSelectedAttribute(e.value);
    },
  },
};
</script>
