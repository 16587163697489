<template>
  <f7-page>
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Searcher.observations.observations_title')}`" />
    <f7-row>
      <f7-col>
        <ObservationPointForm />
      </f7-col>
      <f7-col>
        <f7-row
          class="content-devx"
        >
          <f7-col width="150">
            <div v-if="!loadedGIS">
              <div class="loadingFalse">
                <div class="preloader" />
              </div>
            </div>
            <div v-else>
              <GISPointsMap
                :zoom="zoom"
                :name-map="mainMap"
                :tree-locations="treeLocations"
              />
            </div>
          </f7-col>
        </f7-row>
      </f7-col>
    </f7-row>

    <f7-block
      class="footer"
    >
      <f7-row>
        <f7-col width="15">
          <f7-button
            class="dx-btn-cancel btnBack"
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            style="margin-bottom: 10px"
            @click="goToCreateObservation"
          >
            {{ $t('Go_Back') }}
          </f7-button>
        </f7-col>
        <f7-col width="70" />
        <f7-col width="15">
          <f7-button
            class="dx-btn-save margin-right"
            raised
            type="success"
            styling-mode="outlined"
            name="ButtonSave"
            @click="createObservation"
          >
            {{ $t('Button_save_text') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </f7-block>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import ObservationPointForm from '../../../components/searcher/observations/observationForm/mainData/observationPointForm/index.vue';
import GISPointsMap from '../../../components/Gis/GISPointsMap/index.vue';

export default {
  name: 'CreateObservationPoint',
  components: {
    navbar,
    ObservationPointForm,
    GISPointsMap,
  },
  data() {
    return {
      zoom: 17,
      mainMap: 'createObservationPoint',
      loadedGIS: false,
    };
  },
  computed: {
    ...mapState('SearcherObservations', ['name', 'latitude', 'longitude', 'area', 'edit']),
    ...mapState('Plantation', ['currentPlantation', 'treeLocations']),
    ...mapState('Gis', ['currentPoint']),
  },
  async beforeMount() {
    try {
      this.resetCurrentPoint();
      await this.fetchLocationsByPlantation();
      this.loadedGIS = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    goToCreateObservation() {
      if (this.edit) {
        this.$f7.views.main.router.navigate('/editObservation/', { reloadCurrent: true });
      } else {
        this.$f7.views.main.router.navigate('/createObservation/', { reloadCurrent: true });
      }
    },
    async createObservation() {
      this.$f7.preloader.show();
      try {
        if (!this.currentPoint.latitude && !this.edit) {
          this.$notifyDX(
            {
              message: this.$t('mustSelectPoint'),
              width: 550,
            },
            'warning',
            3000,
          );
          this.$f7.preloader.hide();

          return;
        }
        await this.createPointObservation(this.currentPlantation);

        if (this.edit) {
          this.$f7.views.main.router.navigate('/editObservation/', { reloadCurrent: true });
        } else {
          this.$f7.views.main.router.navigate('/createObservation/', { reloadCurrent: true });
        }
      } catch (error) {
        if (error.message.includes('500')) {
          this.$notifyDX(
            {
              message: this.$t('error_500'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('SearcherObservations', ['createPointObservation']),
    ...mapActions('Plantation', ['fetchLocationsByPlantation']),
    ...mapActions('Gis', ['resetCurrentPoint']),
  },
};
</script>

<style scoped>
.dx-btn-save{
    background: #0071BD;
    color: #ffffff;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: auto;
    line-height: 3;
    box-shadow: none;
  }
  .dx-btn-save:hover {
    box-shadow: 0px 2px 1px rgba(0,0,0,0.07);
  }
  .dx-btn-back{
    background: #FFFFFF;
    color: #74838C;
    border: 1px solid #74838C;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    height: auto;
    line-height: 3;
    box-shadow: none;
  }
  .dx-btn-back:hover {
    box-shadow: 0px 2px 1px rgba(0,0,0,0.07);
  }
  .footer{
      margin-top: 10px;
  }
</style>
