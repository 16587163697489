<template>
  <div v-if="loaded">
    <f7-row class="no-gap">
      <f7-col>
        <div class="content-devx">
          <f7-row>
            <f7-col width="80">
              <f7-block-title class="no-padding">
                <span>{{ `${$t('Utilities.Location_title_deatil')}` }}</span>
              </f7-block-title>
            </f7-col>
            <f7-col width="20">
              <div class="justify-content-end">
                <ContextMenu :items="items" />
              </div>
            </f7-col>
          </f7-row>
          <f7-block>
            <f7-row>
              <f7-col>{{ $t('Utilities.ViewLocation_name_location') }}</f7-col>
              <f7-col>{{ $t('Utilities.ViewLocation_title') }}</f7-col>
              <f7-col>{{ $t('Utilities.ViewLocation_mobile') }}</f7-col>
            </f7-row>
            <f7-row>
              <f7-col class="bold">
                {{ actualName }}
              </f7-col>
              <f7-col class="bold">
                {{ actualOwnerFarm }}
              </f7-col>
              <f7-col class="bold">
                {{ actualMobile }}
              </f7-col>
            </f7-row>
          </f7-block>
          <f7-block class="contentData">
            <f7-row>
              <f7-col>{{ $t('Utilities.ViewLocation_number_register') }}</f7-col>
              <f7-col>{{ $t('Utilities.ViewLocation_province') }}</f7-col>
              <f7-col>{{ $t('Utilities.ViewLocation_Localidad') }}</f7-col>
            </f7-row>
            <f7-row>
              <f7-col class="bold">
                {{ actualNumberRegister }}
              </f7-col>
              <f7-col class="bold">
                {{ actualProvince }}
              </f7-col>
              <f7-col class="bold">
                {{ actualLocality }}
              </f7-col>
            </f7-row>
          </f7-block>
        </div>
        <div class="content-devx">
          <f7-block-title class="no-padding title-position-absolute-left">
            <span>{{ `${$t('Utilities.datagrid_title_parcel')}` }}</span>
          </f7-block-title>
          <f7-block>
            <DxDataGrid
              id="gridContainer"
              :data-source="parcel"
              :remote-operations="true"
              :column-min-width="50"
              :show-borders="true"
              :show-column-lines="true"
              :show-row-lines="false"
              :column-auto-width="true"
              :row-alternation-enabled="true"
              @exporting="onExporting"
              @toolbar-preparing="onToolbarPreparing($event)"
            >
              <DxSelection
                mode="single"
              />
              <DxStateStoring
                :enabled="true"
                type="localStorage"
                :storage-key="`${keyName}`"
              />
              <DxHeaderFilter :visible="true" />
              <DxExport
                :enabled="false"
              />
              <DxSelection
                mode="single"
              />
              <DxPaging :page-size="5" />
              <DxPager
                :show-page-size-selector="true"
                :allowed-page-sizes="pageSizes"
                :show-info="true"
              />
              <DxSearchPanel
                :visible="true"
                :width="200"
                :highlight-case-sensitive="true"
                :placeholder="$t('Search_placeholder')"
              />
              <DxColumn
                data-field="name"
                name="name"
                :caption="$t('Utilities.ViewLocation_parcel_column_parcela')"
                :allow-header-filtering="true"
                alignment="left"
              />
              <DxColumn
                data-field="area"
                name="area"
                :caption="$t('Utilities.ViewLocation_parcel_column_area')"
                alignment="left"
              />
              <DxColumn
                :buttons="editButtons"
                type="buttons"
                width="80"
                data-field=""
                :fixed="gridSettings.fixed"
                :fixed-position="gridSettings.fixedPosition"
              />
            </DxDataGrid>
          </f7-block>
        </div>
      </f7-col>
      <f7-col>
        <f7-block class="no-padding-left padding-top-half">
          <GISGeoJsonMap
            :zoom="zoom"
            :name-map="mainMap"
            :tree-locations="treeLocationFromExplotation"
            selected-min-height="466px"
          />
        </f7-block>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import ContextMenu from '@/components/contextMenu/index.vue';
import GISGeoJsonMap from '../../Gis/GISGeoJsonMap/index.vue';
import settings from '../../../js/commonSettings';

export default {
  name: 'ViewLocation',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    GISGeoJsonMap,
    ContextMenu,
  },
  data() {
    return {
      loaded: false,
      editButtons: [{
        icon: 'find',
        onClick: this.showViewParcel,
      }],
      pageSizes: [5, 10, 20],
      keyName: 'ViewLocation',
      allDataTreeMap: {},
      parcel: [],
      actualName: '',
      actualLocality: '',
      actualProvince: '',
      actualNumberRegister: '',
      actualMobile: '',
      actualOwnerFarm: '',
      actualPostalCode: '',
      actualFarm: '',
      zoom: 17,
      mainMap: 'explotationMainMap',
      items: [
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.editExplotation,
        },
      ],
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Explotaciones', ['treeLocation', 'actualLocation', 'actualUser']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('RegisterLocation', ['allCities']),
    ...mapGetters('Plantation', ['treeLocationFromExplotation']),

  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
      await this.fetchTreeLocation(this.actualUser.id);
      await this.getCities(this.actualLocation.properties.province);
      await this.fetchTreeLocationById({ companyId: this.actualUser.id, locationId: (this.actualLocation.id || this.actualLocation.bdId) });
      this.getAllDatasParcel();
      if (typeof this.actualLocation.farm !== 'undefined') this.actualFarm = this.actualLocation.farm;
      if (typeof this.actualLocation.name !== 'undefined') this.actualName = this.actualLocation.name;
      const { properties } = this.actualLocation;
      for (const key in properties) {
        if (Object.hasOwnProperty.call(properties, key)) {
          const property = properties[key];
          switch (key) {
            case 'number_register':
              this.actualNumberRegister = property;
              break;
            case 'owner_Farm':
              this.actualOwnerFarm = property;
              break;
            case 'mobile':
              this.actualMobile = property;
              break;
            case 'province':
              this.actualProvince = property;
              break;
            case 'locality':
              if (property) {
                this.getLocalityByID(property);
              }
              break;
            case 'postal_code':
              this.actualPostalCode = property;
              break;
            default:
              break;
          }
        }
      }
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    showViewParcel(e) {
      this.setCurrentParcelId(e.row.data.id);
      this.$f7.views.main.router.navigate('/viewParcel/', { reloadCurrent: true });
    },
    getAllDatasParcel() {
      for (const level0 of this.treeLocation) {
        if (this.actualLocation.bdId === level0.bd_id) {
          for (let i = 0; i < level0.children.length; i += 1) {
            this.parcel.push({
              id: level0.children[i].bd_id,
              name: level0.children[i].name,
              area: parseFloat(level0.children[i].area.toFixed(2)),
            });
          }
        }
      }
    },
    editExplotation() {
      this.$f7.views.main.router.navigate('/editLocation/', { reloadCurrent: true });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ViewLocation');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ViewLocation.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          text: this.$t('Utilities.title_button_viewLocation_parcel'),
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.setCurrentName('');
            this.resetAll();
            this.setCurrentParcel({});
            this.setTreeLocations([]);
            this.$f7.views.main.router.navigate('/registerParcelStep2/', { reloadCurrent: true });
          },
        },
      });
    },
    getLocalityByID(id) {
      this.actualLocality = this.allCities.filter((city) => city.code === id)[0].name;
    },
    ...mapActions('Explotaciones', ['fetchTreeLocation']),
    ...mapActions('GisEditor', ['resetAll']),
    ...mapActions('Parcel', ['setCurrentParcelId', 'setCurrentParcel', 'setCurrentName']),
    ...mapActions('Plantation', ['setTreeLocations', 'fetchTreeLocationById']),
    ...mapActions('RegisterLocation', ['getCities']),

  },
};
</script>
<style scoped>
@import '../ViewLocation/ViewLocation.styles.scss';
</style>
