<template>
  <div class="content-devx no-padding">
    <DxForm
      id="form"
      :col-count="1"
      :show-colon-after-label="false"
    >
      <DxGroupItem :col-count="2">
        <DxSimpleItem
          :data-field="$t('sector.register.sectorName')"
          :editor-options="{
            value: currentSector.sectorName,
            mode: 'text',
            placeholder: $t('sector.register.sectorName'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        >
          <DxRequiredRule :message="$t('sector.register.required_name')" />
        </DxSimpleItem>
        <DxSimpleItem
          v-if="!editing"
          :data-field="$t('useParcelGeometry')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: selectOptions,
            onValueChanged: onValueChanged,
            value: currentSector.useParcelGeometry,
            placeholder: $t('select_placeholder'),
          }"
        />
        <DxSimpleItem
          :data-field="$t('sector.register.groundTexture')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: groundTexturesAvailables,
            onValueChanged: onValueChanged,
            value: currentSector.groundTexture,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            placeholder: $t('select_placeholder'),
          }"
        />
        <DxSimpleItem
          :data-field="$t('sector.register.irrigationType')"
          editor-type="dxSelectBox"
          :editor-options="{
            items: irrigationTypesAvailables,
            onValueChanged: onValueChanged,
            value: currentSector.irrigationType,
            searchEnabled: true,
            searchTimeout: searchTimeoutOption,
            minSearchLength: minSearchLengthOption,
            showDataBeforeSearch: showDataBeforeSearchOption,
            placeholder: $t('select_placeholder'),
          }"
        >
          <DxRequiredRule :message="$t('sector.register.required_irrigationType')" />
        </DxSimpleItem>
        <DxGroupItem :col-span="1" />
      </DxGroupItem>
    </DxForm>
    <DxForm
      v-if="currentSector.irrigationType === 'GOTEO'"
      id="formGoteo"
      :show-colon-after-label="false"
    >
      <DxGroupItem :col-count="2">
        <DxSimpleItem
          :data-field="$t('sector.register.flowPerPlant')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.flow,
            placeholder: $t('sector.register.flowPerPlant'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
        <DxSimpleItem
          :data-field="$t('sector.register.dripperPerPlant')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.dripperPerPlant,
            placeholder: $t('sector.register.dripperPerPlant'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
      </DxGroupItem>
    </DxForm>
    <DxForm
      v-if="currentSector.irrigationType === 'ASPERSIÓN'"
      id="formAspersion"
      :show-colon-after-label="false"
    >
      <DxGroupItem :col-count="2">
        <DxSimpleItem
          :data-field="$t('sector.register.sprinklersNumber')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.sprinklersNumber,
            placeholder: $t('sector.register.sprinklersNumber'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
        <DxSimpleItem
          :data-field="$t('sector.register.flow360')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.flow,
            placeholder: $t('sector.register.flow360'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
        <DxSimpleItem
          :data-field="$t('sector.register.separationBetweenLines')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.separationBetweenLines,
            placeholder: $t('sector.register.separationBetweenLines'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
        <DxSimpleItem
          :data-field="$t('sector.register.separationBetweenSprinkler')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.separationBetweenSprinkler,
            placeholder: $t('sector.register.separationBetweenSprinkler'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
      </DxGroupItem>
    </DxForm>
    <DxForm
      v-if="currentSector.irrigationType === 'INUNDACIÓN'"
      id="formInundacion"
      :show-colon-after-label="false"
    >
      <DxGroupItem :col-count="2">
        <DxSimpleItem
          :col-span="1"
          :data-field="$t('sector.register.flow')"
          editor-type="dxNumberBox"
          :editor-options="{
            value: currentSector.flow,
            placeholder: $t('sector.register.flow'),
            showClearButton: true,
            onValueChanged: onValueChanged,
          }"
        />
      </DxGroupItem>
    </DxForm>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'RegisterSectorStep1',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    groundTexturesAvailables: {
      type: Array,
      default: () => [],
    },
    irrigationTypesAvailables: {
      type: Array,
      default: () => [],
    },
    currentSector: {
      type: Object,
      default: () => {},
    },
    editing: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      selectOptions: ['Si', 'No'],
      selectedOptionParcelGeometry: 'No',
      loaded: true,
    };
  },
  computed: {
    ...mapState('Parcel', ['currentParcel']),
  },
  mounted() {
    this.onValueChanged('useParcelGeometry');
  },
  methods: {
    onValueChanged(e) {
      this.loaded = false;
      let keyToUpdate = '';

      if (e === 'useParcelGeometry') {
        keyToUpdate = 'useParcelGeometry';
        this.updateCurrentSectorKey({ currentSectorKey: keyToUpdate, newValue: 'Si' });

        setTimeout(() => {
          if (this.currentSector.useParcelGeometry === 'Si') {
            this.setArea(Number(this.currentParcel.properties[0].value));
            this.setCentroide({
              lat: this.currentParcel.coordinates.latitude,
              lng: this.currentParcel.coordinates.longitude,
            });
            // Actualizamos las coordenadas del sector
            this.setCurrentCentroid(({
              lat: this.currentParcel.coordinates.latitude,
              lng: this.currentParcel.coordinates.longitude,
            }))
          } else {
            this.setArea(0);
            this.setCentroide({});
            this.setCurrentCentroid({})
          }
        }, 0);
        this.loaded = true;
        return;
      }
      switch (e.element.getElementsByTagName('input')[0].getAttribute('name')) {
        case this.$t('sector.register.sectorName'):
          keyToUpdate = 'sectorName';
          break;
        case this.$t('sector.register.groundTexture'):
          keyToUpdate = 'groundTexture';
          break;
        case this.$t('sector.register.irrigationType'):
          keyToUpdate = 'irrigationType';
          this.resetDynamicForm();
          break;
        case this.$t('sector.register.dripperPerPlant'):
          keyToUpdate = 'dripperPerPlant';
          break;
        case this.$t('sector.register.sprinklersNumber'):
          keyToUpdate = 'sprinklersNumber';
          break;
        case this.$t('sector.register.transformedArea'):
          keyToUpdate = 'transformedArea';
          break;
        case this.$t('sector.register.separationBetweenLines'):
          keyToUpdate = 'separationBetweenLines';
          break;
        case this.$t('sector.register.separationBetweenSprinkler'):
          keyToUpdate = 'separationBetweenSprinkler';
          break;
        case this.$t('sector.register.flowPerPlant'):
        case this.$t('sector.register.flow360'):
        case this.$t('sector.register.flow'):
          keyToUpdate = 'flow';
          break;
        case this.$t('useParcelGeometry'):
          keyToUpdate = 'useParcelGeometry';

          setTimeout(() => {
            if (this.currentSector.useParcelGeometry === 'Si') {
              this.setArea(Number(this.currentParcel.properties[0].value));
              this.setCentroide({
                lat: this.currentParcel.coordinates.latitude,
                lng: this.currentParcel.coordinates.longitude,
              });
              // Actualizamos las coordenadas del sector
              this.setCurrentCentroid(({
                lat: this.currentParcel.coordinates.latitude,
                lng: this.currentParcel.coordinates.longitude,
            }))
            } else {
              this.setArea(0);
              this.setCentroide({});
              this.setCurrentCentroid({})
            }
          }, 0);
          break;
        default:
          break;
      }
      this.updateCurrentSectorKey({ currentSectorKey: keyToUpdate, newValue: e.value });
      this.loaded = true;
    },
    ...mapActions('Sector', ['updateCurrentSectorKey', 'resetDynamicForm', 'setCurrentCentroid']),
    ...mapActions('GisEditor', ['setArea', 'setCentroide']),
  },
};
</script>
<style>
@import './RegisterSectorStep1.styles.scss';
</style>
