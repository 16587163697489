var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"form","col-count":1,"show-colon-after-label":false}},[_c('DxGroupItem',{attrs:{"col-count":2}},[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('parcel.register.name.field'),"name":"name","caption":_vm.$t('parcel.register.name.caption'),"editor-options":{
          value: _vm.currentName,
          mode: 'text',
          placeholder: _vm.$t('parcel.register.name.placeholder'),
          showClearButton: true,
          onValueChanged: _vm.onNameChanged,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('parcel.register.name.requiredRule')}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }