var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.showPopUp,"drag-enabled":false,"close-on-outside-click":false,"on-hidden":_vm.onHidden,"show-title":true,"show-close-button":true,"width":500,"height":"auto","title":_vm.$t('registes_irrigation_title_datagrid')}},[[_c('div',{staticClass:"content-form"},[_c('DxForm',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerAndEditIrrigation_date'),"caption":_vm.$t('registerAndEditIrrigation_date'),"editor-type":"dxDateBox","editor-options":{
              value: _vm.initDate,
              onValueChanged: _vm.onChangeDateIrrigation,
              displayFormat: 'dd/MM/yyyy',
              readOnly: _vm.isView,
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              disabledDates: _vm.disabledDates,
              pickerType: 'calendar',
              type: 'date',
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerAndEditIrrigation_doses'),"caption":_vm.$t('registerAndEditIrrigation_doses'),"editor-type":"dxNumberBox","editor-options":{
              min: 0,
              mode: 'number',
              readOnly: _vm.isView,
              value: _vm.actualIrrigation.doses,
              onValueChanged: _vm.onChangeDosesIrrigation,
              placeholder: '0',
            }}})],1)],1)],_vm._v(" "),_c('f7-block-footer',{staticClass:"justify-content-center"},[_c('f7-row',[_c('f7-col',{staticClass:"justify-content-center"},[(!_vm.isView)?_c('f7-button',{staticClass:"dx-btn-succes-pop-up margin-right-block btn-width-150 margin-top-block margin-bottom-block",attrs:{"raised":"","type":"success","styling-mode":"contained"},on:{"click":_vm.goToBack}},[_vm._v("\n            "+_vm._s(_vm.$t("End_Campaign"))+"\n          ")]):_vm._e()],1)],1)],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }