<template>
  <div
    class="content-devx"
  >
    <f7-row>
      <f7-col width="80">
        <f7-block-title>
          {{ $t('parcel.detail.title') }}
        </f7-block-title>
      </f7-col>
      <f7-col width="20">
        <div class="justify-content-end">
          <ContextMenu :items="items" />
        </div>
      </f7-col>
    </f7-row>

    <f7-block>
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('parcel.detail.client') }}
        </f7-col>
        <f7-col>
          {{ $t('parcel.detail.location') }}
        </f7-col>
        <f7-col>
          {{ $t('parcel.detail.parcelName') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ currentClient }}
        </f7-col>
        <f7-col>
          {{ currentParcel.explotation }}
        </f7-col>
        <f7-col>
          {{ currentParcel.name }}
        </f7-col>
      </f7-row>
      <f7-row class="margin-top-half">
        <f7-col>
          {{ $t('parcel.detail.area') }}
        </f7-col>
      </f7-row>
      <f7-row class="bold">
        <f7-col>
          {{ parseFloat(area).toFixed(2) }} ha
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import ContextMenu from '@/components/contextMenu/index.vue';

export default {
  name: 'ParcelDetail',
  components: {
    ContextMenu,
  },
  props: {
    currentParcel: {
      type: Object,
      default: () => {},
    },
    currentClient: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      items: [
        {
          icon: 'edit',
          text: this.$t('ContextMenu.Edit'),
          onItemClick: this.editCurrentParcel,
        },
        {
          icon: 'map',
          text: `${this.$t('viewParcel')} (Google maps)`,
          onItemClick: () => {
            window.open(
              `https://www.google.es/maps/@${this.parcelCentroide.latitude},${this.parcelCentroide.longitude},700m/data=!3m1!1e3!5m2!1e4!1e1?hl=es`, '_blank',
            );
          },
        },
      ],
    };
  },
  computed: {
    area() {
      return this.$helpers.getProperty(this.currentParcel.properties, 'area');
    },
    ...mapState('Gis', ['parcelCentroide']),
  },
  methods: {
    editCurrentParcel() {
      this.setCurrentName(this.currentParcel.name);
      this.$f7.views.main.router.navigate('/registerParcelStep2/', { reloadCurrent: true });
    },
    ...mapActions('Parcel', ['setCurrentName']),
  },
};
</script>
<style lang="scss" scoped>
@import './ParcelDetail.styles.scss';
</style>
