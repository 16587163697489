var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DxPopup',{attrs:{"visible":_vm.showPopUpRecommended,"drag-enabled":false,"close-on-outside-click":false,"on-hidden":_vm.onHidden,"show-title":true,"show-close-button":true,"width":500,"height":"auto","title":_vm.$t('register_irrigation_title_datagridRecommendation')}},[[_c('div',{staticClass:"content-form"},[_c('DxForm',[_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerAndEditIrrigation_date'),"caption":_vm.$t('registerAndEditIrrigation_date'),"editor-type":"dxDateBox","editor-options":{
              value: _vm.initDate,
              onValueChanged: _vm.onChangeDateIrrigation,
              displayFormat: 'dd/MM/yyyy',
              readOnly: _vm.isView,
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              disabledDates: _vm.disabledDates,
              pickerType: 'calendar',
              type: 'date',
            }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"data-field":_vm.$t('registerAndEditIrrigation_doses'),"caption":_vm.$t('registerAndEditIrrigation_doses'),"editor-type":"dxNumberBox","editor-options":{
              min: 0,
              mode: 'number',
              value: _vm.actualIrrigationRecommended.doses,
              onValueChanged: _vm.onChangeDosesIrrigation,
              readOnly: _vm.isView,
              placeholder: '0',
            }}})],1)],1)],_vm._v(" "),_c('f7-block-footer',{staticClass:"content-action-botton"},[(!_vm.isView)?_c('f7-button',{staticClass:"dx-btn-succes-pop-up",attrs:{"raised":"","type":"success","styling-mode":"contained"},on:{"click":_vm.goToBack}},[_vm._v("\n        "+_vm._s(_vm.$t("End_Campaign"))+"\n      ")]):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }