<template>
  <div class="margin-top-half">
    <div
      class="content-devx"
    >
      <div class="card-header no-padding-horizontal margin-left-half">{{ $t('Configuration.ObservationsForms.detailTitle') }}</div>
      <f7-block>
        <f7-row>
          <f7-col>
            <div class="label-text-data">
              {{ $t('Configuration.ObservationsForms.formName') }}
            </div>
            <div class="item-title-bold x2">
              {{ formName }}
            </div>
          </f7-col>
        </f7-row>
      </f7-block>
    </div>
  </div>
</template>
<script>

export default {
  name: 'HeaderTemplate',
  props: {
    formName: {
      type: String,
      default: '',
    },
  },
};
</script>
<style lang="scss" scoped>
@import './HeaderTemplate.styles.scss';
</style>
