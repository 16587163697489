<template>
  <div class="margin-top-half">
    <div
      class="content-devx"
    >
      <f7-block v-if="!loaded">
        <div class="loadingFalse">
          <div class="preloader" />
        </div>
      </f7-block>
      <f7-block v-else>
        <DxDataGrid
          id="Clients"
          :data-source="allClients"
          :remote-operations="true"
          :column-min-width="70"
          :column-auto-width="true"
          :allow-column-resizing="true"
          :show-borders="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :on-editing-start="onEditingStart"
          @exporting="onExporting"
        >
          <DxSelection
            mode="single"
          />
          <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="DxClient"
          />
          <DxHeaderFilter :visible="true" />
          <DxExport
            :enabled="false"
          />
          <DxPaging :page-size="gridSettings.pageSize" />
          <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="pageSizes"
            :show-info="true"
          />
          <DxSearchPanel
            :visible="true"
            :width="200"
            :placeholder="$t('Search_placeholder')"
          />
          <DxEditing
            :allow-updating="true"
            :allow-adding="false"
            :allow-deleting="false"
            :confirm-delete="true"
            mode="popup"
          >
            <DxPopup
              :show-title="true"
              :title="isEditing"
              :on-hidden="onHidden"
            />
            <DxForm>
              <DxItem
                :col-count="1"
                item-type="group"
              >
                <DxItem data-field="group" />
                <DxItem data-field="category" />
                <DxItem data-field="name" />
                <DxItem data-field="active_ingredients" />
              </DxItem>
            </DxForm>
          </DxEditing>
          <DxColumn
            data-field="registration_date"
            :width="90"
            :caption="$t('DxClients_fecha_alta')"
            :calculate-display-value="formatingDate"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="name"
            :caption="$t('client')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="mobile_phone"
            :caption="$t('DxClients_numero_movil')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="phone"
            :caption="$t('DxClients_telefono')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="province"
            :caption="$t('DxClients_provincia')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            data-field="city"
            :caption="$t('DxClients_localidad')"
            :allow-header-filtering="false"
            alignment="left"
          />
          <DxColumn
            type="buttons"
          >
            <DxButton
              icon="find"
              :hint="$t('DxDailyPlanning_edit_hint')"
              alignment="right"
              :on-click="showInfo"
            />
            <DxButton
              name="delete"
              icon="trash"
            />
          </DxColumn>
        </DxDataGrid>
      </f7-block>
    </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxForm,
  DxPopup,
  DxEditing,
  DxButton,
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import moment from 'moment';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import settings from '../../../js/commonSettings';

export default {
  name: 'DxClients',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxPopup,
    DxForm,
    DxItem,
    DxEditing,
    DxButton,
  },
  data() {
    return {
      loaded: false,
      pageSizes: [5, 10, 20],
      isEdit: false,
      popupVisible: false,
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapState('Clients', ['allClients']),
    isEditing() {
      return this.isEdit === true ? this.$t('DxClient_edit_Client') : this.$t('DxClient_register_Client');
    },
  },
  async beforeMount() {
    try {
      await this.fetchAllClients();
      this.loaded = true;
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    }
  },
  methods: {
    showInfo(rowSelected) {
      this.setActualUser(rowSelected.row.data);
      this.$f7.views.main.router.navigate('/viewClient/clients/', { reloadCurrent: true });
    },
    onHidden(e) {
      this.isEdit = false;
    },
    onEditingStart(e) {
      this.isEdit = true;
    },
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Clients');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Clients.xlsx');
          });
      });
      e.cancel = true;
    },
    formatingDate(e) {
      return moment(e.date).format('DD/MM/YYYY');
    },
    ...mapActions('Clients', ['fetchAllClients', 'setActualUser']),
    ...mapActions('Explotaciones', ['setActualUser']),
  },
};
</script>
