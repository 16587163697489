import Framework7 from 'framework7';
import EventBus from '../js/event-bus';
import { APP_VERSION } from '../js/constants';

export default {
  request(url, payload, verb, processData = true, contentType = 'application/x-www-form-urlencoded', headerAuth, enabledCache,
    headerCompany = null, callback, timeout = 180000) {
    Framework7.request.setup({
      headers: {
        Authorization: headerAuth,
        company: headerCompany,
        'App-version': this.getAppVersion(),
      },
    });
    Framework7.request({
      url,
      method: verb,
      cache: enabledCache,
      data: payload.data,
      processData,
      contentType,
      timeout,
      complete: callback,
    });
  },
  getAppVersion() {
    const appVersionSplitted = APP_VERSION.split('-');
    if (appVersionSplitted.length > 1) {
      return appVersionSplitted[0];
    }
    return APP_VERSION;
  },
  async(url, payload, verb, processData, contentType, headerAuth = null, headerCompany = null, enabledCache = false) {
    const self = this;
    return new Promise(((resolve, reject) => {
      self.request(url, payload, verb, processData, contentType, headerAuth, enabledCache, headerCompany,
        (xhr, status) => {
          if (status >= 300 || status === 'timeout') { // TODO: Tratar adecuademente las respuestas (1xx,2xx,3xx,4xx,5xx)
            if (status === 401) {
              EventBus.$emit('invalidTokenDetected');
            }
            reject(new Error(`${xhr.response}`, { cause: status }));
          }
          try {
            resolve(xhr);
          } catch (e) {
            reject(e);
          }
        });
    }));
  },

  async blobRequest(url, callback) {
    const logoRes = await fetch(url);
    const blob = await logoRes.blob();
    const reader = new FileReader();
    reader.onloadend = () => callback(reader.result);
    reader.readAsDataURL(blob);
  },
};
