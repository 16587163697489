<template>
  <f7-page
    @page:beforein="beforein"
    name="converter"
    class="converter"
  >
    <navbar :text="`${$t('Utilities_Title')} > ${$t('Utilities.converter')}`" />
    <f7-toolbar
      tabbar
      top
      class="no-shadow"
    >
      <f7-link
        tab-link="#water"
        tab-link-active
      >
        {{ $t('Utilities.converterWater.tabTitle') }}
      </f7-link>
      <f7-link tab-link="#ground">
        {{ $t('Utilities.converterGround.tabTitle') }}
      </f7-link>
      <f7-link tab-link="#others">
        {{ $t('Utilities.tabTitleOther') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs
      animated
    >
      <f7-tab
        id="water"
        class="page-content padding-half"
        tab-active
      >
        <converter-water />
      </f7-tab>
      <f7-tab
        id="ground"
        class="page-content padding-half"
      >
        <converter-ground />
      </f7-tab>
      <f7-tab
        id="others"
        class="page-content padding-half"
      >
        <converter-nutritional-solutions />
        <converter-molecular-weight />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script>
import ConverterWater from '../../components/converterWater/index.vue';
import ConverterGround from '../../components/converterGround/index.vue';
import ConverterNutritionalSolutions
from '../../components/converterNutritionalSolutions/index.vue';
import ConverterMolecularWeight from '../../components/converterMolecularWeight/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'ConverterPage',
  components: {
    ConverterWater,
    ConverterGround,
    ConverterMolecularWeight,
    ConverterNutritionalSolutions,
    navbar,
  },
  data() {
    return {};
  },
  methods: {
    async beforein() {
      this.$fa.setScreenName('Utilidades > Conversor');
    },
  },
};

</script>
<style src="./converter.scss" scoped lang="scss"></style>
