<template v-if="loaded">
  <f7-page>
    <navbar :text="`${$t('Configuration.name')} > ${$t('Configuration.Observations_Title')}`" />
    <HeaderTemplateForm
      :templates-categories="templatesCategories"
      :template-category="currentObservationTemplate.category"
      :form-name="currentObservationTemplate.subcategory"
    />
    <AttributesTemplateForm
      :attributes="attributes"
    />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          styling-mode="contained"
          name="ButtonCancel"
          @click="goToObservations"
        >
          {{ $t('Button_cancel_text') }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          styling-mode="outlined"
          name="ButtonSave"
          @click="callCreateObservationTemplate"
        >
          {{ $t('Button_save_text') }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import HeaderTemplateForm from '../../../components/configuration/observations/headerTemplateForm/index.vue';
import AttributesTemplateForm from '../../../components/configuration/observations/attributesTemplateForm/index.vue';

export default {
  name: 'CreateObservationTemplate',
  components: {
    navbar,
    HeaderTemplateForm,
    AttributesTemplateForm,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    attributes() {
      const attributes = [];
      for (const attribute of this.currentObservationTemplate.form) {
        attributes.push(attribute);
      }
      return attributes;
    },
    ...mapState('Observations', ['currentObservationTemplate', 'templatesCategories']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchTemplatesCategories();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async callCreateObservationTemplate() {
      if (this.currentObservationTemplate.category === '') {
        this.$notifyDX(
          {
            message: this.$t('Configuration.Category_Validation'),
            width: 550,
          },
          'error',
          3000,
        );
      } else if (this.currentObservationTemplate.subcategory === '') {
        this.$notifyDX(
          {
            message: this.$t('Configuration.Subcategory_Validation'),
            width: 550,
          },
          'error',
          3000,
        );
      } else if (this.currentObservationTemplate.form.length === 0) {
        this.$notifyDX(
          {
            message: this.$t('Configuration.Form_Validation'),
            width: 550,
          },
          'error',
          3000,
        );
      } else {
        this.$f7.preloader.show();
        try {
          await this.createObservationTemplate(this.currentObservationTemplate);
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          this.$f7.preloader.hide();
          this.$f7.views.main.router.navigate('/observations/', { reloadCurrent: true });
        }
      }
    },
    goToObservations() {
      this.$f7.views.main.router.navigate('/observations/', { reloadCurrent: true });
    },
    ...mapActions('Observations', ['createObservationTemplate', 'fetchTemplatesCategories']),
  },
};
</script>
