var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-devx"},[_c('f7-row',{attrs:{"no-gap":""}},[_c('f7-col',{attrs:{"width":"100"}},[_c('DxForm',{attrs:{"id":"mainData-form","show-colon-after-label":true,"read-only":_vm.readOnly}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"visible":_vm.shouldShowItem('date'),"name":"date","data-field":_vm.$t('Searcher.observations.date'),"editor-type":"dxDateBox","editor-options":{
              onValueChanged: _vm.onChangeDate,
              width: '100%',
              value: _vm.currentValuesMainData.date,
              displayFormat: 'dd/MM/yyyy',
              invalidDateMessage: _vm.$t('dates_isInvalid'),
              onInitialized:_vm.init,
              pickerType: 'calendar',
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.dateValidation')}})],1)],1),_vm._v(" "),_c('DxGroupItem',{attrs:{"css-class":"geolocation-container"}},[_c('DxSimpleItem',{attrs:{"visible":_vm.shouldShowItem('geolocation'),"name":"geolocation","data-field":_vm.$t('Searcher.observations.geolocation'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.scoutingPoints,
              onValueChanged: _vm.onGeolocationChange,
              value: _vm.currentValuesMainData.idGeolocationPoint,
              displayExpr: 'name',
              valueExpr: 'id',
              width: '100%',
              searchEnabled: true,
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.geolocationValidation')}})],1),_vm._v(" "),(!_vm.readOnly && _vm.shouldShowItem('geolocation'))?_c('DxButtonItem',{attrs:{"css-class":"button-container","button-options":_vm.addGeolocationPoint}}):_vm._e()],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"visible":_vm.shouldShowItem('category'),"name":"category","data-field":_vm.$t('Searcher.observations.category'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.categoriesOfTemplates,
              onValueChanged: _vm.onCategoryChange,
              value: _vm.currentValuesMainData.category,
              searchEnabled: true,
              width: '100%',
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Searcher.observations.categoryValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.shouldShowItem('subcategory'),"name":"subcategory","data-field":_vm.$t('Searcher.observations.subcategory'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.templatesByCategory,
              onValueChanged: _vm.onSubcategoryChange,
              value: _vm.currentValuesMainData.subcategory,
              displayExpr: 'subcategory',
              valueExpr: 'id',
              searchEnabled: true,
              width: '100%',
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"visible":!_vm.showLaboratoryData,"message":_vm.$t('Searcher.observations.subcategoryValidation')}})],1)],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"visible":_vm.shouldShowItem('laboratory'),"name":"laboratory","data-field":_vm.$t('Searcher.observations.laboratory'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.laboratoriesOfTemplates,
              onValueChanged: _vm.onLaboratoryChange,
              value: _vm.currentValuesMainData.laboratory,
              displayExpr: 'name',
              valueExpr: 'id',
              searchEnabled: true,
              width: '100%',
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"visible":_vm.showLaboratoryData,"message":_vm.$t('Searcher.observations.laboratoryValidation')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"visible":_vm.shouldShowItem('analysis_type'),"name":"analysis_type","data-field":_vm.$t('Searcher.observations.analysis'),"editor-type":"dxSelectBox","editor-options":{
              items: _vm.templatesByLaboratory,
              onValueChanged: _vm.onAnalysisTypeChange,
              value: _vm.currentValuesMainData.analysis_type,
              displayExpr: _vm.getAnalyseSubDisplayText,
              valueExpr: 'id',
              searchEnabled: true,
              width: '100%',
              searchTimeout: _vm.searchTimeoutOption,
              minSearchLength: _vm.minSearchLengthOption,
              showDataBeforeSearch: _vm.showDataBeforeSearchOption,
            }}},[_c('DxRequiredRule',{attrs:{"visible":_vm.showLaboratoryData,"message":_vm.$t('Searcher.observations.analysisValidation')}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }