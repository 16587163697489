import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    tasks: [],
    taskDetail: {},
  },
  mutations: {
    GET_TASKS(state, value) {
      state.tasks = value;
    },
    GET_TASK_DETAIL(state, value) {
      state.taskDetail = value;
    },
  },
  actions: {
    async getTasks({ commit }) {
      try {
        const xhr = await Api.getTasks();
        const tasks = JSON.parse(xhr.response).data;
        const tasksFormatted = [];
        for (const task of tasks) {
          tasksFormatted.push({
            id: task.id,
            group: task.group,
            species: task.species,
            name: task.name,
            status: task.status,
          });
        }
        commit('GET_TASKS', tasksFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getTaskDetail(context, idTask) {
      try {
        const xhr = await Api.getTaskDetail(idTask);
        const taskDetail = JSON.parse(xhr.response);
        const taskDetailFormatted = [];
        taskDetailFormatted.push({
          id: taskDetail.id,
          group: taskDetail.group,
          species: taskDetail.species,
          name: taskDetail.name,
          status: taskDetail.status,

        });
        context.commit('GET_TASK_DETAIL', taskDetailFormatted);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createTask(context, task) {
      try {
        await Api.createNewTask(
          {
            group: task.data.group,
            species: task.data.species,
            variety: task.data.variety,
          },
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateTask(context, task) {
      try {
        await Api.updateTask(
          {
            group: task.data.group,
            species: task.data.species,
            variety: task.data.variety,
          },
          task.data.id,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteTask(context, task) {
      try {
        await Api.deleteTask(task.data.id);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
};
