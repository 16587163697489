var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"treatment-plan"},[_c('div',[_c('div',{staticClass:"content-devx-no-margin margin-top-block margin-bottom-block position-relative"},[_c('div',{staticClass:"card-header title-top"},[_c('span',[_vm._v(_vm._s(_vm.$t('treatment.treatmentPlan.title')))])]),_vm._v(" "),_c('div',{staticClass:"card-body"},[_c('DxDataGrid',{staticClass:"data-grid-reference-value",attrs:{"data-source":_vm.items,"row-alternation-enabled":true,"show-borders":true,"column-auto-width":true},on:{"toolbar-preparing":function($event){return _vm.onToolbarPreparing($event)}},scopedSlots:_vm._u([{key:"buttonsColumnTemplate",fn:function({ data: cellData }){return [_c('div',{staticClass:"action-buttons"},[_c('DxButton',{staticClass:"dx-button dx-widget dx-button-has-icon dx-button-mode-text button-search",attrs:{"styling-mode":"text","type":"success","icon":"search"},on:{"click":function($event){return _vm.viewItem(cellData.key)}}}),_vm._v(" "),_c('DxButton',{staticClass:"dx-button dx-widget dx-button-has-icon dx-button-mode-text button-edit",attrs:{"styling-mode":"text","icon":"edit"},on:{"click":function($event){return _vm.editItem(cellData.key)}}}),_vm._v(" "),_c('DxButton',{attrs:{"styling-mode":"text","type":"danger","icon":"trash"},on:{"click":function($event){return _vm.deleteItem(cellData.key)}}})],1)]}},{key:"statusColumnTemplate",fn:function({ data }){return [_c('div',[_c('DxSwitch',{attrs:{"value":_vm.switchValue(data.data.status),"switched-on-text":_vm.$t('ACTIVO').toUpperCase(),"switched-off-text":_vm.$t('INACTIVO').toUpperCase(),"width":85},on:{"valueChanged":() => {
                    _vm.updateState(
                      {
                        newState: !_vm.switchValue(data.data.status),
                        id: data.data.id,
                      },
                      data
                    );
                  }}})],1)]}}])},[_c('DxPaging',{attrs:{"page-size":3}}),_vm._v(" "),_c('DxPager',{attrs:{"show-page-size-selector":true,"allowed-page-sizes":_vm.pageSizes,"show-info":true,"info-text":"{2} items"}}),_vm._v(" "),_c('DxSearchPanel',{attrs:{"visible":true,"width":200,"highlight-case-sensitive":false,"placeholder":_vm.$t('Search_placeholder')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"init_date","min-width":200,"allow-sorting":false,"caption":_vm.$t('treatment.treatmentPanel.date'),"calculate-display-value":_vm.formatingDate}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"description","min-width":200,"allow-sorting":false,"caption":_vm.$t('treatment.treatmentPanel.description')}}),_vm._v(" "),_c('DxColumn',{attrs:{"data-field":"products","allow-sorting":false,"caption":_vm.$t('treatment.treatmentPanel.product')}}),_vm._v(" "),_c('DxColumn',{attrs:{"cell-template":"statusColumnTemplate","fixed-position":"right","fixed":true,"min-width":100,"caption":_vm.$t('treatment.treatmentPanel.status'),"alignment":"center","allow-sorting":false}}),_vm._v(" "),_c('DxColumn',{attrs:{"cell-template":"buttonsColumnTemplate","alignment":"center","fixed":_vm.gridSettings.fixed,"fixed-position":_vm.gridSettings.fixedPosition}})],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }