<template>
  <f7-block class="margin-top">
    <div
      id="chart-demo"
      class="content-devx"
    >
      <DxButton
        class="margin-half"
        icon="redo"
        @click="resetGraph"
      />
      <DxChart
        id="chart"
        :data-source="getDataDate"
        palette="Material"
        @legend-click="onLegendClick"
      >
        <DxCommonSeriesSettings
          type="line"
          argument-field="date"
        />
        <template v-for="data in dataWeather">
          <DxSeries
            v-for="d in data"
            :key="d.value"
            :value-field="d.value"
            :name="d.name"
          />
        </template>
        <DxArgumentAxis
          discrete-axis-division-mode="crossLabels"
          argument-type="datetime"
        >
          <DxLabel
            display-mode="stagger"
            format="MMM yy"
            :customize-text="formatLabel"
          />
        </DxArgumentAxis>
        <DxLegend
          vertical-alignment="bottom"
          horizontal-alignment="center"
          item-text-position="bottom"
        />
        <DxExport :enabled="false" />
        <DxTitle text="">
          <DxSubtitle text="" />
        </DxTitle>
        <DxTooltip
          :enabled="true"
          :z-index="999999"
          :customize-tooltip="customizeTooltip"
        />
        <DxScrollBar :visible="true" />
        <DxZoomAndPan argument-axis="both" />
      </DxChart>
    </div>
  </f7-block>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxExport,
  DxLegend,
  DxTitle,
  DxSubtitle,
  DxTooltip,
  DxLabel,
  DxArgumentAxis,
  DxZoomAndPan,
  DxScrollBar,
} from 'devextreme-vue/chart';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';

export default {
  name: 'GraphicWeather',
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxExport,
    DxLegend,
    DxTitle,
    DxSubtitle,
    DxTooltip,
    DxArgumentAxis,
    DxLabel,
    DxButton,
    DxZoomAndPan,
    DxScrollBar,
  },
  props: {
    date: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('weather', ['dataWeather', 'parameterSelected', 'dataDate']),
    getDataDate() {
      return this.date;
    },
  },
  methods: {
    onLegendClick({ target: series }) {
      if (series.isVisible()) {
        series.hide();
      } else {
        series.show();
      }
    },
    formatLabel(label) {
      const splitted = label.value ? label.value.toString().split(' ') : label.toString().split(' ');

      const day = splitted[2];
      const month = splitted[1];
      const year = splitted[3];
      const hour = splitted[4].slice(0, -3) ?? '';

      return `${day} ${this.getMonthSpanish(month)} ${year} ${hour}`;
    },
    getMonthSpanish(month) {
      const monthsES = new Map();
      monthsES.set('Jan', 'Ene');
      monthsES.set('Feb', 'Feb');
      monthsES.set('Mar', 'Mar');
      monthsES.set('Apr', 'Abr');
      monthsES.set('May', 'May');
      monthsES.set('Jun', 'Jun');
      monthsES.set('Jul', 'Jul');
      monthsES.set('Aug', 'Ago');
      monthsES.set('Sep', 'Sep');
      monthsES.set('Oct', 'Oct');
      monthsES.set('Nov', 'Nov');
      monthsES.set('Dec', 'Dic');

      return monthsES.get(month);
    },
    resetGraph() {
      this.initStoreGraph();
    },
    customizeTooltip(pointInfo) {
      return {
        html: `<div><div class='tooltip-header'>${
          pointInfo.seriesName.split('-')[1]
        }</div><div class='tooltip-body'>
          <span class='bold'>${this.$t('weather_parameter')}: </span>${pointInfo.seriesName.split('-')[0]}
          </span> </div>
          <span class="bold"> ${this.$t('weather_parameter_value')}:</span> ${parseFloat(pointInfo.valueText).toFixed(2)} ${this.getUnitByParameter(pointInfo.seriesName.split('-')[0])}
        </span>
        <br>
        <span class="bold"> ${this.$t('date')}: </span>${this.formatLabel(pointInfo.argumentText)}
          </span> 
         </div></div></div>`,
      };
    },
    getUnitByParameter(parameter) {
      let unit = '';
      switch (parameter) {
        case 'TEMPERATURA':
          unit = '°C';
          break;
        case 'TEMPERATURA MÍNIMA':
          unit = '°C';
          break;
        case 'TEMPERATURA MÁXIMA':
          unit = '°C';
          break;
        case 'PLUVIOMETRÍA':
          unit = 'mm';
          break;
        case 'HUMEDAD MEDIA':
          unit = '%';
          break;
        case 'HUMEDAD MÁXIMA':
          unit = '%';
          break;
        case 'HUMEDAD MÍNIMA':
          unit = '%';
          break;
        case 'VELOCIDAD DEL VIENTO':
          unit = 'm/s';
          break;
        case 'VELOCIDAD DEL VIENTO MEDIA':
          unit = 'm/s';
          break;
        case 'VELOCIDAD DEL VIENTO MÁXIMA':
          unit = 'm/s';
          break;
        default:
          break;
      }
      return unit;
    },
    exportChart() {
      this.$refs.chart.instance.exportTo('Analysis/Climate', 'png');
    },
    ...mapActions('weather', ['initStoreGraph']),
  },
};
</script>
<style>
.bold {
  font-weight: bold;
}
.tooltip-header {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 500;
    padding-bottom: 5px;
    border-bottom: 1px solid #c5c5c5;
}

.tooltip-body {
    width: 170px;
}

.tooltip-body .series-name {
    font-weight: normal;
    opacity: 0.6;
    display: inline-block;
    line-height: 1.5;
    padding-right: 10px;
    width: 126px;
}

.tooltip-body .value-text {
    display: inline-block;
    line-height: 1.5;
    width: 30px;
}
</style>
