<template>
  <span class="icon-container">
    <svg
      v-if="name === 'menu'"
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0H20V2.5H0V0ZM0 6.25H20V8.75H0V6.25ZM0 12.5H20V15H0V12.5Z"
        fill="#23465E"
      />
    </svg>

    <!-- <svg
      v-if="name === 'download'"
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.333496 2.33464C0.333496 1.89261 0.509091 1.46868 0.821651 1.15612C1.13421 0.843563 1.55814 0.667969 2.00016 0.667969H8.66683C8.88782 0.668016 9.09975 0.755844 9.25599 0.912135L13.4227 5.0788C13.579 5.23505 13.6668 5.44697 13.6668 5.66797V15.668C13.6668 16.11 13.4912 16.5339 13.1787 16.8465C12.8661 17.159 12.4422 17.3346 12.0002 17.3346H2.00016C1.55814 17.3346 1.13421 17.159 0.821651 16.8465C0.509091 16.5339 0.333496 16.11 0.333496 15.668V2.33464ZM11.6552 5.66797L8.66683 2.67964V5.66797H11.6552ZM7.00016 2.33464H2.00016V15.668H12.0002V7.33464H7.8335C7.61248 7.33464 7.40052 7.24684 7.24424 7.09056C7.08796 6.93428 7.00016 6.72232 7.00016 6.5013V2.33464ZM7.00016 8.58464C7.22118 8.58464 7.43314 8.67243 7.58942 8.82871C7.7457 8.98499 7.8335 9.19695 7.8335 9.41797V11.573L8.07766 11.3288C8.23483 11.177 8.44533 11.093 8.66383 11.0949C8.88233 11.0968 9.09134 11.1844 9.24584 11.339C9.40035 11.4935 9.48799 11.7025 9.48989 11.921C9.49179 12.1395 9.40779 12.35 9.25599 12.5071L7.58933 14.1738C7.43306 14.33 7.22113 14.4178 7.00016 14.4178C6.77919 14.4178 6.56727 14.33 6.411 14.1738L4.74433 12.5071C4.66474 12.4303 4.60125 12.3383 4.55758 12.2366C4.5139 12.135 4.49092 12.0256 4.48995 11.915C4.48899 11.8043 4.51008 11.6946 4.55198 11.5922C4.59388 11.4898 4.65576 11.3967 4.734 11.3185C4.81224 11.2402 4.90529 11.1784 5.0077 11.1365C5.11011 11.0946 5.21985 11.0735 5.3305 11.0744C5.44115 11.0754 5.5505 11.0984 5.65217 11.1421C5.75384 11.1857 5.84579 11.2492 5.92266 11.3288L6.16683 11.573V9.41797C6.16683 9.19695 6.25463 8.98499 6.41091 8.82871C6.56719 8.67243 6.77915 8.58464 7.00016 8.58464Z"
        fill="black"
      />
    </svg> -->
    <svg
      v-if="name === 'download'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-file-download"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
      <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
      <path d="M12 17v-6" />
      <path d="M9.5 14.5l2.5 2.5l2.5 -2.5" />
    </svg>

    <!-- <svg
      v-if="name === 'edit'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3758 3.14844H3.15746C2.76203 3.14844 2.3828 3.30551 2.10319 3.5851C1.82359 3.8647 1.6665 4.24391 1.6665 4.63931V15.0754C1.6665 15.4709 1.82359 15.8501 2.10319 16.1297C2.3828 16.4093 2.76203 16.5663 3.15746 16.5663H13.5941C13.9896 16.5663 14.3688 16.4093 14.6484 16.1297C14.928 15.8501 15.0851 15.4709 15.0851 15.0754V9.85738"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.9672 2.02956C14.2637 1.73301 14.666 1.56641 15.0854 1.56641C15.5048 1.56641 15.907 1.73301 16.2036 2.02956C16.5002 2.32612 16.6668 2.72833 16.6668 3.14772C16.6668 3.56711 16.5002 3.96932 16.2036 4.26588L9.12156 11.3475L6.13965 12.093L6.88513 9.11122L13.9672 2.02956Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg> -->
    <svg
      v-if="name === 'edit'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-edit"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
      <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
      <path d="M16 5l3 3" />
    </svg>

    <svg
      v-if="name === 'delete'"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.53125 1.35H4.375C4.46094 1.35 4.53125 1.2825 4.53125 1.2V1.35H10.4688V1.2C10.4688 1.2825 10.5391 1.35 10.625 1.35H10.4688V2.7H11.875V1.2C11.875 0.538125 11.3145 0 10.625 0H4.375C3.68555 0 3.125 0.538125 3.125 1.2V2.7H4.53125V1.35ZM14.375 2.7H0.625C0.279297 2.7 0 2.96813 0 3.3V3.9C0 3.9825 0.0703125 4.05 0.15625 4.05H1.33594L1.81836 13.8563C1.84961 14.4956 2.40039 15 3.06641 15H11.9336C12.6016 15 13.1504 14.4975 13.1816 13.8563L13.6641 4.05H14.8438C14.9297 4.05 15 3.9825 15 3.9V3.3C15 2.96813 14.7207 2.7 14.375 2.7ZM11.7832 13.65H3.2168L2.74414 4.05H12.2559L11.7832 13.65Z"
        fill="black"
      />
    </svg>

    <!-- <svg
      v-if="name === 'copy'"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5558 2.22213H11.9724C11.8207 1.80553 11.5444 1.44569 11.1812 1.19143C10.8179 0.937162 10.3853 0.800781 9.94188 0.800781C9.49849 0.800781 9.06583 0.937162 8.70259 1.19143C8.33935 1.44569 8.0631 1.80553 7.91133 2.22213H7.44466C7.09149 2.22359 6.7532 2.36454 6.50347 2.61427C6.25374 2.864 6.11279 3.20229 6.11133 3.55547V5.55547H13.8891V3.55547C13.8876 3.20229 13.7467 2.864 13.497 2.61427C13.2472 2.36454 12.9089 2.22359 12.5558 2.22213V2.22213ZM12.778 4.44435H7.22244V3.47213C7.22244 3.4353 7.23707 3.39997 7.26312 3.37392C7.28917 3.34788 7.32449 3.33324 7.36133 3.33324H8.85577L8.92244 2.71658C8.93426 2.61687 8.96774 2.52096 9.02052 2.43555C9.0733 2.35014 9.14411 2.2773 9.22799 2.22213C9.3771 2.11098 9.55171 2.03903 9.73583 2.01288C9.91996 1.98673 10.1077 2.00721 10.2819 2.07245C10.456 2.13769 10.611 2.2456 10.7326 2.38628C10.8542 2.52697 10.9386 2.69593 10.978 2.87769L11.028 3.34435H12.6391C12.6759 3.34435 12.7113 3.35899 12.7373 3.38503C12.7634 3.41108 12.778 3.44641 12.778 3.48324V4.44435Z"
        fill="black"
      />
      <path
        d="M18.4722 10.0323H11.8499L13.4277 8.46009C13.4795 8.40829 13.5206 8.34679 13.5486 8.27911C13.5767 8.21143 13.5911 8.1389 13.5911 8.06564C13.5911 7.99239 13.5767 7.91985 13.5486 7.85217C13.5206 7.78449 13.4795 7.723 13.4277 7.6712C13.3759 7.6194 13.3144 7.57831 13.2468 7.55027C13.1791 7.52224 13.1065 7.50781 13.0333 7.50781C12.96 7.50781 12.8875 7.52224 12.8198 7.55027C12.7521 7.57831 12.6906 7.6194 12.6388 7.6712L9.72217 10.5879L12.6388 13.5045C12.6907 13.556 12.7523 13.5968 12.82 13.6244C12.8877 13.6521 12.9602 13.6661 13.0333 13.6656C13.1425 13.6652 13.2491 13.6326 13.3399 13.5718C13.4307 13.5111 13.5015 13.425 13.5436 13.3242C13.5857 13.2234 13.5972 13.1125 13.5766 13.0052C13.5559 12.898 13.5042 12.7992 13.4277 12.7212L11.8499 11.1434H18.4722C18.6195 11.1434 18.7608 11.0849 18.865 10.9807C18.9692 10.8765 19.0277 10.7352 19.0277 10.5879C19.0277 10.4405 18.9692 10.2992 18.865 10.195C18.7608 10.0908 18.6195 10.0323 18.4722 10.0323Z"
        fill="black"
      />
      <path
        d="M16.1111 8.88846H17.2222V3.71068C17.2237 3.5891 17.2011 3.46844 17.1557 3.35563C17.1104 3.24282 17.0432 3.14008 16.958 3.05334C16.8728 2.9666 16.7713 2.89756 16.6593 2.85019C16.5474 2.80282 16.4271 2.77807 16.3055 2.77734H15.0444V3.88845H16.1111V8.88846Z"
        fill="black"
      />
      <path
        d="M16.1112 17.2218H3.88901V3.88845H5.00012V2.77734H3.68901C3.44539 2.78027 3.21282 2.87949 3.04211 3.05333C2.8714 3.22716 2.77641 3.46149 2.7779 3.70512V17.3996C2.77643 17.5211 2.79902 17.6418 2.84436 17.7546C2.8897 17.8674 2.9569 17.9702 3.0421 18.0569C3.12729 18.1436 3.2288 18.2127 3.34077 18.2601C3.45275 18.3074 3.57299 18.3322 3.69457 18.3329H16.3112C16.5549 18.33 16.7874 18.2308 16.9581 18.0569C17.1288 17.8831 17.2238 17.6488 17.2223 17.4051V12.2551H16.1112V17.2218Z"
        fill="black"
      />
    </svg> -->
    <svg
      v-if="name === 'copy'"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
      />
    </svg>

    <svg
      v-if="name === 'map'"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.82 11.83C11.1258 11.83 10.4472 11.6241 9.86995 11.2385C9.29273 10.8528 8.84285 10.3046 8.57718 9.66322C8.31152 9.02185 8.24201 8.31611 8.37744 7.63523C8.51288 6.95436 8.84717 6.32894 9.33806 5.83805C9.82894 5.34717 10.4544 5.01288 11.1352 4.87744C11.8161 4.74201 12.5218 4.81152 13.1632 5.07718C13.8046 5.34285 14.3528 5.79273 14.7385 6.36995C15.1241 6.94716 15.33 7.62579 15.33 8.32C15.3274 9.2501 14.9567 10.1413 14.299 10.799C13.6413 11.4567 12.7501 11.8274 11.82 11.83ZM11.82 6.31C11.4244 6.31 11.0378 6.4273 10.7089 6.64706C10.38 6.86682 10.1236 7.17918 9.97224 7.54463C9.82087 7.91008 9.78126 8.31222 9.85843 8.70018C9.9356 9.08814 10.1261 9.44451 10.4058 9.72421C10.6855 10.0039 11.0419 10.1944 11.4298 10.2716C11.8178 10.3487 12.2199 10.3091 12.5854 10.1578C12.9508 10.0064 13.2632 9.75004 13.4829 9.42114C13.7027 9.09224 13.82 8.70556 13.82 8.31C13.82 7.77957 13.6093 7.27086 13.2342 6.89579C12.8591 6.52071 12.3504 6.31 11.82 6.31Z"
        fill="black"
      />
      <path
        d="M17.46 10.19H17.35C17.2524 10.1766 17.1584 10.1438 17.0736 10.0936C16.9888 10.0433 16.9149 9.97669 16.8562 9.89751C16.7975 9.81833 16.7552 9.72823 16.7319 9.63249C16.7085 9.53675 16.7044 9.43731 16.72 9.33999V9.18999V8.94999C16.7288 8.8602 16.7288 8.76977 16.72 8.67999C16.72 8.40999 16.72 8.26999 16.72 8.26999C16.6574 7.02836 16.1184 5.8587 15.2153 5.00438C14.3121 4.15006 13.1143 3.6769 11.8711 3.68334C10.6279 3.68978 9.43509 4.17533 8.54083 5.03896C7.64657 5.90259 7.11975 7.07778 7.07 8.31999C7.07 8.31999 7.07 8.44999 7.07 8.63999C7.0622 8.72982 7.0622 8.82016 7.07 8.90999C7.07 9.01999 7.07 9.09999 7.07 9.18999V9.33999C7.08907 9.53087 7.03428 9.72179 6.91689 9.87351C6.7995 10.0252 6.62844 10.1262 6.43889 10.1557C6.24933 10.1851 6.0557 10.1408 5.89781 10.0319C5.73991 9.92295 5.62976 9.75765 5.59 9.56999V9.20999C5.57181 9.03379 5.57181 8.85619 5.59 8.67999V8.38999C5.58606 7.56003 5.74563 6.73743 6.0596 5.96915C6.37357 5.20086 6.83579 4.50194 7.41987 3.91229C8.00395 3.32263 8.69845 2.8538 9.46372 2.53255C10.229 2.2113 11.05 2.04393 11.88 2.03999C12.71 2.03605 13.5326 2.19562 14.3008 2.50959C15.0691 2.82356 15.7681 3.28578 16.3577 3.86986C16.9474 4.45394 17.4162 5.14844 17.7374 5.91371C18.0587 6.67898 18.2261 7.50003 18.23 8.32999C18.23 8.40999 18.23 8.51999 18.23 8.70999C18.2293 8.89161 18.2092 9.07264 18.17 9.24999V9.56999C18.1404 9.73894 18.0538 9.89264 17.9246 10.0055C17.7954 10.1183 17.6314 10.1834 17.46 10.19Z"
        fill="black"
      />
      <path
        d="M12 22C11.9015 22.0005 11.8038 21.9812 11.7128 21.9435C11.6218 21.9057 11.5392 21.8501 11.47 21.78C6.1 16.41 5.7 8.67999 5.68 8.34999C5.67451 8.15174 5.74674 7.95919 5.88126 7.81346C6.01578 7.66773 6.20194 7.58035 6.4 7.56999C6.49851 7.56597 6.59685 7.58141 6.68939 7.61544C6.78192 7.64946 6.86684 7.70139 6.93929 7.76826C7.01173 7.83514 7.07028 7.91564 7.11159 8.00516C7.15289 8.09468 7.17614 8.19147 7.18 8.28999C7.18 8.35999 7.58 15.77 12.53 20.72C12.6705 20.8606 12.7493 21.0512 12.7493 21.25C12.7493 21.4487 12.6705 21.6394 12.53 21.78C12.4608 21.8501 12.3782 21.9057 12.2872 21.9435C12.1962 21.9812 12.0985 22.0005 12 22Z"
        fill="black"
      />
      <path
        d="M12 22C11.9015 22.0005 11.8038 21.9812 11.7128 21.9435C11.6218 21.9057 11.5392 21.8501 11.47 21.78C11.3296 21.6394 11.2507 21.4487 11.2507 21.25C11.2507 21.0512 11.3296 20.8606 11.47 20.72C16.47 15.72 16.81 8.35999 16.82 8.28999C16.8239 8.19147 16.8471 8.09468 16.8884 8.00516C16.9297 7.91564 16.9883 7.83514 17.0607 7.76826C17.1332 7.70139 17.2181 7.64946 17.3106 7.61544C17.4032 7.58141 17.5015 7.56597 17.6 7.56999C17.7981 7.58035 17.9842 7.66773 18.1187 7.81346C18.2533 7.95919 18.3255 8.15174 18.32 8.34999C18.32 8.67999 17.9 16.41 12.53 21.78C12.4608 21.8501 12.3782 21.9057 12.2872 21.9435C12.1962 21.9812 12.0985 22.0005 12 22Z"
        fill="black"
      />
    </svg>

    <!-- <svg
      v-if="name === 'share'"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 12C3 13.654 4.346 15 6 15C6.794 15 7.512 14.685 8.049 14.18L14.04 17.604C14.022 17.734 14 17.864 14 18C14 19.654 15.346 21 17 21C18.654 21 20 19.654 20 18C20 16.346 18.654 15 17 15C16.206 15 15.488 15.315 14.951 15.82L8.96 12.397C8.978 12.266 9 12.136 9 12C9 11.864 8.978 11.734 8.96 11.603L14.951 8.18C15.488 8.685 16.206 9 17 9C18.654 9 20 7.654 20 6C20 4.346 18.654 3 17 3C15.346 3 14 4.346 14 6C14 6.136 14.022 6.266 14.04 6.397L8.049 9.82C7.496 9.29468 6.76273 9.00123 6 9C4.346 9 3 10.346 3 12Z"
        fill="black"
      />
    </svg> -->
    <svg
      v-if="name === 'share'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-share"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <circle
        cx="6"
        cy="12"
        r="3"
      />
      <circle
        cx="18"
        cy="6"
        r="3"
      />
      <circle
        cx="18"
        cy="18"
        r="3"
      />
      <line
        x1="8.7"
        y1="10.7"
        x2="15.3"
        y2="7.3"
      />
      <line
        x1="8.7"
        y1="13.3"
        x2="15.3"
        y2="16.7"
      />
    </svg>

    <svg
      v-if="name === 'save'"
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-device-floppy"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        stroke="none"
        d="M0 0h24v24H0z"
        fill="none"
      />
      <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" />
      <circle
        cx="12"
        cy="14"
        r="2"
      />
      <polyline points="14 4 14 8 8 8 8 4" />
    </svg>

  </span>
</template>

<script>
export default {
  name: 'ContextMenuIcons',
  props: {
    name: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import './contextMenu.styles.scss';
</style>
