<template>
  <f7-page @page:beforein="beforein">
    <navbar :text="`${$t('Plans.title')}`" />
    <f7-toolbar
      tabbar
      top
      class="no-shadow"
    >
      <f7-link
        tab-link="#Plans_Fertilizations"
        tab-link-active
        @click="toShowFertilizationsTab()"
      >
        {{ $t('Plans.fertilizations') }}
      </f7-link>
      <f7-link
        tab-link="#Plans_Treatments"
        @click="toShowTreatmentsTab()"
      >
        {{ $t('Plans.treatments') }}
      </f7-link>
    </f7-toolbar>
    <f7-tabs
      animated
    >
      <f7-tab
        id="Plans_Fertilizations"
        class="page-content block no-padding"
        tab-active
      >
        <Plans
          v-if="!flagToShowTabs"
          :custom-store="customStore"
          :plans="plans"
          current-type-plan="fertilization"
        />
      </f7-tab>
      <f7-tab
        id="Plans_Treatments"
        class="page-content block no-padding"
      >
        <Plans
          v-if="flagToShowTabs"
          :custom-store="customStore"
          :plans="plans"
          current-type-plan="treatment"
        />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navbar from '../../../components/NavBar.vue';
import Plans from '../../../components/utilities/plans/index.vue';

export default {
  name: 'PlansPage',
  components: {
    navbar,
    Plans,
  },
  data() {
    return {
      flagToShowTabs: false,
      customStore: null
    };
  },
  computed: {
    ...mapState('Plans', ['plans']),
  },
  async beforeMount() {
    this.customStore = await this.getStore({ type: 'fertilizations' });
  },
  methods: {
    beforein() {
      this.$fa.setScreenName('Análisis > Planes');
    },
    async toShowFertilizationsTab() {
      this.flagToShowTabs = false;
      this.customStore = await this.getStore({ type: 'fertilizations' });
    },
    async toShowTreatmentsTab() {
      this.flagToShowTabs = true;
      this.customStore = await this.getStore({ type: 'treatments' });
    },
    ...mapActions('Plans', ['fetchPlans']),
    ...mapActions('Pagination', ['getStore']),
  },
};
</script>
