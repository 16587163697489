<template>
  <f7-page class="main-viewnutritionreport">
    <navbar :text="`${$t('Searcher.nutrition.title')} > ${$t('nutritionDetail.report.report')}`" />
    <div class="content-viewnutritionreport">
      <div
        id="report-header-image"
        class="content-viewnutritionreport-header"
      >
        <div class="content-viewnutritionreport-header__logo">
          <img
            v-if="loaded"
            :src="reportHeaderImage"
            class="nutrition-report-logo"
            alt=""
          >
        </div>
        <div class="content-viewnutritionreport-header__info">
          <div class="content-viewnutritionreport-header__info--text">
            Polígono Arazuri-Orcoyen, Calle C, nº 32<br>
            31160 ORCOYEN Navarra - España<br>
            Teléfono 948 324 500 - Fax 948 324 032<br>
            timacagro@timacagro.es &#8226; www.timacagro.es<br>
            FÁBRICA LODOSA<br>
            Teléfono 948 662 308 - Fax 948 693 657
          </div>
          <img
            v-if="loaded"
            :src="reportHeaderImageTwo"
            style="width:100%;"
            alt=""
          >
        </div>
      </div>
      <HeaderReportInfo
        v-if="loaded"
        :explotation-data="actualPlantation"
        :data="fertilization"
      />
      <ExplotationData
        v-if="loaded"
        :explotation-data="actualPlantation"
      />
      <ElementResumeComponent
        v-if="loaded"
        :fertilization="fertilization"
      />
      <ProductResumeComponent
        v-if="loaded"
        :plantationData="actualPlantation"
        :fertilization="fertilization"
      />
      <f7-row>
        <f7-col class="go-back-button margin-left-half margin-bottom">
          <f7-button
            v-if="loaded"
            class="dx-btn-cancel no-margin padding-horizontal"
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goToBack()"
          >
            {{ $t('Button_go_to_back') }}
          </f7-button>
        </f7-col>
        <f7-col>
          <f7-button
            v-if="loaded"
            class="dx-btn-success"
            raised
            type="success"
            styling-mode="contained"
            @click="downloadReport"
          >
            {{ $t("nutritionDetail.report.download") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import fertilizationService from '../../services/fertilizationService';
import navbar from '../../components/NavBar.vue';
import HeaderReportInfo from '../../components/headerReportInfo/index.vue';
import ExplotationData from '../../components/nutritionDetail/explotationData/index.vue';
import ElementResumeComponent from '../../components/nutritionDetail/elementResume/index.vue';
import ProductResumeComponent from '../../components/nutritionDetail/productResume/index.vue';
import reportHeaderImage from '../../static/img/TIMAC-Agro.png';
import reportHeaderImageTwo from '../../static/img/reportHeaderTwo.png';

import PDFactory from '../../js/pdf-factory';

import NutritionDataFormatter from './report-formatter/nutrition-data-formatter';
import ProductDataFormatter from './report-formatter/product-data-formatter';

export default {
  name: 'ViewNutritionReport',
  components: {
    navbar,
    HeaderReportInfo,
    ExplotationData,
    ElementResumeComponent,
    ProductResumeComponent,
  },
  data() {
    return {
      loaded: false,
      fertilization: {},
      reportHeaderImage,
      reportHeaderImageTwo,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('fertilizationStore', ['currentFertilizationId']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.getPlantationById({ companyId: this.currentPlantation.companyId, plantationId: this.actualPlantation.id });
      await fertilizationService.getById(this.currentFertilizationId, this.currentPlantation.companyId).then((response) => {
        this.fertilization = response;
      });
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${error}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.$f7.preloader.hide();
      this.loaded = true;
    }
  },
  methods: {
    goToBack() {
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-detail',
        params: { id: this.currentFertilizationId },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },

    async downloadReport() {
      const pdfactory = new PDFactory();

      // ----------------------------------------------
      // Logo
      const headerImg = document.getElementById('report-header-image');
      await pdfactory.insertImageFromHTMLElementAsync(headerImg, 500);

      pdfactory.insertHeader(this.$t('nutritionDetail.report.report'), 'h1');

      // -------------------------------------------------
      // Nutrition plan
      pdfactory.insertHeader(this.$t('nutritionDetail.report.nutritionPlan'), 'h2');

      const initDate = this.$helpers.dateFormatting(this.actualPlantation.init_date);
      const endDate = this.$helpers.dateFormatting(this.actualPlantation.end_date);
      pdfactory.insertTable(
        [
          this.$t('nutritionDetail.report.client'),
          this.$t('nutritionDetail.report.date'),
          this.$t('nutritionDetail.report.description'),
        ],
        [
          [
            this.fertilization.company_name,
            `${initDate} - ${endDate}`,
            this.fertilization.description,
          ],
        ],
      );

      // -------------------------------------------------
      // Explotation data
      pdfactory.insertHeader(this.$t('nutritionDetail.report.explotationData'), 'h2');

      pdfactory.insertVerticalTable(
        [
          this.$t('nutritionDetail.report.campaign'),
          this.$t('nutritionDetail.report.cropType'),
          this.$t('nutritionDetail.report.area'),
          this.$t('nutritionDetail.report.explotation'),
          this.$t('nutritionDetail.report.specie'),
          this.$t('nutritionDetail.report.groundTexture'),
          this.$t('nutritionDetail.report.parcel'),
          this.$t('nutritionDetail.report.variety'),
          this.$t('nutritionDetail.report.irrigationType'),
          this.$t('nutritionDetail.report.sector'),
          this.$t('nutritionDetail.report.plantsDensity'),
          this.$t('nutritionDetail.report.flow'),
          this.$t('nutritionDetail.report.plantation'),
          this.$t('nutritionDetail.report.plantsNumber'),
          this.$t('nutritionDetail.report.dripperPerPlant'),
        ],
        [
          [
            this.actualPlantation.campaign,
            this.actualPlantation.crop_type,
            `${parseFloat(this.$helpers.getProperty(this.actualPlantation.farms[0].properties, 'area')).toFixed(2)} ha`,
            this.actualPlantation.explotation,
            this.actualPlantation.species,
            this.$helpers.getProperty(this.actualPlantation.farms[0].properties, 'ground_texture'),
            this.actualPlantation.parcel,
            this.actualPlantation.variety,
            this.$helpers.getProperty(this.actualPlantation.farms[0].properties, 'irrigation_type'),
            this.actualPlantation.farms[0].name,
            `${this.actualPlantation.planting_density}  ${this.$t('nutritionDetail.report.plantsHa')}`,
            `${this.$helpers.getProperty(this.actualPlantation.farms[0].properties, 'flow')} L/h`,
            this.actualPlantation.name,
            this.actualPlantation.plants,
            this.$helpers.getProperty(this.actualPlantation.farms[0].properties, 'dripper_per_plant'),
          ],
        ],
      );

      pdfactory.insertPageBreak();

      // -------------------------------------------------
      // Element Resume
      pdfactory.insertHeader(this.$t('nutritionDetail.elementResume.title'), 'h2');

      const ufsTotal = NutritionDataFormatter.getUfsTotal(this.fertilization);

      const needs = NutritionDataFormatter.invertRowDataSource(
        NutritionDataFormatter.convertDataSource(this.fertilization.nutritional_needs),
      );
      const fetilizationBalance = NutritionDataFormatter.diffRowElement(needs, ufsTotal);

      const ufContributed = NutritionDataFormatter.calculateUfContributed(this.fertilization);

      const finalBalance = {
        n: fetilizationBalance.n + ufContributed.n,
        p: fetilizationBalance.p + ufContributed.p,
        k: fetilizationBalance.k + ufContributed.k,
        c: fetilizationBalance.c + ufContributed.c,
        m: fetilizationBalance.m + ufContributed.m,
        s: fetilizationBalance.s + ufContributed.s,
      };

      pdfactory.insertTable(
        [
          this.$t('nutritionDetail.elementResume.uf'),
          this.$t('nutritionDetail.elementResume.n'),
          'P2 O5', // this.$t('nutritionDetail.elementResume.p'),
          'K2 O', // this.$t('nutritionDetail.elementResume.k'),
          this.$t('nutritionDetail.elementResume.c'),
          this.$t('nutritionDetail.elementResume.m'),
          'SO3', // this.$t('nutritionDetail.elementResume.s'),
        ],
        [
          [
            this.$t('nutritionDetail.elementResume.necNutritional'),
            needs.n,
            needs.p,
            needs.k,
            needs.c,
            needs.m,
            needs.s,
          ],
          [
            this.$t('nutritionDetail.elementResume.fertilizationBalance'),
            fetilizationBalance.n.toFixed(2),
            fetilizationBalance.p.toFixed(2),
            fetilizationBalance.k.toFixed(2),
            fetilizationBalance.c.toFixed(2),
            fetilizationBalance.m.toFixed(2),
            fetilizationBalance.s.toFixed(2),
          ],
          [
            this.$t('nutritionDetail.elementResume.ufContributed'),
            ufContributed.n.toFixed(2),
            ufContributed.p.toFixed(2),
            ufContributed.k.toFixed(2),
            ufContributed.c.toFixed(2),
            ufContributed.m.toFixed(2),
            ufContributed.s.toFixed(2),
          ],
          [
            this.$t('nutritionDetail.elementResume.finalBalance'),
            finalBalance.n.toFixed(2),
            finalBalance.p.toFixed(2),
            finalBalance.k.toFixed(2),
            finalBalance.c.toFixed(2),
            finalBalance.m.toFixed(2),
            finalBalance.s.toFixed(2),
          ],
        ],
      );

      const formatter = new ProductDataFormatter(this.fertilization);
      const productResume = await formatter.buildProductResumeData();

      const productRows = productResume.map((product) => [
        product.product,
        product.dosageUnit,
        product.dosagePlant,
        product.dosageTotal,
        product.price,
        product.costUnit,
        product.costTotal,
      ]);

      // -------------------------------------------------
      // Product Resume
      pdfactory.insertHeader(this.$t('nutritionDetail.productResume.title'), 'h2');

      pdfactory.insertTable(
        [
          this.$t('nutritionDetail.productResume.product'),
          this.$t('nutritionDetail.productResume.dosageUnit'),
          this.$t('nutritionDetail.productResume.dosagePlant'),
          this.$t('nutritionDetail.productResume.dosageTotal'),
          this.$t('nutritionDetail.productResume.price'),
          this.$t('nutritionDetail.productResume.costUnit'),
          this.$t('nutritionDetail.productResume.costTotal'),
        ],
        productRows,
      );

      // -------------------------------------------------
      // Graph
      pdfactory.insertHeader(this.$t('nutritionDetail.productResume.title'), 'h2');

      const graph = document.querySelector('.product-chart');
      await pdfactory.insertImageFromHTMLElementAsync(graph, 500);

      // pdfactory.buildAndOpen();
      const title = `${this.$t('Searcher.nutrition.title')}_${this.$t('nutritionDetail.report.report')}_${this.fertilization.description}`;
      if (Device.desktop) {
        pdfactory.buildAndDownload(title);
      } else {
        const pdfdata = pdfactory.buildForMobile();

        pdfdata.getBase64((base64) => {
          const path = `${cordova.file.externalRootDirectory}Download/`;
          const fileExtension = '.pdf';

          this.savebase64AsPDF(path, title + fileExtension, base64, 'application/pdf');
        });
      }
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter((fileWriter) => {
            fileWriter.write(DataBlob);
            this.$notifyDX(
              {
                message: this.$t('FileDownloadedInDownloads'),
                width: 550,
              },
              'info',
              3000,
            );
          }, () => {
            this.$notifyDX(
              {
                message: this.$t('SheetExport_sheet_export_error'),
                width: 550,
              },
              'info',
              3000,
            );
          });
        });
      });
    },
    ...mapActions('RegisterPlantation', ['getPlantationById']),
  },
};
</script>

<style lang="scss">

.report-header-text {
  overflow:hidden;
  max-height:180px;
}
.content-viewnutritionreport{
  width: 210mm;
  padding: 20px 60px;
  border: 1px solid #D2D2D2;
  background: #fff;
  margin: 10px auto;
  .content-viewnutritionreport-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .nutrition-report-logo{
      height: auto;
      width: 300px;
    }
    &__info{
      display: flex;
      align-items: center;
      &--text{
        width: 100%;
        padding-right: 30px;
        font-size: 10px;
        letter-spacing: -0.02em;
      }
      img{
        max-height:auto;
        max-width: 150px;
      }
    }
  }
  .go-back-button{
    margin: 0;
    padding: 0;
  }
}

</style>
