<template>
  <div
    class="content-devx"
  >
    <f7-row no-gap>
      <f7-col width="100">
        <DxForm
          id="mainData-form"
          :show-colon-after-label="true"
          :read-only="readOnly"
        >
          <DxGroupItem>
            <DxSimpleItem
            :visible="shouldShowItem('date')"
              name="date"
              :data-field="$t('Searcher.observations.date')"
              editor-type="dxDateBox"
              :editor-options="{
                onValueChanged: onChangeDate,
                width: '100%',
                value: currentValuesMainData.date,
                displayFormat: 'dd/MM/yyyy',
                invalidDateMessage: $t('dates_isInvalid'),
                onInitialized:init,
                pickerType: 'calendar',
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.dateValidation')" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem css-class="geolocation-container">
            <DxSimpleItem
              :visible="shouldShowItem('geolocation')"
              name="geolocation"
              :data-field="$t('Searcher.observations.geolocation')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: scoutingPoints,
                onValueChanged: onGeolocationChange,
                value: currentValuesMainData.idGeolocationPoint,
                displayExpr: 'name',
                valueExpr: 'id',
                width: '100%',
                searchEnabled: true,
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.geolocationValidation')" />
            </DxSimpleItem>
            <DxButtonItem
            v-if="!readOnly && shouldShowItem('geolocation')"

              css-class="button-container"
              :button-options="addGeolocationPoint"
            />
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :visible="shouldShowItem('category')"
              name="category"
              :data-field="$t('Searcher.observations.category')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: categoriesOfTemplates,
                onValueChanged: onCategoryChange,
                value: currentValuesMainData.category,
                searchEnabled: true,
                width: '100%',
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
              <DxRequiredRule :message="$t('Searcher.observations.categoryValidation')" />
            </DxSimpleItem>
            <DxSimpleItem
              :visible="shouldShowItem('subcategory')"
              name="subcategory"
              :data-field="$t('Searcher.observations.subcategory')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: templatesByCategory,
                onValueChanged: onSubcategoryChange,
                value: currentValuesMainData.subcategory,
                displayExpr: 'subcategory',
                valueExpr: 'id',
                searchEnabled: true,
                width: '100%',
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
            <DxRequiredRule :visible="!showLaboratoryData" :message="$t('Searcher.observations.subcategoryValidation')" />
            </DxSimpleItem>
          </DxGroupItem>
          <DxGroupItem>
            <DxSimpleItem
              :visible="shouldShowItem('laboratory')"
              name="laboratory"
              :data-field="$t('Searcher.observations.laboratory')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: laboratoriesOfTemplates,
                onValueChanged: onLaboratoryChange,
                value: currentValuesMainData.laboratory,
                displayExpr: 'name',
                valueExpr: 'id',
                searchEnabled: true,
                width: '100%',
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
            <DxRequiredRule :visible="showLaboratoryData" :message="$t('Searcher.observations.laboratoryValidation')" />
            </DxSimpleItem>
            <DxSimpleItem
              :visible="shouldShowItem('analysis_type')"
              name="analysis_type"
              :data-field="$t('Searcher.observations.analysis')"
              editor-type="dxSelectBox"
              :editor-options="{
                items: templatesByLaboratory,
                onValueChanged: onAnalysisTypeChange,
                value: currentValuesMainData.analysis_type,
                displayExpr: getAnalyseSubDisplayText,
                valueExpr: 'id',
                searchEnabled: true,
                width: '100%',
                searchTimeout: searchTimeoutOption,
                minSearchLength: minSearchLengthOption,
                showDataBeforeSearch: showDataBeforeSearchOption,
              }"
            >
              <DxRequiredRule :visible="showLaboratoryData" :message="$t('Searcher.observations.analysisValidation')" />
            </DxSimpleItem>
          </DxGroupItem>
        </DxForm>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import moment from 'moment';
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
  DxGroupItem,
  DxButtonItem,
} from 'devextreme-vue/form';
import { mapActions, mapState } from 'vuex';
import { Device } from 'framework7/framework7-lite.esm.bundle';

export default {
  name: 'MainData',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxGroupItem,
    DxButtonItem,
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: '',
    },
    plantationId: {
      type: String,
      default: '',
    },
    scoutingPoints: {
      type: Array,
      default: () => [],
    },
    categoriesOfTemplates: {
      type: Array,
      default: () => [],
    },
    laboratoriesOfTemplates: {
      type: Array,
      default: () => [],
    },
    templatesByCategory: {
      type: Array,
      default: () => [],
    },
    templatesByLaboratory: {
      type: Array,
      default: () => [],
    },
    currentValuesMainData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      addGeolocationPoint: {
        icon: 'add',
        type: 'default',
        onClick: () => {
          this.$f7.views.main.router.navigate('/createObservationPoint/', { reloadCurrent: true });
        },
      },
      isMobile: Device.cordova,
    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
    showLaboratoryData(){
      return this.currentValuesMainData.category === 'Muestreo';
    },
    // Funcionalidad para hacer visible el item dependiendo de la categoría seleccionada
    shouldShowItem() {
      return (itemName) => {
        const category = this.currentValuesMainData.category;
        
        if (!category) {
          // Si no hay categoría seleccionada, solo mostrar 'category', 'date'
          return itemName === 'category' || itemName === 'date';
        } else if (category === 'Recetario') {
          return itemName === 'date' || itemName === 'category';
        } else if (category === 'Muestreo') {
          if (this.showLaboratoryData) {
            return itemName === 'date' || itemName === 'category' || itemName === 'geolocation' ||
                itemName === 'laboratory' || itemName === 'analysis_type'
          }
          return itemName !== 'laboratory' && itemName !== 'analysis_type';
        } else if (['Incidencia', 'Otros', 'Producción y calidad'].includes(category)) {
          return itemName === 'date' || itemName === 'category' || 
                itemName === 'subcategory' || itemName === 'geolocation';
        }
        // Para las demás categorías, mostrar todos los items
        return true;
      };
    },
  },
  methods: {
    init(e) {
      e.component.option('calendarOptions',
        {
          cellTemplate: (args, itemIndex, itemElement) => {
            const actualDate = moment(args.date, 'YYYY-MM-DD');
            const initDate = moment(this.currentPlantation.initDate, 'YYYY-MM-DD');
            const endDate = moment(this.currentPlantation.endDate, 'YYYY-MM-DD');

            if (args.view === 'month') {
              if (actualDate.isBetween(initDate, endDate, 'days', '[]')) {
                return `<div style='color: #16a34a;'>${args.text}</div>`;
              }
            }

            if (args.view === 'year') {
              if (actualDate.isBetween(initDate, endDate, 'months', '[]')) {
                return `<div style='color: #16a34a;'>${args.text}</div>`;
              }
            }

            if (args.view === 'decade') {
              if (actualDate.isBetween(initDate, endDate, 'years', '[]')) {
                return `<div style='color: #16a34a;'>${args.text}</div>`;
              }
            }

            if (args.view === 'century') {
              const exactYear = parseInt(actualDate.year() / 10) * 10;
              const initDateIsBetween = initDate.year() >= exactYear && initDate.year() <= exactYear + 9;
              const endDateIsBetween = endDate.year() >= exactYear && endDate.year() <= exactYear + 9;

              if ((initDateIsBetween || endDateIsBetween)) {
                return `<div style='color: #16a34a;'>${args.text}</div>`;
              }
            }
            return args.text;
          },

        });
    },
    async updateCurrentScoutingPoint(scoutingPointId) {
      if (scoutingPointId && scoutingPointId !== '' && this.currentValuesMainData.category !== 'Recetario') {
        this.$f7.preloader.show();
        try {
          await this.fetchCurrentScoutingPoint({ companyId: this.companyId, scoutingPointId });
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          this.$f7.preloader.hide();
        }
      }
    },
    onChangeDate(e) {
      this.setDate(e.value);
      this.setConfirmedDate(false);
    },
    onGeolocationChange(e) {
      this.resetCurrentScoutingPoint();
      this.updateCurrentScoutingPoint(e.value);
      this.setGeolocation(e.value);
    },
    getAnalyseSubDisplayText(item) {
      if (item && item.sub_family_tm && item.description) {
        return `${item.sub_family_tm} - ${item.description}`;
      } else 
        return '';
    },
    // Sacar el valor de flux para controlar el value del switch (estado muestra)
    getFlux(laboratoryId) {
      const laboratory = this.laboratoriesOfTemplates.find(lab => lab.id === laboratoryId)
      this.setFlux(laboratory.flux)
    },
    async onLaboratoryChange(e) {
      if (e.event) {
        if (typeof e.value !== 'undefined' && e.value !== '') {
          try {
            this.setTemplateValue({});
            this.setLaboratory(e.value);
            this.setShowTemplateForm(false);
            this.setCurrentRoute(this.$f7route.path)
            await this.getTemplatesByLaboratory(e.value);
            await this.getFlux(e.value)
          } catch (error) {
            this.$f7.this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      }
    },
    async onCategoryChange(e) {
      if (e.event) {
        if (typeof e.value !== 'undefined' && e.value !== '') {
          try {
            this.setTemplateValue({});
            this.setCategory(e.value);
            this.setShowTemplateForm(false);
            this.setSubCategory('');
            this.setAnalysisType('');
            this.setLaboratory('');
            await this.getTemplatesByCategory(e.value);
            if (e.value === 'Recetario') {
              this.setTemplateId(this.templatesByCategory[0].id);
              // Borramos el geolocation
              this.setGeolocation('');
              await this.getTemplateById(this.templatesByCategory[0].id);
            }
          } catch (error) {
            this.$f7.this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      }
    },
    async onSubcategoryChange(e) {
      if (e.event) {
        if (typeof e.value !== 'undefined' && e.value !== '') {
          try {
            this.setTemplateValue({});
            this.setSubCategory(e.value);
            await this.getTemplateById(e.value);
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      }
    },
    async onAnalysisTypeChange(e) {
      if (e.event) {
        if (typeof e.value !== 'undefined' && e.value !== '') {
          try {
            this.setTemplateValue({});
            this.setAnalysisType(e.value);
            this.setTemplateId(this.templatesByCategory[0].id);
            await this.getTemplateById(this.templatesByCategory[0].id);
          } catch (error) {
            this.$notifyDX(
              {
                message: this.$t(`${error}`),
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      }
    },
    ...mapActions('SearcherObservations', ['resetCurrentScoutingPoint', 'setDate', 'setGeolocation', 'setCategory', 'setSubCategory', 'setLaboratory', 'setAnalysisType', 'setCurrentRoute', 'setTemplateId', 'getTemplatesByCategory','getTemplatesByLaboratory', 'getTemplateById', 'setFlux', 'setShowTemplateForm', 'fetchCurrentScoutingPoint', 'setTemplateValue', 'setConfirmedDate']),
  },
};
</script>

<style lang="scss" scoped>
@import './MainData.styles.scss';
</style>
