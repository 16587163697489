import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    registersResume: [],
  },
  actions: {
    setRegistersResume({ commit }, newRegistersResume) {
      commit('setRegistersResume', newRegistersResume);
    },
    async fetchRegistersResume({ commit }, { companyId, plantationId }) {
      try {
        const xhr = await Api.getRegistersResume(companyId, plantationId);
        commit('setRegistersResume', JSON.parse(xhr.response).data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
  mutations: {
    setRegistersResume(state, newRegistersResume) {
      state.registersResume = newRegistersResume;
    },
  },
};
