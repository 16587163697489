<template>
  <div>
    <DxPopup
      :visible="displayPopup"
      :drag-enabled="false"
      :close-on-outside-click="false"
      :show-title="true"
      :show-close-button="true"
      :width="600"
      height="auto"
      :title="$t('laboratoryAnalysis.viewDetail.title_view_deatils')"
      @hiding="updateDisplayPopup"
    >
      <f7-block class="no-padding">
        <div
          class="content-devx"
        >
          <f7-block>
            <f7-row>
              <f7-col width="20" />
              <f7-col width="70" />
              <f7-col
                width="10"
                class="justify-content-end"
              >
                <!--<f7-button
              class="dx-btn-pdf"
              raised
              type="default"
              styling-mode="outlined"
              :external="true"
              :target="!isMobile ? '_blank': '_system'"
              :href="productDetail.pdf"
            >
              <f7-icon
                md="material:picture_as_pdf"
              />
            </f7-button>-->
              </f7-col>
            </f7-row>
          </f7-block>
          <f7-block>
            <f7-row class="margin-bottom">
              <f7-col>
                {{ $t('laboratoryAnalysis.viewDetail.date') }}
              </f7-col>
              <f7-col>
                ID
              </f7-col>
              <f7-col>
                {{ $t('Searcher.observations.category') }}
              </f7-col>
              <f7-col>
                Sub{{ $t('Searcher.observations.category') }}
              </f7-col>
            </f7-row>
            <f7-row class="bold margin-bottom">
              <f7-col>
                {{ dataSource.date }}
              </f7-col>
              <f7-col>
                {{ dataSource.sample_id }}
              </f7-col>
              <f7-col>
                {{ dataSource.category }}
              </f7-col>
              <f7-col>
                {{ dataSource.subcategory }}
              </f7-col>
            </f7-row>
            <f7-row class="margin-bottom">
              <f7-col>
                {{ $t('searchEngine_description') }}
              </f7-col>
              <f7-col>
                {{ $t('laboratoryAnalysis.viewDetail.interpretation') }}
              </f7-col>
              <f7-col />
              <f7-col />
            </f7-row>
            <f7-row class="bold margin-bottom">
              <f7-col>
                {{ dataSource.description }}
              </f7-col>
              <f7-col>
                {{ dataSource.interpretation }}
              </f7-col>
              <f7-col />
              <f7-col />
            </f7-row>
          </f7-block>
        </div>

        <f7-block class="margin">
          <f7-row>
            <f7-col class="">
              <f7-button
                class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block"
                raised
                type="default"
                styling-mode="contained"
                @click="updateDisplayPopup()"
              >
                {{ $t('Go_Back') }}
              </f7-button>
            </f7-col>
            <f7-col class="justify-content-end">
              <f7-button
                class="dx-btn-cancel margin-right-block btn-width-150 margin-top-block margin-bottom-block standard-blue-btn"
                raised
                type="default"
                styling-mode="contained"
                @click="goDetail()"
              >
                {{ $t('Searcher.detail.title') }}
              </f7-button>
            </f7-col>
          </f7-row>
        </f7-block>
      </f7-block>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import { DxPopup } from 'devextreme-vue/popup';
import Api from '../../../../services/Api';

export default {
  name: 'AnalyticsDisplayPopup',
  components: {
    DxPopup,
  },
  props: {
    displayPopup: {
      type: Boolean,
      default: false,
    },
    dataSource: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
    };
  },
  computed: {

  },
  methods: {
    ...mapActions('Explotaciones', ['getCompanyById']),
    ...mapActions('Plantation', ['setCurrentPlantation', 'setOldPlantation', 'setSearcherPlantation']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('SearcherObservations', ['setIdCurrentObservation', 'setBackToAnalytics']),
    updateDisplayPopup() {
      this.$emit('updateDisplayPopup');
    },
    async goDetail() {
      try {
        const observationXhr = await Api.getObservationDetail(this.dataSource.company_id, this.dataSource.id);
        const observation = JSON.parse(observationXhr.response);

        const plantationXhr = await Api.getPlantationById(this.dataSource.company_id, observation.plantation);
        const plantation = JSON.parse(plantationXhr.response);

        const formattedPlantation = {
          ...plantation,
          company: plantation.company_name,
          companyId: this.dataSource.company_id,
          initDate: plantation.init_date,
          endDate: plantation.end_date,
          sector: plantation.sector_name,
        };

        await this.getPlantationById({
          companyId: this.dataSource.company_id,
          plantationId: plantation.id,
        });
        this.setCurrentPlantation(formattedPlantation);
        this.setSearcherPlantation(formattedPlantation);
        this.setOldPlantation(formattedPlantation);

        await this.getCompanyById(this.dataSource.company_id);

        this.setBackToAnalytics(true);

        this.setIdCurrentObservation(observation.id);
        this.$f7.views.main.router.navigate('/viewObservation/', { reloadCurrent: true });
      } catch (error) {
        this.$notifyDX(
          {
            message: error,
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .button-gray {
    background-color: var(--ha-gray)  !important;
    color: white !important;
  }
</style>
