<template>
  <div class="content-devx-margin-block">
    <div
      v-if="(treeLocations.length !== 0 && observations.length !== 0)"
      :id="`${nameMap}_${initialDate}`"
      :style="minHeight"
    />
    <div
      v-else
    >
      {{ $t('GISGeoJsonMap_not_observations') }}
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GISGeoJsonObservationMap',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '450px' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    observations: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      initialDate: Date.now(),
      statusMeasureTool: false,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {
    if (this.treeLocations.length !== 0 && this.observations.length !== 0) {
      this.setCurrentLayersMap([]);
      this.renderMap();
      this.addFuncionalities();
      this.addOverlayLayer();
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        for (const geoData of this.treeLocations) {
          for (const children of geoData.children) {
            if (typeof children.coordinates !== 'undefined' && children.coordinates !== null) {
              this.map.setView([children.coordinates.latitude, children.coordinates.longitude], this.zoom);
            }
            layer = L.geoJson(children.geo_feature).addTo(this.map);
            this.layersEvents(layer);
            for (const children2 of children.children) {
              layer2 = L.geoJson(children2.geo_feature).addTo(this.map);
              layer2.setStyle(layerStyles.active);
              this.layersEvents(layer2);
            }
          }
        }
        const markerLayer = L.layerGroup().addTo(this.map);

        let marker;
        const markers = this.observations;

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < markers.length; i++) {
          if (markers[i].coordinates.length > 0) {
            marker = L.marker([markers[i].coordinates[1], markers[i].coordinates[0]], {
              properties: {
                date: markers[i].date,
                category: markers[i].category,
                subcategory: markers[i].subcategory,
                name: markers[i].pointName,
              },
            });
            markerLayer.addLayer(marker);

            const blueIcon = new L.Icon({
              iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
              shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
            });
            marker.setIcon(blueIcon);
          }
        }
        this.layersEvents(markerLayer);
        if (this.center !== null) {
          this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
        } else {
          const bounds = layer.getBounds();
          this.map.fitBounds(bounds);
          this.centerMapGeoJsonLayer(layer);
        }
      } catch (error) {
        if (error.message === 'Bounds are not valid.') {
          this.$notifyDX(
            {
              message: this.$t('BoundsAreNotValid'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },

    /**
     * Centramos el mapa cuando tenemos un GeoJson
     */
    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
      this.map.flyToBounds(bounds);
    },

    layersEvents(target) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            // eslint-disable-next-line no-prototype-builtins
            if (e.target.hasOwnProperty('feature')) {
              self.openLayerDataPopup(e.target.feature.properties, e);
            } else {
              self.openLayerDataPopup(e.target.options.properties, e);
            }
          },
          dblclick(e) {
          },
          mouseover(e) {
          },
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        this.addHomeButton();
        this.addMeasureToolButton();
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(`${e}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
            `${controlName}-home`, container, this.zoomHome);

          return container;
        },

        zoomHome() {
          self.map.fitBounds(self.layerBounds);
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },

    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText:
              '<i class="fg-measure-area fa-2x"></i>',
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea,
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText:
              '<i class="fg-measure-line fa-2x"></i>',
          measureLineStringTitle: `${self.$t('Gis.Buttons.MeasureLineString')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString,
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t('Gis.Buttons.DesactiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure,
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:block';
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: '<i class="fg-measure fa-2x"></i>',
          activeMeasureTitle: `${self.$t('Gis.Buttons.ActiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure,
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);

          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:none';
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },
    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (e.shape === 'Polygon') {
            self.areaLayer = e.layer.addTo(self.map);
            const toGeoJson = self.areaLayer.toGeoJSON();
            const area = turf.area(toGeoJson);
            const html = `<div class='popup-content'><h3>${self.$t('Gis.Editor.PopupMeasureArea')}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${(
              area / 10000
            ).toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureHas')}</p></div>`;
            const popup = L.popup().setContent(html);
            self.areaLayerPopup = self.areaLayer.bindPopup(popup);
            self.areaLayer.openPopup();
            self.map.fitBounds(self.areaLayer.getBounds());
            self.map.pm.disableDraw();
          }
          if (e.shape === 'Line') {
            self.lineLayer = e.layer.addTo(self.map);
            // eslint-disable-next-line no-underscore-dangle
            if (self.lineLayer._latlngs.length > 2) {
              self.map.removeLayer(self.lineLayer);
            } else {
              const from = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lng,
              ]);
              const to = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lng,
              ]);
              const options = {
                units: 'kilometers',
              };
              const distance = turf.distance(from, to, options);
              const html = `<div class='popup-content'><h3>${self.$t('Gis.Editor.PopupMeasureLineString')}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${distance.toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureKm')}</p></div>`;
              const popup = L.popup().setContent(html);
              self.lineLayerPopup = self.lineLayer.bindPopup(popup);
              self.lineLayer.openPopup();
              self.map.fitBounds(self.lineLayer.getBounds());
            }
          }
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${e}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          self.$f7.preloader.hide();
        }
      });
      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },
    openLayerDataPopup(properties, e, extra = {}) {
      let htmlString = "<div class='popup-content'>";
      htmlString += extra.before || '';
      let listHtml = '<ul>';
      listHtml += this.getLayerDataPropertiesHtml(properties);
      listHtml += '</ul>';
      htmlString += `${listHtml}</div>`;
      htmlString += extra.after || '';
      this.openPopup(htmlString, e.latlng);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null || property === 'id' || property === 'plantation_id' || property === 'company_id') continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${this.$t(`${property}_popup`)}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openPopup(html, latlng) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer']),
  },
};
</script>
<style>
  @import './GISGeoJsonObservationMap.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
