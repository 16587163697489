import CustomStore from 'devextreme/data/custom_store';
import Api from '../../../services/Api';
import moment from 'moment';


// Este límite viene para llamadas de devextreme a la paginación del backend
// en tablas donde existe agrupación por alguna columna. En este contexto,
// devextreme necesita todos los elementos que sean grupo y no se puede paginar
// por lo que se establece un límite máximo muy elevado para que el backend siempre
// devuelva todos los elementos y así devextreme pueda agruparlos correctamente.
const MAX_LIMIT = 100000;

const endpoints = {
  companies: Api.getCompanies,
  observations: Api.getObservations,
  contactPlantation: Api.getContactPlantation,
  contactPlantationByDate: Api.getContactPlantationByDate,
  analysisTreatment: Api.getTreatmentDataForAnalisis,
  analysisFertilization: Api.fetchFertilizationsForAnalysis,
  configVarieties: Api.getVarietiesConfig,
  fertilizations: Api.fetchFertilizations,
  treatments: Api.fetchTreatments,
  analytics: Api.getAnalysisSamples,
};

const typesToFormat = [
  'analysisFertilization',
  'analysisTreatment',
  'fertilizations',
  'contactPlantation',
  'contactPlantationByDate',
];

const columnsNames = {
  analysisFertilization: {
    description: 'description',
    species: 'species',
    company_name: 'company_name',
    explotation: 'explotation_name',
    campaign: 'campaign_name',
    plantation: 'plantation_name',
  },
  analysisTreatment: {
    description: 'description',
    species: 'species',
    company_name: 'company_name',
    explotation: 'explotation_name',
    campaign: 'campaign_name',
    plantation: 'plantation_name',
  },
  fertilizations: {
    description: 'description',
    company_name: 'company_name',
    species: 'species',
    variety: 'variety',
    province: 'province',
    owner_fullname: 'owner_fullname',
  },
  contactPlantation: {
    company: 'company_name',
    company_name: 'company_name',
    name: 'name',
    province: 'province_name',
    province_name: 'province_name',
    explotation: 'explotation_name',
    explotation_name: 'explotation_name',
    parcel: 'parcel_name',
    parcel_name: 'parcel_name',
    sector: 'sector_name',
    sector_name: 'sector_name',
    campaign: 'campaign_name',
    campaign_name: 'campaign_name',
    species: 'species',
    variety: 'variety',
    status: 'status',
    crop_type: 'crop_type',
    ground_texture: 'ground_texture',
    irrigation_type: 'irrigation_type',
    management: 'management',
    protection: 'protection',
    initDate: 'init_date',
    endDate: 'end_date',
    owner_fullname: 'owner_fullname',
  },
  contactPlantationByDate: {
    company: 'company_name',
    name: 'name',
    province_name: 'province_name',
    explotation: 'explotation_name',
    parcel: 'parcel_name',
    sector: 'sector_name',
    campaign: 'campaign_name',
    species: 'species',
    variety: 'variety',
    status: 'status',
    crop_type: 'crop_type',
    ground_texture: 'ground_texture',
    irrigation_type: 'irrigation_type',
    management: 'management',
    protection: 'protection',
    initDate: 'init_date',
    endDate: 'end_date',
  },
};

const formatData = (type, data) => {
  if (data?.[0]?.key !== undefined) return data;

  if (type === 'contactPlantation' || type === 'contactPlantationByDate') {
    return data?.map((item) => ({
      ...item,
      companyId: item.company_id,
      initDate: item.init_date,
      endDate: item.end_date,
      sectorId: item.sector_id,
    })) ?? data;
  }

  if (type === 'observations') {
    return data.map((item) => {
      let coordinates = [];
      let pointName = '';

      if (item.scouting_point.type === 'POINT') {
        coordinates = [item.scouting_point.longitude, item.scouting_point.latitude];
        pointName = item.scouting_point.name;
      }

      return {
        ...item,
        date: moment(item.date).format('DD/MM/YYYY'),
        description: item.multimedia_description,
        coordinates,
        pointName,
      };
    });
  }
  return data;
};

const formatFilter = (type, filter) => {
  if (typesToFormat.includes(type)) {
    if (filter[0] === '!') {
      return [];
    }

    if (!Array.isArray(filter[0])) {
      filter[0] = columnsNames[type][filter[0]];
      return filter;
    }

    return filter.map((item) => {
      if (Array.isArray(item)) {
        const column = columnsNames[type][item[0]];
        if (column !== undefined) {
          item[0] = columnsNames[type][item[0]];
          return item;
        }

        return [];
      }
      return item;
    });
  }
  return filter;
};

const removeEmptyArrays = (data) => {
  const finalArray = [];
  let removeNext = false;

  for (let i = 0; i < data.length; i++) {
    if (removeNext) {
      removeNext = false;
      continue;
    }

    if ((Array.isArray(data[i]) && data[i].length === 0) || typeof data[i]?.[0] !== 'string') {
      removeNext = true;
    } else {
      finalArray.push(data[i]);
    }
  }

  if (finalArray[finalArray.length - 1] === 'or') {
    finalArray.pop();
  }

  return finalArray;
};

const getStoreKey = (type) => {
  if (type === 'analytics') {
    return 'sample_id';
  }

  return 'id';
};

export default {
  namespaced: true,
  actions: {
    async getSamples({ rootState }, { params, type, data }) {
      try {
        let xhr = null;
        await Api.renewAccessToken();
        if (data !== undefined) {
          xhr = await endpoints[type](params, data);
        } else {
          xhr = await endpoints[type](params);
        }

        const response = JSON.parse(xhr.response);
        const formattedData = formatData(type, response.results || response.data);

        return Promise.resolve({
          data: formattedData,
          totalCount: response.count,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    getStore({ dispatch }, { type, data }) {
      return new CustomStore({
        key: getStoreKey(type),
        load: async (loadOptions) => {
          let params = '?';

          let sortSettings = [];
          let groupSettings = [];

          ['skip', 'take', 'sort', 'group'].forEach((i) => {
            if (i in loadOptions) {
              if (i === 'sort' && loadOptions[i] !== null) {
                sortSettings = [{ ...loadOptions[i][0], selector: columnsNames[type]?.[loadOptions[i][0].selector] ?? loadOptions[i][0].selector }];
              } else if (i === 'group' && loadOptions[i] !== null) {
                groupSettings = [{ ...loadOptions[i][0], selector: columnsNames[type]?.[loadOptions[i][0].selector] ?? loadOptions[i][0].selector }];
                params += `limit=${MAX_LIMIT}&`;
              } else {
                params += `${i}=${JSON.stringify(loadOptions[i])}&`;
              }
            }
          });

          params = params.slice(0, -1);
          let subsistuteParams = params.replace('skip', 'offset');
          subsistuteParams = subsistuteParams.replace('take', 'limit');
          if (sortSettings.length > 0) {
            subsistuteParams += `&sort=${encodeURI(JSON.stringify(sortSettings))}`;
          }
          subsistuteParams += `&group=${encodeURI(JSON.stringify(groupSettings))}`;

          if (!subsistuteParams.startsWith('?')) {
            subsistuteParams = `?${subsistuteParams}`;
          }
          if (loadOptions.filter) {
            if (loadOptions.filter[1] === 'and') {
              const filter1 = removeEmptyArrays(formatFilter(type, loadOptions.filter[0]));
              const filter2 = removeEmptyArrays(formatFilter(type, loadOptions.filter[2]));
              subsistuteParams += `&filter=${encodeURI(
                JSON.stringify([filter1, 'and', filter2]),
              )}`;
            } else {
              const filter = removeEmptyArrays(formatFilter(type, loadOptions.filter));
              subsistuteParams += `&filter=${encodeURI(
                JSON.stringify([filter]),
              )}`;
            }
          }
          return dispatch('getSamples', { params: subsistuteParams, type, data });
        },
        remove: async (key) => key,
      });
    },
  },
};
