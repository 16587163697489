<template>
    <section>
      <div
        class="content-devx margin-half"
        style="padding: 0"
      >
        <div style="padding: 4px 4px 0 4px">
          <div class="main-title-content">
            TABLA DE RIEGO
          </div>
        </div>
  
        <div style="padding: 0 4px">
          <div class="content-separatos">
            <DxDataGrid
              class="grid-application-efficiency-per-period"
              :data-source="dataSource"
              :show-borders="true"
              :row-alternation-enabled="true"
              :column-auto-width="true"
              :show-row-lines="true"
              :show-column-lines="true"
            >
            <DxColumn caption="">
              <DxColumn
                :caption="fertilization.period"
                data-field="name"
                :allow-editing="false"
                :allow-sorting="false"
              />
            </DxColumn>
            <DxColumn 
              v-for="(column, index) in productColumns" :key="index"
                :caption="column.caption" 
                :data-field="column.dataField" 
                :alignment="column.alignment"
            >
              <DxColumn caption="NºRiegos" :data-field="`periods_product_irrigation_${index}`" alignment="left" />
              <DxColumn :caption="column.name" :data-field="`periods_dose_${index}`" alignment="left" />
            </DxColumn>
            <DxColumn 
              :caption="'NºTotal de riegos'" 
              data-field="periods_irrigation"
              data-type="number"
              :allow-editing="true"
            />
            <DxColumn 
              :caption="'Tiempo/Riego(horas)'" 
              data-field="periods_irrigation_time" 
              data-type="number"
              :allow-editing="true"
            />
            </DxDataGrid>
          </div>
        </div>
      </div>
    </section>
  </template>
  <script>
  import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
  import moment from 'moment';
  import { getTimeValues } from '../../../js/utils/timeValuesUtils';
  
  export default {
    name: 'IrrigationResumeTable',
    components: {
      DxDataGrid,
      DxColumn,
    },
    props: {
    fertilization: {
      type: Object,
      default: () => {},
    },
    isReferencePlan: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataSource: [],
      productColumns: [] // Columnas para los nombres de productos
    };
  },
  computed: {
    getPeriods() {
      const { timeValuesIrrigation } = getTimeValues(
        moment(this.fertilization.init_date),
        moment(this.fertilization.end_date),
        this.fertilization.period,
        this.isReferencePlan
      );

      return Object.entries(timeValuesIrrigation[0]).map(([name, value]) => {
        return {
          name,
        };
      });
    },
  },
  async mounted() {
    // Se comprueba que si viene a null periods_irrigation no se llama a la funcion formadData
    if(this.fertilization?.periods_irrigation !== null) {
    // Llamar a la función que formatea los datos cuando el componente se monta
      this.formatData();
    }
  },
  methods: {
    formatData() {
      const contributionProducts = this.fertilization.contribution_products;
      const periodsIrrigation = this.fertilization.periods_irrigation;
      const periodsIrrigationTime = this.fertilization.periods_irrigation_time;

      // Obtener los datos de periodData desde la computed property getPeriods
      const periodData = this.getPeriods;

      // Generar columnas para los nombres de productos
      contributionProducts.forEach((product, index) => {
        const column = {
          caption: product.product_name,
          dataField: `Product_${index}`,
          alignment: 'left',
          name: product.presentation === 'LIQUIDO' ? 'l/Riego' : product.presentation === 'SOLIDO' ? 'kg/Riego' : 'l/Riego'
        };
        this.productColumns.push(column);

        // Asignar valores a las celdas correspondientes
        const periodsProductIrrigation = product.periods_product_irrigation;
        const periodsDose = product.periods_dose;

        periodsProductIrrigation.forEach((value, i) => {
          // Asegurarse de que la fila exista en dataSource
          if (!this.dataSource[i]) {
            this.dataSource[i] = {};
          }

          // Asignar valores a las celdas correspondientes
          this.dataSource[i][column.dataField] = value;

          // Asignar valores a las columnas de NºRiegos y l/Riego
          this.dataSource[i][`periods_product_irrigation_${index}`] = periodsProductIrrigation[i];
          this.dataSource[i][`periods_dose_${index}`] = periodsDose[i];

          // Agregar valores de periodData al objeto de datos
          const period = periodData[i];
          Object.keys(period).forEach((key) => {
            const newDataField = period[key];
            this.dataSource[i][key] = newDataField;
          });
        });
      });

      // Agregar valores de periods_irrigation y periods_irrigation_time a dataSource
      periodsIrrigation.forEach((value, i) => {
        // Asegurarse de que la fila exista en dataSource
        if (!this.dataSource[i]) {
          this.dataSource[i] = {};
        }

        // Asignar valores a periods_irrigation y periods_irrigation_time
        this.dataSource[i]['periods_irrigation'] = value;
        this.dataSource[i]['periods_irrigation_time'] = periodsIrrigationTime[i];
      });
    },
  },
};
</script>

<style scoped>
.grid-application-efficiency-per-period::v-deep .dx-header-row td[role="columnheader"] {
  text-transform: none;
}
</style>

  