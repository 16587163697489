<template>
  <div
    class="main-gis-view-clients"
  >
    <div class="content-gis-map">
      <div
        :id="`${nameMap}_${initialDate}`"
        :style="minHeight"
      />
      <!--<div
        style="display: block; text-align: center;"
      >
        {{ $t('GISGeoJsonMap_not_locations') }}
      </div>-->
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.pm/dist/leaflet.pm.css';
import '@fortawesome/fontawesome-free';
import * as turf from '@turf/turf';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GISViewClients',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '45vh' },
    nameMap: { type: String, default: '' },
    treeLocationsProp: {
      type: Array,
      default: () => [],
    },
    center: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      marker: null,
      initialDate: Date.now(),
      plantationSelectedId: '',
      layerGroup: L.layerGroup(),
      radiusLayer: L.layerGroup(),
      markerLayer: L.layerGroup(),
      circleStyle: {
        className: 'leaflet-control-locate-circle',
        color: '#136AEC',
        fillColor: '#136AEC',
        fillOpacity: 0.15,
        weight: 0,
      },
      statusMeasureTool: false,
      areaLayer: null,
      lineLayer: null,
      areaLayerPopup: null,
      lineLayerPopup: null,
      searchGeometries: null,
      newCenter: {},
      svgIcon: `<svg viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" fill="none" r="10" stroke="#06C6FF" stroke-width="2">
    <animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>
    <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
  </circle>
  <circle cx="20" cy="20" fill="#067CEF" r="10"/>
</svg>
`,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    ...mapState('Plantation', ['plantations', 'treeLocations']),
  },
  mounted() {
    this.addTopCenter();
    if (this.treeLocationsProp.length !== 0 && this.treeLocations.length !== 0) {
      this.setCurrentLayersMap([]);
      this.renderMap();
      this.map.createPane('myPane1');
      this.map.createPane('myPane2');
      this.map.getPane('myPane1').style.zIndex = 400;
      this.map.getPane('myPane2').style.zIndex = 800;
      this.addFuncionalities();
      this.addOverlayLayer();
      this.setMarker();

      this.mapEvents();
    }
  },
  methods: {
    addTopCenter() {
      (function (L) {
        // Extend Map._initControlPos to also create a topcenter-container
        L.Map.prototype._initControlPos = (function (_initControlPos) {
          return function () {
            // Original function/method
            _initControlPos.apply(this, arguments);

            // Adding new control-containers

            // topcenter is the same as the rest of control-containers
            this._controlCorners.topcenter = L.DomUtil.create('div', 'leaflet-top leaflet-center', this._controlContainer);

            // bottomcenter need an extra container to be placed at the bottom
            this._controlCorners.bottomcenter = L.DomUtil.create(
              'div',
              'leaflet-bottom leaflet-center',
              L.DomUtil.create('div', 'leaflet-control-bottomcenter', this._controlContainer),
            );
          };
        }(L.Map.prototype._initControlPos));
      }(L));
    },
    addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        if (this.treeLocations.length !== 0) {
          for (const geoData of this.treeLocationsProp) {
            for (const children of geoData.children) {
              layer = L.geoJson(children.geo_feature, { pane: 'myPane1' }).addTo(this.layerGroup);
              this.layersEvents(layer);
              this.centerMapGeoJsonLayer(layer);
              for (const children2 of children.children) {
                layer2 = L.geoJson(children2.geo_feature, { pane: 'myPane1' }).addTo(this.layerGroup);
                layer2.setStyle(layerStyles.active);
                this.layersEvents(layer2, geoData.properties.owner_Farm);
              }
            }
          }
        } else {
          this.$notifyDX(
            {
              message: this.$t('Gis.Alerts.NoPlotsInRange'),
              width: 450,
            },
            'error',
            3000,
          );
        }
      } catch (error) {
        if (error.message === 'Bounds are not valid.') {
          this.$notifyDX(
            {
              message: this.$t('BoundsAreNotValid'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },

    centerMapGeoJsonLayer(layer) {
      const bounds = layer.getBounds();
      this.layerBounds = bounds;
    },

    layersEvents(target, client) {
      const self = this;
      target.eachLayer((layer) => {
        layer.on({
          click(e) {
            self.openLayerDataPopup(e.target.feature.properties, e, client);
          },
          dblclick(e) {
          },
          mouseover(e) {
          },
          mouseout(e) {
            self.map.closePopup(e);
          },
        });
      });
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.layerGroup.addTo(this.map);
    },

    setMarker(e = null) {
      if (e) {
        this.markerLayer.clearLayers();
        const latlng = [e.latitude, e.longitude];

        this.newCenter = {
          latitude: e.latitude,
          longitude: e.longitude,
          radius: e.accuracy,
        };

        L.circle(latlng, e.accuracy, this.circleStyle).addTo(this.markerLayer);

        // distancia
        const distanceInMeters = e.accuracy.toFixed(0);

        this.marker = L.marker(latlng);
        const customIcon = L.divIcon({
          html: this.svgIcon,
          className: 'location-pin',
          iconAnchor: [10, 20],
          iconSize: [20, 20],
        });
        this.marker.setIcon(customIcon);
        const popup = L.popup({ offset: [0, -10] }).setLatLng(latlng).setContent(`Estás a ${distanceInMeters} metros de este punto`);
        this.marker.bindPopup(popup);

        this.marker.addTo(this.markerLayer);
        this.markerLayer.addTo(this.map);
        this.map.setView(latlng, this.zoom);

        return;
      }

      // circulo - rango

      this.markerLayer.clearLayers();
      const latlng = [this.center.latitude, this.center.longitude];

      L.circle(latlng, this.center.radius, this.circleStyle).addTo(this.markerLayer);

      // distancia
      const distanceInMeters = this.center.radius.toFixed(0);

      this.marker = L.marker(latlng);

      const customIcon = L.divIcon({
        html: this.svgIcon,
        className: 'location-pin',
        iconAnchor: [10, 20],
        iconSize: [20, 20],
      });

      this.marker.setIcon(customIcon);
      const popup = L.popup({ offset: [0, -10] }).setLatLng(latlng).setContent(`Estás a ${distanceInMeters} metros de este punto`);
      this.marker.bindPopup(popup);

      this.marker.addTo(this.markerLayer);
      this.markerLayer.addTo(this.map);
      this.map.setView([this.center.latitude, this.center.longitude], this.zoom);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        this.addHomeButton();
        this.addGPSButton();
        this.addSearchGeometriesButton();
        this.addMeasureToolButton();
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(`${e}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },

    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: `${self.$t('Gis.Buttons.ZoomHome')}`,
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
            `${controlName}-home`, container, this.zoomHome);

          return container;
        },

        zoomHome() {
          if (self.newCenter !== null) {
            self.map.setView([self.newCenter.latitude, self.newCenter.longitude], self.zoom);
          } else if (self.center !== null) {
            self.map.setView([self.center.latitude, self.center.longitude], self.zoom);
          } else {
            self.map.fitBounds(self.layerBounds);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },
    addGPSButton() {
      const self = this;
      L.Control.zoomLocation = L.Control.extend({
        options: {
          position: 'topleft',
          zoomLocationText: '<i class="fg-position fa-2x"></i>',
          zoomLocationTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomLocationButton = this.createButton(options.zoomLocationText, options.zoomLocationTitle,
            `${controlName}-home`, container, this.zoomLocation);

          return container;
        },

        zoomLocation() {
          let marker;
          self.map
            .locate({
              setView: true,
              maxZoom: 120,
              enableHighAccuracy: true,
              maximumAge: 10000,
            })
            .on('locationfound', (e) => {
              self.setMarker(e);
            })
            .on('locationerror', (error) => {
              console.error(error);
            });
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomLocation = new L.Control.zoomLocation();
      zoomLocation.addTo(self.map);
    },

    addSearchGeometriesButton() {
      const self = this;
      L.Control.searchGeometries = L.Control.extend({
        options: {
          position: 'topcenter',
          searchGeometriesText: `<span class="search-geometries-text">${self.$t('Gis.Buttons.SearcherGeometries')}</span><i class="fg-search-globe fa-2x"></i>`,
          searchGeometriesTitle: '',
        },
        onAdd() {
          const controlName = 'gin-control-search';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._searchGeometriesButton = this.createButton(
            options.searchGeometriesText,
            options.searchGeometriesTitle,
            `${controlName}-geometries`, container, this.searchGeometries,
          );

          return container;
        },

        async searchGeometries() {
          try {
            const centerMap = self.map.getCenter();
            self.$notifyDX(
              {
                message: self.$t('searching'),
                width: 200,
              },
              'info',
              1000,
            );
            await self.fetchClientLocationsByCoords({
              latitude: centerMap.lat,
              longitude: centerMap.lng,
              radio: 5,
            });
            self.layerGroup.clearLayers();
            self.addOverlayLayer();
          } catch (error) {
            self.$notifyDX(
              {
                message: error,
                width: 200,
              },
              'error',
              2000,
            );
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.searchGeometries = new L.Control.searchGeometries();
    },

    addMeasureToolButton() {
      const self = this;
      L.Control.measureArea = L.Control.extend({
        options: {
          position: 'topright',
          measureAreaText:
              '<i class="fg-measure-area fa-2x"></i>',
          measureAreaTitle: `${self.$t('Gis.Buttons.MeasureArea')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-area';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureAreaButton = this.createButton(
            options.measureAreaText,
            options.measureAreaTitle,
            `${controlName}-measureArea`,
            container,
            this.measureArea,
          );

          return container;
        },

        measureArea() {
          self.map.pm.enableDraw('Polygon', {
            snappable: true,
            continueDrawing: false,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureArea = new L.Control.measureArea();

      L.Control.measureLineString = L.Control.extend({
        options: {
          position: 'topright',
          measureLineStringText:
              '<i class="fg-measure-line fa-2x"></i>',
          measureLineStringTitle: `${self.$t('Gis.Buttons.MeasureLineString')}`,
        },
        onAdd() {
          const controlName = 'gin-control-measure-line';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          self._measureLineStringButton = this.createButton(
            options.measureLineStringText,
            options.measureLineStringTitle,
            `${controlName}-measureLineString`,
            container,
            this.measureLineString,
          );

          return container;
        },

        measureLineString() {
          self.map.pm.enableDraw('Line', {
            snappable: true,
          });
          self.createLayer();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(link, 'mousedown click', L.DomEvent.stopPropagation)
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.measureLineString = new L.Control.measureLineString();

      L.Control.desactiveMeasure = L.Control.extend({
        options: {
          position: 'topright',
          desactiveMeasureText: '<i class="fas fa-times"></i>',
          desactiveMeasureTitle: `${self.$t('Gis.Buttons.DesactiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-desactive-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.desactiveMeasureText,
            options.desactiveMeasureTitle,
            `${controlName}-desactiveMeasure`,
            container,
            this.desactiveMeasure,
          );

          return container;
        },

        desactiveMeasure() {
          self.statusMeasureTool = false;
          self.measureArea.remove(self.map);
          self.measureLineString.remove(self.map);
          // eslint-disable-next-line no-use-before-define
          self.desactiveMeasure.remove(self.map);
          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:block';
          if (self.areaLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.areaLayer);
          }
          if (self.lineLayer !== null) {
            self.map.closePopup();
            self.map.removeLayer(self.lineLayer);
          }
          self.map.pm.disableDraw();
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.desactiveMeasure = new L.Control.desactiveMeasure();

      L.Control.activeMeasure = L.Control.extend({
        options: {
          position: 'topright',
          activeMeasureText: '<i class="fg-measure fa-2x"></i>',
          activeMeasureTitle: `${self.$t('Gis.Buttons.ActiveMeasureMode')}`,
        },
        onAdd() {
          const controlName = 'gin-control-active-measure';
          const container = L.DomUtil.create(
            'div',
            `${controlName} leaflet-bar`,
          );
          const { options } = this;
          this.activeMeasure = this.createButton(
            options.activeMeasureText,
            options.activeMeasureTitle,
            `${controlName}-activeMeasure`,
            container,
            this.activeMeasure,
          );

          return container;
        },

        activeMeasure() {
          self.statusMeasureTool = true;
          self.measureArea.addTo(self.map);
          self.measureLineString.addTo(self.map);
          self.desactiveMeasure.addTo(self.map);
          // activeMeasure.remove(self.map);

          const buttonId = document.getElementById('activeMeasure');
          buttonId.style = 'display:none';
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.id = 'activeMeasure';
          link.href = '#';
          link.style = 'display:block';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      self.activeMeasure = new L.Control.activeMeasure();
      self.activeMeasure.addTo(self.map);
    },
    getLayerDataPropertiesHtml(properties) {
      let listHtml = '';
      for (const property in properties) {
        // eslint-disable-next-line no-continue
        if (properties[property] == null) continue;
        if (typeof properties[property] === 'object') {
          listHtml += this.getLayerDataPropertiesHtml(properties[property]);
        } else {
          listHtml += `<li><b>${property}</b>: ${properties[property]}</li>`;
        }
      }
      return listHtml;
    },
    openLayerDataPopup(properties, e) {
      if (typeof properties.plantation_id !== 'undefined') {
        this.plantationSelectedId = properties.plantation_id;
        let htmlString = "<div class='popup-content'>";
        let listHtml = '<ul>';
        listHtml += `<li>${this.$t('client')}: ${properties.company_name}</li>
        <li>${this.$t('Explotation')}: ${properties.explotation}</li>
        <li>${this.$t('Parcel')}: ${properties.parcel}</li>
        <li>${this.$t('Sector')}: ${properties.sector}</li>
        <li>${this.$t('Plantation')}: ${properties.plantation_name}</li>`;
        listHtml += '</ul>';
        listHtml += `<button id="go-button">${this.$t('Go')}</button>`;
        htmlString += `${listHtml}</div>`;

        this.openPopup(htmlString, e.latlng, properties.plantation_id, properties.company_id);
      }
    },

    openPopup(html, latlng, plantation = null, company = null) {
      this.map.openPopup(html, latlng, {
        maxHeight: 4000,
      });
      const button = document.getElementById('go-button');
      button.addEventListener('click', () => {
        this.setSearcherPlantation(plantation);
        this.goToMainMenu({ plantation, company });
      });
    },
    createLayer() {
      const self = this;
      this.map.on('pm:create', (e) => {
        try {
          self.$f7.preloader.show();
          if (e.shape === 'Polygon') {
            self.areaLayer = e.layer.addTo(self.map);
            const toGeoJson = self.areaLayer.toGeoJSON();
            const area = turf.area(toGeoJson);
            const html = `<div class='popup-content'><h3>${self.$t('Gis.Editor.PopupMeasureArea')}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${(
              area / 10000
            ).toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureHas')}</p></div>`;
            const popup = L.popup().setContent(html);
            self.areaLayerPopup = self.areaLayer.bindPopup(popup);
            self.areaLayer.openPopup();
            self.map.fitBounds(self.areaLayer.getBounds());
            self.map.pm.disableDraw();
          }
          if (e.shape === 'Line') {
            self.lineLayer = e.layer.addTo(self.map);
            // eslint-disable-next-line no-underscore-dangle
            if (self.lineLayer._latlngs.length > 2) {
              self.map.removeLayer(self.lineLayer);
            } else {
              const from = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[0].lng,
              ]);
              const to = turf.point([
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lat,
                // eslint-disable-next-line no-underscore-dangle
                self.lineLayer._latlngs[1].lng,
              ]);
              const options = {
                units: 'kilometers',
              };
              const distance = turf.distance(from, to, options);
              const html = `<div class='popup-content'><h3>${self.$t('Gis.Editor.PopupMeasureLineString')}</h3><p>${self.$t('Gis.Editor.PopupMeasureResult')}: ${distance.toFixed(2)} ${self.$t('Gis.Editor.PopupMeasureKm')}</p></div>`;
              const popup = L.popup().setContent(html);
              self.lineLayerPopup = self.lineLayer.bindPopup(popup);
              self.lineLayer.openPopup();
              self.map.fitBounds(self.lineLayer.getBounds());
            }
          }
        } catch (error) {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        } finally {
          self.$f7.preloader.hide();
        }
      });
      if (self.areaLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.areaLayer);
      }
      if (self.lineLayer !== null) {
        self.map.closePopup();
        self.map.removeLayer(self.lineLayer);
      }
      this.map.on('pm:globaldrawmodetoggled', (e) => {
        this.drawMode = e.enabled;
      });
    },
    async goToMainMenu(data) {
      const currentPlantation = await this.getPlantationById({ plantationId: data.plantation, companyId: data.company });

      this.setCurrentPlantation({
        id: currentPlantation.id,
        companyId: data.company,
        company: currentPlantation.company_name,
        name: currentPlantation.name,
        province: currentPlantation.province,
        explotation: currentPlantation.explotation,
        parcel: currentPlantation.parcel,
        sector: currentPlantation.sector_name,
        campaign: currentPlantation.campaign,
        variety: currentPlantation.variety,
        initDate: currentPlantation.init_date,
        endDate: currentPlantation.end_date,
        species: currentPlantation.species,
        area: currentPlantation.area,
        coordinates: currentPlantation.coordinates,
        sectorId: currentPlantation.sectorId,
      });

      this.$f7.views.main.router.navigate('/searcher/home/', { reloadCurrent: true });
    },
    mapEvents() {
      this.map.on('moveend', (e) => {
        this.searchGeometries.addTo(this.map);
      });
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer']),
    ...mapActions('Plantation', ['setCurrentPlantation', 'fetchClientLocationsByCoords']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('Plantation', ['setSearcherPlantation']),

  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
