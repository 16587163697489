<template>
  <div
    v-if="!loaded"
    class="no-padding"
  >
    <div class="loadingFalse">
      <div class="preloader" />
    </div>
  </div>
  <div v-else>
    <section
      class="nutrition-plan-list"
      :class="{
        'nutrition-plan-list': true,
        'margin-left-block': calledFromPath === 'home'
      }"
    >
      <div
        class="content-devx no-margin-horizontal no-margin-top position-relative"
      >
        <f7-block-title class="text-uppercase title-top">
          {{ $t('nutritionalList.title') }}
        </f7-block-title>
        <div class="card-body main">
          <DxDataGrid
            class="data-grid-reference-value"
            :row-alternation-enabled="true"
            :data-source="newFormattedFertilization"
            :column-auto-width="true"
            :show-borders="true"
            @toolbar-preparing="onToolbarPreparing($event)"
          >
            <DxStateStoring
              :enabled="true"
              type="localStorage"
              storage-key="NutritionalPlanList"
            />
            <DxPaging :page-size="3" />
            <DxPager
              :show-page-size-selector="true"
              :allowed-page-sizes="pageSizes"
              :show-info="true"
              info-text="{2} items"
            />
            <DxSearchPanel
              :visible="true"
              :width="200"
              :highlight-case-sensitive="false"
              :placeholder="$t('Search_placeholder')"
            />
            <DxColumn
              :min-width="200"
              :width="250"
              data-field="description"
              :caption="$t('nutritionalList.description')"
            />
            <DxColumn
              :min-width="85"
              data-field="production"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.production')"
            />
            <DxColumn
              :min-width="85"
              data-field="cost"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.cost')"
            />
            <DxColumn
              :min-width="85"
              data-field="n"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.n')"
            />
            <DxColumn
              :min-width="85"
              data-field="p205"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.p')"
            />
            <DxColumn
              :min-width="85"
              data-field="k20"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.k')"
            />
            <DxColumn
              :min-width="85"
              style="text-transform: none !important;"
              data-field="ca0"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.c')"
            />
            <DxColumn
              :min-width="85"
              data-field="mg0"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.m')"
            />
            <DxColumn
              :min-width="85"
              data-field="s03"
              data-type="number"
              :allow-sorting="false"
              :caption="$t('nutritionalList.s')"
            />
            <DxColumn
              :min-width="100"
              cell-template="statusColumnTemplate"
              fixed-position="right"
              :fixed="true"
              :caption="$t('nutritionalList.status')"
              alignment="center"
              :allow-sorting="false"
            />
            <DxColumn
              fixed-position="right"
              :fixed="true"
              cell-template="buttonsColumnTemplate"
              alignment="center"
            />
            <template #buttonsColumnTemplate="{ data: cellData }">
              <div class="action-buttons">
                <DxButton
                  styling-mode="text"
                  class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-search"
                  type="success"
                  icon="search"
                  @click="viewItem(cellData.key)"
                />
                <DxButton
                  styling-mode="text"
                  class="dx-button dx-widget dx-button-has-icon dx-button-mode-text button-edit"
                  icon="edit"
                  @click="editItem(cellData.key)"
                />
                <DxButton
                  styling-mode="text"
                  type="danger"
                  class=""
                  icon="trash"
                  @click="deleteItem(cellData.key)"
                />
              </div>
            </template>
            <template #statusColumnTemplate="{ data }">
              <div>
                <DxSwitch
                  :value="switchValue(data.data.status)"
                  :switched-on-text="$t('ACTIVO').toUpperCase()"
                  :switched-off-text="$t('PLANIFICADO').toUpperCase()"
                  :width="85"
                  @valueChanged="
                    () => {
                      updateState(
                        {
                          newState: !switchValue(data.data.status),
                          id: data.data.id,
                          companyId: data.data.company
                        },
                        data
                      );
                    }
                  "
                />
              </div>
            </template>
          </DxDataGrid>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import {
  DxDataGrid, DxColumn, DxStateStoring, DxPager, DxPaging, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { DxSwitch } from 'devextreme-vue/switch';
import { mapState, mapGetters, mapActions } from 'vuex';
import DxButton from 'devextreme-vue/button';
import moment from 'moment';
import { fertilizationService, fertilizationStatus } from '../../../services/fertilizationService';
import settings from '../../../js/commonSettings';

export default {
  name: 'NutritionPlanList',
  components: {
    DxDataGrid,
    DxColumn,
    DxButton,
    DxStateStoring,
    DxPaging,
    DxPager,
    DxSearchPanel,
    DxSwitch,
  },
  props: {
    calledFromPath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      newFormattedFertilization: [],
      pageSizes: [3, 10, 20],
      loaded: false,
      path: '/searcher/home/',
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
    ...mapGetters('fertilizationStore', ['fertilization']),
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('nutritionStepper', ['stepData']),
  },
  async beforeMount() {
    if (this.isPlantationSelected) {
      try {
        const data = {
          companyId: this.currentPlantation.companyId,
          plantationId: this.currentPlantation.id,
        };
        await this.getPlantationById(data);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${this.$options.name} > ${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    }
  },
  async mounted() {
    try {
      await this.getFertilization({
        companyId: this.currentPlantation.companyId,
        id: this.currentPlantation.id,
      });
      this.formattedFertilization();
    } catch (error) {
      this.$notifyDX(
        {
          message: this.$t(`${this.$helpers.getFilteredErrorMessage(error)}`),
          width: 550,
        },
        'error',
        3000,
      );
    } finally {
      this.loaded = true;
    }
  },
  methods: {
    async updateState(data, grid) {
      const status = data.newState ? 'ACTIVO' : 'INACTIVO';
      const payload = { status };
      try {
        await fertilizationService.edit(data.id, payload, data.companyId);

        grid.row.data.status = status;
        grid.component.repaintRows(grid.rowIndex);
      } catch (error) {
        if (error === 'date_range_invalid') {
          this.$notifyDX(
            {
              message: this.$t('DateRangeInvalidPlan'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: error,
              width: 550,
            },
            'error',
            3000,
          );
        }
      }
    },
    switchValue(status) {
      this.$fa.logEvent('buscador_nutricion_checkbox', {
        status: status,
        content_type: 'component',
        description: 'Evento al entrar en buscador/nutricion y pulsamos en el switch para cambiar estado'
      });
      return status === 'ACTIVO';
    },
    getNameStatus(status) {
      return this.$t(status);
    },
    getClassStatus(status) {
      switch (status) {
        case fertilizationStatus.ACTIVE:
          return 'label-success';
        case fertilizationStatus.CANCELLED:
          return 'label-danger';
        case fertilizationStatus.PLANNED:
        default:
          return 'label-primary';
      }
    },
    getStatusFertilization(initDate, endDate) {
      if (moment(this.actualDate).isBetween(initDate, endDate)) {
        return this.$t('nutritionalList.assets');
      }
      if (endDate < this.actualDate) {
        return this.$t('nutritionalList.archived');
      }
      return this.$t('nutritionalList.planned');
    },
    async addNutrition() {
      await this.resetWizard();
      this.setIsEditing(false);
      this.setEditingStepData(null);
      this.setIsCopying(false);
      this.setCopyingStepData(null);
      this.$f7.views.main.router.navigate('/utilities/nutrition/nutrition-step-one',
        { reloadCurrent: true });
    },

    deleteItem(item) {
      const that = this;
      this.$f7.dialog.create({
        title: this.$t('Confirm_Delete_Title'),
        text: this.$t('Confirm_Delete_Text'),
        closeByBackdropClick: 'true',
        buttons: [
          {
            text: this.$t('No'),
          },
          {
            text: this.$t('Ok'),
            onClick() {
              fertilizationService.delete(item.id, that.currentPlantation.companyId).finally(() => {
                that.getFertilization(
                  {
                    companyId: that.currentPlantation.companyId,
                    id: that.currentPlantation.id,
                  },
                );
                that.$f7.views.main.router.navigate(that.path, { reloadCurrent: true });
              });
            },
          },
        ],
      }).open();
    },
    viewItem(item) {
      this.setCurrentFertilizationId(item.id);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-detail',
        params: { id: item.id },
      });
      this.$fa.logEvent('buscador_nutricion_lupa', {
        id: item.id,
        content_type: 'button',
        description: item.description,
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    editItem(item) {
      this.setIsPlantationSelected(false);
      this.setIsEditing(true);
      this.setIsNew(true);
      const url = this.$f7.views.main.router.generateUrl({
        name: 'utilities_nutrition_nutrition-step-one',
        params: { id: item.id },
      });
      this.$f7.views.main.router.navigate(
        url,
        { reloadCurrent: true },
      );
    },
    getCostByHA(fertilization) {
      if (fertilization.cost === null || fertilization.cost === 0) {
        return 0;
      }
      return (fertilization.cost / this.currentPlantation.area).toFixed(2);
    },
    formattedFertilization() {
      const toIntnteger = (value) => {
        if (value != null) {
          return parseInt(value, 10);
        }

        return null;
      };

      for (const fertilization of this.fertilization) {
        this.newFormattedFertilization.push({
          description: fertilization.description,
          cost: this.getCostByHA(fertilization),
          n: toIntnteger(fertilization.n),
          p205: toIntnteger(fertilization.p205),
          k20: toIntnteger(fertilization.k20),
          ca0: toIntnteger(fertilization.ca0),
          mg0: toIntnteger(fertilization.mg0),
          s03: toIntnteger(fertilization.s03),
          status: fertilization.status,
          id: fertilization.id,
          company: fertilization.company,
          production: fertilization.production,
        });
      }
    },
    onToolbarPreparing(e) {
      if (this.calledFromPath === 'home') return;

      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            style: 'padding: 6px 0;',
            class: 'add-task',
          },
          onClick: () => {
            this.addNutrition();
            this.setIsNew(true);
          },
        },
      });
    },
    ...mapActions('nutritionStepper', ['resetWizard', 'setIsEditing', 'setEditingStepData', 'setIsCopying', 'setCopyingStepData', 'setIsNew']),
    ...mapActions('fertilizationStore', ['getFertilization', 'setCurrentFertilizationId']),
    ...mapActions('Plantation', ['setIsPlantationSelected']),
    ...mapActions('RegisterPlantation', ['getPlantationById']),
    ...mapActions('nutritionStepper', ['setIsNew']),
  },
};

</script>
<style src="./nutritionPlanList.scss" scoped lang="scss"></style>
