const dictionary = {
  es: {
    translation: {
      date_invalid: 'La fecha no es correcta',
      clear: 'Limpiar',
      copy: 'Copiar',
      Login_txtWelcome: 'Acceso',
      Login_inpUsername_placeholder: 'Usuario',
      Log_Out: 'Cerrar sesión',
      Login_inpPassword_label: 'Contraseña',
      Login_inpPassword_placeholder: 'Contraseña',
      Login_chkRememberMe: 'Recordar contraseña',
      Login_txtRestorePass: 'Cambiar contraseña',
      Login_txtNeedHelp: '¿Necesitas ayuda?',
      Login_btnSignIn: 'ENTRAR',
      Login_btnSignUp_question: '¿Todavía no te has registrado?',
      Login_btnSignUp_link: 'Registrate aquí',
      Login_msgEmail_title: 'Información',
      Login_msgEmail_content: 'En breve recibirá un email para cambiar su contraseña',
      Login_msgEmail_btnClose: 'Cerrar',
      loggOut_Message: '¿Estás seguro que quieres salír de la aplicación?',
      RecoveryPassword_txtDataAccess: 'Introduzca su email',
      RecoveryPassword_inpEmail_name: 'Email',
      RecoveryPassword_inpEmail_placeholder: 'Email',
      RecoveryPassword_btnSendEmail: 'Reestrablecer contraseña',
      RecoveryPassword_btnGoBack: 'Volver',
      ChangePassword_txtDataAccess: 'Introduzca su nueva contraseña',
      ChangePassword_inpEmail_placeholder: 'Contraseña',
      ChangePassword_inpRepeatEmail_placeholder: 'Repita contraseña',
      ChangePassword_btnChangePassword: 'Cambiar contraseña',
      Status_Closed: 'Cerrada',
      Status_notReally_Closed: 'No Cerrada',
      Status_reallyNot_Closed: 'No Cerrada',
      Register_btnEnter: 'ENTRAR',
      Register_inpName_name: 'Nombre',
      Register_inpName_placeholder: 'Nombre',
      Register_inpSurnames_name: 'Apellidos',
      Register_inpSurnames_placeholder: 'Apellidos',
      Register_inpEmail_name: 'Email',
      Register_inpEmail_placeholder: 'Email',
      Register_inpPassword_name: 'Contrasena',
      Register_inpPassword_placeholder: 'Contraseña',
      Register_inpPasswordVerification_name: 'RepetirContrasena',
      Register_inpPasswordVerification_placeholder: 'Repetir contraseña',
      Register_inpUsername_name: 'NombreUsuario',
      Register_inpUsername_placeholder: 'Nombre de usuario',
      NfcManually_listInput_placeholder: 'Nombre o apellido',
      DxSelectSupervisor: 'Se necesita al menos un supervisor',
      Profile_info_profile: 'Información de perfil',
      Profile_name_placeholder: 'Nombre',
      Profile_surname_label: 'Apellidos',
      Profile_surname_placeholder: 'Apellidos',
      Profile_email_label: 'E-mail',
      Profile_email_placeholder: 'E-mail',
      Profile_mobile_label: 'Teléfono',
      Profile_mobile_placeholder: 'Teléfono',
      Profile_password_profile: 'Contraseña',
      Profile_oldPass_label: 'Contraseña anterior',
      Profile_oldPass_placeholder: 'Contraseña anterior',
      Profile_newPass_label: 'Nueva contraseña',
      Profile_newPass_placeholder: 'Nueva contraseña',
      Profile_checkNewPass_label: 'Confirmar nueva contraseña',
      Profile_change_password: 'Guardar contraseña',
      Profile_photo_profile: 'Foto perfil',
      Profile_update_photo: 'Actualizar foto perfil',
      Profile_setup_profile: 'Configuración',
      Profile_language_label: 'Idioma',
      Profile_theme_label: 'Tema',
      Profile_save_changes: 'Guardar cambios',
      Home_info_txtTitle: 'No hay trabajadores',
      ExportWorkerList_btnExport: 'Exportar a Excel',
      ExportExcel_thNombre: 'Nombre',
      ExportExcel_thTipo: 'Tipo',
      ExportExcel_thFecha: 'Fecha',
      PlanningComponent_numberWorkers_caption: 'Número de trabajadores',
      ExportExcel_captionListaEmpleados: 'Lista de trabajadores',
      SheetExport_sheet_export_ok: 'Fichero descargado: ',
      SheetExport_sheet_export_error: 'Error en la descarga',
      TasksList_navbar_text: 'Ordenes de trabajo',
      TasksList_lstNewTasks_title: 'Iniciar tarea',
      TasksList_lstInProgressTasks_title: 'Tareas en progreso',
      TasksList_lstCompletedTasks_title: 'Tareas completadas',
      Home_conductor: 'Conductor',
      Start_order: 'Selecciona trabajador',
      ManualList_listItem_title: 'Ningun empleado encontrado',
      checkOut_Productivity_placeholder: 'Campo Numérico',
      checkOut_Productivity: 'Productividad',
      checkOut_Task: 'Tarea',
      list_content_farm_img_alt: 'Cambiar de unidad territorial',
      farmSelection_navbar_text: 'Seleccionar unidad territorial',
      locationSelect_navbar_text: 'Seleccionar Localización',
      DxDailyPlanning_text: 'Crear nueva OT',
      DxAddEmployee_text: 'Añadir nuevo empleado',
      DxMonthlyPlanning_text: 'Crear nueva planificación menual',
      DetailTabs_machinary_title: 'Maquinaria',
      DetailTabs_attachment_title: 'Implementos',
      DxEmployeeForm_name_require: 'Nombre es requerido',
      DxEmployeeForm_surname_require: 'Apellido es requerido',
      DxEmployeeForm_ID_require: 'Numero de identificacion es requerido',
      DxEmployeeForm_status_require: 'Estatus es requerido',
      DxEmployeeForm_role_require: 'Rol es requerido',
      DxEmployeeForm_type_require: 'Tipo es requerido',
      DxEmployeeForm_email_require: 'Email es requerido',
      DxEmployeeForm_password_require: 'Contraseña es requerida',
      DetailTabs_tools_title: 'Herramientas',
      DetailTabs_products_title: 'Productos',
      DetailTabs_workers_title: 'Trabajadores',
      DetailTabs_locations_title: 'Localizaciones',
      DxDailyPlanning_date_caption: 'Fecha',
      DxListEmployee_name_caption: 'Nombre',
      DxListEmployee_surname_caption: 'Apellidos',
      DxListEmployee_type_caption: 'Tipo',
      DxListEmployee_nfc_caption: 'identificación de la placa',
      DxListEmployee_code_caption: 'RUT',
      DxListEmployee_status_caption: 'Estado',
      DxListEmployee_driver_caption: 'Conductor',
      DxListEmployee_info_caption: 'Info',
      DxListEmployee_farm_caption: 'Unidad territorial',
      DxDailyPlanning_task_caption: 'Tarea',
      DxDailyPlanning_activity_caption: 'Actividad',
      DxDailyPlanning_ha_caption: 'Ha',
      DxDailyPlanning_workers_caption: 'Trabajadores',
      DxDailyPlanning_location_caption: 'Localización',
      DxDailyPlanning_workingarea_caption: 'Area Trabajo (HA)',
      DxDailyPlanning_workingArea_caption: 'Area de trabajo (HA)',
      DxMonthlyPlanning_goal_caption: 'Objetivo',

      DxDailyWorkOrders_dialogDelete_title: 'Borrar orden de trabajo',
      DxDailyWorkOrders_dialogDelete_text: '¿Está seguro de borrar la orden de trabajo?',
      DxDailyWorkOrders_dialogDelete_confirm: 'Confirmar',
      DxDailyWorkOrders_dialogDelete_cancel: 'Cancelar',
      DxDailyWorkOrders_dialogDelete_noOpen: 'No se puede eliminar una orden de trabajo ya empezada.',
      DxDailyWorkOrders_dialogUpdate_noOpen: 'No se puede editar una orden de trabajo ya empezada.',
      MonthlyComponent_type_caption: 'Tipo',
      MonthlyComponent_performance_caption: 'Rendimiento',
      MonthlyComponent_performanceunit_caption: 'Rendimiento Unitario',
      MonthlyComponent_consumption_caption: 'Consumo',
      MonthlyComponent_consumptionunit_caption: 'Unidad Consumo',
      MonthlyComponent_quantity_caption: 'Cantidad',
      MonthlyComponent_typeconsumption_caption: 'Tipo Consumo',
      MonthlyComponent_notes_caption: 'Notas',
      PlanningComponent_caption_text: 'Actividad',
      PlanningComponent_activityName_caption: 'Nombre Actividad',
      PlanningComponent_activitycode_caption: 'Codigo Actividad',
      PlanningComponent_workingarea_caption: 'Area de Trabajo',
      EmployeeComponent_name_caption: 'Nombre',
      EmployeeComponent_surname_caption: 'Apellidos',
      EmployeeComponent_identificationNumber_caption: 'RUT',
      EmployeeComponent_status_caption: 'Estado',
      EmployeeComponent_role_caption: 'Rol',
      EmployeeComponent_type_caption: 'tipo',
      EmployeeComponent_drive_caption: 'Conductor',
      EmployeeComponent_group_caption: 'Grupo',
      EmployeeComponent_shift_caption: 'Turno',
      EmployeeComponent_email_caption: 'Email',
      EmployeeComponent_password_caption: 'Nueva Contraseña',
      EmployeeComponent_identification_title: 'Identificacion del trabajador',
      EmployeeComponent_typeIdentification_caption: 'Tipo',
      EmployeeComponent_code_caption: 'Codigo',
      PlanningComponent_taskName_caption: 'Nombre Tarea',
      PlanningComponent_taskCode_caption: 'Codigo Tarea',
      PlanningComponent_date_caption: 'Fecha',
      PlanningComponent_numberWokers_caption: 'Numero de trabajadores',
      PlanningComponent_family_caption: 'Familia',
      PlanningComponent_addMachineButton_caption: 'Añadir Maquinaria',
      PlanningComponent_shift_caption: 'Turno',
      PlanningComponent_groupName_caption: 'Nombre Grupo',
      PlanningComponent_dailyPlanning: 'PLAN DIARIO',
      PlanningComponent_edit_dailyPlanning: 'Editar planificación',
      CreatePlanning_ButtonSave_caption: 'Guardar',
      CreatePlanning_Title: 'CREAR NUEVA PLANIFICACIÓN',
      CreateMonthlyPlanning_Title: 'CREAR NUEVA PLANIFICACIÓN MENSUAL',
      EditMonthlyPlanning_Title: 'EDITAR PLANIFICACIÓN MENSUAL',
      CreateEmployee_Title: 'CREAR TRABAJADOR',
      EditEmployee_Title: 'EDITAR TRABAJADOR',
      DxTaskOrders_text: 'Registrar nueva orden de trabajo',
      StoreProduct_Buttoncancel_caption: 'Cancelar',
      StoreProduct_Buttonsave_caption: 'Guardar',
      CreatePlanning_ButtonCancel_caption: 'Cancelar',
      CreatePlanning_ValidationDate: 'La fecha es necesaria.',
      CreatePlanning_ValidationTaskName: 'El nombre de la tarea es necesaria.',
      CreatePlanning_ValidationActivityName: 'El nombre de la actividad es necesaria.',
      CreatePlanning_ValidationNumberOfWorker: 'El numero trabajadores debe de ser mayor que cero.',
      CreatePlanning_ValidationNumberOfDriver: 'El numero de conductores debe de ser superior a cero.',
      CreatePlanning_ValidationActualLocationsSelected: 'Es necesario seleccionar una localización.',
      CreateEmployee_ValidationIdentificationNumber: 'Numero de indentificacion es requerido',
      CreateEmployee_ValidationName: 'Nombre es requerido.',
      CreateEmployee_ValidationSurname: 'Apellido es requerido.',
      CreateEmployee_ValidationEmail: 'Email es requerido.',
      CreateEmployee_ValidationPassword: 'Contraseña es requerido.',
      EmployeeComponent_caption_text: 'Datos del Trabajador',
      CreateOrder_ValidationSupervisorsLocation: 'Hay localizaciones sin un supervisor asignado.',
      CreateOrder_ValidationItemsWorkOrder: 'Hay items sin asignar.',
      CreateOrder_ValidationLocationExists: 'Ya hay una tarea con esta localización para la misma fecha.',
      DxDailyPlanning_edit_text: 'Editar',
      DxDailyPlanning_edit_hint: 'Editar',
      DxDailyPlanning_new_order_text: 'Nueva orden',
      DxDailyPlanning_new_order_hint: 'Nueva orden',
      DxDailyPlanning_task_status_caption: 'Estado Tarea',
      DxDailyPlanning_work_order_status_caption: 'Estado Orden Trabajo',
      DxDailyPlanning_supervisors_caption: 'Supervisores',
      DetailTabs_supervisors_title: 'Supervisores',
      SelectSupervisor_supervisor_caption: 'Supervisor',
      SelectSupervisor_location_caption: 'Localización',
      WorkOrder_title: 'ESTADO ORDEN DE TRABAJO',
      FuelRegistration_title: 'REGISTRO COMBUSTIBLE',
      Employee_title: 'LISTA TRABAJADORES',
      WorkOrderDetail_select_supervisor: 'Seleccionar supervisor',
      WorkOrderDetail_select_maquinaria: 'Seleccionar maquinaria',
      WorkOrderDetail_order: 'ORDEN',
      DxWorkOrders_edit_text: 'Editar',
      DxWorkOrders_edit_hint: 'Editar',
      DxWorkOrders_modal_title: 'Editar ordenes de trabajo',
      DxWorkOrders_machinery_caption: 'Maquinaria',
      DxWorkOrders_driver_caption: 'Conductor',
      DxWorkOrders_km_start_caption: 'Km comienzo',
      DxWorkOrders_km_finish_caption: 'Km fin',
      DxWorkOrders_horometer_start_caption: 'Horometro comienzo',
      DxWorkOrders_horometer_finish_caption: 'Horometro fin',
      DxWorkOrders_fuel_consumption_caption: 'Combustibles consumido (L)',
      DxWorkOrders_fuel_type_caption: 'Tipo Combustible',
      DxWorkOrders_working_area_caption: 'Area trabajo',
      DxWorkOrders_aditional_info_caption: 'Información adicional',
      DxProductPerformance_unit: 'Unidad',
      DxProductPerformance_products_name: 'Producto',
      DxProductPerformance_products_qtyCollected: 'Cantidad recolectada',
      DxProductPerformance_products_qtyPlanned: 'Cantidad planificada',
      DxProductPerformance_machinery_qtyTask: 'Cantidad devuelva a almacén',
      DxProductPerformance_products_qtyUsed: 'Cantidad usada',
      DxProductPerformance_products_qtyReturnWarehouse: 'Cantidad devuelta',
      DxProductPerformance_products_totalRealPrescription: 'Disolución Utilizada (L)',
      DxProductPerformance_products_realSolution: 'Real solución',
      DxProductPerformance_products_realDilutionFactor: 'Factor de dilución real',
      DxProductPerformance_products_appliedDose: 'Dosis aplicada',
      DxProductPerformance_products_theoricalDose: 'Dosis teórica',
      EditOrder_ButtonSave_caption: 'Guardar',
      EditOrder_ButtonCancel_caption: 'Cancelar',
      CreateOrder_ButtonSave_caption: 'Guardar',
      CreateOrder_ButtonCancel_caption: 'Cancelar',
      CreateOrder_ValidationOutDate:
        'No puede crear una orden de trabajo con una planificación fuera de fecha',
      DxSelectedItems_ya_esta_seleccionado_alert: 'Ya está seleccionado',
      DxDailyPlanning_delete_text: 'Eliminar',
      DxDailyPlanning_delete_hint: 'Eliminar',
      DxWorkOrder_Button_View_text: 'Vista',
      DxWorkOrder_Button_View_hint: 'Vista',
      DetailTabWorkers_sin_trabajadores: 'No existen trabajadores asignados a la tarea',
      WorkOrderDetail_select_date: 'Seleccionar fecha',
      DxSelectDate_date_caption: 'Fecha',
      PlanningComponent_typeUnit_caption: 'Tipo unidad',
      DxDailyPlanning_performanceha_caption: 'Objetivo',
      DxDailyPlanning_totalArea: 'Area total (ha)',
      PlanningComponent_performanceUnit_caption: 'Productividad Unidad',
      PlanningComponent_performanceCount_caption: 'Productividad Objetivo',
      PlanningComponent_distributed_caption: 'Distribuido',
      EmployeeComponent_expirationDate_caption: 'Fecha de expiración',
      EmployeeComponent_datepicker_expirationDate_placeholder: 'Seleccione Fecha',
      EmployeeComponent_applicator_caption: 'Aplicador',
      Status_Open: 'Abierta',
      Status_InProgress: 'En progreso',
      Status_Completed: 'Completa',
      Status_Pending: 'Pendiente',
      Status_Deleted: 'Eliminada',
      DetailTabWorkers_sin_items: 'No existe maquinaria asignada a la tarea',
      DetailTab_sin_seleccion: 'No existe selección',
      WorkOrderDetail_additional_info: 'Información adicional',
      WorkOrderDetail_select_driver: 'Seleccionar conductor',
      CreateOrder_ValidationItemsDriversWorkOrder: 'Hay conductores sin asignar',
      Login_inpUsername_validate: 'Completa este campo',
      Login_inpPassword_validate: 'Completa este campo',
      EffortAgriFarmCockpit_navbar_title: 'REPORTE DE ESFUERZO AGRI FARM COCKPIT',
      AgriFarmCockpit_navbar_title: 'REPORTE AGRI FARM COCKPIT', // TODO: Revisar nombre
      WeatherFarmCockpit_navbar_title: 'GRAFICOS DEL CLIMA',
      EffortTask_navbar_title: 'REPORTE DE ESFUERZO TAREA',
      LeftPanel_title_reporting: 'REPORTES',
      LeftPanel_title_Analysis: 'Análisis',
      LeftPanel_title_Utilities: 'Utilidades',
      LeftPanel_title_weather: 'CLIMA',
      LeftPanel_title_warehouse: 'BODEGUERO',
      LeftPanel_subtitle_fuelRegistration: 'Registro combustible',
      LeftPanel_title_fertilizers: 'FERTILIZANTES',
      LeftPanel_title_information: 'INFORMACION',
      LeftPanel_title_referencePlans: 'Planes de referencia',
      LeftPanel_subtitle_climate: 'Clima',
      LeftPanel_subtitle_workOrders: 'Estado orden de trabajo',
      LeftPanel_subtitle_unplannedWorkOrders: 'Registro manual OT',
      LeftPanel_subtitle_logOut: 'Salir',
      LeftPanel_subtitle_home: 'Buscador',
      LeftPanel_subtitle_activeIngredients: 'Principios Activos autorizados EU',
      LeftPanel_subtitle_AgriFarmCockpit: 'Agri Farm Cockpit',
      LeftPanel_subtitle_effortAgriFarm: 'Esfuerzo',
      LeftPanel_subtitle_weatherFarmCockpit: 'Gráficos del Clima',
      LeftPanel_subtitle_phytosanitariesLocalDatabase: 'Base de Datos local de fitosanitarios',
      LeftPanel_subtitle_Tareas: 'Tareas',
      LeftPanel_title_dataConfiguration: 'Configuración',
      LeftPanel_subtitle_productsLocalDatabase: 'Base de Datos local de productos',
      LeftPanel_subtitle_nutrition: 'Nutrición',
      DxEffort_level_caption: 'Nivel',
      DxMonthlyMachinary_text: 'Añadir nueva maquinaria',
      DxDailyPlanning_days_caption: 'Dias',
      DxDailyPlanning_p_caption: 'Permanente',
      DxDailyPlanning_t_caption: 'Temporal',
      DxDailyPlanning_c: 'Contratista',
      DxDailyPlanning_coste: 'Coste',
      DxEffort_activity_caption: 'Actividad',
      DxEffort_task_caption: 'Tarea',
      DxEffort_hrHours_caption: 'Horas RH',
      DxEffort_totalHours_caption: 'Horas totales',
      DxEffort_totalHoursHa_caption: 'Horas totales/Ha',
      DxEffort_totalHoursTotalHa_caption: '% de horas totales vs total de Ha',
      DxEffort_progressCompleted_caption: 'Progreso (% Completado)',
      Search_placeholder: 'Buscar',
      WeatherForecast_title: 'Predicción Meteorológica',
      WeatherStatus_title: 'Estado Meteorológico',
      GisMap_title: 'Mapa',
      PlanningComponent_machinary_caption: 'Maquinaria',
      DxProducts_dxColumn_Unit: 'Unidad',
      DxAttachment_dxColumn_TypeAttachment: 'Tipo',
      DxAttachment_dxColumn_QuantityAttachment: 'Cantidad',
      DxMachinery_dxColumn_TypeMachinery: 'Tipo',
      DxMachinery_dxColumn_QuantityMachinery: 'Cantidad',
      DxProducts_dxColumn_activeIngredients: 'Ingredientes activos',
      DxProducts_dxColumn_productName: 'Nombre producto',
      DxProducts_dxColumn_dosis: 'Dosis',
      DxProducts_dxColumn_dosisUt: 'Dosis Unid',
      DxProducts_dxColumn_dosisTotal: 'Cantidad Planificada',
      DxProducts_dxColumn_solucion: 'Solución',
      DxProducts_dxColumn_solucionUt: 'Solución Unid',
      DxProducts_dxColumn_dilutionFactor: 'Factor de mojamiento',
      DxProducts_dxColumn_unit: 'Unidad',
      DxProducts_dxColumn_dilutionFactorUt: 'Unidad',
      DxProducts_dxColumn_solutionTotal: 'Disolucion planificada (L)',
      DxProducts_dxColumn_caution: 'Precaución',
      DxProducts_dxColumn_restrictedEntryInterval: 'Intervalo de entrada',
      DxProducts_dxColumn_preharvestInterval: 'Intervalo de precosecha',
      ActiveIngredients_navbar_title: 'PRINCIPIOS ACTIVOS AUTORIZADOS EU',
      ORCHARD: 'Huerto',
      EditProduct_navbar_create: 'EDITAR PRODUCTO',
      EditProduct_navbar_edit: 'CREAR NUEVO PRODUCTO',
      DxFormProduct_code_caption: 'SAP ID',
      DxFormProduct_code_required: 'El código es requerido',
      DxFormProduct_status_caption: 'ESTADO',
      DxFormProduct_comment_caption: 'COMENTARIO',
      DxFormProduct_description_caption: 'DESCRIPCION ARTICULO SAP',
      DxFormProduct_component_caption: 'INGREDIENTE ACTIVO',
      DxFormProduct_family_caption: 'NOMBRE GRUPO SAP',
      DxFormProduct_subfamily_caption: 'SUBGRUPO',
      DxFormProduct_sapunit_caption: 'U.M SAP',
      DxFormProduct_toxicity_caption: 'TOXICIDAD',
      DxFormProduct_description_required: 'La descripcion es requerida.',
      totalArea_field: 'Área total (ha)',
      totalArea_title: 'Resumen de parcelas seleccionadas',
      DxAttachment_Title: 'Aperos',
      DxMachineryPerformance_Title: 'Rendimiento de Maquinaria',
      DxProductsPerformance_Title: 'Rendimiento de Productos',
      DxMachinery_Title: 'Maquinaria',
      DxTools_Title: 'Herramientas',
      DxProducts_Title: 'Productos',
      DxTools_dxColumn_TypeTool: 'Tipo',
      DxTool_dxColumn_QuantityTool: 'Cantidad',
      CreatePrescription_ValidationName: 'El nombre es necesario',
      CreatePrescription_ValidationNameDuplicated: 'El nombre indicado ya está en uso',
      CreatePrescription_ValidationProducts: 'Es necesario incluir al menos un producto',
      CreatePrescription_ValidationMethod: 'Es necesario seleccionar un método de aplicación',
      CreatePrescription_ValidationType: 'Es necesario seleccionar un tipo de prescripción',
      CreatePrescription_ValidationDate: 'Es necesario seleccionar una fecha',
      CreatePrescription_ValidationActive: 'Una nueva prescripción debe ser activa',
      DxPlanningProducts_prescription: 'Nombre Prescripción',
      DxPlanningProducts_prescription_required: 'Prescripción requerida',
      DxPlanningProducts_typePrescription: 'Tipo Prescripción',
      DxPlanningProducts_typePrescription_required: 'Tipo Prescripción requerido',
      DxPlanningProducts_typeOfPrescription: 'Tipo de presc.',
      DxPlanningProducts_typeOfPrescription_required: 'Tipo de presc. requerido',
      // DxActiveIngredientsVademecum i18next desde vuex.
      active_substance_id: 'Sustancia activa',
      approval_date: 'Fecha de aprobación',
      approval_expiration: 'Fecha de expiración',
      authorisation_in_progress: 'Autorización en curso',
      authorised: 'Autorizada',
      category: 'Categoría',
      organic_approved: 'Orgánica aprobada',
      remark: 'Observación',
      status: 'Estado',
      substitution_candidate: 'Candidata a sustitución',
      countries: 'Países',
      ProductsDatabase_title: 'BASE DE DATOS DE PRODUCTOS',
      DxAttachmentMachinary_dxColumn_Machinary: 'Maquinaria',
      DxAttachmentMachinary_dxColumn_Attachment: 'Apero',
      DxWeatherElementLinesGraph_average: 'Media',
      DxWeatherElementLinesGraph_averageForecasted: 'Media Predictiva',
      DxWeatherElementLinesGraph_minForecasted: 'Mínima Predictiva',
      DxWeatherElementLinesGraph_maxForecasted: 'Máxima Predictiva',
      DxWeatherElementLinesGraph_totalForecasted: 'Total Predictivo',
      DxWeatherElementLinesGraph_total: 'Total',
      DxProduts_rule_range: 'El rango debe ser superior a 0',
      DxProduts_rule_solution_required: 'La solución es requerida',
      DxProduts_rule_dosis_required: 'Dosis es requerida',
      DxProdutsDataBase_button_text: 'Crear nuevo producto',
      DxProdutsDataBase_edit_text: 'Editar',
      DxProdutsDataBase_edit_hint: 'Editar',
      DxEmployeeTable_Title: 'Trabajadores',
      DxTaskOrders_navbar_Name: 'REGISTRO DE ORDEN DE TRABAJO',
      PlanningComponent_datepicker_multiple_placeholder: 'Seleccionar fechas',
      DxProducts_dxColumn_productGroup: 'tipo',
      DxProducts_dxColumn_productFamily: 'Grupo',
      DxPlanningForm_activity_name: 'Nombre de la actividad obligatorio',
      DxPlanningForm_Task_name: 'Nombre de la tarea obligatorio',
      DxFertilizersDataBase_active_caption: 'Activo',
      DxFertilizersDataBase_description_caption: 'Descripción',
      DxFertilizersDataBase_family_caption: 'Familia',
      DxFertilizersDataBase_subfamily_caption: 'Subfamilia',
      DxPhytosanitariesDataBase_active_caption: 'Activo',
      DxPhytosanitariesDataBase_description_caption: 'Descripción',
      DxPhytosanitariesDataBase_family_caption: 'Familia',
      DxPhytosanitariesDataBase_subfamily_caption: 'Subfamilia',
      DxProductsDataBase_status_caption: 'Estado',
      DxProductsDataBase_code_caption: 'Código SAP',
      DxProductsDataBase_description_caption: 'Descripción artículo SAP',
      DxProductsDataBase_family_caption: 'Nombre grupo SAP',
      DxProductsDataBase_subfamily_caption: 'Subgrupo',
      DxProductsDataBase_component_caption: 'Ingrediente Activo',
      LeftPanel_title_machinery: 'MAQUINARIA',
      LeftPanel_subtitle_machineryDataBase: 'Base de datos de Maquinaria',
      Machinery_title: 'MAQUINARIA',
      CreateMachinery_Title: 'CREAR MAQUINARIA',
      EditMachinery_Title: 'EDITAR MAQUINARIA',
      DxMachineryDatabase_column_status: 'Estado',
      DxMachineryDatabase_column_family: 'Familia',
      DxMachineryDatabase_column_subfamily: 'Subfamilia',
      DxMachineryDatabase_column_description: 'Descripción artículo SAP',
      DxMachineryDatabase_column_brand: 'Marca',
      DxMachineryDatabase_column_code: 'Código Interno',
      DxMachineryDatabase_column_attachment: 'Implementos',
      DxMachineryDatabase_button_text: 'Crear nueva maquinaria',
      DxMachineryForm_code_caption: 'Código SAP',
      DxMachineryForm_code_required: 'Código es requerido',
      DxMachineryForm_description_caption: 'Descripción',
      DxMachineryForm_description_required: 'Descripción es requerida',
      DxMachineryForm_brand_caption: 'Marca',
      DxMachineryForm_brand_required: 'Marca es requerida',
      DxMachineryForm_model_caption: 'Modelo',
      DxMachineryForm_model_required: 'Modelo es requerido',
      DxMachineryForm_serial_number_caption: 'Número de serie',
      DxMachineryForm_serial_number_required: 'Número de serie requerido',
      DxMachineryForm_plate_caption: 'Placa',
      DxMachineryForm_plate_required: 'Placa es requerida',
      DxMachineryForm_internal_code_caption: 'Código interno',
      DxMachineryForm_internal_code_required: 'Código interno es requerido',
      DxMachineryForm_family_caption: 'Familia',
      DxMachineryForm_family_required: 'Familia es requerida',
      DxMachineryForm_subfamily_caption: 'Subfamilia',
      DxMachineryForm_subfamily_required: 'Subfamilia es requerida',
      DxMachineryForm_attachment_caption: 'Implementos',
      DxMachineryForm_purchase_caption: 'Fecha adquisición',
      DxMachineryForm_purchase_required: 'Fecha adquisición es requerida',
      DxMachineryForm_status_caption: 'Estado',
      Machinery_ValidationSAPCode: 'El campo código SAP es obligatorio',
      Machinery_ValidationInternalCode: 'El campo código interno es obligatorio',
      Machinery_ValidationBrand: 'El campo marca es obligatorio',
      Machinery_ValidationModel: 'El campo modelo es obligatorio',
      Machinery_ValidationSerialNumber: 'El campo nuúmero de serie es obligatorio',
      Machinery_ValidationPlate: 'El campo placa es obligatorio',
      Machinery_ValidationDescription: 'El campo descripción es obligatorio',
      Machinery_ValidationFamily: 'El campo familia es obligatorio',
      Machinery_ValidationSubfamily: 'El campo subfamilia es obligatorio',
      Machinery_ValidationPurchase: 'El campo adquisición es obligatorio',
      // Generics
      Button_cancel_text: 'Cancelar',
      Button_save_text: 'Guardar',
      Button_preview_text: 'Previsualizar',
      LeftPanel_subtitle_productsDataBase: 'Base de datos de productos',
      LeftPanel_subtitle_timeWorked: 'Entrada / Salida',
      ReportingTimeWorked_navbar_title: 'ENTRADA / SALIDA',
      DxWorker_date_caption: 'Fecha',
      DxWorker_task_caption: 'Tarea',
      DxWorker_typeTask_caption: 'Tipo',
      DxWorker_codeTask_caption: 'Código',
      DxWorker_workerName_caption: 'Trabajador',
      DxWorker_name_caption: 'Nombre',
      DxWorker_workerSurname_caption: 'Apellido',
      DxWorker_idNumber_caption: 'Número de ID',
      DxWorker_typeWorker_caption: 'Tipo',
      DxWorker_territorialUnit_caption: 'Unidades Territoriales',
      DxWorker_totalHours_caption: 'Nº de Horas',
      DxWorker_workingDay_caption: 'Jornada',
      DxWorker_performanceUnit_caption: 'Unidad de medida',
      DxWorker_performanceCount_caption: 'Productividad',
      DxWorker_note_caption: 'Nota',
      CreateMonthlyPlanning_ValidationDate:
        'El número de días de trabajo debe corresponder con la cantidad de fechas seleccionadas',
      CreateMonthlyPlanning_ValidationTotalWorkingDays: 'Debe de planificar al menos un día de trabajo',
      DxMonthlyForm_activityTask: 'Tarea de actividad',
      DxMonthlyForm_goalUnit: 'Obejtivo Unitario',
      DxMonthlyForm_DateProgrammed: 'Fecha Programada',
      DxMonthlyForm_numberWorkers_caption: 'Número de trabajadores',
      DxMonthlyForm_performanceWorker_caption: 'RENDIMIENTO/TRABAJADOR',
      DxMonthlyForm_workersDaysRequired: 'DIAS DE TRABAJO REQUERIDOS',
      DxMonthlyForm_machineryDaysRequired: 'DIAS DE MAQUINARIA REQUERIDOS',
      DxMonthlyForm_toolsConsumptionTotal: 'CONSUMO TOTAL HERRAMIENTAS (L)',
      DxMonthlyForm_estimatedCostActivity: 'COSTE ESTIMADO ACTIVIDAD',
      DxMonthlyForm_taskDuration: 'DURACIÓN DE LA TAREA',
      DxToolsMonthlyTable_type_caption: 'Tipo',
      DxToolsMonthlyTable_consumption_caption: 'consumo',
      DxToolsMonthlyTable_consumption_type_caption: 'tipo de consumo',
      DxToolsMonthlyTable_consumption_unit_caption: 'Obejtivo Unitario',
      DxMachineryMonthlyTableHADAY: 'HA/DIA',
      DxMachineryMonthlyTableUNDIA: 'UN/DIA',
      Home_navbar_title: 'INICIO',
      // CRUD Costes.
      Costs_title: 'COSTES',
      DxCostsDatabase_column_name: 'Nombre',
      DxCostsDatabase_edit_text: 'Editar',
      DxCostsDatabase_edit_hint: 'Editar',
      LeftPanel_subtitle_costsDataBase: 'Base de datos de costes',
      DxCostsDatabase_column_code: 'Codigo',
      DxCostsDatabase_column_id: 'Id',
      DxCostsDatabase_column_price: 'Precio',
      DxCostsDatabase_column_currency: 'Moneda',
      DxCostsDatabase_column_currency_code: 'Codigo Moneda',
      DxCostsDatabase_column_unit: 'Unidad',
      DxCost_button_text: 'Crear nuevos Costes',
      DxCost_title_creation: 'CREAR COSTES',
      CreacteCost_ValidationCode: 'El campo código es obligatorio',
      CreacteCost_ValidationName: 'El campo name es obligatorio',
      CreacteCost_ValidationPrecio: 'El campo Precio es obligatorio',
      CreacteCost_ValidationUnit: 'El campo unidad de serie es obligatorio',
      CreacteCost_ValidationCurrency: 'El campo moneda es obligatorio',
      CreacteCost_ValidationCurrencyCode: 'El campo codigo moneda es obligatorio',
      CreacteCost_ValidationId: 'El campo ID es obligatorio',
      CreateCost_dialogDelete_title: 'Eliminar Coste',
      DeleteCost_dialogDelete_text: '¿Estas seguro de eliminar el coste seleccinado?',
      DxListEmployee_role_caption: 'Rol',
      DxMachineryDatabase_company: 'Localizacion',
      // Summary Machinery
      DxMachinery_Date_caption: 'Fecha',
      DxMachinery_Location_caption: 'Unidad Territorial',
      DxMachinery_Attachment_caption: 'Implementos',
      DxMachinery_unit_caption: 'Unidad de medida',
      DxMachinery_init_caption: 'km inicial',
      DxMachinery_end_caption: 'km final',
      DxMachinery_total_caption: 'km realizados',
      DxMachinery_Name_caption: 'Máquina',
      DxMachinery_workingArea_caption: 'Área de trabajo',
      DxMachinery_SolutionTotal_caption: 'Mojamiento',
      DxMachinery_Variety_caption: 'Variedad',
      DxMachinery_Worker_caption: 'Conductor',
      DxMachinery_Task_caption: 'Tarea',
      DxMachinery_typeFuel_caption: 'Tipo de Combustible',
      DxMachinery_quantityFuel_caption: 'Cantidad de Combustible',
      DxMachinery_performance_caption: 'Cantidad',
      DxMachinery_quantity_caption: 'Cantidad',
      DxMachinery_CodeTask_caption: 'Código Tarea',
      DxMachinery_InternalCode_caption: 'Códgio Interno',
      DxMachinery_TypeTask_caption: 'Tipo',
      DxMachinery_Formulas_caption: 'Formulación',
      DxMachinery_comment_caption: 'Notas',
      DxWorker_ActivityCode_caption: 'Código de Actividad',
      DxWorker_group_caption: 'Grupo',
      DxWorker_shift_caption: 'Turno',
      DxWorker_Activity_caption: 'Actividad',
      DxWorker_location_caption: 'Localización',
      DxWorker_initDate_caption: 'Hora Inicio',
      DxWorker_endDate_caption: 'Hora Fin',
      DxWorker_checkIn_caption: 'Check in',
      DxWorker_checkOut_caption: 'Check out',
      DxWorker_jornada_caption: 'Jornada',
      ReportingMachinery_navbar_title: 'REPORTE MAQUINARÍA',
      DxMachinery_Activity_caption: 'Actividad',
      DxMachinery_ActivityCode_caption: 'Código Actividad',
      DxMonthlyPlanning_totalCost: 'Coste total',
      CreatePlanning_ValidationTotalWorkingDays:
        'El número de días de trabajo de la maquinaria supera el número de días de trabajo totales. Ajuste el rendimiento de la maquinaria.',
      CreateOrder_ValidationAttachments: 'Hay maquinaria sin asignar en los aperos',
      LeftPanel_subtitle_logs: 'Cosecha Logs',
      ReportingLogs_navbar_title: 'LOGS',
      DxErrorLogs_Date_caption: 'Fecha',
      DxErrorLogs_Time_caption: 'Hora',
      DxErrorLogs_HDSID_caption: 'HDS Id',
      DxErrorLogs_Description_caption: 'Descripción',
      DxSelectSupervisorPerformance_SelectSupervisor_required: 'El supervisor es requerido',
      CalendarInitEndDate_btnClear: 'Limpiar',
      CalendarInitEndDate_btnSearch: 'Buscar',
      PlanningComponent_seasional_caption: 'Cantidad',
      DxProductPerformance_machinery_total_real_consumption: 'Total area prescripcion',
      LeftPanel_subtitle_reportingMachinery: 'Maquinaría',
      DxWorkOrders_notes_caption: 'Notas',
      DxWorkOrders_variety_caption: 'Variedad',
      ReportingEmployeeEffectiveness_navbar_title: 'EFECTIVIDAD DE EMPLEADO',
      ReportingEmployeeEffectiveness_date: 'Fecha',
      ReportingEmployeeEffectiveness_task: 'Tarea',
      ReportingEmployeeEffectiveness_taskCode: 'Código Tarea',
      ReportingEmployeeEffectiveness_Activity: 'Actividad',
      ReportingEmployeeEffectiveness_activityCode: 'Código Actividad',
      ReportingEmployeeEffectiveness_performance: 'Rendimiento',
      ReportingEmployeeEffectiveness_timeSpent: 'Tiempo Empleado',
      ReportingEmployeeEffectiveness_fullname: 'Nombre',
      ReportingEmployeeEffectiveness_type: 'Tipo',
      ReportingEmployeeEffectiveness_IdTask: 'Id Tarea',
      ReportingEmployeeEffectiveness_SumaParcial: 'Parcial Suma',
      HOURS: 'Horas',
      ReportingEmployeeEffectiveness_ratioTimeSpent: '% Tiempo Empleado',
      ReportingEmployeeEffectiveness_ratioTotalPerformance: '% Rendimiento Total',
      ReportingEmployeeEffectiveness_ratioPerformanceGoal: '% Rendimiento Objetivo',
      DxDailyPlanning_realGoal_caption: 'Objetivo real',
      DxDailyPlanning_goalVsReal_caption: '% objetivo completado',
      DxDailyPlanning_realCost: 'Coste real',
      DxWorkOrders_PlannMachinary_caption: 'Maquinaria Planeada',
      DxWorkOrders_WorkedMachinary_caption: 'Maquinaria trabajando',
      DxPlannReal_plannWorkers_caption: 'Empleado planificado',
      DxPlannReal_workers_caption: 'Empleado trabajando',
      DxPlannReal_Totalworkers_caption: 'Total trabajadores',
      ReportingWorkOrders_navbar_title: 'INFORME ÓRDENES DE TRABAJO',
      LeftPanel_subtitle_reportingWorkOrders: 'Órdenes de Trabajo',
      DxListTaskGroupedActivivty_task: 'Tarea',
      DxListTaskGroupedActivivty_taskCode: 'Código Tarea',
      DxListTaskGroupedActivivty_performanceUnit: 'Objetivo Unitario',
      DxListTaskGroupedActivivty_Activity: 'Actividad',
      DxListTaskGroupedActivivty_NavBarName: 'ACTIVIDAD Y TAREAS',
      LeftPanel_subtitle_ListTaskGroupedActivivty: 'Actividad y Tareas',
      PlanningComponent_variety_caption: 'Variedad',
      DxPlanningForm_variety_required: 'Variedad requerida',
      DxDailyPlanning_CostVsReal: '% Coste Completado',
      planned_title: 'PLANEADO VS REAL',
      ReportingplannedVsReal_navbar_title: 'PLANEADO VS REAL',
      Variety_title: 'VARIEDAD',
      LeftPanel_subtitle_varietyDatabase: 'Base de datos de Variedad',
      DxVarietyDatabase_column_code: 'Código',
      DxVarietyDatabase_column_name: 'Nombre',
      DxMachineryDatabase_sapid: 'SAP ID',
      DxProductsDataBase_UMSAP_caption: 'U.M SAP',
      DxProductTable_dialogCreate_solutionNotEmpty: 'El campo solución no puede estar vacio o ser menor de 0',
      DxProducts_dxColumn_solution: 'Mojamiento',
      DxProducts_dxColumn_solutionUt: 'Unidad Mojamiento',
      DxDetailTabs_products_quantityPlanned: 'Cantidad Planificada',
      DxDetailTabs_products_waterPlanned: 'Disolución planificada',
      CreatePlanning_ValidationPrescription: 'Es necesario seleccionar una prescripción',
      DxWorkOrders_kmTotal_caption: 'Km realizados',
      DxWorkOrders_HorometerTotal_caption: 'Horas realizadas',
      DxProductPerformance_products_doseUsed: 'Dosis usada',
      PlanningComponent_grouped_caption: 'Agrupado',
      Workers_StackedBarPlanned: 'Trabajadores',
      machinary_StackedBarPlanned: 'Maquinarías',
      ChartPlanned_StackedBarPlanned: 'Planificado vs Trabajando',
      plannWorkers_StackedBarPlanned: 'Trabajadores Planificados',
      plannMachinery_StackedBarPlanned: 'Maquinarías Planificadas',
      dxSideBySideBar_totalWorkerByActivity: 'Trabajadores Totales Por Actividad',
      dxSideBySideBar_Worker: 'Trabajadores',
      dxSideBySideBarDayWorked_totalWorkerByActivity: 'Jornada trabajada por Actividad',
      dxSideBySideBarDayWorked_totalWorkerByActivity_Worked: 'Jornada',
      DxToolsDatabase_column_status: 'Estado',
      DxToolsDatabase_column_code: 'Código',
      DxToolsDatabase_column_family: 'Familia',
      DxToolsDatabase_column_subfamily: 'Subfamilia',
      DxToolsDatabase_column_description: 'Descripción',
      DxToolsDatabase_column_brand: 'Matricula',
      DxToolsDatabase_column_id: 'id',
      DxToolsDatabase_column_fuel: 'Combustible',
      DxToolsDatabase_column_itemId: 'Id articulo',
      LeftPanel_subtitle_tools: 'Herramientas',
      editTool_Title: 'EDITAR HERRAMIENTA',
      createTool_Title: 'CREAR HERRAMIENTA',
      Tools_ValidationCode: 'El campo código es obligatorio',
      Tools_ValidationBrand: 'El campo marca es obligatorio',
      Tools_ValidationID: 'El campo ID es obligatorio',
      Tools_ValidationItemID: 'El campo elemento ID es obligatorio',
      Tools_ValidationDescription: 'El campo descripción es obligatorio',
      Tools_ValidationFamily: 'El campo familia es obligatorio',
      Tools_ValidationSubfamily: 'El campo subfamilia es obligatorio',
      Tools_ValidationFuel: 'El campo Combustible es obligatorio',
      DxWorkOrder_short_id: 'ID',
      DxManualPerformance_total_performance: 'Rendimiento total',
      DxPopupWorkOrder_Title_supervisor: 'Supervisor',
      DxPopupWorkOrder_Title_totalPerformance: 'Rendimiento total',
      DxPopupWorkOrder_Title_machinery: 'Maquinaria',
      DxPopupWorkOrder_Title_product: 'Productos',
      DxWorkOrders_manualPerformance_caption: 'Rendimiento total',
      PlanningComponent_manualPerformance_caption: 'Rendimiento total',
      ProductCollection_navbar_title: 'RETIRAR PRODUCTOS',
      LeftPanel_subtitle_productCollection: 'Retirar Productos',
      ReturnedProduct_navbar_title: 'DEVOLVER PRODUCTOS',
      LeftPanel_subtitle_returnedProduct: 'Devolver Productos',
      DxProductWarehouse_column_date: 'Fecha',
      DxProductWarehouse_shortId: 'ID',
      DxProductWarehouse_column_activity: 'Actividad',
      DxProductWarehouse_column_activityCode: 'Código Actividad',
      DxProductWarehouse_column_task: 'Tarea',
      DxProductWarehouse_column_taskCode: 'Código Tarea',
      DxProductWarehouse_column_productName: 'Producto',
      DxProductWarehouse_column_supervisor: 'Supervisor',
      DxProductWarehouse_column_productWarehouse: 'Cantidad',
      DxProductWarehouse_column_statusCollection: 'Estado',
      DxProductWarehouse_column_statusConfirmed: 'Enviado',
      DxProductWarehouse_column_statusNotConfirmed: 'No enviado',
      DxProductWarehouse_column_status: 'Estado de orden',
      DxProductWarehouse_column_cost_center: 'Centro de costes',
      DxProductWarehouse_column_cost_center_required: 'Centro de costes requerido',
      DxProductWarehouse_column_warehouse: 'Almacén',
      DxProductWarehouse_column_warehouse_required: 'Almacén requerido',
      DxProductWarehouse_column_COMPLETED: 'Completada',
      DxProductWarehouse_column_IN_PROGRESS: 'En progreso',
      DxProductWarehouse_column_planedQuantity: 'Cantidad Planificada',
      ButtonCancel_caption: 'CANCELAR',
      BatchesInfoForm_name: 'Nombre',
      DxProductWarehouse_edit_validation: 'El producto ya está enviado',
      DxProductWarehouse_Product_Not_Completed:
        'No puede editar este producto: La orden del producto no está completada',
      AnnualBudgets_Title: 'BUDGET ANUAL',
      DxAnnualBudgets_year_caption: 'Año',
      DxAnnualBudgets_version_caption: 'Versión',
      DxAnnualBudgets_status_caption: 'Estado',
      DxAnnualBudgets_createAnnualBudget_text: 'Crear badget anual',
      CreateAnnualBudget_Title: 'CREAR BADGET ANUAL',
      AnnualBudget_version_validation: 'El campo Versión es requerido',
      AnnualBudget_year_validation: 'El campo Año es requerido',
      DxAnnualBudgetForm_version_caption: 'Versión',
      DxAnnualBudgetForm_year_caption: 'Año',
      PlanningComponent_monthlyPlanning: 'Budget anual',
      CloneAnnualBudget_Title: 'CLONAR VERSIÓN BADGET ANUAL',
      AnnualBudget_catch_NAME_EXIST_case: 'Ya existe una versión con el mismo nombre',
      AnnualBudget_catch_NOT_CLOSED_case: 'Todas las veriones tienen que estar cerradas',
      AnnualBudget_catch_VERSION_NOT_FOUND_case: 'No se encontró la versión',
      AnnualBudget_catch_BADGET_NOT_FOUND_case: 'No se encontró el badget',
      AnnualBudget_catch_ERROR_BADGET_MANY_VERSIONS_case: 'No puede haber más de 3 versiones en un badget',
      Rules_count_more_than_zero: 'La cantidad tiene que ser superior a 0.',
      LeftPanel_subtitle_pestsDiseases: 'Plagas y enfermedades',
      DxPestsDiseasesDatabase_edit_hint: 'Editar',
      DxPestsDiseasesDatabase_column_type: 'Tipo',
      DxPestsDiseasesDatabase_column_name: 'Nombre',
      DxPestsDiseasesDatabase_column_scientific_name: 'Nombre científico',
      DxPestsDiseasesDatabase_column_symthoms: 'Síntomas',
      DxPestsDiseasesDatabase_column_photos: 'Fotos',
      DxPestsDiseasesDatabase_button_create_text: 'Crear nueva plaga/enfermedad',
      PestsDiseases_title: 'PLAGAS Y ENFERMEDADES',
      LeftPanel_subtitle_extremeWeatherCondicions: 'Condiciones climáticas extremas',
      ExtremeWeatherCondicions_title: 'CONDICIONES CLIMÁTICAS EXTREMAS',
      DxExtremeWeatherCondicionsDatabase_edit_hint: 'Editar',
      DxExtremeWeatherCondicionsDatabase_column_type: 'Tipo',
      DxExtremeWeatherCondicionsDatabase_column_name: 'Nombre',
      DxExtremeWeatherCondicionsDatabase_button_create_text: 'Crear nueva condición climática extrema',
      LeftPanel_subtitle_phenologies: 'Fenologías',
      PhenologiesDatabase_title: 'FENOLOGIAS',
      DxPhenologiesDatabase_edit_hint: 'Editar',
      DxPhenologiesDatabase_column_photos: 'Fotos',
      DxPhenologiesDatabase_column_code: 'Código',
      DxPhenologiesDatabase_column_phase: 'Fase',
      DxPhenologiesDatabase_column_description: 'Descripción',
      DxPhenologiesDatabase_button_create_text: 'Crear nueva fenología',
      DxPlannReal_workersOut_caption: 'Trabajadores saliendo',
      LeftPanel_title_scouting: 'MONITOREO',
      LeftPanel_subtitle_monitoringPoints: 'Puntos de monitoreo',
      MonitoringPoints_title: 'PUNTOS DE MONITOREO',
      CreatePoint_Title: 'CREAR PUNTO',
      EditPoint_Title: 'EDITAR PUNTO',
      DxMonitoringPoints_name: 'Nombre',
      DxMonitoringPoints_location: 'Unidad territorial',
      DxMonitoringPoints_coordinates: 'Coordenadas',
      DxMonitoringPoints_numberPlants: 'Número de plantas',
      DxMonitoringPoints_variety: 'Variedad',
      DxMonitoringPoints_type: 'Tipo',
      DxMonitoringPoints_yearPlanting: 'Año de plantación',
      DxMonitoringPoints_statusFormatted: 'Estado',
      DxMonitoringPoints_note: 'Nota',
      DxMonitoringPoints_createPoint_button: 'Crear punto',
      Point_name_validation: 'El campo Nombre es requerido',
      DxPointForm_name_caption: 'Name',
      DxPointForm_latitude_caption: 'Latitude',
      DxPointForm_longitude_caption: 'Longitude',
      DxPointForm_numberPlants_caption: 'Número de plantas',
      DxPointForm_variety_caption: 'Variedad',
      DxPointForm_yearPlanting_caption: 'Año de plantación',
      DxPointForm_note_caption: 'Nota',
      DxPointForm_name_required: 'El campo Nombre es requerido',
      DxPointForm_type_caption: 'Tipo',
      DxTreePlanning_onlyLevel1_location_already_selected: 'Ya hay una localización seleccionada',
      DxPointForm_location_caption: 'Unidad territorial',
      Point_type_validation: 'Tipo requerido',
      Button_confirm_text: 'Confirmar',
      DxMonitoringPoints_dialogDelete_title: 'Borrar punto de monitoreo',
      DxMonitoringPoints_dialogDelete_text: '¿Está seguro de borrar el punto de monitoreo?',
      Point_date_validation: 'Año requerido',
      DxWarehouse_totalKm_caption: 'Km total',
      DxWarehouse_totalHorometer_caption: 'Horómetro total',
      DxWarehouse_totalKm_validation: 'Km inicio no puede ser mayor que Km final',
      DxWarehouse_totalHorometer_validation: 'Horómetro inicio no puede ser mayor que Horómetro final',
      StoreProduct_ValidationComponent: 'Componente requerido',
      StoreProduct_ValidationCode: 'Código requerido',
      StoreProduct_ValidationFamily: 'Familia requerida',
      StoreProduct_ValidationSubfamily: 'Subfamilia requerida',
      StoreProduct_ValidationSapunit: 'Unidad SAP requerida',
      StoreProduct_ValidationDescription: 'Descripción requerida',
      StoreProduct_ValidationCodeDuplicated: 'Código esta duplicado',
      DxProductPerformance_products_qtyTask: 'Cantidad devuelta al almacén',
      DetailTabProduct_no_seleccionado: 'Ningún producto seleccionado',
      DetailTabAttachemnt_not_selected: 'Ningún implemento seleccionado',
      TasksList_WorkersSelection_subtitle: 'Pulsar el botón superior para seleccionar trabajadores',
      TasksList_WorkersSelection_title: 'No hay trabajadores en la tarea',
      TasksList_Workers_title: 'Trabajadores',
      scs_banned: 'SCS banned',
      DxTimeWorked_location_caption: 'Localización',
      DxWorkOrders_fuel_horometerDiff_caption: 'Horas de funcionamiento',
      Monitoring_column_date: 'Fecha',
      Monitoring_point_type: 'Tipo de punto',
      Monitoring_point_description: 'Descripción del punto',
      Monitoring_point_location: 'Localización',
      Monitoring_point_longitude: 'Longitud',
      Monitoring_point_area: 'Superficie (ha)',
      Monitoring_point_latitude: 'Latitud',
      Monitoring_point_number_plants: 'Nº Plantas',
      Monitoring_point_variety: 'Variedad',
      Monitoring_point_planting_year: 'Año de plantado',
      Monitoring_point_note: 'Notas del punto',
      Monitoring_number: 'Numbero',
      Monitoring_plagues_and_diseases: 'Plagas/Enfermedades',
      Monitoring_state: 'Estado',
      Monitoring_phenological_phase: 'Fase fenologica',
      Monitoring_comments: 'Comentarios',
      Monitoring_photos: 'Fotos',
      LeftPanel_subtitle_monitoringPests: 'Plagas',
      LeftPanel_subtitle_monitoringDiseases: 'Enfermedades',
      LeftPanel_subtitle_monitoringPhenologies: 'Fenologías',
      LeftPanel_subtitle_monitoringWeatherCondicions: 'Condiciones climáticas',
      LeftPanel_subtitle_monitoringOthers: 'Otros',
      Monitoring_pests_navbar_title: 'MONITOREO - PLAGAS',
      Monitoring_diseases_navbar_title: 'MONITOREO - ENFERMEDADES',
      Monitoring_phenologies_navbar_title: 'MONITOREO - FENOLOGIAS',
      Monitoring_weather_condicions_navbar_title: 'MONITOREO - CONDICIONES CLIMÁTICAS',
      Monitoring_others_navbar_title: 'MONITOREO - OTROS',
      LeftPanel_subtitle_climateGrids: 'Tablas del Clima',
      ClimateGrids_navbar_title: 'TABLAS DEL CLIMA',
      ClimateGrids_DxWeatherHumidityGrid_blockTitle: 'HUMEDAD',
      ClimateGrids_DxWeatherPrecipitationGrid_blockTitle: 'PRECIPITACIÓN',
      ClimateGrids_DxWeatherTemperatureGrid_blockTitle: 'TEMPERATURA',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Temperature: 'Temperatura media',
      ClimateGrids_DxWeatherTemperatureGrid_max_Temperature: 'Temperatura máxima',
      ClimateGrids_DxWeatherTemperatureGrid_min_Temperature: 'Temperatura mínima',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Humidity: 'Humedad media',
      ClimateGrids_DxWeatherTemperatureGrid_max_Humidity: 'Humedad máxima',
      ClimateGrids_DxWeatherTemperatureGrid_min_Humidity: 'Humedad mínima',
      ClimateGrids_DxWeatherTemperatureGrid_measured_Precipitation: 'Precipitación media',
      Monitoring_point_name: 'PUNTO DE CONTROL',
      Monitoring_point_variety_code: 'Código Variedad',
      no_photos: 'No hay imágenes que mostrar',
      show: 'Mostrar',
      UploadFile_error: 'Se ha producido el siguiente error:',
      UploadFile_preloader:
        'Subiendo el fichero. Esto puede tardar un poco de tiempo según el tamaño del fichero',
      UploadFile_success:
        'Fichero subido correctamente. Estamos procesandolo. Puede tardar un tiempo en los cambios',
      UploadFile_button: 'Cargar fichero',
      UploadFile_empty: 'Por favor, seleccione un fichero',
      Point_location_validation: 'Seleccionar solo una localización',
      Point_no_selected_location_validation: 'Seleccione una localización válida',
      CreateEmployee_EmployeeCodeDuplicated: 'El ID de empleado se encuentra en uso',
      CreateEmployee_EmployeeGenericError: 'Ha ocurrido un error: ',
      DxDailyPlanning_creation_caption: 'Creación',
      DxDailyPlanning_CreationPlanned_caption: 'Planificado',
      DxDailyPlanning_CreationManually_caption: 'Manual',
      DxWorkOrders_ExportTranslation_WorkerType: 'TIPO TRABAJADOR',
      ExportDataToExcel_Button: 'Exportar Todo',
      company_not_found: 'Empresa no encontrada',
      task_type_not_found: 'Tipo de tarea no encontrado',
      planner_not_found: 'Planificación no encontrada',
      task_not_found: 'Tarea no encontrada',
      item_not_found: 'Item no encontrado',
      farm_not_found: 'Granja no encontrada',
      product_not_found: 'Producto no encontrado',
      product_not_in_prescription: 'El producto no se encuentra en la prescripción',
      product_not_in_task: 'El producto no se encuentra en la tarea',
      product_stock_is_less: 'El stock del producto es inferior al seleccionado',
      prescription_not_found: 'Prescripción no encontrada',
      badget_not_found: 'Badget no encontrada',
      badget_version_not_found: 'Versión de Badget no encontrada',
      badget_version_task_not_found: 'No se encuentra la tarea en la versión actual de Badget',
      variety_not_found: 'Variedad no encontrada',
      scouting_not_found: 'Exploración no encontrada',
      scouting_point_not_found: 'Punto de monitoreo no encontrado',
      warehouse_log_not_found: 'Log de Almacén no encontrado',
      warehouse_not_found: 'Almacén no encontrado',
      cost_center_not_found: 'Centro de coste no encontrado',
      badget_version_is_closed: 'La versión de Badget ya está cerrada',
      planner_not_in_planning_status: 'No se encuentra la planifiación',
      task_location_exist_with_same_date: 'Ya hay una tarea en esta localización y con esta fecha',
      task_not_referenced: 'Tarea no referenciada',
      planner_not_referenced: 'Planificación no referenciada',
      task_deleted_correctly: 'Tarea eliminada correctamente',
      no_task_for_selected_date_404: 'No hay tareas para la fecha actual',
      location_not_found: 'Localización no encontrada',
      worker_not_found: 'Trabajador no encontrado',
      worker_already_exist_in_task: 'El trabajador ya se encuentra en esta tarea',
      worker_already_exist_in_other_task: 'El trabajador se encuentra en otra tarea',
      worker_not_exist_in_task: 'El trabajador no existe en esta tarea',
      code_not_exist_in_request: 'No existe código en la petición',
      code_not_exist: 'El código no existe',
      outdated: 'Fuera de fecha',
      invalid_responsible: 'Responsable no válido',
      invalid_item_family: 'Familia no válida',
      Not_Found: 'No encontrado',
      ID_Must_be_especified: 'El ID debe ser especificado',
      Phenological_phase_not_found: 'Fase fenológica no encontrada',
      Plagues_and_diseases_not_found: 'Plagas y enfermedades no encontradas',
      SectorValidation: 'Error al dibujar el sector, por favor, dibuje dentro de la parcela',
      Scouting_point_required: 'Punto de monitoreo requerido',
      CreatePlanning_DuplicatedEmployees: 'Trabajadores duplicados: ',
      PlanningComponent_comments_caption: 'Comentarios',
      DxDailyPlanning_CostVsReal_versionName: 'Versión',
      DxProductWarehouse_Popup_Send_Title: 'Enviar:',
      DxProductWarehouse_ButtonText_ReSend: 'Reenviar',
      DxProductWarehouse_ButtonText_Adjust: 'Ajustar',
      DxProductWarehouse_Alert_FieldsRequired: 'Todos los campos son requeridos',
      DxProductWarehouse_Alert_CorrectResend: 'Producto reenviado correctamente',
      DxProducts_dxColumn_solution_total: 'Mojamiento total',
      DxLocationSelector_ParentLevel: 'Nivel Superior',
      DxListEmployee_group_caption: 'Grupo',
      DxListEmployee_shift_caption: 'Turno',
      DxWeatherGrid_date_time: 'Tiempo',
      Monitoring_point_code: 'Código',
      DxFormProduct_maxdosis: 'Máxima Dosis Permitida',
      DxFormProduct_currentIn: 'Intervalo de entrada restringida',
      DxFormProduct_SCS_autorizado: 'SCS Autorizado',
      DxFormProduct_EU_autorizado: 'EU Autorizado',
      DxFormProduct_preHarvest: 'Intervalo de precosecha',
      DxProductsDataBase_currentIN: 'Horas',
      DxProductsDataBase_preHarverst: 'Dias',
      DxMonthlyForm_jornada: 'jornadas',
      DxMachinery_AttachmentInternalCode_caption: 'Código implemento',
      DxWorkOrders_ExportTranslation_Group: 'Grupo',
      DxWorkOrders_ExportTranslation_Shift: 'Turno',
      DxMonthlyPlanning_comments: 'Notas',
      DxDailyPlanning_workers_performance_caption: 'Productividad',
      DxMachinery_horometer_start_caption: 'Horómetro start',
      DxMachinery_horometer_end_caption: 'Horómetro finish',
      DxMachinery_horometer_total_caption: 'Horómetro total',
      DxScouting_fullName: 'Nombre Completo',
      LeftPanel_subtitle_territorialUnit: 'Unidades Terrritoriales',
      LeftPanel_subtitle_territorialUnitcaps: 'UNIDADES TERRITORIALES',
      DxTerritorialUnit_area: 'Area',
      DxTerritorialUnit_code: 'Codigo',
      DxTerritorialUnit_farm: 'Explotación',
      DxTerritorialUnit_id: 'ID',
      DxTerritorialUnit_name: 'Nombre',
      DxTerritorialUnit_pattern: 'Patrón',
      DxTerritorialUnit_planting_year: 'Año de plantado',
      DxTerritorialUnit_plants: 'Plantas',
      DxTerritorialUnit_variety_code: 'Código variedad',
      DxTerritorialUnit_variety_name: 'Nombre variedad',
      DetailTabDrivers_sin_items: 'No hay datos',
      DetailTabs_driver_title: 'Conductores',
      Stock_Title: 'Stock',
      LeftPanel_subtitle_stock: 'Stock',
      DxStockTable_dxColumn_Code: 'Código',
      DxStockTable_dxColumn_Description: 'Descripción',
      DxStockTable_dxColumn_Stock: 'Stock',
      DxProductWarehouse_column_productWarehouse_required: 'La cantidad es requerida',
      DxWorkOrders_ExportTranslation_Variety: 'Variedad',
      DxWorkOrders_ExportTranslation_Driver: 'Conductor',
      DxDailyPlanning_export_activity_code: 'Código de actividad',
      DxDailyPlanning_export_areaEfective: 'Área efectiva',
      DxDailyPlanning_export_Comments: 'Comentarios',
      DxDailyPlanning_export_TaskCode: 'Código de tarea',
      DxDailyPlanning_export_variety: 'Variedad',
      DxDailyPlanning_export_performance: 'Productividad',
      DxDailyPlanning_export_machinary_consumption: 'Consumo de maquinaria',
      DxDailyPlanning_export_machinary_consumption_type: 'Tipo de consumo',
      DxDailyPlanning_export_machinary_notes: 'Notas de maquinaria',
      DxDailyPlanning_export_machinary_performance: 'Productividad de maquinaria',
      DxDailyPlanning_export_machinary_type: 'Tipo de maquinaria',
      DxDailyPlanning_export_tools_consumption: 'Consumo de herramienta',
      DxDailyPlanning_export_tools_consumption_type: 'Tipo de consumo',
      DxDailyPlanning_export_tools_count: 'Cantidad',
      DxDailyPlanning_export_tools_type: 'Tipo de herramienta',
      DxDailyPlanning_export_products_code: 'Código de producto',
      DxDailyPlanning_export_products_dilution_factor: 'Factor de dilución',
      DxDailyPlanning_export_products_dosis: 'Dosis del producto',
      DxDailyPlanning_export_products_dosis_total: 'Dosis total',
      DxDailyPlanning_export_products_name: 'Nombre del producto',
      DxDailyPlanning_export_products_solution: 'Solución del producto',
      DxDailyPlanning_export_products_solution_total: 'Solución total',
      DxStockTable_dxColumn_Unit: 'Unidad',
      DxTaskOrder_origen: 'Origen',
      DxBareRootTable_dxColumn_CreationDate: 'Fecha de creación',
      DxBareRootTable_dxColumn_Name: 'Nombre',
      DxBareRootTable_dxColumn_Origin: 'Origen',
      DxBareRootTable_dxColumn_PlantYear: 'Año de plantación',
      DxBareRootTable_dxColumn_Variety: 'Variedad',
      DxBareRootTable_dxColumn_Category: 'Categoría',
      DxBareRootTable_dxColumn_ClassType: 'Clase',
      DxBareRootTable_dxColumn_NumPlants: 'Nº Plantas',
      DxBatchesInfoForm_Farm: 'Ubicación',
      DxBatchesInfoForm_Farm_Rule: 'Es necesario establecer una ubicación',
      DxBatchesInfoForm_Name_Rule: 'El nombre es requerido',
      DxToolsTable_Add_Change_typeTools: 'La herramienta ya está seleccionada',
      DxToolsTable_tools: 'Herramienta',
      error_401: 'El usuario o contraseña son incorrectos',
      TASK_WORDER_PRODUCT_PERFORMANCE_REQUIRED: 'Los Kg totales recogidos deben ser mayores a cero',
      TASK_WORDER_MACHINERY_PERFORMANCE_INCOMPLETE: 'Los datos de maquinaría están incompletos',
      TASK_WORDER_PRODUCT_PERFORMANCE_TOTAL_REAL_PRESCRIPTION_ZERO:
        'Cuando las unidades son "Cc/Hl", "Gr/Hl" debe seleccionar una total prescription distinta de 0.',
      error_403:
        'No tiene permisos para realizar esta acción. Por favor, póngase en contacto con su administrador',
      DxToolsTable_Add_Change_error_count: 'No hay suficientes herramientas',
      DxTable_Date_caption: 'Fecha',
      DxTable_ut_caption: 'UT',
      DxTable_type_caption: 'Tipo',
      DxTable_variety_caption: 'Variedad',
      DxTable_plantYear_caption: 'Año plantación',
      DxTable_category_caption: 'Categoría',
      DxTable_amount_caption: 'Cantidad',
      DxTable_notes_caption: 'Notas',
      DxNurseryStockReport_typeReclassified_caption: 'Tipo reclasificación',
      DxNurseryStockReport_pottedYear_caption: 'Año enmacetado',
      DxNurseryStockReport_categoryReclassified_caption: 'Categoría reclasificación',
      DxNurseryStockReport_finalDestination_caption: 'Destino final',
      DxNurseryReport_classification: 'Clasificación',
      DxNurseryReport_stock: 'Stock',
      DxNurseryReport_egresos: 'Egresos',
      DxNurseryEgresosReport_egresosType_caption: 'Tipo de egreso',
      DxNurseryEgresosReport_bins_caption: 'Bins',
      DxNurseryEgresosReport_orm_caption: 'N Guia / ORM',
      DxNurseryEgresosReport_destiny_caption: 'Destino',
      DxNurseryEgresosReport_state_caption: 'Estado de envio',
      specie: 'Especie',
      group: 'Grupo',
      variety: 'Variedad',
      DxProduct_name: 'Nombre',
      DxProduct_group: 'Grupo',
      DxProduct_category: 'Categoría',
      DxProduct_active_ingredients: 'Ingredientes Activos',
      LeftPanel_subtitle_variety: 'Variedades',
      Page_subtitle_variety: 'VARIEDADES',
      LeftPanel_subtitle_tasksdef: 'Tareas',
      LeftPanel_subtitle_product: 'Productos',
      Page_subtitle_product: 'Productos',
      DxProduct_register_Product: 'Registrar Producto',
      DxProduct_edit_Product: 'Editar Producto',
      DxViewProduct_detail_Product: 'Detalle producto',
      Page_subtitle_clients: 'CLIENTES',
      DxClients_name: 'Nombre',
      DxClients_fecha_alta: 'Fecha alta',
      DxClients_numero_movil: 'Número móvil',
      DxClients_telefono: 'Teléfono',
      DxClients_provincia: 'Provincia',
      DxClients_localidad: 'Localidad',
      DxClients_sales: 'Ventas',
      DxClients_atc_propietario: 'ATC propietario',
      DxClients_email: 'Email',
      LeftPanel_subtitle_client: 'Clientes',
      DxClient_register_Client: 'Registrar Cliente',
      DxVariety_register_Variety: 'Registrar Variedad',
      DxClient_edit_Client: 'Editar Cliente',
      DxVariety_edit_Variety: 'Editar Variedad',
      DxViewClient_detail_Client: 'DETALLE CLIENTE',
      DxViewClient_detail_Ventas: 'Ventas',
      Button_go_to_parcel_system: 'Clientes',
      Button_go_to_back: 'Volver',
      Button_go_to_home: 'Ir a inicio',
      DxClients_postal_code: 'Código postal',
      DxClients_pc: 'CP',
      DxClients_client: 'Cliente',
      DxAtc_atc: 'ATC',
      Campania_title: 'CAMPAÑA AGRÍCOLA',
      DxRegisterCampania_nombre: 'Nombre de campaña agrícola',
      DxRegisterCampania_fecha_inicio: 'Fecha inicio',
      DxRegisterCampania_fecha_finalizacion: 'Fecha finalización',
      DxRegisterCampania_nombre_validation: 'El Nombre de la campaña es obligatorio',
      DxRegisterCampania_fecha_inicio_validation: 'La Fecha inicio es obligatoria',
      DxRegisterCampania_fecha_finalizacion_validation: 'La Fecha de finalización es obligatoria',
      Button_go_create_new_Campania: 'Guardar',
      LeftPanel_subtitle_campaña: 'Campaña',
      LeftPanel_title_ParcelSystem: 'Clientes',
      Button_go_parcel: 'Parcelario',
      Location_title: 'EXPLOTACIONES',
      ParcelSystemCampania_designacion: 'Designación',
      ParcelSystemCampania_status: 'Estado',
      ParcelSystemLocation_designacion: 'Designación',
      ParcelSystemLocation_parcelas: 'Nº Parcelas',
      ParcelSystemLocation_plantations: 'Nº Plantaciones',
      ParcelSystemLocation_sectores: 'Nº Sectores',
      Register_farm: 'REGISTRAR EXPLOTACIÓN',
      Edit_farm: 'EDITAR EXPLOTACIÓN',
      Register_farm_name: 'Nombre explotación',
      Register_farm_provincia: 'provincia',
      Register_farm_localidad: 'localidad',
      Register_farm_municipio: 'municipio',
      Register_Farm_numeroRegistro: 'Número Registro',
      Register_Farm_titularExplotacion: 'Titular explotación',
      Register_Farm_mobile: 'Número móvil',
      Edit_Farm_successfuly: 'Datos guardados correctamente.',
      DxSimpleItem_PopUpRegister_campania: 'Explotación',
      Register_completed: 'Has registrado la campaña con éxito.',
      End_Campaign: 'Finalizar',
      title_create_campania: '+ Crear plantación',
      campañia_register: 'CAMPAÑA REGISTRADA',
      ploit_register: 'EXPLOTACIÓN REGISTRADA',
      title_create_parcel: '+ Crear parcela',
      ploit_completed: 'Has registrado la explotación con éxito.',
      DxSales_sale_date: 'Fecha Venta',
      DxSales_deal: 'Cliente',
      DxSales_company: 'Distribuidor',
      DxSales_sale_point: 'Punto de Venta',
      DxSales_product_code: 'Código producto',
      DxSales_product: 'Producto',
      DxSales_quantity: 'Cantidad',
      DxSales_species_code: 'Código especie',
      DxSales_status: 'Estado',
      DxElement: 'Elemento',
      DxRegisterCampania_name_require: 'El campo Cliente es obligatorio',
      DxRegisterCampania_parameter_required: 'El campo Parámetros es obligatorio',
      DxRegisterCampania_init_date_require: 'El campo Fecha de inicio es obligatorio',
      DxRegisterCampania_campaign_require: 'El campo Campaña es obligatorio',
      DxRegisterCampania_plantation_require: 'El campo Plantación es obligatorio',
      DxRegisterCampania_end_date_require: 'El campo Fecha finalización es obligatorio',
      DxRegisterLocation_nombre_validation: 'El Nombre de la explotación es obligatorio',
      DxRegisterDateRange_validation: 'El rango de fechas es obligatorio',
      status_campania_active: 'Activa',
      status_campania_end: 'Finalizada',
      status_campania_pending: 'Pendiente',
      status_active: 'Activa',
      status_end: 'Finalizada',
      status_pending: 'Pendiente',
      dates_isInvalid: 'Las fechas son incorrectas',
      DxProduct_nameCommercial: 'NOMBRE COMERCIAL',
      DxProduct_code: 'Código',
      DxProduct_Ntotal: 'N TOTAL',
      DxProduct_P205: 'P₂O₅ TOTAL',
      DxProduct_K20: 'K₂O',
      DxProduct_CaO: 'CaO',
      DxProduct_tipology: 'Tipología',
      DxProduct_presentation: 'Presentación',
      DxProduct_density_no_unit: 'Densidad',
      DxProduct_density: 'Densidad (g/cm³)',
      DxProduct_density_upper: 'DENSIDAD (g/cm³)',
      DxProduct_ecology: 'Ecología',
      DxProduct_mainElement: 'Elementos Principales',
      DxProduct_secondaryElement: 'Elementos Secundarios',
      DxProduct_microelements: 'Microelementos',
      LeftPanel_subtitle_Treatment: 'Tratamiento',
      LeftPanel_subtitle_Nutrition: 'Nutrición',
      LeftPanel_subtitle_Irrigation: 'Riego',
      LeftPanel_subtitle_Gis: 'Teledetección',
      LeftPanel_subtitle_observation: 'Observaciones',
      LeftPanel_title_Informes: 'Informes',
      confirmDeleteMessage: '¿Estás seguro de eliminar esta entrada?',
      registerAnalaysisTitle: 'Registrar análisis de laboratorio',
      RegisterAnalysisSampling_date: 'Fecha de muestreo:',
      RegisterAnalysisSampling_type: 'Tipo de muestra:',
      RegisterAnalysisFile_Attachment: 'Adjuntar archivo:',
      RegisterAnalysisFile_allowed_extension: 'Extensiónes permitidas:',
      RegisterAnalysisFile_selected: 'Selecciona archivo',
      RegisterAnalysisDescription: 'Descripción',
      RegisterAnalysisInterpretation: 'Interpretación',
      RegisterAnalysisInterpretation_textArea: 'escriba un texto aquí',
      Register_product: 'REGISTRAR PRODUCTO',
      Edit_product: 'EDITAR PRODUCTO',
      first_element_floatinButton: 'Alta plan nutricional',
      second_element_floatinButton: 'Alta tratamiento',
      third_element_floatinButton: 'Alta observaciones',
      LeftPanel_title_utilities: 'Utilidades',
      LeftPanel_subtitle_vademecum: 'Vademécum',
      LeftPanel_subtitle_converter: 'Conversor',
      LeftPanel_subtitle_markets: 'Lonjas',
      LeftPanel_subtitle_customers: 'Clientes',
      LeftPanel_subtitle_plans: 'Planes',
      Vademecum_Title: 'Vademécum',
      Vademecum_product_code: 'Código de producto',
      Utilities_Title: 'Utilidades',
      Phytosanitary_Products: 'Productos fitosanitarios',
      Nutritional_Products: 'Productos nutricionales',
      nutritionProductsDeleted: 'Los siguientes productos ya no están disponibles:',
      nutritionDosificationProblem: 'No se pudo guardar. Existen problemas en la dosificación.',
      Plague: 'Plaga',
      Product: 'Producto',
      Product_Type: 'Tipo de producto',
      Commercial_Name: 'Nombre comercial',
      Holder: 'Titular',
      Registration_Number: 'Nº de registro',
      Crop: 'Cultivo',
      Agent: 'Agente/Efecto',
      Filters: 'Filtros',
      Filter: 'Filtrar',
      Filter_Crop: 'Cultivo',
      Filter_Agent: 'Agente/Efecto',
      Filter_Commercial_Name: 'Nombre comercial',
      Select_Crop: 'Escribe o selecciona cultivo *',
      Select_Agent: 'Escribe o selecciona plaga / efecto *',
      Select_CommercialName: 'Seleccionar nombre comercial',
      Active_Product: 'Producto Activo',
      Apply: 'Aplicar',
      Reset: 'Resetear',
      Go_Back: 'Volver',
      Product_Detail_Title: 'DETALLE PRODUCTO',
      Uses_Doses_Detail_Title: 'USOS Y DOSIS AUTORIZADOS',
      Active_Principle: 'Principio Activo',
      Display: 'Presentación',
      Expiration_Date: 'Fecha de caducidad',
      Application_Num: 'Nº de aplicaciones',
      Dose: 'Dosis',
      Liquid_Vol: 'Vol. Caldo ',
      Application_Info: 'Forma y época de aplicación',
      No_Data: 'No existen datos para mostrar',
      No_Observation: 'Ninguna observación general para mostrar.',
      No_Images_Data: 'Imágenes de teledetección en procesamiento. Estarán disponible en unos instantes',
      Type_Product: 'Tipo de producto',
      Application: 'APLICACIÓN',
      Microelements: 'Microelementos',
      Secondary_Elements: 'Elementos secundarios',
      Main_Elements: 'Elementos principales',
      How_To_Use: 'Modo de empleo',
      Packing: 'Envasado',
      PermissionsReadWrite: 'Habilitar permisos de escritura/lectura e intenta de nuevo.',
      PermissionsReadWriteCode1: 'Error de lectura/escritura. Compruebe si existe ya un fichero con el mismo nombre',
      FileDownloadedInDownloads: 'Archivo descargado en la carpeta de Download',
      FileNotFound: 'Error al descargar. Archivo no encontrado.',
      parcelViewClient_number_phone: 'Número de móvil',
      DxClients_atc_prop: 'ATC prop',
      Register_campaign: 'REGISTRAR CAMPAÑA AGRÍCOLA',
      register_location_placeholder_name: 'Nombre de la explotación',
      register_location_placeholder_province: 'Seleccionar provincia',
      register_location_placeholder_locality: 'Seleccionar localidad',
      register_location_placeholder_municipality: 'Seleccionar municipio',
      register_location_placeholder_number_register: 'Número de registro',
      register_location_placeholder_owner: 'Titular',
      register_location_placeholder_number_phone: 'Número de móvil',
      DxRegisterCampania_fecha_inicio_mayor_validation:
        'La fecha de inicio es posterior a la fecha de finalización',
      Search: 'Buscar',
      Campania_title_deatil: 'DETALLE CAMPAÑA AGRÍCOLA',
      ViewCampania_species: 'Especie botánica',
      ViewCampania_variety: 'Variedad',
      ViewCampania_init_date: 'Fecha de inicio',
      ViewCampania_end_date: 'Fecha de finalización',
      ViewCampania_farm: 'Explotación',
      ViewCampania_parcel: 'Parcela',
      ViewCampania_sector: 'Sector',
      title_datagrid_plantation: 'PLANTACIONES DE LA CAMPAÑA AGRÍCOLA',
      Location_title_deatil: 'DETALLE DE EXPLOTACIÓN',
      ViewLocation_title: 'Titular de la explotación',
      ViewLocation_mobile: 'Número de móvil',
      ViewLocation_name_location: 'Nombre de la explotación',
      ViewLocation_number_register: 'Número de registro',
      ViewLocation_province: 'Provincia',
      ViewLocation_Localidad: 'Localidad',
      ViewLocation_postal_code: 'Código postal',
      ViewLocation_name: 'Nombre',
      title_button_viewLocation_parcel: '+ Crear parcela',
      ViewLocation_parcel_column_parcela: 'Parcela',
      ViewLocation_parcel_column_area: 'Superficie (ha)',
      datagrid_title_parcel: 'PARCELAS',
      LeftPanel_title_analisys: 'Análisis',
      Title_page_weather: 'CLIMA',
      Title_page_analisys_treatment: 'TRATAMIENTOS',
      Title_page_analisys_nutrition: 'NUTRICIÓN',
      Button_add_weather: 'Añadir',
      weather_campaign: 'Campaña',
      weather_parameters: 'Parámetros',
      weather_date: 'Fecha',
      weather_farm: 'Parcela',
      registerClima_campaign_placeholder: 'Seleccione campaña',
      registerClima_parameter_placeholder: 'Seleccione variable',
      registerClima_initDate_placeholder: 'DD/MM/AAAA',
      registerClima_endDate_placeholder: 'DD/MM/AAAA',
      registerClima_parcel_placeholder: 'Selección plantación',
      registerClima_campaign_required: 'La campaña es obligatoria.',
      egisterClima_parameter_required: 'Elegir un parametro es obligatorio.',
      registerClima_parcel_required: 'La parcela es obligatoria.',
      Confirm_Delete_Text: '¿Estás seguro de eliminar esta entrada?',
      wrong_user_edit: 'No es posible editar productos no registrados por el usuario',
      Confirm_Delete_Title: 'Alerta',
      Field_Required: 'Este campo es requerido',
      Atc_Propietario: 'Propietario',
      Fields_Required: 'Todos los campos deben estar rellenos',
      LeftPanel_subtitle_lonjas: 'Lonjas',
      Slices_simpleItem_slice: 'Lonja',
      Slices_simpleItem_family: 'Familia',
      Slices_simpleItem_product: 'Producto',
      Slices_simpleItem_initDate: 'Desde',
      Slices_simpleItem_endDate: 'Hasta',
      Slices_simpleItem_origin: 'Origen',
      Slices_placeholder_slice: 'Selecciona lonja',
      Slices_placeholder_family: 'Selecciona familia',
      Slices_placeholder_product: 'Selecciona producto',
      Slices_placeholder_initDate: 'DD/MM/AAAA',
      Slices_placeholder_endDate: 'DD/MM/AAAA',
      Slices_placeholder_origin: 'Selecciona origen',
      Slices_validate_slice: 'El campo lonja es requerido',
      Slices_validate_family: 'El campo familia es requerido',
      Slices_validate_product: 'El campo producto es requerido',
      Slices_validate_origin: 'El campo origen es requerido',
      notExistOrigin: 'No se han encontrado datos de origen.',
      Norte: 'Norte',
      Sur: 'Sur',
      Noreste: 'Noreste',
      Este: 'Este',
      Sureste: 'Sureste',
      Suroeste: 'Suroeste',
      Oeste: 'Oeste',
      Noroeste: 'Noroeste',
      LeftPanel_subtitle_price_lonjas: 'PRECIOS LONJAS',
      LeftPanel_title_search: 'Buscador',
      searchEngine_name: 'Nombre',
      searchEngine_description: 'Descripción',
      searchEngine_province: 'Provincia',
      searchEngine_farm: 'Explotación',
      searchEngine_parcel: 'Parcela',
      searchEngine_sector: 'Sector',
      searchEngine_campaign: 'Campaña',
      searchEngine_state: 'Estado',
      searchEngine_botanical_species: 'Cultivo',
      searchEngine_variety: 'Variedad',
      searchEngine_init_date: 'Fecha inicio',
      searchEngine_end_date: 'Fecha fin',
      LeftPanel_subtitle_irrigation: 'Riego',
      irrigation_date: 'FECHA',
      irrigation_doses: 'DOSIS RIEGO (L/ha)',
      irrigation_doses_m3: 'DOSIS DE RIEGO (m³/ha)',
      irrigation_time: 'TIEMPO (h)',
      irrigation_totalVol: 'VOL. TOTAL (L)',
      irrigation_totalVol_m3: 'VOL. TOTAL (m³)',
      irrigation_accumulatedVol: 'VOL. ACUMULADO (L)',
      irrigation_accumulatedVol_m3: 'VOL. ACUMULADO (m³)',
      irrigation_title_datagridRecommendation: 'RECOMENDACIONES DE RIEGO',
      irrigation_title_datagrid: 'ÓRDENES DE RIEGO',
      registerAndEditIrrigation_date: 'Fecha de riego',
      registerAndEditIrrigation_doses: 'Dosis de riego (m³/ha)',
      registerAndEditIrrigation_time: 'Tiempo de riego (h)',
      LeftPanel_subtitle_analysis_nutrition: 'Análisis nutrición',
      laboratory_nutrition_date_sample: 'Fecha muestreo',
      laboratory_nutrition_type_sample: 'Tipo muestreo',
      laboratory_nutrition_type_description: 'Descripción',
      laboratory_nutrition_description_required: 'La descripción no puede estar vacía',
      laboratory_nutrition_date_required: 'La fecha no puede estar vacía',
      laboratory_nutrition_type_required: 'El tipo de muestra no puede estar vacío',
      laboratoryAnalaysisTitle: 'ANÁLISIS DE LABORATORIO',
      taskByCompany_group: 'Grupo tarea',
      taskByCompany_Task: 'Tarea',
      taskByCompany_init_date: 'Fecha de inicio',
      taskByCompany_end_date: 'Fecha de fin',
      taskByCompany_description: 'Descripción',
      LeftPanel_subtitle_analysis_taskByCompany: 'Tareas por compañia',
      taskByCompany_placeholder_group: 'Seleccionar',
      taskByCompany_placeholder_task: 'Seleccionar tarea',
      taskByCompany_placeholder_init_date: 'DD/MM/AAAA',
      taskByCompany_placeholder_description: 'Nombre de la tarea',
      taskByCompany_invalid_task_selection: 'La tarea no puede estar vacía',
      taskByCompany_description_validation: 'La descripción no puede estar vacía',
      wrong_old_password: 'Contraseña incorrecta',
      new_email_exist: 'El correo electrónico del usuario ya existe',
      invalid_farm_to_campaign: 'La ubicación no existe o la ubicación no es válida para la campaña',
      invalid_farm_to_plantation: 'La ubicación no existe o la ubicación no es válida para la plantación',
      invalid_location: 'La ubicación no existe o la ubicación no es válida',
      invalid_sector_period: 'No se puede asignar dos plantaciones a un mismo sector y misma fecha',
      invalid_campaign: 'La campaña no existe o la campaña no es válida',
      invalid_plantation: 'La plantación no existe o la plantación no es válida',
      invalid_periods: 'Número de períodos no válidos para el período y el intervalo de fechas seleccionados',
      invalid_template: 'La plantilla no existe o la plantilla no es válida',
      invalid_scouting_point: 'El punto de exploración no existe o el punto de exploración no es válido',
      invalid_task_definition: 'La definición de la tarea no existe o la definición de la tarea no es válida',
      parent_is_my_offspring: 'No es un padre válido',
      template_not_owner: 'La plantilla no es de su propiedad',
      date_range_invalid: 'El rango de fecha está fuera del rango de plantación',
      NO_MARKETS: 'No se encontraron mercados',
      INVALID_MARKET: 'Mercado inválido',
      INVALID_MARKET_FAMILY: 'Familia de mercado inválida',
      DATE_REQUIRED: 'Fecha requerida',
      UNKNOWN_CONTACT: 'Contacto no encontrado',
      UNKNOWN_CLIENT: 'Cliente no encontrado',
      UNKNOWN_PRODUCT: 'Producto no encontrado',
      PRODUCT_NOT_OWNER: 'El producto no es de tu propiedad',
      PRODUCT_CODE_EXIST: 'Hay otro producto con el mismo código',
      required_locations: 'La plantación no tiene una localización asociada',
      ACTIVO: 'Activo',
      CANCELADO: 'Cancelado',
      PLANIFICADO: 'Inactivo',
      INACTIVO: 'Inactivo',
      register_irrigation_title_datagridRecommendation: 'REGISTRAR RECOMENDACIONES DE RIEGO',
      registes_irrigation_title_datagrid: 'REGISTRAR ÓRDENES DE RIEGO',
      title_task_by_company: 'TAREAS',
      location_code_exist: 'Ya existe una explotación con el mismo nombre y número de registro',
      WeatherStationIdentifier: 'IDENTIFICADOR DE ESTACIÓN',
      laboratoryAnalysis: {
        viewDetail: {
          date: 'Fecha',
          sampleType: 'Tipo de muestra',
          description: 'Descripción',
          interpretation: 'Interpretación',
          title_view_deatils: 'DETALLE DE ANÁLISIS',
        },
      },
      Register_registerText: 'REGISTRO',
      Profile_checkNewPass_placeholder: '',
      DetailTabAttachemnt_no_selected: 'Ningún archivo adjunto seleccionado',
      DetailTabProduct_not_selected: 'Ningún producto seleccionado',
      LeftPanel_title_prescriptions: 'PRESCRIPCIONES',
      Utilities: {
        name: 'UTILIDADES',
        converter: 'Conversor',
        converterWater: {
          tabTitle: 'Agua',
          title: 'Conversor entre meq/l y mg/l',
          bottomTitle: 'Ingresar uno de los campos para estimar los otros dos',
          m3: 'Dotacion de riego: m³/ha',
          meq: 'meq/l',
          mg: 'mg/l',
          kg: 'kg/ha',
          reset: 'Resetear',
        },
        converterGround: {
          tabTitle: 'Suelo',
          title: 'Conversor entre meq/100g, ppm y kg/ha',
          apparentDensity: 'densidad aparente (t/m³)',
          soilDepth: 'profundidad (m)',
          bottomTitle: 'Ingresar uno de los campos para estimar los otros dos',
          meq: 'meq/100g',
          ppm: 'ppm',
          percent: '%',
          kg: 'kg/ha',
          reset: 'Resetear',
        },
        stepperForm: {
          back: 'Volver',
          next: 'Siguiente',
          save: 'Guardar',
          monthly: 'Mensual',
          weekly: 'Semanal',
          planDescription: 'Descripción',
          temporalFrame: 'Marco temporal',
          ph: 'pH',
          init: 'Iniciar',
          end: 'Fin',
        },
        converterNutritionalSolutions: {
          title: 'Soluciones nutritivas - Conversor iones a óxidos',
          bottomTitle: 'Ingresar uno de los campos para estimar los otros dos',
          element: 'Elemento (mg)',
          oxide: 'Óxido (mg)',
          reset: 'Resetear',
        },
        converterMolecularWeight: {
          title: 'Peso molecular a sustancias puras',
          bottomTitle: 'Ingresar uno de los campos para estimar los otros dos',
          eq: 'Eq/kg',
          percent: '%',
          mol: 'mol/kg',
          reset: 'Resetear',
        },
        nutrition: 'Nutrición',
        tabTitleOther: 'Otros',
        Campania_title_deatil: 'DETALLE DE LA CAMPAÑA',
        ViewCampania_species: 'Especies botánicas',
        ViewCampania_variety: 'Variedad',
        ViewCampania_init_date: 'Fecha de inicio',
        ViewCampania_end_date: 'Fecha de fin',
        ViewCampania_farm: 'Explotación',
        ViewCampania_parcel: 'Parcela',
        ViewCampania_sector: 'Sector',
        title_datagrid_plantation: 'PLANTACIONES DE CAMPAÑA',
        Location_title_deatil: 'DETALLE DE LA EXPLOTACIÓN',
        ViewLocation_title: 'Propietario de la explotación',
        ViewLocation_mobile: 'Número de teléfono',
        ViewLocation_name_location: 'Nombre de la explotación',
        ViewLocation_number_register: 'Número de registro',
        ViewLocation_province: 'Provincia',
        ViewLocation_Localidad: 'Localidad',
        ViewLocation_postal_code: 'Código Postal',
        ViewLocation_name: 'Nombre',
        title_button_viewLocation_parcel: '+ Crear parcela',
        ViewLocation_parcel_column_parcela: 'Parcela',
        ViewLocation_parcel_column_area: 'Area (ha)',
        datagrid_title_parcel: 'PARCELAS',
      },
      referenceValue: {
        title: 'Valor de referencia',
        production: 'Producción (t/ha)',
        n: 'N',
        p2o5: 'P₂O₅',
        k2o: 'K₂O',
        cao: 'CaO',
        mgo: 'MgO',
        so3: 'SO₃',
      },
      nutritionalNeeds: {
        title: 'Necesidades nutricionales',
        productionInput: 'Producción estimada (t/ha)',
        n: 'N',
        p2o5: 'P₂O₅',
        k2o: 'K₂O',
        cao: 'CaO',
        mgo: 'MgO',
        so3: 'SO₃',
      },
      nutritionalNeedsTips: {
        unitsReference: 'Toneladas de materia seca',
        observation: 'Observación',
        reference: 'Fuente',
      },
      nutritionalBalance: {
        title: 'BALANCE NUTRICIONAL PREVIO FERTILIZACIÓN',
        uf: 'UF (kg/ha)',
        n: 'N',
        p: 'P₂O₅',
        k: 'K₂O',
        c: 'CaO',
        m: 'MgO',
        s: 'SO₃',
        necNutritional: 'Nec. nutricionales',
        availableGround: 'UF Disponibles suelo',
        irrigationWater: 'UF Agua de riego',
        harvestScraps: 'UF Restos de cosecha',
        availableTotal: 'UF Totales disponibles',
        advanceFertilization: 'Balance previo fertilización',
      },
      dosageProduct: {
        title: 'Contribución de productos y dosificación',
        finalBalance: 'Balance final',
        uf: 'UF (kg/ha)',
        n: 'N',
        p: 'P₂O₅',
        k: 'K₂O',
        c: 'CaO',
        m: 'MgO',
        s: 'SO₃',
        searchFertilizer: 'Búsqueda de fertilizantes',
        searchFertilizerPlaceholder: 'Busca por nombre o código...',
        add: 'Añadir',
        addIrrigationTable: 'Añadir Tabla riego',
        deleteIrrigationTable: 'Eliminar Tabla riego',
        recalculate: 'Recalcular',
        messageRecalculate: 'Recuerda usar el boton de recalcular (si modificas las dosis de los productos)',
        product: 'Producto',
        riches: 'Riqueza %',
        dosage: 'Dosificación (kg/ha)',
        dose: 'Dosis (kg/ha)',
        deleteDialogMessage: '¿Eliminar {{product}}?',
        deleteDialogTitle: 'Eliminar',
        deleteDialogConfirm: 'Aceptar',
        deleteDialogCancel: 'Cancelar',
      },
      productResume: {
        area: 'Area (ha)',
        productResume: 'RESUMEN POR PRODUCTOS',
        product: 'Producto',
        dosageUnit: 'Dosis unitaria (kg/ha)',
        dosagePlant: 'Dosis por planta (g/planta)',
        dosageTotal: 'Dosis total (kg)',
        dosageTotalKg: 'Dosificación (kg-L / ha)',
        dosageTotalKgL: 'Dosificación Total (kg-L)',
        price: 'Precio (€/kg)',
        unitPrice: 'Precio unitario (€)',
        costUnit: 'Coste unitario (€/ha)',
        costTotal: 'Coste total (€)',
        chartTitle: 'PLAN DE TRATAMIENTO TIMAC AGRO (STACK DE PRODUCTOS)',
        dosage: 'Dosis (L / 1000L)',
        caldoMedium: 'Vol. caldo medio (L / ha)',
        caldoTotal: 'Vol. Total de caldo (L)',
      },
      popupSelectObservationsReport: {
        pdfType: 'Este PDF es de tipo',
        observations: 'Observaciones',
        elementsResume: 'Resumen por elementos',
        nutritionNeeds: 'Incluir Necesidades nutricionales en el informe',
        previusBalance: 'Incluir Balance previo en el informe',
        theoreticalUF: 'Incluir UF teóricas en el informe',
        efficientUF: 'Incluir UF eficientes aportadas en el informe',
        finalBalance: 'Incluir el Balance final en el informe',
        resumeProducts: 'Resumen por productos',
        completeTable: 'Tabla completa',
        noCostTable: 'Tabla sin costes',
        productsAplication: 'Aplicación de productos en cada período kg-l/ha',
        resumeProductsGraphics: 'Gráfico Resumen por productos',
        productsAplicationTotals: 'Aplicación de productos en cada período kg-l totales',
        irrigationTable: 'Tabla Riego',
        ufEfficientTable: 'Tabla Aplicación de UF eficientes de cada periodo',
        ufEfficientGraph: 'Grafico Aplicación de UF eficientes de cada periodo',
        Date: 'Fecha',
        buttons: {
          cancel: 'cancelar',
        },
      },
      elementResume: {
        elementResume: 'RESUMEN POR ELEMENTOS',
      },
      planCompost: {
        planCompost: 'PLAN DE TRATAMIENTO TIMAC AGRO (STACK DE PRODUCTOS)',
      },
      nutritionalDistribution: {
        nutritionalDistribution: 'Reparto nutricional mensual kg/ha (STACK POR ELEMENTOS)',
      },
      Configuration: {
        name: 'CONFIGURACIÓN',
        Variety_Detail_Title: 'DETALLE VARIEDAD',
        Observations_Title: 'OBERVACIONES',
        Observations: 'Observaciones',
        Edit_Observation: 'Editar Observaciones',
        Register_Observation: 'Registrar Observación',
        Observation_Detail_Title: 'DETALLE OBSERVACIÓN',
        Tasks_Title: 'TAREAS',
        Tasks: 'Tareas',
        Edit_Task: 'Editar tarea',
        Register_Task: 'Registrar tarea',
        Create_Task: 'Crear',
        Task_Detail_Title: 'DETALLE TAREA',
        Activity: 'Actividad',
        Name: 'Nombre',
        Name_validation: 'El campo nombre no puede estar vacío',
        Activity_validation: 'El campo actividad no puede estar vacío',
        Delete_Validation: 'Se ha producido un error al intentar eliminar el elemento',
        Subcategory_Validation: 'El campo nombre del formulario es requerido',
        Category_Validation: 'El campo categoría es requerido',
        Form_Validation: 'La plantilla no contiene atributos',
        ObservationsForms: {
          title: 'FORMULARIOS PERSONALIZADOS DE OBSERVACIONES',
          formName: 'Nombre del formulario',
          atcProp: 'ATC Prop',
          attributes: 'Número de atributos',
          registerTitle: 'REGISTRAR FORMULARIO PERSONALIZADO DE OBSERVACIONES',
          description: 'Descripción',
          descriptionRequired: 'El campo descripción es requerido',
          formNameRequired: 'El campo nombre del formulario es requerido',
          attributeNameRequired: 'El campo nombre es requerido',
          attributesTitle: 'ATRIBUTOS',
          name: 'Nombre',
          tipology: 'Tipología',
          domain: 'Dominio',
          detailTitle: 'DETALLE DE FORMULARIO PERSONALIZADO DE OBSERVACIONES',
          view: 'VER',
          setup: 'CONFIGURAR',
          category: 'Categoria',
          field: 'Clave',
          PatternRuleField: 'El campo Clave no debe contener espacios ni caracteres especiales',
          rowValidatingText: 'Existen campos requeridos sin rellenar',
          fieldHelpText: 'No puede contener espacios ni caracteres especiales',
          choicesHelpText: 'Introducir opciones separadas por comas y sin espacios',
          validatingChoices: 'Las opciones deben estar separadas por comas y sin espacios',
          validatingChoices2: 'No debe haber una coma seguida de vacío',
        },
        AttributesPopup: {
          required: 'Requerido',
          allowNull: 'Permitir nulo',
          allowBlank: 'Permitir vacío',
          maxLength: 'Longitud máxima',
          minLength: 'Longitud mínima',
          maxValue: 'Valor máximo',
          minValue: 'Valor mínimo',
          maxDigits: 'Dígitos máximos',
          decimalPlaces: 'Cantidad decimales',
          choices: 'Opciones',
          date: 'Fecha',
          dateTime: 'Fecha y hora',
          close: 'Cerrar',
          default: 'Valor por defecto',
          typeINT: 'Número entero',
          typeFLOAT: 'Número decimal',
          typeBOOLEAN: 'Verdadero/Falso',
          typeTEXT: 'Texto no editable',
          typeSTRING: 'Texto',
          typeDATE: 'Fecha',
          typeDATETIME: 'Fecha y hora',
          typeSELECT: 'Selector',
          typeLIST: 'Multiselector',
        },
      },
      LeftPanel_title_search_page: 'Inicio',
      wizardTreatment: {
        treatment: 'Tratamiento',
      },
      nutritionalList: {
        title: 'Planes Nutricionales',
        description: 'DESCRIPCIÓN DEL PLAN',
        production: 'PRODUCCIÓN (t/ha)',
        cost: 'COSTE €/ha',
        n: 'N TOTAL',
        p: 'P₂O₅ TOTAL',
        k: 'K₂O TOTAL',
        c: 'CaO TOTAL',
        m: 'MgO TOTAL',
        s: 'SO₃ TOTAL',
        status: 'ESTADO',
        active: 'Activo',
        cancel: 'Cancelar',
        planned: 'Planificado',
        assets: 'Activos',
        archived: 'Archivados',
      },
      nutritionDetail: {
        nutritionDetail: {
          title: 'DETALLE DE PLAN DE NUTRICIÓN',
          description: 'Descripción',
          initDate: 'Fecha de inicio',
          endDate: 'Fecha de fin',
          status: 'Estado',
          expectedProduction: 'Producción esperada',
          area: 'Superficie',
          textureSoil: 'Textura del suelo',
          irrigationSystem: 'Sistema de riego',
          chartLine: 'Línea',
          chartBar: 'Barra',
          shareError: 'Su navegador no es compatible para compartir archivos'
        },
        elementResume: {
          title: 'RESUMEN POR ELEMENTOS',
          uf: 'UF (kg/ha)',
          n: 'N',
          p: 'P₂O₅',
          k: 'K₂O',
          c: 'CaO',
          m: 'MgO',
          s: 'SO₃',
          necNutritional: 'Nec. nutrionales totales',
          fertilizationBalance: 'Balance previo fertilización',
          ufContributed: 'UF Aportadas',
          finalBalance: 'Balance final',
        },
        productResume: {
          title: 'RESUMEN POR PRODUCTOS',
          product: 'Producto',
          dosageUnit: 'Dosis unitaria (kg/ha)',
          dosagePlant: 'Dosis por planta (g/planta)',
          dosageTotal: 'Dosis total (kg)',
          price: 'Precio (€/kg)',
          costUnit: 'Coste unitario (€/ha)',
          costPlant: 'Coste (€/planta)',
          costTotal: 'Coste total (€)',
          chartTitle: 'PLAN DE TRATAMIENTO TIMAC AGRO (STACK DE PRODUCTOS)',
          total: 'Total',
        },
        report: {
          report: 'REPORTE',
          treatmentPlan: 'PLAN DE TRATAMIENTO',
          nutritionPlan: 'PLAN DE NUTRICIÓN',
          client: 'Cliente',
          date: 'Fecha',
          description: 'Descripción',
          explotationData: 'DATOS DE LA EXPLOTACIÓN',
          campaign: 'Campaña',
          explotation: 'Explotación',
          parcel: 'Parcela',
          sector: 'Sector',
          plantation: 'Plantación',
          cropType: 'Cultivo',
          specie: 'Especie botánica',
          variety: 'Variedad',
          plantsDensity: 'Densidad de plantas',
          plantsNumber: 'Número de plantas',
          area: 'Superficie',
          groundTexture: 'Textura del suelo',
          irrigationType: 'Sistema de riego',
          flow: 'Caudal',
          dripperPerPlant: 'Goteros/planta',
          plantsHa: 'plantas/ha',
          plagueAgent: 'Plaga / agente',
          dosage: 'Dosificación',
          download: 'Descargar',
          status: 'Estado',
          clouds: 'Area cubierta nubes(%)',
        },
        annotationsProductResume: {
          title: 'ANOTACIONES DE LOS PRODUCTOS',
          annotation: 'ANOTACIÓN',
        },
        descriptionValidation: 'La descripción del plan no puede estar vacía.',
        dateRangeInvalid: 'Rango de fechas no valido',
        emptyReferenceValues: 'Los valores de referencia están vacíos',
      },
      referencePlan: {
        title: 'PLAN DE REFERENCIA',
        titleDetail: 'DETALLE DE PLAN DE REFERENCIA',
        duration: 'Duración',
      },
      Searcher: {
        name: 'BUSCADOR',
        LeftPanel_title_Searcher: 'Buscador',
        nutrition: {
          title: 'NUTRICIÓN',
        },
        treatment: {
          title: 'TRATAMIENTO',
        },
        irrigation: {
          title: 'RIEGO',
        },
        tasks: {
          title: 'TAREAS',
        },
        weatherGraph: {
          title: 'CLIMA',
        },
        detail: {
          title: 'DETALLE',
        },
        observations: {
          observations_title: 'OBSERVACIONES',
          edit_observation_title: 'EDITAR OBSERVACIONES',
          observations: 'Observaciones',
          category: 'Categoría',
          subcategory: 'Subcategoría',
          description: 'Descripción',
          laboratory: 'Laboratorio',
          analysis: 'Tipo de Analisis',
          date: 'Fecha',
          add_observation_title: 'AÑADIR OBSERVACIÓN',
          detail_observation_title: 'DETALLE OBSERVACIÓN',
          geolocation: 'Geolocalización',
          multimedia_content_title: 'CONTENIDO MULTIMEDIA',
          file: 'Archivo',
          attach_file_title: 'ADJUNTAR ARCHIVO',
          attach_file: 'Adjuntar archivo',
          select_file: 'Seleccionar archivo',
          preview_image_title: 'Previsualizar archivo',
          register_observation_point_title: 'REGISTRAR PUNTO DE OBSERVACIÓN',
          point: 'Punto',
          area: 'Area',
          sector: 'Sector',
          name: 'Nombre',
          latitude: 'Latitud',
          longitude: 'Longitud',
          area_surface: 'Area',
          observation_detail_title: 'DETALLE DE OBSERVACIÓN',
          observation_point: 'Punto de Observación',
          template: 'Plantilla',
          geolocationValidation: 'Geolocalización es requerida',
          categoryValidation: 'Categoría es requerida',
          subcategoryValidation: 'Subcategoría es requerida',
          laboratoryValidation: 'Laboratorio es requerida',
          analysisValidation: 'Tipo de analisis es requerida',
          drop_file: 'o Arrastrar fichero aquí',
          dateValidation: 'Fecha es requerida',
          fillFormValidation: 'Rellene todos los campos del formulario',
          deleteMuestreoCategory: 'No está permitido eliminar la categoría Muestreo',
          confirmDate:
            'El análisis introducido se encuentra fuera del periodo de la plantación. ¿Desea continuar?',
        },
        gis: {
          gisTimeDimension: {
            title: 'Línea temporal',
          },
          gisScanning: {
            title: 'Barrido',
          },
          gisWMS: {
            title: 'Índice radiometrico',
          },
        },
        nutritionalPlanActive: 'PLAN NUTRICIONAL ACTIVO',
        lastTreatmentPlan: 'ÚLTIMO PLAN DE TRATAMIENTO',
      },
      treatment: {
        title: 'Tratamiento',
        treatmentPanel: {
          treatmentOrder: 'Orden de tratamiento',
          date: 'Fecha',
          description: 'Descripción',
          product: 'Producto',
          status: 'Estado',
          executed: 'Ejecutado',
          planned: 'Planeado',
          canceled: 'Cancelado',
        },
        treatmentEditOrder: {
          form: {
            title: 'Modificar orden de tratamiento',
            description: 'Descripción',
            dosage: 'Dosificación',
            date: 'Fecha',
            plagueAgent: 'Plaga / Agente',
            plagueAgentPlaceholder: 'Tipo de búsqueda…',
            treatmentType: 'Tipo de tratamiento',
            volume: 'Volumen',
            lHa: 'L/ha',
            foliar: 'Foliar',
            fertigacion: 'Fertigación',
            product_validation: 'El producto no puede estar vacío',
            description_validation: 'La descripción no puede estar vacía',
            date_validation: 'La fecha no puede estar vacía',
            treatmentType_validation: 'Tipo de tratamiento no puede estar vacío',
            dosage_validation: 'Dosificación no puede estar vacía',
            productResume_validation: 'Debe haber al menos 1 producto añadido en la lista',
          },
          products: {
            title: 'Productos',
            area: 'Area',
            ha: 'ha',
            productFinder: 'Buscador de productos',
            typeToSearch: 'Escribe para buscar...',
            add: 'Añadir',
            product: 'Producto',
            dosageRange: 'Rango de dosificación',
            dosageTotalL: 'Dosificación (L/1000L)',
            dosageTotalKg: 'Dosisificación (kg-L/ha)',
            price: 'Precio (€/kg-L)',
            unitCost: 'Coste unitario (€/ha)',
            totalCost: 'Coste total (€)',
            total: 'Total',
          },
          back: 'Volver',
          cancel: 'Cancelar',
          save: 'Guardar',
        },
        treatmentCreateOrder: {
          form: {
            title: 'Registrar orden de tratamiento',
            description: 'Descripción',
            dosage: 'Dosificación',
            date: 'Fecha',
            plagueAgent: 'Plaga / Agente',
            plagueAgentPlaceholder: 'Tipo de búsqueda…',
            treatmentType: 'Tipo de tratamiento',
            volume: 'Volumen',
            lHa: 'L/ha',
            foliar: 'Foliar',
            fertigacion: 'Fertigación',
            product_validation: 'El producto no puede estar vacío',
            description_validation: 'La descripción no puede estar vacía',
            date_validation: 'La fecha no puede estar vacía',
            treatmentType_validation: 'Tipo de tratamiento no puede estar vacío',
            dosage_validation: 'Dosificación no puede estar vacía',
            productResume_validation: 'Debe haber al menos 1 producto añadido en la lista',
          },
          products: {
            title: 'Productos',
            area: 'Area',
            ha: 'ha',
            productFinder: 'Buscador de productos',
            typeToSearch: 'Tipo de búsqueda…',
            add: 'Añadir',
            product: 'Producto',
            dosageRange: 'Rango de dosificación',
            dosageTotalL: 'Dosificación (L/1000L)',
            dosageTotalKg: 'Dosisificación (kg-L/ha)',
            price: 'Precio (€/kg-L)',
            unitCost: 'Coste unitario (€/ha)',
            totalCost: 'Coste total (€)',
            total: 'Total',
          },
          back: 'Volver',
          cancel: 'Cancelar',
          save: 'Guardar',
        },
        treatmentPlan: {
          title: 'Plan de tratamiento',
          description: 'Descripción',
          initDate: 'Fecha de inicio',
          endDate: 'Fecha de fin',
          products: 'Productos',
          cost: 'Coste (€)',
          status: 'Estado',
          changesMessage: 'Los cambios en esta vista se propagarán a las unidades temporales respectivas.',
          treatmentCreatePlan: {
            title: 'Registrar Plan de tratamiento',
            editTitle: 'Modificar Plan de tratamiento',
            titleRegister: 'Registrar tratamiento',
            description: 'Descripción',
            dosage: 'Dosificación',
            dosageCuba: 'Dosificación cuba',
            volumeCuba: 'Volumen por cuba',
            speedCuba: 'Velocidad de avance',
            volume: 'Volumen',
            speed: 'Velocidad',
            temporalFrame: 'Marco temporal',
            plagueAgent: 'Plaga / Agente',
            initDate: 'Fecha de inicio',
            endDate: 'Fecha de fin',
            annotations: 'Anotaciones',
            observations: 'Observaciones',
            productDosage: 'Producto y dosificación',
            productFinder: 'Buscador de productos',
            typeToSearch: 'Tipo de búsqueda...',
            add: 'Añadir',
            product: 'Producto',
            emptyProducts: 'La lista de productos no debe estar vacía',
            dosageRange: 'Rango de dosificación',
            numApplication: 'Sin aplicaciones',
            maxApplications: 'Aplicaciones máximas',
            totalApplications: 'Aplicaciones',
            totalDosage: 'Dosificación total',
            productStack: 'Plan de tratamiento (Stack de productos)',
            resume: 'Resumen',
            area: 'Area',
            ha: 'ha',
            totalDosageL: 'Dosificación total (L/1000L)',
            caldoMedium: 'Vol. Caldo Medio (L/ha)',
            totalDosageKg: 'Dosificación total (kg-L/ha)',
            cost: 'Coste (€/ha)',
            totalCost: 'Coste total (€)',
            description_validation: 'Descripción no puede estar vacía',
            agent_validation: 'Plaga/Agente no pueden estar vacios',
            dose_type_validation: 'Dosificación no puede estar vacía',
            treatmentType: 'T. Tratamiento',
            application: 'Aplicación',
            stepTwo: {
              doseVolumeHa: 'Dosis (Kg-L/ha)',
              doseVolumeL: 'Dosis (Kg-L/1000L)',
              doseTotal: 'Dosis (kg-L Total)',
              doseVat: 'Dosis (kg-L/Cuba)',
              doseVatTotal: 'Dosis Total (kg-L/Cuba)',
              doseTree: 'Dosis (g-ml)/planta',
              doseTreeTotal: 'Dosis Total planta (g-ml)',
              doseKgL: 'Dosis (Kg-L)',
              brothVolumeHa: 'Vol Caldo (L/ha)',
              brothVolumeTree: 'Vol. Caldo (L/planta)',
              brothTotalHa: 'Vol. Caldo Total (l/ha)',
              brothTotalLitre: 'Vol. Caldo Total (L)',
              brothTotal: 'Vol. Caldo Total',
              priceL: 'Precio (€/kg-L)',
              priceVat: 'Precio (€/cuba)',
              costVatTotal: 'Coste/cuba Total €',
              priceTree: 'Precio/planta',
              costHa: 'Coste (€/ha)',
              cost: 'Coste (€)',
              costTotal: 'Coste Total',
              costTotalTree: 'Coste Total planta €',
              vatVolume: 'Vol. cuba',
              vatNumberHa: 'Nº de cubas/ha',
              vatNumber: 'Nº de cubas',
              speed: 'Velocidad de avance',
              totalDose: 'Dosis total',
            },
            forwardSpeedRequired: 'La velocidad de avance es obligatoria.',
            vatVolumeRequired: 'El volumen de cuba es obligatoria.',
          },
          treatmentDetailPlan: {
            title: 'Detalle de plan de tratamiento',
            titleDetail: 'Detalle de orden de tratamiento',
            titleRegister: 'Detalle de tratemiento',
            description: 'Descripción',
            dosage: 'Dosificación',
            temporalFrame: 'Marco temporal',
            plagueAgent: 'Plaga / Agente',
            initDate: 'Fecha inicial',
            endDate: 'Fecha final',
            status: 'Estado',
            productDosage: 'Dosificación de producto',
            chartTitle: 'PLAN DE TRATAMIENTO (STACK DE PRODUCTOS)',
            doseTotal: 'Dosis Total',
            annotation: 'Anotación',
            products: {
              title: 'Productos',
              area: 'Superficie',
              ha: 'ha',
              productFinder: 'Buscador de productos',
              typeToSearch: 'Escribe para buscar...',
              add: 'Agregar',
              product: 'Product',
              dosageRange: 'Rango de dossificación',
              dosageTotalL: 'Dosificación (L / 1000L)',
              dosageTotalKg: 'Dosificación (kg-L / ha)',
              price: 'Precio (€/kg-L)',
              unitCost: 'Costo unitario (€/ha)',
              totalCost: 'Costo total (€)',
              total: 'Total',
            },
            back: 'Volver',
          },
        },
      },
      next: 'Siguiente',
      save: 'Guardar',
      parcel: {
        register: {
          title: 'REGISTRAR PARCELA',
          name: {
            field: 'Nombre de la parcela',
            caption: 'Nombre de la parcela',
            placeholder: 'Nombre de la parcela',
            requiredRule: 'El nombre es requerido',
          },
          geofeatures: {
            requiredRule: 'La parcela debe tener un polígono asociado',
          },
          step1: 'Paso 1',
          step2: 'Paso 2',
          registeredParcel: 'PARCELA REGISTRADA',
          completedSuccessfullyParcelRegister: 'Has registrado la parcela con éxito.',
          completedSuccessfullyParcelUpdated: 'Has actualizado la parcela con éxito.',
        },
        detail: {
          title: 'DETALLE DE PARCELA',
          client: 'Cliente',
          location: 'Explotación',
          parcelName: 'Nombre de la parcela',
          area: 'Superficie',
        },
      },
      registerPlantation: {
        title_register_plantation: 'REGISTRAR PLANTACIÓN',
        plantationName: 'Nombre de Plantación',
        cultive_type: 'Tipo de cultivo',
        protected: 'Protección',
        manejo: 'Manejo',
        init_date: 'Fecha inicio',
        end_date: 'Fecha finalización',
        specie_botanic: 'Especie botánica',
        plantation_frameX: 'Marco plantación X',
        plantation_frameY: 'Marco plantación Y',
        distance_between_streets: 'Distancia entre calles',
        distance_between_feets: 'Distancia entre pies',
        plants_number: 'Número de plantas',
        variety: 'Variedad',
        plants_density: 'Densidad de plantas',
        plantation_date: 'Fecha plantación',
        cup_diameter: 'Diámetro de copa',
        planting_density: 'Densidad de siembra',
        unit: 'unidad',
        required_plantation_name: 'La descripción de la plantación es obligatorio.',
        required_plantation_management: 'El manejo de la plantación es obligatorio.',
        required_cropType: 'El tipo de cultivo de la plantación es obligatorio.',
        required_protection: 'El tipo de protección de la plantación es obligatorio.',
        required_init_date: 'Las fechas son obligatoria.',
        required_end_date: 'La fecha de finalización es obligatoria.',
        required_endDate_bigger: 'La fecha de fin no puede ser anterior a la de inicio.',
        required_specie_selected: 'La especie de la plantación es obligatoria.',
        required_plants_number: 'El número de plantas es obligatorio.',
        required_plantation_variety: 'La variedad es obligatoria.',
        required_plants_density: 'La densidad de la plantación es obligatoria.',
        required_unit: 'La unidad es obligatoria',
        required_init_date_be_in_range:
          'La fecha de inicio de la plantación no puede ser inferior al inicio de la campaña seleccionada',
        required_end_date_be_in_range:
          'La fecha de fin de la plantación no puede ser superior al final de la campaña seleccionada',
        popUpRegisterPlantation_title: 'Selecciona la parcela y el sector.',
        popUpRegisterPlantation_parcels: 'Parcelas',
        popUpRegisterPlantation_sectors: 'Sectores',
        popUpRegisterPlantation_parcel: 'Parcela',
        popUpRegisterPlantation_sector: 'Sector',
        unit_plantas_ha: 'Plantas/ha',
        parcel_is_required: 'La parecela es obligatoria',
        sector_is_required: 'El sector es obligatorio',
        step1: 'PASO 1',
        step2: 'PASO 2',
        subtitle_step1: 'SELECCIÓN DE SECTOR',
        subtitle_step2: 'DEFINICIÓN DE CULTIVO',
        plantation_register: 'Has registrado la plantación con éxito',
        plantation_register_edit: 'Has editado la plantación con éxito',
        register_plantation_complete: 'PLANTACIÓN REGISTRADA',
        required_element_x: 'El elemento X es obligatorio',
        required_element_y: 'El elemento Y es obligatorio',
        required_distance_between_streets: 'La distancia entre calles es obligatoria',
        required_distance_between_feets: 'La distancia entre pies es obligatoria',
        required_cupDiameter: 'El diámetro de la copa es obligatorio',
        required_date_plantation: 'La fecha de plantación es obligaotria',
        title_select_campaign: 'Creación de Plantación',
        subtitle_select_campaign: 'Seleciona la campaña para la creación de plantación',
        required_farm: 'Es necesario seleccionar una explotación',
        required_parcel: 'Es necesario seleccionar una parcela',
        required_sector: 'Es necesario seleccionar un sector',
        required_campaign: 'Es necesario seleccionar una campaña',
        expected_production: 'Producción esperada (t/ha)',
      },
      ViewPlantation: {
        viewPlantationTitle: 'DETALLE PLANTACIÓN',
        view_description: 'Descripción',
        view_campaign: 'Campaña agrícola',
        view_status: 'Estado',
        view_client: 'Cliente',
        view_init_date: 'Fecha de Inicio',
        view_end_date: 'Fecha de fin',
        view_explotation: 'Explotación',
        view_name_parcel: 'Nombre parcela',
        view_name_sector: 'Nombre sector',
        view_superficie: 'Superficie',
        view_textura_suelo: 'Textura del suelo',
        view_system_irrigation: 'Sistema de riego',
        view_caudal: 'Caudal',
        view_goteros: 'Goteros por planta',
        view_specie: 'Especie botánica',
        view_variety: 'Variedad',
        view_crop_type: 'Tipo de cultivo',
        view_protection: 'Protección',
        view_management: 'Manejo',
        view_date_plantation: 'Fecha de plantación',
        view_planting_frame: 'Marco de plantación',
        view_planting_unit: 'Unidad',
        view_planting_density: 'Densidad de siembra',
        view_density_plants: 'Densidad de plantas',
        view_number_plants: 'Número de plantas',
        view_cup_diameter: 'Diámetro de copa',
        goToSearcher: 'Buscador',
        view_number_sprinkler_number: 'Número de aspersores',
        transformedArea: 'Superficie transformada',
        plants_ha_unit: 'plantas/ha',
        expected_production: 'Producción esperada',
      },
      DxRegisterCampania_actual_plot: 'La Explotación es obligatoria',
      blank_agent: 'El producto es obligatorio',
      Not_controled: 'Error inesperado. Si este continúa, contacte con soporte. Disculpe las molestias',
      token_not_valid: 'La sesión ha caducado',
      Next: 'Siguiente',
      sector: {
        register: {
          title: 'REGISTRAR SECTOR',
          step1: 'Paso 1',
          step2: 'Paso 2',
          sectorName: 'Nombre de sector',
          groundTexture: 'Textura del suelo',
          irrigationType: 'Sistema de riego',
          flowPerPlant: 'Caudal por gotero (L/h)',
          dripperPerPlant: 'Goteros por planta (Unidades)',
          sprinklersNumber: 'Número de aspersores (Unidades)',
          flow360: 'Caudal en 360º (l/h)',
          transformedArea: 'Superficie transformada (m²)',
          separationBetweenLines: 'Separación entre líneas de aspersores (m)',
          separationBetweenSprinkler: 'Separación entre aspersores dentro de una misma línea (m)',
          flow: 'Caudal (L/h)',
          formValidation: 'Existen campos sin rellenar',
          registerValidation:
            'No se puedo completar la operación, por favor, realice de nuevo el proceso de registro',
          gisValidation: 'Error al dibujar el sector, por favor, dibuje de nuevo el polígono deseado',
          registeredSector: 'SECTOR REGISTRADO',
          completedSuccessfullySectorRegister: 'Has registrado el sector con éxito.',
          completedSuccessfullySectorUpdate: 'Has actualizado el sector con éxito.',
          finish: 'Finalizar',
          required_name: 'El nombre es necesario',
          required_groundTexture: 'Es necesario seleccionar la textura del suelo',
          required_irrigationType: 'Es necesario seleccionar el sistema de riego',
          required_flowPerPlant: 'Es necesario especificar el caudal',
          required_dripperPerPlant: 'Es necesario especificar los goteros por planta',
          required_sprinklersNumber: 'Es necesario especificar el número de aspersores',
          required_transformedArea: 'Es necesario especificar la superficie transformada',
          requiredGeometry: 'El sector debe tener un polígono asociado',
        },
        autoSector: {
          promptHeader: 'Primer sector en la parcela',
          prompt: '¿Quiere asignarle al sector la superficie completa de la parcela?',
          no: 'No',
          yes: 'Sí',
        },
        grid: {
          registeredSectors: 'SECTORES REGISTRADOS',
          sectorName: 'Nombre de sector',
          groundTexture: 'Textura del suelo',
          irrigationType: 'Sistema de riego',
          area: 'Superficie (ha)',
          createSector: 'Crear sector',
        },
        detail: {
          title: 'DETALLE SECTOR',
          client: 'Cliente',
          location: 'Explotación',
          parcelName: 'Nombre de parcela',
          sectorName: 'Nombre de sector',
          area: 'Superficie',
          groundTexture: 'Textura del suelo',
          irrigationType: 'Sistema de riego',
          flowPerPlant: 'Caudal por gotero (L/h)',
          dripperPerPlant: 'Goteros por planta',
          sprinklersNumber: 'Número de aspersores',
          flow360: 'Caudal en 360º (l/h)',
          transformedArea: 'Superficie transformada',
          separationBetweenLines: 'Separación entre líneas de aspersores',
          separationBetweenSprinkler: 'Separación entre aspersores dentro de una misma línea',
          flow: 'Caudal',
          m: 'm',
          m2: 'm²',
          lH: 'L/h',
        },
      },
      plantations: {
        grid: {
          plantations: 'PLANTACIONES',
          botanicSpecie: 'Especie botánica',
          campaign: 'Campaña',
          initDate: 'Fecha de inicio',
          endDate: 'Fecha de finalización',
          status: 'Estado',
          active: 'Activa',
          end: 'Finalizada',
          pending: 'Pendiente',
          createPlantation: 'Crear plantación',
        },
        delete_title: 'ELIMINAR PLANTACIÓN',
        delete_message:
          '¿Desea eliminar la plantación seleccionada? Se perderá toda la información asociada y no podrá volver a ser consultada.',
        confirmDateChange:
          'La plantación tiene registros de actividad. Este cambio puede afectarles y será necesario actualizarlos manualmente.',
      },
      not_authorization: 'Su sesión ha caducado. Por favor, vuelva a hacer login',
      invalid_credentials: 'El usuario o contraseña son incorrectos',
      authentication_failed: 'No tiene acceso. Por favor, vuelva a hacer login',
      parcel_system: 'Sistema parcelario',
      select_placeholder: 'Seleccionar...',
      sectors: 'Sectores',
      period: 'Ya existe una plantación en el mismo periodo',
      parcels: 'Parcelas',
      Temperature: 'Temperatura',
      Precipitation: 'Precipitación',
      Evaporacion: 'Evapotranspiración',
      name: 'Nombre',
      weather_client: 'Cliente',
      weather_client_placeholder: 'Seleccione cliente',
      weather_plantation: 'Plantación',
      GISGeoJsonMap_not_observations: 'Se requiere una observación',
      GISGeoJsonMap_not_locations: 'Una parcela es requerida',
      client: 'Cliente',
      area: 'Área',
      Register_fertilization_plan: 'Registrar plan de NUTRICIÓN',
      nutrition_reference_value_info: 'Unidades Fertilizantes (UF) Recomendadas (kg/ha)',
      nutrition_needs_info: 'Unidades Fertilizantes (UF) (kg/ha)',
      requiredNutritionFertiliser: 'El campo fertilizante no puede estar vacío',
      GIS: 'GIS',
      layerOptions_select_index: 'Índice',
      formAction_Alert_EnableGPS: 'Debe conceder permisos de ubicación y habilitar el GPS',
      searchEngine_gis_view: 'No hay explotaciones cerca de tu posición actual',
      Explotation: 'Explotación',
      Parcel: 'Parcela',
      Sector: 'Sector',
      Plantation: 'Plantación',
      Go: 'Ir',
      type: 'Tipo',
      date: 'Fecha',
      action: 'Acción',
      PUT: 'Actualizar',
      POST: 'Crear',
      DELETE: 'Eliminar',
      Register: {
        resume: {
          title: 'RESUMEN DE REGISTROS',
        },
        type: {
          Fertilization: 'Planes de nutrición',
          Campaign: 'Campaña',
          Climate: 'Clima',
          FertilizationPlan: 'Plan de nutrición',
          Irrigation_recommendation: 'Recomendaciones de riego',
          Irrigation_register: 'Registro de riego',
          Irrigation_register_doses_valitaion: 'La dosis de riego no puede estar vacía',
          LaboratoyAnalisis: 'Análisis de laboratorio',
          Location: 'Sistema parcelario',
          Plantation: 'Plantación',
          Product: 'Productos fitosanitarios',
          ScoutingPoint: 'Punto de observación',
          Scouting: 'Observaciones',
          TaskDefinition: 'Tipo de tarea',
          Task: 'Tareas',
          Template: 'Plantilla de observaciones',
          TreatmentOrder: 'Ordenes de tratamiento',
          TreatmentPlan: 'Plan de tratamiento',
          Variety: 'Variedad',
        },
      },
      Plans: {
        description: 'Descripción',
        title: 'ANÁLISIS > PLANES',
        LeftPanel_subtitle_plans: 'Planes',
        planName: 'Nombre del plan',
        crop: 'Cultivo',
        variety: 'Variedad',
        province: 'Provincia',
        cost: 'Coste €/ha',
        timeFrame: 'Marco temporal',
        atcOwner: 'ATC Propietario',
        fertilizations: 'Nutrición',
        treatments: 'Tratamientos',
        details: 'DETALLES',
      },
      DxSales_species: 'Especie',
      register_location_required_province: 'Es necesario seleccionar una provincia',
      register_location_required_locality: 'Es necesario seleccionar una localidad',
      register_location_postalCode: 'Códgio postal',
      register_location_placeholder_postalCode: 'Escriba código postal',
      Login_Azure: 'Acceso con Azure',
      Gis: {
        Editor: {
          PopupGetGeometry: 'Obtener geometría',
          PopupMeasureResult: 'Medición',
          PopupMeasureHas: 'Hectáreas',
          PopupMeasureKm: 'Kilómetros',
          PopupMeasureArea: 'Área',
          PopupMeasureLineString: 'Distancia lineal',
          SectorOverlaps: 'El sector creado se sobrepone a otros sectores ya existentes.',
        },
        Buttons: {
          ZoomHome: 'Zoom a casa',
          CurrentPosition: 'Localización actual',
          DownloadImage: 'Descargar imagen de la capa',
          CoordinatesSearcher: 'Buscar por coordenadas',
          SigpacSearcher: 'Buscador SIGPAC',
          MeasureArea: 'Medir área',
          MeasureLineString: 'Medir distancia lineal',
          DesactiveMeasureMode: 'Desactivar modo medición',
          ActiveMeasureMode: 'Activar modo medición',
          ActiveMergeMode: 'Activar modo unión',
          ConfirmMerge: 'Confirmar unión',
          DesactiveMergeMode: 'Desactivar modo unión',
          Opacity: 'Cambia la opacidad de la capa',
          Zoning: 'Cambia el rango de valor de la capa',
          SearcherGeometries: 'Buscar en esta zona',
        },
        Alerts: {
          AdjacentGeometry: 'El polígono seleccionado no es adyacente a las geometrías seleccionadas',
          NumberExceededGeometries: 'Número excedido de parcelas seleccionadas',
          NumberMinimumPlots: 'Seleccione más de una parcela',
          NoPlotsInRange: 'No hay parcelas para el rango seleccionado',
          downloadingImage: 'Descargando imagen',
        },
        SigpacReference: 'Referencia SIGPAC',
        Province: 'Provincia',
        Municipality: 'Municipio',
        Aggregate: 'Agregado',
        Zone: 'Zona',
        Polygon: 'Polígono',
        Plot: 'Parcela',
        Enclosure: 'Recinto',
        Search: 'Buscar',
        GeometryNotFound:
          'Los valores intoducidos no pertenecen a ningún recinto SIGPAC. Por favor, revise los valores introducidos.',
        SigpacFormIncomplete:
          'Se deben introducir valores en todos los campos. Por favor, vuelva a realizar la búsqueda.',
      },
      irrigation_graph_precipitation: 'Precipitación acumulada',
      irrigation_graph_evaporation: 'Evaporación de referencia',
      Humidity: 'Humedad',
      SolarRadiation: 'Radiación solar',
      WindSpeed: 'Velocidad viento',
      error_range_date:
        'El rango de fechas máximo de la solicitud es de 30 días. Por favor, ajusta las fechas y reintenta la consulta.',
      weather_agrupation_data: 'Agrupación dato:',
      weather_parameter: 'Parámetro',
      weather_parameter_value: 'Valor',
      price: 'Precio',
      treatmentError: {
        error: ' del plan ha sido retirado del catálogo, por favor revisa la dosificación',
      },
      irrigationAnalysis: {
        eto: 'ETO(Acumulada)',
        irrigation: 'Riego(Acumulado)',
        pluviometry: 'Pluviometria(Acumulada)',
      },
      product: 'Producto',
      AnalysisGis: {
        LeftPanel_subtitle_Gis: 'GIS',
        title: 'ANÁLISIS > GIS',
        compare: 'Comparar',
        left: 'IZQ',
        right: 'DCHA',
      },
      id_popup: 'ID',
      parcel_popup: 'Parcela',
      sector_popup: 'Sector',
      name_popup: 'Nombre',
      date_popup: 'Fecha',
      category_popup: 'Categoría',
      subcategory_popup: 'Subcategoría',
      explotation_popup: 'Explotación',
      company_id_popup: 'Id Compañía',
      dateRange: 'Rango de fechas',
      information: 'INFORMACIÓN',
      histogram: 'HISTOGRAMA',
      timeline: 'LÍNEA DEL TIEMPO',
      index: 'Índice',
      count: 'Acumulado',
      class: 'Clase',
      mean: 'Media',
      MeasuringFrequency: {
        HOURLY: 'HORARIOS',
        DAILY: 'DIARIOS',
      },
      WeatherParameter: {
        TEMPERATURE: 'TEMPERATURA MEDIA',
        TEMPERATURE_MIN: 'TEMPERATURA MÍNIMA',
        TEMPERATURE_MAX: 'TEMPERATURA MÁXIMA',
        PLUVIOMETER: 'PLUVIOMETRÍA',
        HUMIDITY_AVG: 'HUMEDAD MEDIA',
        HUMIDITY_MAX: 'HUMEDAD MÁXIMA',
        HUMIDITY_MIN: 'HUMEDAD MÍNIMA',
        HUMIDITY: 'HUMEDAD MEDIA',
        WIND_SPEED: 'VELOCIDAD DEL VIENTO MEDIA',
        WIND_SPEED_AVG: 'VELOCIDAD DEL VIENTO MEDIA',
        WIND_SPEED_MAX: 'VELOCIDAD DEL VIENTO MÁXIMA',
        EVAPO_ETO_PEMON: 'ET0',
        CLOUD_COVER: 'NUBOSIDAD',
        SOLAR_RADIATION: 'RADIACIÓN SOLAR MEDIA',
      },
      Sync: {
        InfoUpdate: {
          Title: 'Actualización de datos',
          Text: 'La sincronización con el servidor es necesaria para que puedas seguir trabajando incluso sin conexión.',
        },
        InfoLoading: {
          Title: 'Cargando datos…',
          Text: 'Este proceso puede durar unos minutos, según la velocidad de la conexión del dispositivo.',
        },
        TextError: 'Error:',
        ButtonSync: 'Sincronizar',
      },
      weekDays: {
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sábado',
        Sunday: 'Domingo',
      },
      chooseDatesError: 'Debe seleccionar fechas antes de filtrar',
      pendingRegister: 'Registro pendiente de finalizar',
      pendingRegisterMessage: 'Si no completas el proceso perderás los cambios sin guardar',
      pendingObservationMessage: 'Si no guardas la observación perderás el ID de la muestra, siendo necesario generar uno nuevo.',
      continue: 'Continuar',
      abandon: 'Abandonar',
      months: 'Meses',
      years: 'Años',
      cropAge: 'Edad del cultivo',
      competence: 'Competencia',
      ContextMenu: {
        Delete: 'Eliminar',
        Edit: 'Editar',
        Copy: 'Copiar',
        Download: 'Descargar',
        Share: 'Compartir',
        saveReferencePlan: 'Guardar Plan Referencia',
      },
      noDataInRange: 'No existen datos para el rango seleccionado',
      BoundsAreNotValid:
        'No existe geometría para mostrar en el visor. Por favor, regístrala con el formulario.',
      Yes: 'Sí',
      No: 'No',
      multimediaFileError: 'Archivo multimedia no soportado.',
      sampleIdDuplicate: 'El id ya esta duplicado, generando uno nuevo',
      productDeleted: 'Este producto ha sido eliminado. Por favor reemplázarlo por uno nuevo.',
      productsDeleted: 'Existen productos que han sido eliminados. Por favor, reemplacelos por nuevos',
      dates: 'Fechas',
      attributes: 'Atributos',
      warning: 'AVISO',
      enclosureSigpacTable: {
        title: 'Recintos SIGPAC solapados',
        reference: 'Referencia del recinto',
        overlap: 'Sup. Solape',
        enclosure: 'Sup. Recinto',
      },
      DateRangeInvalidPlan: 'Las fechas del plan no son válidas para la plantación.',
      itemsToDisplay: 'ELEMENTOS A MOSTRAR',
      weatherVariables: 'VARIABLES CLIMÁTICAS',
      comments: 'COMENTARIOS',
      availableDates: 'Fechas disponibles',
      limitSelectedDates: 'Solo puedes seleccionar 6 fechas',
      imagesToDisplay: 'IMÁGENES A MOSTRAR',
      downloadingPDF: 'Descargando PDF',
      generatingPDF: 'Generando PDF para su descarga',
      generatingPDFShare: 'Generando PDF para compartir',
      generatingFileForShare: 'Generando fichero para compartir',
      fileDownloadError: 'Descarga fallida',
      fileSharedSuccessfully: 'Archivo compartido con éxito',
      fileShareNotSupported: 'Su navegador no es compatible para compartir archivos',
      observationsToBeDisplayed: 'OBSERVACIONES A MOSTRAR',
      viewParcel: 'Ver parcela',
      viewSector: 'Ver sector',
      searching: 'Buscando...',
      updateRequired: 'Debe actualizar la versión de la aplicación',
      unitaries: 'Unitarios',
      totals: 'Totales',
      noLeftHistogram: 'No hay datos para el histograma izquierdo',
      noRightHistogram: 'No hay datos para el histograma derecho',
      dateRangeSelector: 'SELECTOR DE RANGO DE FECHAS',
      noImagesToDisplayLeft: 'No hay imagen satelital izquierda para mostrar.',
      noImagesToDisplayRight: 'No hay imagen satelital derecha para mostrar.',
      weatherWidget: {
        icon_200: 'Tormenta con lluvia ligera',
        icon_201: 'Tormenta con lluvia',
        icon_202: 'Tormenta con lluvia fuerte',
        icon_230: 'Tormenta con llovizna',
        icon_231: 'Tormenta con llovizna',
        icon_232: 'Tormenta con llovizna intensa',
        icon_233: 'Tormenta con granizo',
        icon_300: 'Llovizna ligera',
        icon_301: 'Llovizna',
        icon_302: 'Llovizna intensa',
        icon_500: 'Lluvia ligera',
        icon_501: 'Lluvia moderada',
        icon_502: 'Lluvia fuerte',
        icon_511: 'Lluvia helada',
        icon_520: 'Lluvia ligera',
        icon_521: 'Lluvia de chubascos',
        icon_522: 'Chubasco fuerte',
        icon_600: 'Nieve ligera',
        icon_601: 'Nieve',
        icon_602: 'Nieve fuerte',
        icon_610: 'Mezcla de nieve/lluvia',
        icon_611: 'Aguanieve',
        icon_612: 'Aguanieve fuerte',
        icon_621: 'Lluvia de nieve',
        icon_622: 'Fuerte chubasco de nieve',
        icon_623: 'Ráfagas',
        icon_700: 'Niebla',
        icon_711: 'Humo',
        icon_721: 'Niebla',
        icon_731: 'Arena/polvo',
        icon_741: 'Niebla',
        icon_751: 'Niebla helada',
        icon_800: 'Cielo despejado',
        icon_801: 'Pocas nubes',
        icon_802: 'Nubes dispersas',
        icon_803: 'Nubes rotas',
        icon_804: 'Nubes nubladas',
        icon_900: 'Precipitación desconocida',
      },
      targetCampaign: 'Campaña de destino',
      elementsToCopy: 'Elementos a copiar',
      plantationDate: 'Fecha de plantación',
      copyNutritionalPlans: 'Planes nutricionales',
      copyTreatmentPlans: 'Planes de tratamiento',
      copyIrrigationPlans: 'Planes de riego',
      copyAnalytics: 'Analíticas',
      copyingPlantation: 'Copiando plantación',
      copyingNutritionalPlans: 'Copiando planes nutricionales',
      copyingTreatmentPlans: 'Copiando planes tratamiento',
      copyingIrrigationPlans: 'Copiando planes de riego',
      copyingAnalytics: 'Copiando Analíticas',
      useParcelGeometry: 'Utilizar geometría de la parcela para el sector',
      weekFirstLetter: 'S',
      weekFullName: 'Semana',
      monthFullName: 'Mes',
      monthShortToFull: {
        Jan: 'Enero',
        Feb: 'Febrero',
        Mar: 'Marzo',
        Apr: 'Abril',
        May: 'Mayo',
        Jun: 'Junio',
        Jul: 'Julio',
        Aug: 'Agosto',
        Sep: 'Septiembre',
        Oct: 'Octubre',
        Nov: 'Noviembre',
        Dec: 'Diciembre',
      },
      monthFull: {
        January: 'Enero',
        February: 'Febrero',
        March: 'Marzo',
        April: 'Abril',
        May: 'Mayo',
        June: 'Junio',
        July: 'Julio',
        August: 'Agosto',
        September: 'Septiembre',
        October: 'Octubre',
        November: 'Noviembre',
        December: 'Diciembre',
      },
      temperature: 'Temperatura',
      humidity: 'Humedad',
      solar_radiation: 'Radiación solar',
      wind_speed: 'Velocidad viento',
      pluviometer: 'Precipitación',
      average: 'Media',
      forecasted: 'Predictiva',
      averageForecasted: 'Media Predictiva',
      minForecasted: 'Mínima Predictiva',
      maxForecasted: 'Máxima Predictiva',
      totalForecasted: 'Total Predictivo',
      gisNotEnoughTime:
        'Procesamiento de imágenes de teledetección en ejecución. Estarán disponibles en breve',
      copySuccess: 'Copiado correctamente',
      active_campaign: 'Activa',
      finished_campaign: 'Finalizada',
      pending_campaign: 'Pendiente',
      navigatorShareNotSupported: 'Su navegador no soporta la API de Web Share',
      sharePdfSuccess: 'Compartido correctamente',
      sharePdfError: 'Error al compartir',
      confirmSharePdf: '¿Quiere compartir el informe?',
      downloadReportButton: 'Descargar',
      downloadReportError: 'Error en llamada GIS: No data',
      shareReportButton: 'Compartir',
      noClimateData: 'No existen datos meteorológicos para la plantación',
      gisGenericError: 'Problema al cargar datos GIS. Por favor, inténtalo de nuevo más tarde.',
      ACTIVA: 'ACTIVA',
      FINALIZADA: 'FINALIZADA',
      PENDIENTE: 'PENDIENTE',
      noObservationsToSelect: 'No hay observaciones para seleccionar',
      noTasksToSelect: 'No hay tareas para seleccionar',
      copyPlantation: 'Copiar plantación',
      noDateRangeSelected: 'No se ha seleccionado ningún rango de fechas',
      mustSelectPoint: 'Debe seleccionar un punto en el mapa',
      thumbnail: 'Miniatura',
      applicationEfficiencyPerPeriod: 'Aplicación de UF eficientes en cada periodo',
      confirmLeavePage: {
        generalExploitationCreation:
          'Antes de abandonar la página, tenga en cuenta que solo se mostrarán las explotaciones, sectores y parcelas que tengan plantaciones. ¿Está seguro de que desea salir?.',
        parcelCreation: '¿Está seguro de que desea salir? Se perderá la explotación creada.',
        parcelEdition: '¿Está seguro de que desea salir?',
        sectorCreation: '¿Está seguro de que desea salir? Se perderá la explotación y el sector creado.',
        sectorEdition: '¿Está seguro de que desea salir?',
      },
      modalCopyReferencePlan_info_treatment: 'Seleccione la fecha de inicio de los planes de tratamiento que va a generar',
      modalCopyReferencePlan_info_nutrition: 'Seleccione la fecha de inicio de los planes de nutrición que va a generar',
      modalCopyReferencePlan_info_reference: 'Seleccione la fecha de inicio de los planes de referencia que va a generar',
      exclusionDates: {
        list: 'Fechas excluidas',
        add: 'Añadir fecha excluida',
        format: 'aaaa-mm-dd',
      },
      mapbox: {
        controls: {
          home: 'Centrar mapa',
          style: {
            satelliteStreets: 'Callejero satelital',
            light: 'Claro',
            dark: 'Oscuro',
            streets: 'Callejero',
            outdoors: 'Exteriores',
          },
          sigpac: 'SIGPAC',
          removeLastVertex: 'Eliminar último vértice',
          errorRemoveLastVertex: {
            not_polygon_selected: 'Debe seleccionar un polígono',
            feature_selected_is_not_polygon: 'La geometría seleccionada no es un polígono',
            must_have_more_4_vertices: 'El polígono debe tener al menos 4 vértices',
          }
        },
        sigpac: {
          popup: {
            buttonLabel: 'Obtener geometrías',
          },
          form: {
            invalidValues: 'Valores del formulario, vacíos o no válidos',
            noResults: 'No hay parcelas con la información que ha proporcionado',
            buttonSearch: 'Buscar SIGPAC',
            buttonShow: 'Mostrar busqueda SIGPAC',
            buttonHide: 'Ocultar busqueda SIGPAC',
            provinceRequired: 'La provincia es obligatoria cuando selecciona un municipio',
            municipalityRequired: 'El municipio es obligatorio cuando selecciona un agregado',
            aggregateRequired: 'El agregado es obligatorio cuando selecciona una zona',
            zoneRequired: 'La zona es obligatoria cuando selecciona un polígono',
          },
        },
        measurement: {
          area: 'Superficie',
          distance: 'Distancia',
          controlTitle: 'Activar modo de medición',
          lineControlTitle: 'Medir distancia lineal',
          areaControlTitle: 'Medir superficie',
          closeControlTitle: 'Desactivar modo de medición',
        },
      },
      properties: {
        agronomic_zone: "Zona agronómica",
        country: "País",
        explotation: "Parcela",
        locality: "Localidad",
        mobile: "Móvil",
        number_register: "Número de registro",
        owner_Farm: "Propietario",
        postal_code: "Código postal",
        province: "Provincia",
        municipality: "Municipio",
        aggregate: "Agregado",
        zone: "Zona",
        polygon: "Polígono",
        parcel: "Parcela",
        enclosure: "Recinto",
        dnOid: "Referencia SIGPAC",
        sector: "Sector",
      },
      popupSelectObservationsTaskReport: {
        initDate: 'Fecha inicio',
        endDate: 'Fecha fin',
        date: 'Fecha',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        tasks: 'Tareas',
        observations: 'Observaciones'
      }
    },
  },
};
export default dictionary;
