<template>
  <f7-page>
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Utilities.nutrition')}`" />
    <PopupConfirmWizardExit />
    <stepper />
    <nutritional-balance />
    <stepper-navigation />
  </f7-page>
</template>
<script>
import { mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '@/components/wizardNutrition/stepper-navigation/index.vue';
import NutritionalBalance from '@/components/wizardNutrition/nutritionalBalance/index.vue';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';

export default {
  name: 'NutritionStepTwo',
  components: {
    navbar, Stepper, StepperNavigation, NutritionalBalance, PopupConfirmWizardExit,
  },
  props: [],
  data() {
    return {

    };
  },
  computed: {
    ...mapState('Plantation', ['currentPlantation']),
  },
  mounted() {

  },
  methods: {

  },
};

</script>
<style src="./nutrition-step-two.scss" scoped lang="scss"></style>
