import Request from './Request';
import Api from './Api';
import Helpers from '../js/helpers';

const treatmentOrderStatus = {
  EXECUTED: 'ACTIVO',
  CANCELLED: 'CANCELADO',
  PLANNED: 'PLANEADO',
};

const treatmentOrderService = {

  async getAll({ companyId, idPlantation = '' }) {
    let url = `${Api.getBaseUrl()}/treatment_order`;
    if (idPlantation !== '') {
      url += `?plantation=${idPlantation}`;
    }
    const xhr = await Request.async(url,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async getById(id, companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_order/${id}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async edit(id, dataPayload, companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_order/${id}`,
      { data: JSON.stringify(dataPayload) },
      'PUT', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async delete(id, companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_order/${id}`,
      { data: {} },
      'DELETE', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return xhr;
  },
  async save(dataPayload, companyId) {
    try {
    const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_order`,
      { data: JSON.stringify(dataPayload) },
      'POST', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
      return JSON.parse(xhr.response);
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },

};

export default treatmentOrderService;

export { treatmentOrderService, treatmentOrderStatus };
