<template>
  <f7-page class="nutrition-step-five">
    <section id="nutrition-step-five">
      <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Utilities.nutrition')}`" />
      <PopupConfirmWizardExit />
      <stepper />
      <element-resume />
      <product-resume :plantationData="actualPlantation" />
      <observations />
      <stepper-navigation
        :disabled="disabledSaveButton"
        @save="save"
      />

      <DxLoadPanel
        v-model:visible="loadingVisible"
        :position="position"
        shading-color="rgba(0,0,0,0.4)"
      />
    </section>
  </f7-page>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import navbar from '../../../components/NavBar.vue';
import Stepper from '../../../components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '../../../components/wizardNutrition/stepper-navigation/index.vue';
import ElementResume from '../../../components/wizardNutrition/elementResume/index.vue';
import ProductResume from '../../../components/wizardNutrition/productResume/index.vue';
import { fertilizationService } from '../../../services/fertilizationService';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';
import Observations from '../../../components/wizardNutrition/observations/index.vue';

export default {
  name: 'NutritionStepFive',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    ProductResume,
    ElementResume,
    DxLoadPanel,
    PopupConfirmWizardExit,
    Observations,
  },
  props: [],
  data() {
    return {
      loadingVisible: true,
      position: { of: '#nutrition-step-five' },
      disabledSaveButton: false,
    };
  },
  computed: {
    ...mapState('RegisterPlantation', ['actualPlantation']),
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapGetters('referenceValueStore', ['referenceValues']),
    ...mapState('nutritionStepper', ['productsNotDeleted', 'isCopying', 'copyingStepData']),
    ...mapState('Plantation', ['currentPlantation', 'isPlantationSelected']),
    ...mapState('fertilizationStore', ['currentFertilizationId']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
  },
  mounted() {
    this.setProductsNotDeleted(false);

    if (this.stepData.dosageProduct.some((product) => product?.product_deleted ?? false)) {
      this.setProductsNotDeleted(true);
    }
  },
  methods: {
    getIndexIrrigationTable(irrigationTableData, index) {
    let irrigationDosesValues = [];
    let irrigationProductsValues = [];

    // Iterar sobre cada objeto en el array de irrigation Table
    irrigationTableData.forEach((item) => {
        // Obtenemos los nombres de los atributos dinámicamente
        let irrigationDoseKey = `periods_dose_${index}`;
        let irrigationProductKey = `periods_product_irrigation_${index}`;

        // Verificar si irrigationDoseValue es NaN, si es así, asigna 0
       let irrigationDoseValue = isNaN(item[irrigationDoseKey]) ? '0.0' : parseFloat(item[irrigationDoseKey]).toFixed(1);

        // Verificar si irrigationProductValue es null, si es así, asigna 0
        let irrigationProductValue = item[irrigationProductKey] === null ? 0 : item[irrigationProductKey];        

        // Agregar los valores a los arrays respectivos
        irrigationDosesValues.push(irrigationDoseValue);
        irrigationProductsValues.push(irrigationProductValue);
    });
    return {
      periods_dose: irrigationDosesValues,
      periods_product_irrigation: irrigationProductsValues
    };
    },
    async save() {
      this.disabledSaveButton = true;
      if (this.stepData.dosageProduct.some((product) => product?.product_deleted ?? false)) {
        this.$notifyDX(
          {
            message: this.$t('productsDeleted'),
            width: 550,
          },
          'error',
          3000,
        );
        Promise.resolve();
        return;
      }

      this.loadingVisible = true;
      try {
        // A veces estos valores están vacíos y el error no está controlado.
        // if (this.referenceValues.length == 0) {
        //   throw new Error(this.$t('nutritionDetail.emptyReferenceValues'));
        // }

        const payload = {
          version: '2.2.0',
          plantation: this.currentPlantation.id,
          description: this.stepData.planDescription,
          init_date: moment(this.stepData.initDate).format('YYYY-MM-DD'),
          end_date: moment(this.stepData.endDate).format('YYYY-MM-DD'),
          observation: this.stepData.observation?.length === 0 ? null : this.stepData.observation,
          period: this.stepData.temporalFrame.value,
          periods_irrigation_time: this.stepData.irrigationTable.map((item) => item.periods_irrigation_time),
          periods_irrigation: this.stepData.irrigationTable.map((item) => item.periods_irrigation),
          reference_values: {
            n:
              this.referenceValues?.[0]?.n ?? null,
            p205:
              this.referenceValues?.[0]?.p ?? null,
            k20:
              this.referenceValues?.[0]?.k ?? null,
            ca0:
              this.referenceValues?.[0]?.c ?? null,
            mg0:
              this.referenceValues?.[0]?.m ?? null,
            s03:
              this.referenceValues?.[0]?.s ?? null,
            production:
              this.referenceValues?.[0]?.production ?? null,
          },
          nutritional_needs_reference: {
            n: parseFloat(this.stepData.nutritionalNeeds[0].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalNeeds[0].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalNeeds[0].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalNeeds[0].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalNeeds[0].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalNeeds[0].s).toFixed(3),
            expected_production: parseFloat(this.stepData.nutritionalNeeds[0].production ?? 1).toFixed(3),
          },
          nutritional_needs: {
            n: parseFloat(this.stepData.nutritionalNeeds[1].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalNeeds[1].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalNeeds[1].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalNeeds[1].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalNeeds[1].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalNeeds[1].s).toFixed(3),
            production: parseFloat(this.stepData.nutritionalNeeds[1].production).toFixed(3),
          },
          uf_available_soil: {
            n: parseFloat(this.stepData.nutritionalBalanceData[1].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalBalanceData[1].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalBalanceData[1].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalBalanceData[1].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalBalanceData[1].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalBalanceData[1].s).toFixed(3),
          },
          uf_irrigation_water: {
            n: parseFloat(this.stepData.nutritionalBalanceData[2].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalBalanceData[2].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalBalanceData[2].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalBalanceData[2].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalBalanceData[2].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalBalanceData[2].s).toFixed(3),
          },
          uf_rest_harvest: {
            n: parseFloat(this.stepData.nutritionalBalanceData[3].n).toFixed(3),
            p205: parseFloat(this.stepData.nutritionalBalanceData[3].p).toFixed(3),
            k20: parseFloat(this.stepData.nutritionalBalanceData[3].k).toFixed(3),
            ca0: parseFloat(this.stepData.nutritionalBalanceData[3].c).toFixed(3),
            mg0: parseFloat(this.stepData.nutritionalBalanceData[3].m).toFixed(3),
            s03: parseFloat(this.stepData.nutritionalBalanceData[3].s).toFixed(3),
          },
          ph: parseFloat(this.stepData.ph),
          previous_fertilization_balance: {
            n: parseFloat(this.stepData.dosageProductBalance[0].n).toFixed(3),
            p205: parseFloat(this.stepData.dosageProductBalance[0].p).toFixed(3),
            k20: parseFloat(this.stepData.dosageProductBalance[0].k).toFixed(3),
            ca0: parseFloat(this.stepData.dosageProductBalance[0].c).toFixed(3),
            mg0: parseFloat(this.stepData.dosageProductBalance[0].m).toFixed(3),
            s03: parseFloat(this.stepData.dosageProductBalance[0].s).toFixed(3),
          },
          uf_teoric: {
            n: parseFloat(this.stepData.dosageProductBalance[1].n).toFixed(3),
            p205: parseFloat(this.stepData.dosageProductBalance[1].p).toFixed(3),
            k20: parseFloat(this.stepData.dosageProductBalance[1].k).toFixed(3),
            ca0: parseFloat(this.stepData.dosageProductBalance[1].c).toFixed(3),
            mg0: parseFloat(this.stepData.dosageProductBalance[1].m).toFixed(3),
            s03: parseFloat(this.stepData.dosageProductBalance[1].s).toFixed(3),
          },
          uf_efficiency_provided: {
            n: parseFloat(this.stepData.dosageProductBalance[2].n).toFixed(3),
            p205: parseFloat(this.stepData.dosageProductBalance[2].p).toFixed(3),
            k20: parseFloat(this.stepData.dosageProductBalance[2].k).toFixed(3),
            ca0: parseFloat(this.stepData.dosageProductBalance[2].c).toFixed(3),
            mg0: parseFloat(this.stepData.dosageProductBalance[2].m).toFixed(3),
            s03: parseFloat(this.stepData.dosageProductBalance[2].s).toFixed(3),
          },
          final_balance: {
            n: parseFloat(this.stepData.dosageProductBalance[3].n).toFixed(3),
            p205: parseFloat(this.stepData.dosageProductBalance[3].p).toFixed(3),
            k20: parseFloat(this.stepData.dosageProductBalance[3].k).toFixed(3),
            ca0: parseFloat(this.stepData.dosageProductBalance[3].c).toFixed(3),
            mg0: parseFloat(this.stepData.dosageProductBalance[3].m).toFixed(3),
            s03: parseFloat(this.stepData.dosageProductBalance[3].s).toFixed(3),
          },
          contribution_products: this.stepData.dosageProduct.map((product, index) => {
            product.product = product.id;
            product.p205 = parseFloat(product.p).toFixed(3);
            product.k20 = parseFloat(product.k).toFixed(3);
            product.ca0 = parseFloat(product.c).toFixed(3);
            product.mg0 = parseFloat(product.m).toFixed(3);
            product.s03 = parseFloat(product.s).toFixed(3);
            product.periods = [];
            for (const prop in this.stepData.timeValues[index]) {
              product.periods.push(parseFloat(this.stepData.timeValues[index][prop]).toFixed(3));
            }
            product.periods_dose = [];
            product.periods_product_irrigation = [];
            
            // Usamos la funcion para rellenar con el index de dosageProduct sus respectivos valores 
            // periods_dose y periods_product_irrigation
            let result = this.getIndexIrrigationTable(this.stepData.irrigationTable, index);
            product.periods_dose = result.periods_dose;
            product.periods_product_irrigation = result.periods_product_irrigation;
            
            product.total_cost = parseFloat(this.stepData.productResume[index].costTotal).toFixed(3);
            product.unit_cost = parseFloat(this.stepData.productResume[index].costUnit).toFixed(3);
            const price = parseFloat(this.stepData.productResume[index].price).toFixed(3);
            const priceKg = parseFloat(this.stepData.productResume[index].priceKg).toFixed(3);
            const priceL = parseFloat(this.stepData.productResume[index].priceL).toFixed(3);
            product.price = Number.isNaN(price) ? null : price;
            product.price_per_kg = Number.isNaN(priceKg) ? null : priceKg;
            product.price_per_liter = Number.isNaN(priceL) ? null : priceL;
            product.observation = product.observation?.length === 0 ? null : product.observation;

            product.unit_dosis = this.stepData.productResume[index].dosageUnit;
            product.dosis_per_plant = this.stepData.productResume[index]?.dosagePlant ?? 0;
            product.total_dosis = this.stepData.productResume[index].dosageTotal;
            product.cost_per_plant = this.stepData.productResume[index]?.costPlant ?? 0;

            product.presentation = product?.presentation ?? 'SOLIDO';
            product.density = product?.density ?? 1;

            return product;
          }),
        };

        if (this.productsNotDeleted) {
          this.$notifyDX(
            {
              message: this.$t('nutritionDosificationProblem'),
              width: 550,
            },
            'error',
            3000,
          );
          return;
        }

        this.setProductsNotInNutritionPlan([]);

        if (this.isPlantationSelected) {
          const plantationCompantId = this.isCopying ? this.currentPlantation.plantationCompanyId : this.currentPlantation.companyId;
          await fertilizationService.save(payload, plantationCompantId);

          if (this.isCopying) {
            this.setCurrentPlantation({ ...this.currentPlantation, name: this.copyingStepData.name });
          }

          this.initNutritionalSteps();
          this.setOldPlantation(this.currentPlantation);

          if (this.currentFertilizationId) {
            this.$f7.views.main.router.navigate('/plans/', { reloadCurrent: true });
          } else {
            this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
          }

          return;
        }

        if (this.stepData.nutritionalId !== '') {
          await fertilizationService.edit(this.stepData.nutritionalId, payload, this.currentPlantation.companyId);
          this.initNutritionalSteps();
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        } else {
          if (typeof payload.description === 'undefined' || payload.description === '') {
            this.$notifyDX(
              {
                message: this.$t('nutritionDetail.descriptionValidation'),
                width: 550,
              },
              'error',
              3000,
            );
            return;
          }
          await fertilizationService.save(payload, this.currentPlantation.companyId);

          this.initNutritionalSteps();
          this.$f7.views.main.router.navigate('/searcher/nutrition/', { reloadCurrent: true });
        }
      } catch (error) {
        this.disabledSaveButton = false;
        let errorMessage = '';

        // Los errores pueden ser de varios tipos. En el caso de que sea un objeto Error,
        //   el mensage viene en la propiedad 'message'.
        // Si es otro tipo de error, por ejemplo, un string. Se usa como tal.
        if (error.message) {
          errorMessage = error.message;
        } else {
          errorMessage += error;
        }

        if (errorMessage.includes('date_range_invalid')) {
          this.$notifyDX(
            {
              message: this.$t('nutritionDetail.dateRangeInvalid'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.loadingVisible = false;
      }
    },
    initNutritionalSteps() {
      this.stepData.nutritionalNeeds = [];
      this.stepData.expectedProduction = 1;
      this.stepData.planDescription = '';
      this.stepData.initDate = '';
      this.stepData.endDate = '';
      this.stepData.temporalFrame = '';
      this.stepData.nutritionalBalanceData = [];
      this.stepData.timeValues = [];
      this.stepData.dosages = [];
      this.stepData.dosageProduct = [];
      this.stepData.dosageProductBalance = [];
      this.stepData.productResume = [];
      this.setIsEditing(false);
      this.setEditingStepData(null);
      this.setIsCopying(false);
      this.setCopyingStepData(null);
    },
    ...mapActions('Plantation', ['setOldPlantation', 'setCurrentPlantation']),
    ...mapActions('nutritionStepper', ['setProductsNotDeleted', 'setIsEditing', 'setEditingStepData', 'setIsCopying', 'setCopyingStepData']),
    ...mapActions('Nutrition', ['setProductsNotInNutritionPlan']),
  },
};

</script>
<style src="./nutrition-step-five.scss" scoped lang="scss"></style>
