<template>
  <f7-page
    @page:beforein="beforein"
  >
    <navbar :text="`${$t('Configuration.name')} > ${$t('Page_subtitle_variety')}`" />
    <VarietyDatabase
      v-if="loaded"
      :custom-store="customStore"
      :groups-availables="groupsAvailables"
      :species-availables="speciesAvailables"
    />
  </f7-page>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import VarietyDatabase from '../../../components/configuration/varieties/varietyDatabase/index.vue';
import navbar from '../../../components/NavBar.vue';

export default {
  name: 'Varieties',
  components: {
    VarietyDatabase,
    navbar,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Varieties', ['groupsAvailables', 'speciesAvailables']),
  },

  methods: {
    async beforein() {
      this.$fa.setScreenName('Configuración > Variedades');
      this.$f7.preloader.show();
      try {
        this.customStore = await this.getStore({ type: 'configVarieties' });
        await this.getGroupsAvailables();
        await this.getSpeciesAvailables();
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(`${error}`),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.loaded = true;
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('Varieties', ['getVarieties', 'getGroupsAvailables', 'getSpeciesAvailables']),
    ...mapActions('Pagination', ['getStore']),
  },

};
</script>
