<template>
  <transition
    :css="false"
    @leave="leave"
  >
    <f7-page>
      <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name}`" />

      <div class="margin-top-block">
        <Detail v-if="currentTab === 'detail'" />
        <Home v-if="currentTab === 'home'" />
        <WeatherGraph v-if="currentTab === 'weatherGraph'" />
        <Nutrition v-if="currentTab === 'nutrition'" />
        <Treatment v-if="currentTab === 'treatment'" />
        <Irrigation v-if="currentTab === 'irrigation'" />
        <Observations v-if="currentTab === 'observations'" />
        <Tasks v-if="currentTab === 'tasks'" />
        <GISSearcher v-if="currentTab === 'gis'" />
      </div>
    </f7-page>
  </transition>
</template>

<script>
import { mapState } from 'vuex';
import navbar from '../../components/NavBar.vue';
import Home from '../../components/searcher/home/index.vue';
import Observations from '../../components/searcher/observations/observationsDatabase/index.vue';
import Nutrition from '../../components/searcher/nutrition/index.vue';
import Treatment from '../../components/searcher/treatment/index.vue';
import Irrigation from '../../components/searcher/irrigation/index.vue';
import Tasks from '../../components/searcher/tasks/index.vue';
import WeatherGraph from '../../components/searcher/weatherGraph/index.vue';
import Detail from '../../components/searcher/detail/index.vue';
import GISSearcher from '../../components/searcher/gis/index.vue';

export default {
  name: 'Searcher',
  components: {
    navbar,
    Home,
    Observations,
    Nutrition,
    Irrigation,
    Tasks,
    Treatment,
    WeatherGraph,
    Detail,
    GISSearcher,
  },
  computed: {
    ...mapState('Utilities', ['currentTab']),
    ...mapState('Plantation', ['currentPlantation']),
  },
  methods: {
    leave() {
      const timeDimension = document.getElementsByClassName('timecontrol-play')[0];

      if (timeDimension) {
        const timeDimensionClassList = timeDimension.classList;
        if (timeDimensionClassList.contains('pause')) {
          timeDimension.click();
        }
      }
    },
  },
};
</script>
