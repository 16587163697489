<template>
  <div>
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          style="width: 240px;"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
      </div>

      <f7-list
        v-if="manualLogin"
        form
        no-hairlines-between
      >
        <f7-list-item
          name="txtWelcome"
          class="login label-text no-padding margin-left-half margin-top"
        >
          {{ $t("Login_txtWelcome") }}
        </f7-list-item>
        <!-- Username -->
        <f7-list-input
          name="inpUsername"
          outline
          item-label
          type="text"
          :placeholder="$t('Login_inpUsername_placeholder')"
          clear-button
          max="255"
          required
          validate
          :error-message="$t('Login_inpUsername_validate')"
          @input="username = $event.target.value"
        />
        <!-- Password -->
        <f7-list-input
          name="inpPassword"
          outline
          item-label
          type="password"
          :placeholder="$t('Login_inpPassword_placeholder')"
          clear-button
          max="255"
          required
          autocomplete="current-password"
          validate
          :error-message="$t('Login_inpPassword_validate')"
          @input="password = $event.target.value"
        />
      </f7-list>

      <!-- Buttons -->
      <f7-list>
        <f7-list-item
          v-if="hasRememberMe && manualLogin"
          class="login-remember"
          checkbox
          value="chkRememberMe"
          name="chkRememberMe"
          :title="$t('Login_chkRememberMe')"
          :checked="wantToRemember"
          @change="setWantToRemember"
        />

        <f7-button
          v-if="manualLogin"
          name="btnSignIn"
          class="botonPrimary margin"
          col
          button
          button-large
          fill
          :disabled="btnDisable"
          style="color:#FFFFFF"
          @click="signIn"
        >
          {{ $t("Login_btnSignIn") }}
        </f7-button>
        <f7-button
          name="btnSignAzure"
          class="botonPrimary margin-horizontal"
          style="margin-top: 40px"
          col
          button
          @click="signAzure"
        >
          {{ $t("Login_btnSignIn") }}
        </f7-button>
      </f7-list>
      <div
        v-if="hasSignUp"
        name="btnSignUp"
        @click="signUp"
      >
        {{ $t("Login_btnSignUp_question") }}
        <strong>{{ $t("Login_btnSignUp_link") }}</strong>
      </div>
    </f7-block>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import Api from '@/services/Api';
import cordovaApp from '@/js/cordova-app';
import { APPLICATION_NAME, APP_LOGO } from '../../js/constants';
import EventBus from '../../js/event-bus';

export default {
  name: 'Login',
  props: {
    hasRestorePass: { type: Boolean, default: false },
    hasSignUp: { type: Boolean, default: false },
    hasRememberMe: { type: Boolean, default: false },
    hasNeedHelp: { type: Boolean, default: false },
  },
  data() {
    return {
      username: '',
      password: '',
      APPLICATION_NAME,
      APP_LOGO,
      manualLogin: false,
    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.password;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['isLogged', 'wantToRemember', 'user']),
  },
  async created() {
    await this.$f7ready(async (f7) => {
      const uri = window.location.href.split('?');
      const params = new URLSearchParams(uri[1]);
      const code = params.get('code');
      if (code !== null) {
        this.updateAuthAzure(code);
      }
    });
  },
  beforeMount() {
    this.resetLastSync();
    EventBus.$on('azure_callback_auth0', this.updateAuthAzure);
  },

  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        await Api.login({
          username: this.username,
          password: this.password,
        });
        const { user } = this.user;
        this.$fa.setUserId(user.email);
        this.$fa.logEvent('login', {
          fullname: `${user.contact.name} ${user.contact.surname}`,
          email: user.email,
          content_type: 'component',
          description: 'Evento de inicio de sesión'
        });
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t(this.$helpers.getFilteredErrorMessage(this.$helpers.getErrorMSG(error))),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async signAzure() {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.AzureAuthorization();
        const data = JSON.parse(xhr.response);
        cordovaApp.openBrowser(data.redirect_url);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$helpers.getError(error),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async updateAuthAzure(authCode) {
      this.$f7.preloader.show();
      window.history.replaceState({}, document.title, '/');
      this.setWantToRemember(false);
      try {
        await Api.AzureGetToken(authCode);
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$helpers.getError(error),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.$f7.preloader.hide();
      }
    },
    signUp() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/recoveryPasswordPage/', { reloadCurrent: true });
    },
    quit() {
      const self = this;
      const router = self.$f7router;
      router.back();
    },
    ...mapActions('authentication', ['login']),
    ...mapActions('databaseSync', ['resetLastSync']),
    ...mapMutations('authentication', ['setWantToRemember', 'wantToRecoveryPass', 'changePassword']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/accounts/login.scss";
</style>
