import { f7 } from 'framework7-vue';
import moment from 'moment';
import Request from './Request';
import EventBus from '../js/event-bus';
import { MIN_SECONDS_TO_REFRESH_TOKEN, APP_VERSION } from '../js/constants';

let email = '';
let accessToken = '';
let refreshToken = '';
let headerAuth = {};
const appVersion = APP_VERSION.split('-')[0];

const baseUrl = 'https://api.timac.hispatecanalytics.com'; // Endpoint producción
const baseUrlGis = 'https://gis.hispatecanalytics.com'; // Endpoint producción Geoserver
const azureAuthCallback = 'https://timac.hispatecanalytics.com/'; // Callback azure producción
const geoServerWorkspace = 'timacagro';
const geoServerTimacagro = `${baseUrlGis}/geoserver/${geoServerWorkspace}/wms?`;
const geoServerProjectWorkSpace = `${baseUrlGis}/geoserver/${geoServerWorkspace}/wms?`;
const geoServer = `${baseUrlGis}/gisw/ws`;
const geoServerPort = `${baseUrlGis}/geoserver`;
const geoServerSigpacMap = `${baseUrlGis}/gisw/ws/getmap/`;
const geoServerFeatureInfo = `${baseUrlGis}/gisw/ws/getfeatureinfo/`;
const geoServerTemperatureHistogram = `${baseUrl}/service_gis/get_index_stats`;
const geoServerClouds = `${baseUrlGis}/gisw/ws/getclouds/`;
const geoServerSigpacSurface = `${baseUrlGis}/gisw/ws/getsuperficie/`;
const geoServerDissolve = `${baseUrlGis}/gisw/ws/get_dissolve/`;
const geoServerSigpacWFSGeom = `${baseUrlGis}/gisw/ws/get_sigpac_geom/`;
const geoServerPrintMap = `${baseUrl}/service_gis/get_print_map`;
const geoServerMunicipality = `${baseUrlGis}/gisw/ws/get_munis_dict/`;
const geoServerGenRasterDates = `${baseUrlGis}/gisw/ws/gen_raster_dates/`;
const geoServerCollectionSigpac = `${baseUrlGis}/gisw/ws/get_collection_sigpac/`;
const geoServerDiagramBar = `${baseUrl}/service_gis/diagram_bar`;
const geoServerClimateData = `${baseUrl}/climate/diagram`;
const geoServerIndexDates = `${baseUrl}/service_gis/available_dates_diagram`;

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  email = data.user.email;
  accessToken = data.access;
  refreshToken = data.refresh;
  headerAuth = `Bearer ${accessToken}`;
});

export default {
  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!email) return;
        email = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getAzureAuthCallback() {
    return azureAuthCallback;
  },
  getToken() {
    return accessToken;
  },
  getHeaderAuth() {
    return headerAuth;
  },
  getBaseUrl() {
    return baseUrl;
  },
  getBaseUrlGis() {
    return baseUrlGis;
  },
  getGeoServer() {
    return geoServer;
  },
  getGeoServerProjectWorkSpace() {
    return geoServerProjectWorkSpace;
  },
  getGeoServerPort() {
    return geoServerPort;
  },
  getGeoServerSigpacMap() {
    return geoServerSigpacMap;
  },
  getGeoServerTimacAgro() {
    return geoServerTimacagro;
  },
  getGeoServerFeatureInfo() {
    return geoServerFeatureInfo;
  },
  getGeoServerWorkspace() {
    return geoServerWorkspace;
  },
  getGeoServerSigpacSurface() {
    return geoServerSigpacSurface;
  },
  getGeoServerDissolve() {
    return geoServerDissolve;
  },
  getGeoServerSigpacWFSGeom() {
    return geoServerSigpacWFSGeom;
  },
  getGeoServerPrintMap() {
    return geoServerPrintMap;
  },
  getGeoServerDiagramBar() {
    return geoServerDiagramBar;
  },
  getGeoServerClimateData() {
    return geoServerClimateData;
  },
  getGeoServerIndexDates() {
    return geoServerIndexDates;
  },
  getGeoServerCollectionSigpacUrl() {
    return geoServerCollectionSigpac;
  },
  isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
  },
  getCustomStoreQueryParams(loadOptions) {
    let queryParams = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((param) => {
      if (param in loadOptions && this.isNotEmpty(loadOptions[param])) {
        queryParams += `${param}=${JSON.stringify(loadOptions[param])}&`;
        // Esto lo hacemos porque el backend de timac usa el estandar de paginación,
        // que al no ser el mismo que el de devextreme debemos añadirlo manualmente
        // En un futuro, el back debería de cambiar al de devextreme para este tipo de llamadas paginadas.
        if (param === 'skip') {
          queryParams += `offset=${loadOptions[param]}&`;
        }
        if (param === 'take') {
          queryParams += `limit=${loadOptions[param]}&`;
        }
      }
    });
    return queryParams;
  },
  getCustomeStoreByQueryParams(moduleName, queryParams, extraParams = null, companyId = null) {
    let url = `${baseUrl}/${moduleName}${queryParams}`;
    if (extraParams !== null) {
      for (const param in extraParams) {
        if (Object.hasOwnProperty.call(extraParams, param)) {
          url += `&${param}=${extraParams[param]}`;
        }
      }
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  // INICIO: Login y autentificación
  login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = {
      data: JSON.stringify({
        email: credentials.username,
        password: credentials.password,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json').then(
      this.handleLoginResult,
    );
  },

  // Get authorize Azure
  AzureAuthorization() {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json');
  },
  AzureGetToken(code) {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: JSON.stringify({
        code,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json').then(
      this.handleLoginResult,
    );
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
    }
  },
  async setAccessToken(result) {
    if (result.response !== '') {
      accessToken = JSON.parse(result.response).access;
      headerAuth = `Bearer ${accessToken}`;
      EventBus.$emit('newAccessToken', accessToken);
    }
  },
  async renewAccessToken() {
    let oAuthPayload = accessToken.split('.')[1];
    oAuthPayload = window.atob(oAuthPayload);
    oAuthPayload = JSON.parse(oAuthPayload);
    const secondsBeforeExpire = oAuthPayload.exp - moment.utc().unix();
    if (secondsBeforeExpire > MIN_SECONDS_TO_REFRESH_TOKEN) return {};
    const url = `${baseUrl}/user/refresh`;
    const payload = {
      data: JSON.stringify({ refresh: refreshToken }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.setAccessToken);
  },

  // FIN: Login y autentificación

  getQueryParams(params) {
    const queryParams = new URLSearchParams();
    for (const param in params) {
      if (Object.hasOwnProperty.call(params, param)) {
        const value = params[param];
        if (typeof value !== 'undefined') {
          queryParams.set(param, value);
        }
      }
    }
    return queryParams.toString();
  },
  getElementsByQueryParams(module, queryParams = {}) {
    let url = `${baseUrl}/${module}`;
    url += `?${this.getQueryParams(queryParams)}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      null,
    );
  },

  getException(errorcode) {
    return errorcode in this.errors
      ? this.errors[errorcode]
      : this.errors.default;
  },
  checkInitEndDate(actualFilter) {
    let init = '';
    let end = '';
    if (
      typeof actualFilter !== 'undefined'
      && actualFilter.init !== 'Invalid date'
      && actualFilter.end !== 'Invalid date'
    ) {
      init = actualFilter.init;
      end = actualFilter.end;
    }
    return {
      init,
      end,
    };
  },
  async getCampaignsByCompany(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createCampaign(idCompanyHeader, newCampaignData) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign`;
    const payload = {
      data: JSON.stringify(newCampaignData),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateCampaign(idCompanyHeader, newCampaignData, campaignId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign/${campaignId}`;
    const payload = {
      data: JSON.stringify(newCampaignData),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getLocationByLevel(idCompanyHeader, level = '') {
    await this.renewAccessToken();
    const url = `${baseUrl}/location?level=${level}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTreeLocation(idCompanyHeader, plantationId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/tree/location`;
    const queryParams = new URLSearchParams();
    if (plantationId !== '') {
      queryParams.set('plantation', plantationId);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getParcelSystemTreeLocation(idCompanyHeader, campaignId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/tree/plantation`;
    const queryParams = new URLSearchParams();
    if (campaignId !== '' && campaignId !== 'null') {
      queryParams.set('campaign', campaignId);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createLocation(idCompanyHeader, newLocationData) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location`;
    const payload = {
      data: JSON.stringify(newLocationData),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateLocation(idCompanyHeader, newLocationData, sectorId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location/${sectorId}`;
    const payload = {
      data: JSON.stringify(newLocationData),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async fetchTreeLocationById(idCompanyHeader, locationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tree/location?location=${locationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getClient() {
    await this.renewAccessToken();
    const url = `${baseUrl}/user`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getSales(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sale`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },

  async getProductsSync(owner) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sync/product?owner=${owner}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getProductsSyncConfig(owner) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sync/product_conf?owner=${owner}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },

  async getProducts() {
    await this.renewAccessToken();
    const url = `${baseUrl}/product`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewProduct(idCompanyHeader, newProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product`;
    const payload = {
      data: JSON.stringify(newProduct),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getProductDetail(idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateProduct(product, idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: JSON.stringify(product),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteProduct(idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getListAnalysis(idCompanyHeader, plantationId) {
    await this.renewAccessToken();
    let url = `${baseUrl}/laboratory_analysis`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async createNewAnalysis(dataAnalysis, idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis`;
    const payload = {
      data: JSON.stringify(dataAnalysis),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getPhytosanitaryProducts(filterCrop, filterAgent) {
    await this.renewAccessToken();
    let url = `${baseUrl}/vademecum`;
    const queryParams = new URLSearchParams();
    if (typeof filterCrop !== 'undefined') {
      queryParams.set('cultive', filterCrop);
    }
    if (typeof filterCrop !== 'undefined') {
      queryParams.set('agent', filterAgent);
    }
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getUtilityProducts() {
    await this.renewAccessToken();
    const url = `${baseUrl}/utility/products`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getNutritionalProducts() {
    await this.renewAccessToken();
    const url = `${baseUrl}/product`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsCultiveFilter() {
    await this.renewAccessToken();
    const url = `${baseUrl}/cultive/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsPlagueFilter(crop) {
    await this.renewAccessToken();
    let url = `${baseUrl}/treatment/plague`;
    const queryParams = new URLSearchParams();
    queryParams.set('cultive', crop);
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsPlagueFilterVademecum(crop) {
    await this.renewAccessToken();
    let url = `${baseUrl}/plague/vademecum`;
    const queryParams = new URLSearchParams();
    queryParams.set('cultive', crop);
    url += `?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getOptionsCommercialNameFilter() {
    await this.renewAccessToken();
    const url = `${baseUrl}/commercial_name/vademecum`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getPhytosanitaryProductDetail(product) {
    await this.renewAccessToken();
    let url = `${baseUrl}/vademecum/${product.id}`;
    const queryParams = new URLSearchParams();
    if (typeof product.agent !== 'undefined') {
      queryParams.set('agent', product.agent);
    }
    if (typeof product.crop !== 'undefined') {
      queryParams.set('cultive', product.crop);
    }
    url += `?${queryParams.toString()}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getNutritionalProductDetail(idProduct) {
    await this.renewAccessToken();
    const url = `${baseUrl}/product/${idProduct}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getPlantationByCompanyId(idCompanyHeader) {
    await this.renewAccessToken();
    let url = `${baseUrl}/plantation`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getAllCampaignsForUser(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/contact/campaign`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getlocationById(idCompanyHeader, Id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location/${Id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getLocationByCampaign(idCompanyHeader, idCampaign) {
    await this.renewAccessToken();
    const url = `${baseUrl}/location?campaign=${idCampaign}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeather(
    idCompanyHeader,
    locations,
    category,
    initDate,
    endDate,
    parameter,
  ) {
    await this.renewAccessToken();
    const url = `${baseUrl}/climate?locations=${locations}&category=${category}&init_date=${initDate}&end_date=${endDate}&parameters=${parameter}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeatherByPlantation(
    idCompanyHeader,
    plantation,
    category,
    initDate,
    endDate,
    parameter,
  ) {
    await this.renewAccessToken();
    const url = `${baseUrl}/climate/plantation/data?plantation=${plantation}&category=${category}&init_date=${initDate}&end_date=${endDate}&parameters=${parameter}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeatherForParameter(
    idCompanyHeader,
    locations,
    category,
    initDate,
    endDate,
    parameters = [],
  ) {
    await this.renewAccessToken();
    let url = `${baseUrl}/climate?locations=${locations}&category=${category}&init_date=${initDate}&end_date=${endDate}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getWeatherForParameterByPlantation(
    companyId,
    plantationId,
    category,
    initDate,
    endDate,
    parameters = [],
  ) {
    await this.renewAccessToken();
    let url = `${baseUrl}/climate/plantation/data?plantation=${plantationId}&category=${category}&init_date=${initDate}&end_date=${endDate}`;
    if (parameters.length > 0) {
      url += `&parameters=${parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  getVarieties(params) {
    const url = `${baseUrl}/variety${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getVarietiesConfig(params) {
    const url = `${baseUrl}/variety_conf${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewVariety(newVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety`;
    const payload = {
      data: JSON.stringify(newVariety),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getVarietyDetail(idVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateVariety(variety, idVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: JSON.stringify(variety),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteVariety(idVariety) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety/${idVariety}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getObservations(params, { companyId, currentPlantationId = '' }) {
    // await this.renewAccessToken();
    const url = `${baseUrl}/scouting${params}&plantation=${currentPlantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  /**
   * get observations with images for a specific plantation.
   * 
   * @param {string} companyId - The ID of the company to which the plantation.
   * @param {string} currentPlantationId - The ID of the current plantation for which observations are being retrieved.
   * @param {boolean} [recetary=false] - A flag to include "recetary" data. Defaults to false if not provided.
   * @returns {Promise} - A promise that resolves to the observations data with images.
   */
  async getObservationsWithImages(companyId, currentPlantationId, recetary = false) {
    // 
    const url = `${baseUrl}/scouting/with_images?plantation=${currentPlantationId}&recetary=${recetary}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async getObservationsFiltered(companyId, plantationId, subcategory, initDate, endDate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting?plantation=${plantationId}&subcategory=${subcategory}&date_init=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async getObservationsFilteredCategory(companyId, plantationId, category, initDate, endDate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting?plantation=${plantationId}&category=${category}&date_init=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async createNewObservation(idCompanyHeader, newObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting`;
    const payload = {
      data: JSON.stringify(newObservation),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getObservationDetail(idCompanyHeader, idObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateObservation(idCompanyHeader, idObservation, observation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: JSON.stringify(observation),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deleteObservation(idCompanyHeader, idObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async removeObservationFile(idCompanyHeader, idObservation, fileName) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting/${idObservation}/file/${fileName}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTasks() {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewTask(newTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks`;
    const payload = {
      data: JSON.stringify(newTask),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTaskDetail(idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateTask(task, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: JSON.stringify(task),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteTask(idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/tasks/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketSlices() {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/markets`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketFamilies(nameSlice) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/families?market=${nameSlice}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketProduct(nameSlice, nameFamily) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/products?market=${nameSlice}&family=${nameFamily}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketData(
    nameSlice,
    nameFamily,
    nameProduct,
    nameOrigin,
    initDate,
    endDate,
  ) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/data?market=${nameSlice}&family=${nameFamily}&product=${nameProduct}&origin=${nameOrigin}&date_ini=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getDataMarketOrigins(nameSlice, nameFamily, nameProduct, initDate, endDate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/datamarket/origins?market=${nameSlice}&family=${nameFamily}&product=${nameProduct}&date_ini=${initDate}&date_end=${endDate}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllProvince() {
    await this.renewAccessToken();
    const url = `${baseUrl}/geonames/country/ES/province`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllCities(codeProvince) {
    await this.renewAccessToken();
    const url = `${baseUrl}/geonames/country/ES/city?admin1_code=${codeProvince}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getContactPlantation(params) {
    const url = `${baseUrl}/contact/plantation${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getContactPlantationByDate(params, data) {
    const url = `${baseUrl}/contact/plantation${params}&init_date=${data.init}&end_date=${data.end}&status=ACTIVE`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getIrrigation(idCompany, plantationId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/irrigation`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async postIrrigation(dataIrrigation, idCompany) {
    await this.renewAccessToken();
    const url = `${baseUrl}/irrigation`;
    const payload = {
      data: JSON.stringify(dataIrrigation),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async putIrrigation(dataIrrigation, irrigationId, idCompany) {
    await this.renewAccessToken();
    const url = `${baseUrl}/irrigation/${irrigationId}`;
    const payload = {
      data: JSON.stringify(dataIrrigation),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async deleteIrrigation(irrigationId, companyId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/irrigation/${irrigationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async updateAnalysis(dataAnalysis, idCompanyHeader, idAnalysis) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: JSON.stringify(dataAnalysis),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deleteAnalysis(idCompanyHeader, idAnalysis) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  // GIS Methods
  async geoserverGetBBOX(addedQuery) {
    await this.renewAccessToken();
    const url = `${geoServer}/${addedQuery}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  async geoserverWMSLayerDataRequest(addedQuery) {
    await this.renewAccessToken();
    const url = `${geoServerPort}/${addedQuery}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Authorization: headerAuth,
      },
    });
  },
  async geoserverSigpacSurfaceData(polygon) {
    await this.renewAccessToken();
    const url = `${geoServerSigpacSurface}?polygon=${polygon}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
  },
  async getCapabilities(workSpaces) {
    await this.renewAccessToken();
    const url = `${baseUrlGis}/geoserver/${workSpaces}/wms?service=WMS&version=1.3.0&request=GetCapabilities&format=text/xml`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/xml',
        // Authorization: headerAuth,
      },
    });
  },
  async getMunicipalitiesByProvince(province) {
    await this.renewAccessToken();
    const url = `${geoServerMunicipality}?provincia=${province}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/xml',
        // Authorization: headerAuth,
      },
    });
  },
  async updateRasterDates(ws, geom, startDate, endDate) {
    await this.renewAccessToken();
    const url = `${geoServerGenRasterDates}?ws=${ws}&geom=${geom}&start_date=${startDate}&end_date=${endDate}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/xml',
        // Authorization: headerAuth,
      },
    });
  },
  async getTasksForCompany(idCompany, plantationId = '') {
    await this.renewAccessToken();
    let url = `${baseUrl}/task`;
    if (plantationId !== '') {
      url += `?plantation=${plantationId}`;
    }
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async getTasksForCompanyFiltered(idCompany, plantationId = '', activity, initDate, endDate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task?init_date_after=${initDate}&init_date_before=${endDate}&activity=${activity}&plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompany,
    );
  },
  async getTasksActivity() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_activity`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTasksDefinition(activiy) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition?activity=${activiy}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllTasksDefinition() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllTasksDefinitionConfig() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition_conf`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTaskDefinitionById(id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchActivities() {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_activity`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createTaskDefinition(newTaskDefinition) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition`;
    const payload = {
      data: JSON.stringify(newTaskDefinition),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateTaskDefinition(id, data) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteTaskDefinition(id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task_definition/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getScoutingPoints(idCompanyHeader, idPlantation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_point?plantation=${idPlantation}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getCategoriesOfTemplates(listconfig = false) {
    await this.renewAccessToken();
    // Agregamos el queryParam listconfig a true para quitar Muestreo
    // Si no lo dejamos sin enviar para que nos muestre todo
    const queryParams = listconfig ? `?listconfig=true` : '';
    const url = `${baseUrl}/template_category${queryParams}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getLaboratoriesOfTemplates() {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_labs`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTemplatesByCategory(category) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form?category=${category}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTemplatesByLaboratory(laboratory) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_analyse_type?lab=${laboratory}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTemplateById(id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createNewTaskByActivity(idCompanyHeader, newTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task`;
    const payload = {
      data: JSON.stringify(newTask),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deleteTaskByCompany(idCompanyHeader, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async updateTaskByCompany(idCompanyHeader, updTask, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: JSON.stringify(updTask),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTaskById(idCompanyHeader, idTask) {
    await this.renewAccessToken();
    const url = `${baseUrl}/task/${idTask}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getGeoServerCallServices(companyId, service, params) {
    await this.renewAccessToken();
    const url = `${service}${params}`;
    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        'App-version': appVersion,
      },
    });
  },
  async getGeoServerCallServicesClimateData(companyId, service, params, climates) {
    await this.renewAccessToken();
    
    let url = `${service}${params}`;
    
    if (climates.length > 0) {
      url += `&parameters=${climates.join('&parameters=')}`;
    }

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        'App-version': appVersion,
      },
    });
  },
  async getGeoServerTemperatureHistogram(location, date, companyId) {
    await this.renewAccessToken();
    const url = `${geoServerTemperatureHistogram}?location=${location}&init_date=${date}&histogram=true&client=${geoServerWorkspace}`;

    return fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        'App-version': appVersion,
      },
    });
  },
  async getGeoServerClouds(location, date, idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${geoServerClouds}?location=${location}&date=${date}&client=${geoServerWorkspace}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createPointObservation(idCompanyHeader, pointObservation) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_point`;
    const payload = {
      data: JSON.stringify(pointObservation),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getGroupsAvailables() {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety_group`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getSpeciesAvailables(group) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety_specie?group=${group}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getLaboratoryAnalysisById(idAnalysis, idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/laboratory_analysis/${idAnalysis}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getScoutingPointById(companyIdHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/scouting_point/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyIdHeader,
    );
  },
  async getExplotation(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/explotation`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getExplotationDetailed(idCompanyHeader) {
    await this.renewAccessToken();
    const url = `${baseUrl}/explotation/detailed`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json', headerAuth, idCompanyHeader);
  },
  async getKeyValues() {
    await this.renewAccessToken();
    const url = `${baseUrl}/keyvalues`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getVarietyBySpecie(specie) {
    await this.renewAccessToken();
    const url = `${baseUrl}/variety?specie=${specie}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createPlantation(idCompanyHeader, newPlantationData) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation`;
    const payload = {
      data: JSON.stringify(newPlantationData),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getParcelByExplotation(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/explotation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getSectorByParcel(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/parcel/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getPlantationById(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async deletePlantationById(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getCampaignsById(idCompanyHeader, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/campaign/${id}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getTreatmentProducts(crop, agent) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment/product?cultive=${crop}&agent=${agent}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTreatmentProduct(crop, agent, id) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment/product/${id}?cultive=${crop}&agent=${agent}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  getTreatmentDataForAnalisis(params = '', data = { period: '' }) {
    const url = `${baseUrl}/analysis/treatment_v2${params}${params.length > 0 ? '&' : '?'}period=${data.period}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getParcelById(idCompanyHeader, parcelId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/parcel/${parcelId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getSectorById(idCompanyHeader, sectorId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sector/${sectorId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async fetchKeyValuesByFamily(family) {
    await this.renewAccessToken();
    const url = `${baseUrl}/keyvalues?family=${family}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updatePlantation(idCompanyHeader, updateDataPLantation, plantationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/plantation/${plantationId}`;
    const payload = {
      data: JSON.stringify(updateDataPLantation),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getContactLocations(latitude, longitude, radio = 0) {
    await this.renewAccessToken();
    let url = `${baseUrl}/contact/location?latitude=${latitude}&longitude=${longitude}`;

    const queryParams = new URLSearchParams();
    if (radio > 0) {
      queryParams.set('radio', radio);
    }
    url += `&${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getRegistersResume(companyId, plantationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/register?plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  fetchFertilizations(params) {
    const url = `${baseUrl}/utils/fertilization${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  fetchTreatments(params) {
    const url = `${baseUrl}/utils/treatment_v2${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getFertilizationById(fertilizationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/utils/fertilization/${fertilizationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTreatmentById(treatmentId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/utils/treatment_v2/${treatmentId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getTreatmentPlanByIdandCompanyId(companyId, treatmentId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment_plan/${treatmentId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async fetchObservationsTemplates() {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchObservationsTemplatesConfig() {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form_conf`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteObservationTemplateById(observationTemplateId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form/${observationTemplateId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createObservationTemplate(observationTemplate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form`;
    const payload = {
      data: JSON.stringify(observationTemplate),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async updateObservationTemplate(observationTemplateId, observationTemplate) {
    await this.renewAccessToken();
    const url = `${baseUrl}/template_form/${observationTemplateId}`;
    const payload = {
      data: JSON.stringify(observationTemplate),
    };
    return Request.async(
      url,
      payload,
      'PUT',
      false,
      'application/json',
      headerAuth,
    );
  },
  fetchFertilizationsForAnalysis(params = '', data = { period: '' }) {
    const url = `${baseUrl}/analysis/fertilization${params}${params.length > 0 ? '&' : '?'}period=${data.period}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchFertilizationByIdDetailsForAnalysis(fertId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/utils/fertilization/${fertId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchIrrigationDataForAnalysis(plantationId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/analysis/irrigation?plantation=${plantationId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async fetchTimelineIndexes(location, initDate, endDate, companyId) {
    await this.renewAccessToken();
    const url = `${geoServerTemperatureHistogram}`;
    const payload = {
      data: {
        location,
        init_date: initDate,
        end_date: endDate,
        client: geoServerWorkspace,
      },
    };

    return fetch(`${url}?${new URLSearchParams(payload.data)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: headerAuth,
        company: companyId,
        'App-version': appVersion,
      },
    });
  },
  getCompanies(params) {
    const url = `${baseUrl}/company${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getCompanyById(companyId) {
    await this.renewAccessToken();
    const url = `${baseUrl}/company/${companyId}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getAllRecordsToSync(endpoint) {
    await this.renewAccessToken();
    const url = `${baseUrl}/${endpoint}`;
    const payload = {
      data: null,
    };
    return this.request(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },

  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    let responseServer = null;
    const xhr = await Request.async(
      url,
      payload,
      verb,
      processData,
      contentType,
      headerAuth,
    );
    try {
      responseServer = JSON.parse(xhr.response);
      responseServer = responseServer.data
        ? responseServer.data
        : responseServer;
    } catch (error) {
      responseServer = xhr.status;
    }
    return responseServer;
  },
  getWeatherWidgetData(data, companyId) {
    const url = `${baseUrl}/climate/widget?init_date=${data.initDate}&end_date=${data.endDate}&location=${data.sectorId}`;

    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  getClimateData(data, companyId) {
    let url = `${baseUrl}/climate/climate_data?location=${data.sectorId}&init_date=${data.initDate}&end_date=${data.endDate}&grouped=${data.grouped}&formula=${data.formula}`;
    if (data.parameters.length > 0) {
      url += `&parameters=${data.parameters.join('&parameters=')}`;
    }
    const payload = {
      data: null,
    };

    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  async getSectorAvailability(idCompanyHeader, idCampaign, idSector, days) {
    await this.renewAccessToken();
    const url = `${baseUrl}/sector/availability?campaign=${idCampaign}&sector=${idSector}&plantation_days=${days}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      idCompanyHeader,
    );
  },
  async getAnalysisSamples(params) {
    const url = `${baseUrl}/analysis/samples${params}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      null,
    );
  },
  async report(data) {
    const url = `${baseUrl}/report`;

    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
      null,
    );
  },
  async copyReferencePlan(data) {
    await this.renewAccessToken();
    const url = `${baseUrl}/reference_plan_copy`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async copyNutritionalPlan(data) {
    await this.renewAccessToken();
    const url = `${baseUrl}/fertilization_plan_copy`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async createReferencePlan(data) {
    await this.renewAccessToken();
    const url = `${baseUrl}/reference_plan`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getProductEfficiency({ code, tipology, ph }) {
    await this.renewAccessToken();

    const queryParams = new URLSearchParams();
    queryParams.set('code', code);
    queryParams.set('tipology', tipology);
    queryParams.set('ph', ph);

    const url = `${baseUrl}/product_efficiency?${queryParams.toString()}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getReferencePlans({ category } = {}) {
    await this.renewAccessToken();

    const queryParams = new URLSearchParams();

    if (category) {
      queryParams.set('reference_plan_category', category);
    }

    const url = `${baseUrl}/reference_plan?${queryParams.toString()}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async getReferencePlan(id) {
    await this.renewAccessToken();

    const url = `${baseUrl}/reference_plan/${id}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
    );
  },
  async deleteReferencePlan(id) {
    await this.renewAccessToken();

    const url = `${baseUrl}/reference_plan/${id}`;

    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'DELETE',
      false,
      'application/json',
      headerAuth,
    );
  },
  async copyTreatmentPlan(data) {
    await this.renewAccessToken();
    const url = `${baseUrl}/treatment_plan_v2_copy`;
    const payload = {
      data: JSON.stringify(data),
    };
    return Request.async(
      url,
      payload,
      'POST',
      false,
      'application/json',
      headerAuth,
    );
  },
  /**
   * Método que permite crear o actualizar elementos a través de un servicio API
   * 
   * @param {string} path Ruta URL que describe la ubicación específica del recurso en el servidor
   * @param {object} element Elemento que se desea crear
   * @param {string} companyId Identificador de la compañía
   * @param {string} verb Verbo HTTP que se desea utilizar en la petición
   * 
   * @returns Respuesta del servidor en base al resultado del post/put
   */
  async createUpdateElement(path, element, companyId = null, verb = 'POST') {
    await this.renewAccessToken();
    const url = `${baseUrl}${path}`;
    const payload = {
      data: JSON.stringify(element),
    };
    return Request.async(
      url,
      payload,
      verb,
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  /**
   * Método que permite actualizar elementos a través de un servicio API
   * 
   * @param {string} path Ruta URL que describe la ubicación específica del recurso en el servidor
   * @param {object} params Query params que se desean utilizar en la petición
   * @param {string} companyId Identificador de la compañía
   * 
   * @returns Respuesta del servidor con el elemento pedido en la consulta
   */
  async getElement(path, params, companyId = null) {
    await this.renewAccessToken();
    const queryParams = new URLSearchParams();
    for (const param in params) {
      if (Object.hasOwnProperty.call(params, param)) {
        const value = params[param];
        if (typeof value !== 'undefined') {
          queryParams.set(param, value);
        }
      }
    }
    const url = `${baseUrl}${path}?${queryParams.toString()}`;
    const payload = {
      data: null,
    };
    return Request.async(
      url,
      payload,
      'GET',
      false,
      'application/json',
      headerAuth,
      companyId,
    );
  },
  /**
   * Permite obtener la geometría de un conjunto de parcelas SIGPAC
   * 
   * @param {string} filter Queryparams que permite acotar el conjunto de parcelas obtenidas
   * @returns {Array<object>} Array de objetos con la geometría de las parcelas obtenidas
   */
  async getGeoServerCollectionSigpac(filter) {
    const url = `${geoServerCollectionSigpac}?cql_filter=${filter}`;

    return fetch(url, {
      method: 'GET',
    });
  },
  /**
   * Función que permite obtener los datos de un servicio API
   * Se usa para inyección de dependencias en el componente GIS
   * y en la obtención de datos del geoserver de GIS
   * @param {string} url Ruta URL que describe la ubicación específica del recurso en el servidor
   * @returns Respuesta del servidor con el elemento pedido en la consulta
   */
  async getFetch(url) {
    return fetch(url);
  },
};
