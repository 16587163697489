import CustomStore from 'devextreme/data/custom_store';

const filtersTranslated = {};

export default {
  props: {
    permissions: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Permite añadir parámetros en la url para llamadas
     * que requieran una relación entre entidades.
     * Ej: {moduleName}/{product_id}/content
     */
    moduleExtension: {
      type: String,
      default: '',
    },
    extraParams: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    customExtraParams() {
      return { ...this.extraParams };
    },
    modulePath() {
      return `${this.moduleName}${this.moduleExtension}`;
    },
  },
  data() {
    return {
      keyName: 'customStore',
      dataSource: null,
      moduleName: 'ERROR', // Cada componente debe darle el nombre adecuado
      i18nPrefix: '',
      storeKeyExpr: 'id',
      pageSizes: [5, 10, 20],
      hasVisibilityGroup: false,
    };
  },
  async beforeMount() {
    if (this.moduleName && this.moduleName !== 'ERROR') this.dataSource = await this.getNewCustomStore();
  },
  methods: {
    async getNewCustomStore() {
      const newCustomStore = await new CustomStore({
        key: this.storeKeyExpr,
        load: (loadOptions) => {
          let queryParams = this.$api.getCustomStoreQueryParams(loadOptions);
          // Se revierte la traducción de los filtros
          // Devextreme no posee esta funcionalidad
          if (loadOptions.filter) {
            for (const key in filtersTranslated) {
              if (Object.hasOwnProperty.call(filtersTranslated, key)) {
                const realKey = filtersTranslated[key];
                queryParams = queryParams.replace(key, realKey);
              }
            }
          }
          this.callBackCustomStore(queryParams, this.customExtraParams);
          return this.$api.getCustomeStoreByQueryParams(this.modulePath,
            queryParams, this.customExtraParams)
            .then(
              (xhr) => {
                const response = JSON.parse(xhr.response);
                if (response.results?.[0]?.visibility_groups) {
                  this.hasVisibilityGroup = true;
                }
                // Se realiza una traducción de los filtros de forma manual
                // Devextreme no posee esta funcionalidad
                if (loadOptions.group) {
                  for (const filter of response.results) {
                    const translation = this.getTranslation(filter.key);
                    filtersTranslated[translation] = filter.key;
                    filter.key = translation;
                  }
                }
                return {
                  data: response.results,
                  totalCount: response.count,
                  summary: response.summary,
                  groupCount: response.groupCount,
                };
              },
            )
            .catch((error) => { throw new Error(error); });
        },
      });
      return newCustomStore;
    },
    getTranslation(data) {
      let tag = `${this.i18nPrefix}${data}`;
      let translation = this.$t(tag);
      if (translation !== tag) return translation;

      tag = `common.${data}`;
      translation = this.$t(tag);
      if (translation !== tag) return translation;

      return data;
    },
    callBackCustomStore(queryParams, extraParams) {
      this.$emit('callBackCustomStore', { queryParams, extraParams });
    },
  },
};
