<template>
  <div
    :class="{
      'main-weather': true,
      'content-devx':includesContentDevx
    }"
  >
    <div class="content-gis-map">
      <div
        :id="`${nameMap}_${initialDate}`"
        :style="minHeight"
      />
    </div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import '@fortawesome/fontawesome-free';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'GISPointsMap',
  props: {
    zoom: { type: Number, default: 1 },
    selectedMinHeight: { type: String, default: '80vh' },
    nameMap: { type: String, default: '' },
    treeLocations: {
      type: Array,
      default: () => [],
    },
    currentPoint: {
      type: Object,
      default: () => null,
    },
    includesContentDevx: {
      type: Boolean,
      default: false,
    },
    onlyRead: { type: Boolean, default: false },
    showHomeBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentLayerBase: {},
      event: 'updateGIS',
      map: null,
      layerBounds: null,
      initialDate: Date.now(),
      markerLayer: L.layerGroup(),
      radiusLayer: L.layerGroup(),
      marker: null,
      circleStyle: {
        className: 'leaflet-control-locate-circle',
        color: '#136AEC',
        fillColor: '#136AEC',
        fillOpacity: 0.15,
        weight: 0,
      },
      svgIcon: `<svg viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
  <circle cx="20" cy="20" fill="none" r="10" stroke="#06C6FF" stroke-width="2">
    <animate attributeName="r" from="8" to="20" dur="1.5s" begin="0s" repeatCount="indefinite"/>
    <animate attributeName="opacity" from="1" to="0" dur="1.5s" begin="0s" repeatCount="indefinite"/>
  </circle>
  <circle cx="20" cy="20" fill="#067CEF" r="10"/>
</svg>
`,
    };
  },
  computed: {
    minHeight() {
      return `min-height: ${this.selectedMinHeight}`;
    },
    ...mapState('Gis', [
      'osm',
      'googleHybrid',
      'currentOverlayLayer',
    ]),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('SearcherObservations', ['name', 'latitude', 'longitude', 'area']),
  },
  mounted() {
    this.setCurrentLayersMap([]);
    this.renderMap();
    if (!this.onlyRead) {
      this.addFuncionalities();
      this.addClickMapMarker();
    }
    this.addOverlayLayer();
    if (this.showHomeBtn) {
      this.addHomeButton();
    }
  },
  methods: {
    /**
     * Añadimos una capa tanto de tipo GeoJson como WMS a la lista de capas activas
     */
    async addOverlayLayer() {
      this.$f7.preloader.show();
      try {
        let layer;
        let layer2;
        const layerStyles = {
          default: {
            color: 'rgba(255,0,224,0.7)',
            weight: 1,
            fillOpacity: 0.5,
          },
          active: {
            weight: 3,
            color: 'rgba(241,234,234,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
          selected: {
            weight: 2,
            color: 'rgba(250,250,120,0.71)',
            dashArray: '',
            fillOpacity: 0.7,
          },
        };
        for (const geoData of this.treeLocations) {
          for (const children of geoData.children) {
            layer = L.geoJson(children.geo_feature).addTo(this.map);
            for (const children2 of children.children) {
              layer2 = L.geoJson(children2.geo_feature).addTo(this.map);
              layer2.setStyle(layerStyles.active);
            }
          }
        }
        const bounds = layer.getBounds();
        if (this.currentPoint === null) {
          this.map.fitBounds(bounds);
        }
      } catch (error) {
        if (error.message === 'Bounds are not valid.') {
          this.$notifyDX(
            {
              message: this.$t('BoundsAreNotValid'),
              width: 550,
            },
            'error',
            3000,
          );
        } else {
          this.$notifyDX(
            {
              message: this.$t(`${error}`),
              width: 550,
            },
            'error',
            3000,
          );
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },

    renderMap() {
      this.map = L.map(`${this.nameMap}_${this.initialDate}`);
      L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase = L.tileLayer(this.googleHybrid.route, this.googleHybrid.properties).addTo(this.map);
      this.currentLayerBase.bringToBack();
      this.markerLayer.addTo(this.map);
      if (this.currentPoint !== null) {
        const marker = new L.Marker({
          lat: this.currentPoint.latitude,
          lng: this.currentPoint.longitude,
        },
        {
          draggable: !this.onlyRead,
        }).addTo(this.markerLayer);
        this.map.setView([this.currentPoint.latitude, this.currentPoint.longitude], this.zoom);
        this.markerEvents(marker);
      }
    },
    addHomeButton() {
      const self = this;
      L.Control.zoomHome = L.Control.extend({
        options: {
          position: 'topleft',
          zoomHomeText: '<i class="fg-search-home fa-2x"></i>',
          zoomHomeTitle: 'Zoom home',
        },
        onAdd() {
          const controlName = 'gin-control-zoom';
          const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
          const { options } = this;
          // eslint-disable-next-line no-underscore-dangle
          this._zoomHomeButton = this.createButton(options.zoomHomeText, options.zoomHomeTitle,
            `${controlName}-home`, container, this.zoomHome);

          return container;
        },

        zoomHome() {
          if (self.center !== null) {
            self.map.setView(
              [self.currentPoint.latitude, self.currentPoint.longitude],
              self.zoom,
            );
          } else {
            self.map.fitBounds(self.layerBounds);
          }
        },

        createButton(html, title, className, container, fn) {
          const link = L.DomUtil.create('a', className, container);
          link.innerHTML = html;
          link.href = '#';
          link.title = title;
          L.DomEvent.on(
            link,
            'mousedown dblclick',
            L.DomEvent.stopPropagation,
          )
            .on(link, 'click', L.DomEvent.stop)
            .on(link, 'click', fn, this);
          return link;
        },
      });
      // eslint-disable-next-line new-cap
      const zoomHome = new L.Control.zoomHome();
      zoomHome.addTo(self.map);
    },

    addFuncionalities() {
      const self = this;
      try {
        L.control.scale().addTo(self.map);
        self.map.attributionControl.addAttribution(
          'Margaret from <a href="https://hispatecanalytics.com//">HispatecAnalytics SA</a>',
        );
        L.Control.zoomLocation = L.Control.extend({
          options: {
            position: 'topleft',
            zoomLocationText: '<i class="fg-position fa-2x"></i>',
            zoomLocationTitle: 'Zoom home',
          },
          onAdd() {
            const controlName = 'gin-control-zoom';
            const container = L.DomUtil.create('div', `${controlName} leaflet-bar`);
            const { options } = this;
            // eslint-disable-next-line no-underscore-dangle
            this._zoomLocationButton = this.createButton(options.zoomLocationText, options.zoomLocationTitle,
              `${controlName}-home`, container, this.zoomLocation);

            return container;
          },

          zoomLocation() {
            let marker;

            self.map.locate({
              setView: true,
              maxZoom: 120,
              enableHighAccuracy: true,
              maximumAge: 10000,
            }).on('locationfound', (e) => {
              // circulo - rango
              if (this.radiusLayer) {
                this.radiusLayer.clearLayers();
              }

              const latlng = [e.latitude, e.longitude];
              const layer = new L.LayerGroup();
              this.radiusLayer = layer;
              L.circle(latlng, e.accuracy, this.circleStyle).addTo(layer);

              // distancia
              const distanceInMeters = e.accuracy.toFixed(0);

              this.marker = L.marker(latlng);
              const customIcon = L.divIcon({
                html: self.svgIcon,
                className: 'location-pin',
                iconAnchor: [10, 20],
                iconSize: [20, 20],
              });
              this.marker.setIcon(customIcon);
              const popup = L.popup({ offset: [0, -10] }).setLatLng(latlng).setContent(`Estás a ${distanceInMeters} metros de este punto`);
              this.marker.bindPopup(popup);

              this.marker.addTo(layer);
              this.radiusLayer.addTo(self.map);
            }).on('locationerror', (error) => {
              if (self.marker) {
                self.map.removeLayer(self.marker);
                self.marker = undefined;
              }
            });
          },

          createButton(html, title, className, container, fn) {
            const link = L.DomUtil.create('a', className, container);
            link.innerHTML = html;
            link.href = '#';
            link.title = title;
            L.DomEvent.on(link, 'mousedown dblclick', L.DomEvent.stopPropagation)
              .on(link, 'click', L.DomEvent.stop)
              .on(link, 'click', fn, this);
            return link;
          },
        });
        // eslint-disable-next-line new-cap
        const zoomLocation = new L.Control.zoomLocation();
        zoomLocation.addTo(self.map);
      } catch (e) {
        this.$notifyDX(
          {
            message: this.$t(`${e}`),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
    addClickMapMarker() {
      const self = this;
      this.map.on('click', (e) => {
        self.markerLayer.clearLayers();
        this.setCurrentPoint({
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        });
        const blueIcon = new L.Icon({
          iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
          shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        });
        const newMarker = L.marker(e.latlng, {
          draggable: true,
        }).setIcon(blueIcon);
        self.markerEvents(newMarker);
        newMarker.addTo(self.markerLayer);
      });
    },
    markerEvents(marker) {
      const self = this;
      marker.on('dragend', (e) => {
        self.setCurrentPoint({
          // eslint-disable-next-line no-underscore-dangle
          latitude: e.target._latlng.lat,
          // eslint-disable-next-line no-underscore-dangle
          longitude: e.target._latlng.lng,
        });
      });
    },
    ...mapActions('Gis', ['setCurrentLayersMap', 'setCurrentOverlayLayer', 'setCurrentPoint']),
  },
};
</script>
<style>
  @import './Map.styles.scss';
  @import 'https://unpkg.com/leaflet@1.7.1/dist/leaflet.css';
  @import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css';
</style>
