<template>
  <DxChart
    id="chart-class"
    :data-source="histogramFormatted"
    :bar-group-width="200"
    palette="Material"
    :rotated="true"
  >
    <DxCommonSeriesSettings
      argument-field="index"
      type="fullstackedbar"
      :bar-width="showArea ? 40 : 100"
    />
    <DxTitle
      :text="''"
    />
    <DxSeries
      v-for="(serie, index) in getChartSeries"
      :key="index"
      :value-field="serie.value"
      :name="serie.value"
      :color="serie.color"
    />
    <DxTooltip
      :enabled="true"
      :z-index="999999"
      :customize-tooltip="customizeTooltip"
    />
    <DxSize
      :height="getHeight"
    />
    <DxLegend
      :position="isForPdf ? 'inside' : 'outside'"
      vertical-alignment="bottom"
      horizontal-alignment="center"
      item-text-position="right"
      :padding-left-right="isForPdf ? 2 : 10"
      :padding-top-bottom="isForPdf ? 2 : 10"
      :font="isForPdf ? { size: 14, weight: 500 } : {}"
    />
  </DxChart>
</template>
<script>
import {
  DxChart, DxSeries, DxTooltip, DxLegend, DxTitle, DxCommonSeriesSettings, DxSize,
} from 'devextreme-vue/chart';
import moment from 'moment';

export default {
  name: 'HistogramByClass',
  components: {
    DxChart,
    DxSeries,
    DxTooltip,
    DxCommonSeriesSettings,
    DxLegend,
    DxTitle,
    DxSize,
  },
  props: {
    histogram: {
      type: Object,
      default: () => {},
    },
    area: {
      type: Number,
      default: 0,
    },
    cloud: {
      type: Number,
      default: 0,
    },
    showingDate: {
      type: String,
      default: '',
    },
    index: {
      type: String,
      default: '',
    },
    max: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    mean: {
      type: Number,
      default: null,
    },
    stddev: {
      type: Number,
      default: null,
    },
    isForPdf: {
      type: Boolean,
      default: false,
    },
    showArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      colors: ['#f1722b', '#fdae61', '#ffffbf', '#a6d96a', '#1a9641'],
      colorsNDMI: ['#bc2322', '#d99a5c', '#ccce99', '#3fa1bd', '#297099'],
      colorsGLI: ['#5a9542', '#306637', '#19552b', '#104539', '#0b393e'],
      colorsNDVICONTRASTADO: ['#e75437','#febe74','#b8e17b','#74b4fe','#1674e1'],
      indexLimits: {
        NDVI: {
          max: 1,
          min: 0,
        },
        NDVI_CONT: {
          max: 1,
          min: 0,
        },
        SAVI: {
          max: 1,
          min: 0,
        },
        ARVI: {
          max: 1,
          min: 0,
        },
        VARI: {
          max: 1,
          min: -0.6,
        },
        LAI: {
          max: 1,
          min: 0,
        },
        NDRE: {
          max: 1,
          min: 0,
        },
        RECI: {
          max: 1,
          min: -0.6,
        },
        NDMI: {
          max: 1,
          min: -1,
        },
        GNDVI: {
          max: 1,
          min: 0,
        },
        GLI: {
          max: 1,
          min: -1,
        },
        TCI: {
          max: 1,
          min: -1,
        },
        MSAVI: {
          max: 1,
          min: -0.8,
        },
      },
    };
  },
  computed: {
    getHeight() {
      if (this.isForPdf) {
        return 180;
      }
      if (this.showArea) {
        return 180;
      }
      return 200;
    },
    histogramFormatted() {
      const auxHistogramFormatted = [];
      const { hist_X, hist_Y } = this.histogram;
      const { indexLimits, index } = this;
      const indexLimit = indexLimits[index];

      for (let i = 0; i < hist_X.length - 1; i += 1) {
        const x = (hist_X[i] + hist_X[i + 1]) / 2;

        if (x > indexLimit.min && x < indexLimit.max) {
          auxHistogramFormatted.push({
            x,
            y: hist_Y[i],
          });
        }
      }

      if (index === 'NDVI_CONT') {
        auxHistogramFormatted.forEach((item) => {
          item.class = this.getClass(item.x).class;
        });
      } else {
        const classRange = parseInt(this.histogram.hist_X.length / 5);
        auxHistogramFormatted.forEach((item) => {
          item.class = this.getClass(item.x.toFixed(2), classRange);
        });
      }

      const total = auxHistogramFormatted.reduce((acc, cur) => acc + cur.y, 0);

      const groupedByClass = auxHistogramFormatted.reduce((acc, item) => ({
        ...acc,
        [item.class]: (acc[item.class] || 0) + item.y,
      }), {});

      const groupedByClassWithPercentage = {};

      Object.entries(groupedByClass).forEach(([key, value]) => {
        if (this.isForPdf || this.showArea) {
          groupedByClassWithPercentage[`${key} (${(Math.round((parseFloat(value / total) * 100).toFixed(2)))}%, ${value / 100}ha)`] = value;
        } else {
          groupedByClassWithPercentage[`${key} (${(Math.round((parseFloat(value / total) * 100).toFixed(2)))}%)`] = value;
        }
      });

      return [{ ...groupedByClassWithPercentage, index: this.index }];
    },
    getChartSeries() {
      const chart = { ...this.histogramFormatted[0] };
      delete chart.index;
      const keys = Object.keys(chart);

      return Object.entries(chart)
        .filter((item) => item[1] > 0) // Filtrar solo valores positivos
        .sort((a, b) => {
          // Extraer el límite inferior de cada rango
          const lowerLimitA = parseFloat(a[0].split(' - ')[0]);
          const lowerLimitB = parseFloat(b[0].split(' - ')[0]);
          return lowerLimitA - lowerLimitB; // Ordenar de menor a mayor
        })
        .map((item, index) => {
          const value = item[0];
          let color;
          
          // Asignar colores según el índice específico
          if (this.index === 'NDVI_CONT') {
            // Usar el índice directamente para obtener el color de colorsNDVICONTRASTADO
            color = this.colorsNDVICONTRASTADO[index]; // Usar el índice del mapa
          } else {
            // Lógica para otros índices, si es necesario
            color = this.index === 'NDMI' 
              ? this.colorsNDMI[keys.indexOf(value)] 
              : (this.index === 'GLI' 
                ? this.colorsGLI[keys.indexOf(value)] 
                : this.colors[keys.indexOf(value)]);
          }

          return {
            value,
            color,
          };
        });
    },
    formattingShowingDate() {
      return moment(this.showingDate).format('DD-MM-YYYY');
    },
  },
  watch: {
    // Para actualizar el maximo y minimo del indice del NDVI CONTRASTADO
    max(newValue, oldValue) {
      if (newValue !== oldValue && this.index === 'NDVI_CONT') {
        this.updateIndexLimits();
      }
    },
    min(newValue, oldValue) {
      if (newValue !== oldValue && this.index === 'NDVI_CONT') {
        this.updateIndexLimits();
      }
    },
     // Para ejecutarlo cuando cambie el indice ya que en el caso de ndvi a ndvi_cont tiene los mismos datos
     // y no los detectaria solo al cambiar los valores max/min
    index(newValue, oldValue) {
      if (newValue !== oldValue && this.index === 'NDVI_CONT') {
        this.updateIndexLimits();
      }
    },
  },
  methods: {
    // Funcion para actualizar maximo y minimos para el NDVI CONTRASTADO
    updateIndexLimits() {
      // Verificamos que el indice existe
      if (this.indexLimits[this.index]) {
        this.indexLimits[this.index].max = 1;
        this.indexLimits[this.index].min = 0;
      }
    },
    customizeTooltip(pointInfo) {
      const percentCloud = (1-(this.cloud/100)) * parseFloat(pointInfo.percent.toFixed(2));
      const area = parseFloat(this.area * percentCloud).toFixed(2);

      return {
        html: `
          <span class='bold'>${area} ha</span><br>
        `,
      };
    },
    getNDVIContClassAndColor(value) {
      const { mean, min, max } = this;

      // Verificar si break_color está disponible
      if (this.histogram.break_color && this.histogram.break_color.length > 0) {
        const breakPoints = this.histogram.break_color;

        for (let i = 0; i < breakPoints.length - 1; i++) {
          const lowerLimit = breakPoints[i];
          const upperLimit = breakPoints[i + 1];

          if (value >= lowerLimit && value < upperLimit) {
            return { class: `${lowerLimit.toFixed(2)} - ${upperLimit.toFixed(2)}`, color: this.colorsNDVICONTRASTADO[i] };
          }
        }

        // Si el valor es igual o mayor al último límite, devuelve la última clase
        return { class: `${breakPoints[breakPoints.length - 2].toFixed(2)} - ${breakPoints[breakPoints.length - 1].toFixed(2)}`, color: this.colorsNDVICONTRASTADO[breakPoints.length - 2] };
      }

      // Lógica existente para el caso sin break_color
      const filt_hist = this.histogram.hist_X.map((x, i) => ({ x, y: this.histogram.hist_Y[i] })).filter((d) => d.x >= 0 && d.x <= 1);

      // Conseguir el valor x del bin mínimo
      const min_h = filt_hist.find((d) => d.y > 0)?.x;

      // Conseguir el valor x del bin máximo
      const max_h = [...filt_hist].reverse().find((d) => d.y > 0)?.x;

      const j_min = (mean - min) / 4;
      const j_max = (max - mean) / 4;

      const n1 = min_h + j_min;
      const n2 = min_h + 3 * j_min;
      const n3 = max_h - 3 * j_max;
      const n4 = max_h - j_max;

      if (value <= n1) {
        return { class: `0 - ${n1.toFixed(2)}`, color: '#e75437' };
      } else if (value <= n2) {
        return { class: `${n1.toFixed(2)} - ${n2.toFixed(2)}`, color: '#febe74' };
      } else if (value <= n3) {
        return { class: `${n2.toFixed(2)} - ${n3.toFixed(2)}`, color: '#b8e17b' };
      } else if (value <= n4) {
        return { class: `${n3.toFixed(2)} - ${n4.toFixed(2)}`, color: '#74b4fe' };
      } else {
        return { class: `${n4.toFixed(2)} - 1`, color: '#1674e1' };
      }
    },
    getClass(value) {
      if (this.index === 'NDVI_CONT') {
        return this.getNDVIContClassAndColor(value);
      }
      else {
        const total = Math.abs(this.indexLimits[this.index].max - this.indexLimits[this.index].min) / 5;
        let class0, class1, class2, class3, class4, class5;
          class0 = this.indexLimits[this.index].min;
          class1 = this.indexLimits[this.index].min + total;
          class2 = this.indexLimits[this.index].min + total * 2;
          class3 = this.indexLimits[this.index].min + total * 3;
          class4 = this.indexLimits[this.index].min + total * 4;
          class5 = this.indexLimits[this.index].min + total * 5;

        if (value <= class1) {
          return `${class0.toFixed(2)} - ${class1.toFixed(2)}`;
        }

        if (value >= class1 && value < class2) {
          return `${class1.toFixed(2)} - ${class2.toFixed(2)}`;
        }

        if (value >= class2 && value < class3) {
          return `${class2.toFixed(2)} - ${class3.toFixed(2)}`;
        }

        if (value >= class3 && value < class4) {
          return `${class3.toFixed(2)} - ${class4.toFixed(2)}`;
        }

        if (value >= class4 && value < class5) {
          return `${class4.toFixed(2)} - ${class5.toFixed(2)}`;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import './histogramByClass.styles.scss';
</style>
