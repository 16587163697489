<template>
  <f7-page>
    <navbar :text="`${currentPlantation.company} > ${currentPlantation.explotation} > ${currentPlantation.parcel} > ${currentPlantation.sector} > ${currentPlantation.name} > ${$t('Utilities.nutrition')}`" />
    <PopupConfirmWizardExit />
    <stepper />
    <dosage-product
      :key="keyDosageProduct"
      @resetBalance="resetDosageProduct"
    />
    <stepper-navigation
      :disabled="disabledNextButton"
      :alert-message="alertMessage"
    />
  </f7-page>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import navbar from '@/components/NavBar.vue';
import Stepper from '@/components/wizardNutrition/stepper/index.vue';
import StepperNavigation from '@/components/wizardNutrition/stepper-navigation/index.vue';
import DosageProduct from '@/components/wizardNutrition/dosageProduct/index.vue';
import PopupConfirmWizardExit from '../../../components/wizardNutrition/popupConfirmWizardExit/index.vue';

export default {
  name: 'NutritionStepThree',
  components: {
    navbar,
    Stepper,
    StepperNavigation,
    DosageProduct,
    PopupConfirmWizardExit,
  },
  props: [],
  data() {
    return {
      keyDosageProduct: 'keyDosageProduct',
    };
  },
  computed: {
    ...mapGetters('nutritionStepper', ['stepData']),
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Nutrition', ['productsNotInNutritionPlan']),
    disabledNextButton() {
      return this.alertMessage.length > 0;
    },
    alertMessage() {
      if (this.stepData.dosageProduct.some((product) => product?.product_deleted ?? false)) {
        return this.$t('productsDeleted');
      }

      return '';
    },
  },
  mounted() {

  },
  methods: {
    resetDosageProduct() {
      // generate random key to reset component
      this.keyDosageProduct = `keyDosageProduct-${Math.random().toString(36).substring(7)}`;
    },
  },
};

</script>
<style src="./nutrition-step-three.scss" scoped lang="scss"></style>
