<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_title_ParcelSystem')} > ${actualUser.name} >  ${actualLocation.name} > ${$t('parcels')}`" />
    <RegisterParcelStep1 :current-name="currentParcel.name" />
    <f7-row>
      <f7-col>
        <f7-button
          class="dx-btn-cancel margin-right"
          raised
          type="default"
          text-color="grey"
          styling-mode="outlined"
          name="ButtonCancel"
          @click="goToBack()"
        >
          {{ $t("Button_go_to_back") }}
        </f7-button>
      </f7-col>
      <f7-col>
        <f7-button
          class="dx-btn-success"
          raised
          type="success"
          styling-mode="contained"
          @click="goToStep2"
        >
          {{ $t("next") }}
        </f7-button>
      </f7-col>
    </f7-row>
  </f7-page>
</template>

<script>
import { mapState } from 'vuex';
import RegisterParcelStep1 from '../../components/parcel/RegisterParcelStep1/index.vue';
import navbar from '../../components/NavBar.vue';

export default {
  name: 'RegisterParcelStep1Page',
  components: {
    RegisterParcelStep1,
    navbar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('Parcel', ['currentParcel']),
    ...mapState('Explotaciones', ['actualLocation', 'actualUser']),
  },
  methods: {
    async goToStep2() {
      if (typeof this.currentParcel.name === 'undefined' || this.currentParcel.name === '') {
        this.$notifyDX(
          {
            message: this.$t('parcel.register.name.requiredRule'),
            width: 550,
          },
          'info',
          3000,
        );
      } else {
        this.$f7.views.main.router.navigate('/registerParcelStep2/', {
          reloadCurrent: true,
        });
      }
    },
    goToBack() {
      if (typeof this.currentParcel.id === 'undefined') {
        this.$f7.views.main.router.navigate('/viewLocation/', {
          reloadCurrent: true,
        });
      } else {
        this.$f7.views.main.router.navigate('/viewParcel/', {
          reloadCurrent: true,
        });
      }
    },
  },
};
</script>

<style scoped>
.dx-btn-cancel.margin-right {
  width: 15%;
  margin-left: 2%;
}
.dx-btn-success {
  width: 30%;
  margin-left: 68%;
}
</style>
