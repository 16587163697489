import Request from './Request';
import Api from './Api';
import Helpers from '../js/helpers';

const treatmentPlanStatus = {
  EXECUTED: 'ACTIVO',
  CANCELLED: 'CANCELADO',
  PLANNED: 'PLANEADO',
};

const treatmentPlanPeriod = {
  MONTHLY: 'MENSUAL',
  WEEKLY: 'SEMANAL',
};

const treatmentPlanService = {

  async getAll({ companyId, idPlantation = '' }) {
    let url = `${Api.getBaseUrl()}/treatment_plan_v2`;
    if (idPlantation !== '') {
      url += `?plantation=${idPlantation}`;
    }
    const xhr = await Request.async(url,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async getById(id, companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_plan_v2/${id}`,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async getLast(companyId, idPlantation = '') {
    let url = `${Api.getBaseUrl()}/treatment_plan_v2`;
    if (idPlantation !== '') {
      url += `?plantation=${idPlantation}`;
      url += '&latest=true';
    } else {
      url += '?latest=true';
    }

    const xhr = await Request.async(url,
      { data: {} },
      'GET', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return JSON.parse(xhr.response);
  },
  async edit(id, dataPayload, companyId) {
    try {
      const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_plan_v2/${id}`,
        { data: JSON.stringify(dataPayload) },
        'PUT', false,
        'application/json',
        Api.getHeaderAuth(),
        companyId);
      return JSON.parse(xhr.response);
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },
  async delete(id, companyId) {
    const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_plan_v2/${id}`,
      { data: {} },
      'DELETE', false,
      'application/json',
      Api.getHeaderAuth(),
      companyId);
    return xhr;
  },
  async save(dataPayload, companyId) {
    try {
      const xhr = await Request.async(`${Api.getBaseUrl()}/treatment_plan_v2`,
        { data: JSON.stringify(dataPayload) },
        'POST', false,
        'application/json',
        Api.getHeaderAuth(),
        companyId);
      return JSON.parse(xhr.response);
    } catch (error) {
      return Promise.reject(Helpers.getError(error));
    }
  },

};

export default treatmentPlanService;

export { treatmentPlanService, treatmentPlanStatus, treatmentPlanPeriod };
