import moment from 'moment';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    dataIrrigation: [],
    showPopUp: false,
    actualIrrigation: {
      date: moment().format('YYYY-MM-DD'),
      doses: '',
      type: 'REGISTER',
      time: 0,
      plantation: '',
      id: '',
    },
    actualIrrigationRecommended: {
      date: moment().format('YYYY-MM-DD'),
      doses: '',
      type: 'RECOMMENDATION',
      time: 0,
      plantation: '',
      id: '',
    },
    idCompany: '',
    showPopUpRecommended: false,
    isEdit: false,
    isView: false,
    irrigationSelectedId: '',
  },
  mutations: {
    FETCH_DATA_IRRIGATION(state, value) {
      state.dataIrrigation = value;
    },
    SET_SHOW_POPUP(state, value) {
      state.showPopUp = value;
    },
    SET_SHOW_POPUP_RECOMMENDED(state, value) {
      state.showPopUpRecommended = value;
    },
    SET_ACTUAL_IRRIGATION(state, value) {
      state.actualIrrigation = value;
    },
    SET_ACTUAL_IRRIGATION_RECOMMENDED(state, value) {
      state.actualIrrigationRecommended = value;
    },
    SET_ID_PLANTATION(state, value) {
      state.actualIrrigation.plantation = value;
      state.actualIrrigationRecommended.plantation = value;
    },
    SET_ID_COMPANY(state, value) {
      state.idCompany = value;
    },
    SET_DATE(state, value) {
      state.actualIrrigation.date = value;
    },
    SET_DOSES(state, value) {
      state.actualIrrigation.doses = value;
    },
    SET_TIME(state, value) {
      state.actualIrrigation.time = value;
    },
    SET_DATE_RECOMMENDED(state, value) {
      state.actualIrrigationRecommended.date = value;
    },
    SET_DOSES_RECOMMENDED(state, value) {
      state.actualIrrigationRecommended.doses = value;
    },
    SET_TIME_RECOMMENDED(state, value) {
      state.actualIrrigationRecommended.time = value;
    },
    SET_IS_EDIT(state, value) {
      state.isEdit = value;
    },
    SET_IS_VIEW(state, value) {
      state.isView = value;
    },
    SET_IRRIGATION_SELECTED_ID(state, value) {
      state.irrigationSelectedId = value;
    },
  },
  actions: {
    async fetchDataIrrigation({ commit, state }, idPlantation) {
      try {
        const companyData = state.idCompany;
        const xhr = await Api.getIrrigation(companyData, idPlantation);
        const dataIrrigation = JSON.parse(xhr.response).data;
        commit(
          'FETCH_DATA_IRRIGATION',
          dataIrrigation,
        );
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createDataIrrigation({ state }) {
      try {
        const companyData = state.idCompany;
        await Api.postIrrigation(state.actualIrrigation, companyData);
        if (state.showPopUpRecommended) { await Api.postIrrigation(state.actualIrrigationRecommended, companyData); }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createDataIrrigationRecommended({ state }) {
      try {
        const companyData = state.idCompany;
        await Api.postIrrigation(state.actualIrrigationRecommended, companyData);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateDataIrrigation({ state }) {
      try {
        const companyData = state.idCompany;
        await Api.putIrrigation(state.actualIrrigation, state.irrigationSelectedId, companyData);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateDataIrrigationRecommended({ state }) {
      try {
        const companyData = state.idCompany;
        await Api.putIrrigation(state.actualIrrigationRecommended, state.irrigationSelectedId, companyData);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async deleteIrrigation(context, deleteData) {
      try {
        await Api.deleteIrrigation(deleteData.irrigationId, deleteData.companyId);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    setShowPopUp(context, value) {
      context.commit('SET_SHOW_POPUP', value);
    },
    setShowPopUpRecommended(context, value) {
      context.commit('SET_SHOW_POPUP_RECOMMENDED', value);
    },
    setActualIrrigation(context, value) {
      context.commit('SET_ACTUAL_IRRIGATION', value);
    },
    setActualIrrigationRecommended(context, value) {
      context.commit('SET_ACTUAL_IRRIGATION_RECOMMENDED', value);
    },
    setIdPlantation(context, value) {
      context.commit('SET_ID_PLANTATION', value);
    },
    setIdCompany(context, value) {
      context.commit('SET_ID_COMPANY', value);
    },
    setDate(context, value) {
      context.commit('SET_DATE', value);
    },
    setDoses(context, value) {
      context.commit('SET_DOSES', value);
    },
    setTime(context, value) {
      context.commit('SET_TIME', value);
    },
    setDateRecommended(context, value) {
      context.commit('SET_DATE_RECOMMENDED', value);
    },
    setDosesRecommended(context, value) {
      context.commit('SET_DOSES_RECOMMENDED', value);
    },
    setTimeRecommended(context, value) {
      context.commit('SET_TIME_RECOMMENDED', value);
    },
    setIsEdit(context, value) {
      context.commit('SET_IS_EDIT', value);
    },
    setIsView(context, value) {
      context.commit('SET_IS_VIEW', value);
    },
    setIrrigationSelectedId(context, value) {
      context.commit('SET_IRRIGATION_SELECTED_ID', value);
    },
    initStoreIrrigationRecommended({ commit }) {
      commit('SET_DATE_RECOMMENDED', moment().format('YYYY-MM-DD'));
      commit('SET_TIME_RECOMMENDED', '');
      commit('SET_DOSES_RECOMMENDED', '');
    },
    initStoreIrrigationREGISTER({ commit }) {
      commit('SET_DATE', moment().format('YYYY-MM-DD'));
      commit('SET_TIME', '');
      commit('SET_DOSES', '');
    },
  },
};
