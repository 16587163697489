<template>
  <f7-page>
    <navbar
      :key="getNavBarText"
      :text="getNavBarText"
    />

    <div
      v-if="loading"
      class="detail-reference-plan-placeholder"
    >
      <Spinner />
    </div>

    <ModalCopyReferencePlan
      v-if="plan != null"
      :id="id"
      :visible="ShowModal"
      type="reference"
      @close="ShowModal = false"
      @copy="copy"
    />

    <InfoHeader
      v-if="plan != null"
      :plan="plan"
      @open-copy-modal="ShowModal = true"
    />

    <div style="display: grid; grid-template-columns: repeat(2, minmax(0, 1fr))">
      <ResumePerElements
        v-if="plan != null"
        style="margin-top: 0; margin-bottom: 0;"
        :plan="plan"
      />
      <ResumePerProductsChart
        v-if="plan != null"
        style="margin-top: 0; margin-bottom: 0; margin-left: 0"
        :plan="plan"
      />
    </div>

    <ResumePerProducts
      v-if="plan != null"
      :plan="plan"
    />

    <ProductResumeTable
      v-if="plan != null"
      :fertilization="plan"
      is-reference-plan
    />

    <ApplicationEfficiencyPerPeriod
      v-if="plan != null"
      :fertilization="plan"
      is-reference-plan
    />

    <Observations
      v-if="plan != null"
      :fertilization="plan"
    />

    <div class="footer-buttons-page">
      <f7-button
        class="dx-btn-cancel btnBack"
        type="default"
        text-color="grey"
        styling-mode="outlined"
        @click="goBack"
      >
        {{ $t('Go_Back') }}
      </f7-button>
    </div>
  </f7-page>
</template>

<script>
import moment from 'moment';
import navbar from '../../../components/NavBar.vue';
import InfoHeader from '../../../components/utilities/referencePlans/detail/InfoHeader.vue';
import ResumePerElements from '../../../components/utilities/referencePlans/detail/ResumePerElements.vue';
import ResumePerProducts from '../../../components/utilities/referencePlans/detail/ResumePerProducts.vue';
import ResumePerProductsChart from '../../../components/utilities/referencePlans/detail/ResumePerProductsChart.vue';
import ModalCopyReferencePlan from '../../../components/utilities/referencePlans/detail/ModalCopyReferencePlan.vue';

import ApplicationEfficiencyPerPeriod from '../../../components/nutritionDetail/ApplicationEfficiencyPerPeriod/index.vue';
import Observations from '../../../components/nutritionDetail/observations/index.vue';
import Api from '../../../services/Api';
import Spinner from '../../../components/spinner.vue';

import ProductResumeTable from '../../../components/nutritionDetail/productResumeTable/index.vue';

export default {
  name: 'ReferencePlansListByGroup',
  components: {
    navbar,
    InfoHeader,
    ResumePerElements,
    ResumePerProducts,
    ResumePerProductsChart,
    ModalCopyReferencePlan,
    ApplicationEfficiencyPerPeriod,
    Observations,
    Spinner,
    ProductResumeTable,
  },
  data() {
    return {
      id: null,
      ShowModal: false,
      plan: null,
      loading: false,
    };
  },
  computed: {
    getNavBarText() {
      return `${this.$t('Utilities_Title')} > ${this.$t('LeftPanel_title_referencePlans')} > ${this.plan?.plantation_specie} > ${this.plan?.description}`;
    },
  },
  created() {
    this.id = this.$f7route.params.id;
  },
  async beforeMount() {
    await this.getReferencePlan();
  },
  methods: {
    async getReferencePlan() {
      try {
        this.loading = true;
        const xhr = await Api.getReferencePlan(this.id);
        const plan = JSON.parse(xhr.response);
        this.plan = {
          ...plan,
          ph: parseFloat(plan.ph).toFixed(1),
        };
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$helpers.getError(error),
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.loading = false;
      }
    },
    copy(date) {
      const planDuration = moment(this.plan.end_date).diff(moment(this.plan.init_date), 'days') || 0;

      this.$f7router.navigate({
        name: 'reference-plans-copy',
        params: {
          id: this.id,
        },
        query: {
          date,
          duration: planDuration,
          species: this.plan.plantation_specie,
        },
      });
    },
    goBack() {
      this.$f7router.navigate({
        name: 'reference-plans',
      });
    },
  },
};
</script>

<style>
.detail-reference-plan-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>
