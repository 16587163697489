<template>
  <div>
    <div
      class="content-devx-margin-block"
    >
      <div class="margin-bottom">
        <f7-row>
          <f7-col width="80">
            <f7-block-title>
              {{ $t('Searcher.observations.multimedia_content_title') }}
            </f7-block-title>
          </f7-col>
          <f7-col
            width="20"
            class="text-align-right"
          >
            <AttachFilePopup v-if="!readOnly" />
          </f7-col>
        </f7-row>
      </div>
      <ReportNotify
        v-show="reportMessage.length > 0"
        :message="reportMessage"
      />
      <DxDataGrid
        id="MultimediaData-datagrid"
        :data-source="multimedia"
        :remote-operations="true"
        :column-min-width="70"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :show-borders="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :row-alternation-enabled="true"
      >
        <DxSelection
          mode="single"
        />
        <!-- <DxStateStoring
          :enabled="true"
          type="localStorage"
          storage-key="MultimediaData"
        /> -->
        <DxPaging :page-size="gridSettings.pageSize" />
        <DxPager
          :show-page-size-selector="true"
          :allowed-page-sizes="pageSizes"
          :show-info="true"
        />
        <DxColumn
          data-field=""
          :caption="$t('thumbnail')"
          :allow-header-filtering="false"
          alignment="left"
          cell-template="thumbnail"
        />
        <DxColumn
          data-field="description"
          :caption="$t('Searcher.observations.description')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field="extension"
          :caption="$t('Searcher.observations.file')"
          :allow-header-filtering="false"
          alignment="left"
        />
        <DxColumn
          data-field=""
          alignment="center"
          cell-template="preview"
          :width="50"
        />
        <DxColumn
          data-field=""
          alignment="center"
          cell-template="share"
          :width="50"
        />
        <DxColumn
          data-field=""
          alignment="center"
          cell-template="download"
          :width="50"
        />
        <DxColumn
          data-field=""
          alignment="center"
          cell-template="delete"
          :width="50"
        />
        <template #thumbnail="{ data }">
          <div>
            <img
              v-if="getThumbnail(data).length > 0"
              :src="getThumbnail(data)"
              :alt="data.description"
              class="img-thumbnail"
            >
          </div>
        </template>
        <template #preview="{ data }">
          <f7-button
            v-if="getThumbnail(data).length > 0"
            class="dx-btn-preview"
            @click="previewImage(data)"
          >
            <f7-icon
              md="material:search"
            />
          </f7-button>
        </template>
        <template #share="{ data }">
          <f7-button
            @click="shareFile(data)"
          >
            <f7-icon md="material:share" />
          </f7-button>
        </template>
        <template #download="{ data }">
          <f7-button
            class="dx-btn-pdf"
            raised
            type="default"
            styling-mode="outlined"
            @click="openFile( data.data)"
          >
            <f7-icon
              md="material:file_download"
            />
          </f7-button>
        </template>
        <template #delete="{ data }">
          <f7-button
            class="dx-btn-pdf"
            raised
            type="default"
            styling-mode="outlined"
            :disabled="readOnly"
            @click="removeFile(data.data)"
          >
            <f7-icon
              md="material:clear"
            />
          </f7-button>
        </template>
      </DxDataGrid>
    </div>
    <DxPopup
      id="preview-image-popup"
      :title="$t('Searcher.observations.preview_image_title')"
      :visible.sync="previewPopupShow"
      :show-close-button="true"
      :close-on-outside-click="true"
      height="auto"
      :width="700"
      :drag-enabled="false"
      :position="{ my: 'center', at: 'center' }"
    >
    <div>
      <img
        v-if="previewImageSrc"
        :src="previewImageSrc"
        class="img-preview"
        alt="Image Preview"
      />
    </div>
    </DxPopup>
  </div>
</template>

<script>

import { mapActions } from 'vuex';

import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxStateStoring,
  DxPager,
  DxSelection,
} from 'devextreme-vue/data-grid';

import { DxPopup } from 'devextreme-vue/popup';
import { Device } from 'framework7/framework7-lite.esm.bundle';
import AttachFilePopup from './attachFilePopup/index.vue';
import settings from '../../../../../js/commonSettings';
import ReportNotify from '@/components/reportNotify/index.vue';

export default {
  name: 'MultimediaData',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxPopup,
    AttachFilePopup,
    ReportNotify
  },
  props: {
    multimedia: {
      type: Array,
      default: () => [],
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      previewPopupShow: false,
      previewImageSrc: '',
      reportMessage: '',
      sharing: false
    };
  },
  computed: {
    gridSettings() {
      return settings.datagrid;
    },
  },
  methods: {
    previewImage(data) {
      this.previewImageSrc = this.getThumbnail(data);
      this.previewPopupShow = true;
    },
    async openFile(urlFile) {
      try {
        // Realiza la solicitud para obtener la imagen
        const response = await fetch(urlFile.attachment);
        if (!response.ok) {
          throw new Error('Error al descargar la imagen');
        }

        if (Device.desktop) {
          const linkSource = urlFile.attachment;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = urlFile.description + urlFile.extension;
          downloadLink.click();
      } else {
        this.downloadForMobile(urlFile);
      }
      } catch (error) {
        this.$notifyDX(
          {
            message: this.$t('FileNotFound'),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },

    checkForReadWritePermissions() {
      const { permissions } = cordova.plugins;
      const list = [
        permissions.READ_MEDIA_IMAGES,
      ];
      permissions.hasPermission(list, success, null);

      function error() {
        this.$notifyDX(
          {
            message: this.$t('PermissionsReadWrite'),
            width: 550,
          },
          'error',
          3000,
        );
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            (status) => {
              if (!status.hasPermission) error();
            },
            error,
          );
        }
      }
    },

    downloadForMobile(urlFile) {
      this.checkForReadWritePermissions();
      const path = `${cordova.file.externalRootDirectory}Download/`;
      const filename = urlFile.description + urlFile.extension;
      const uri = encodeURI(urlFile.attachment);
      const fileTransfer = new FileTransfer();

      fileTransfer.download(
        uri,
        path + filename,
        () => {
          this.$notifyDX(
            {
              message: this.$t('FileDownloadedInDownloads'),
              width: 550,
            },
            'info',
            3000,
          );
        },
        (error) => {
          if (error.code === 1) {
            this.$notifyDX(
              {
                message: this.$t('PermissionsReadWriteCode1'),
                width: 550,
              },
              'error',
              3000,
            );
          } else {
            this.$notifyDX(
              {
                message: `Error:${error.code}`,
                width: 550,
              },
              'error',
              3000,
            );
          }
        },
        false,
        {
          headers: {
            Authorization: 'Basic dGVzdHVzZXJuYW1lOnRlc3RwYXNzd29yZA==',
          },
        },
      );
    },

    getThumbnail(data) {
      const imagesExtension = ['.png', '.jpg', '.jpeg'];

      if (imagesExtension.includes(data.data.extension.toLowerCase())) {
        return data.data.attachment;
      }

      return '';
    },

    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },

    savebase64AsFileAndShare(
      folderpath,
      filename,
      content,
      contentType,
      message,
    ) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              const fileName = folderpath + filename;

              window.plugins.socialsharing.shareWithOptions({
                message,
                files: [fileName],
              });

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    async shareFile(data) {
      this.sharing = true;
      this.reportMessage = this.$t('generatingFileForShare');

      // extension maps and mime types
      const mimeTypes = {
        '.pdf': 'application/pdf',
        '.png': 'image/png',
        '.jpg': 'image/jpeg',
        '.jpeg': 'image/jpeg',
      };

      try {
        // Intentar descargar el archivo
        const response = await fetch(data.data.attachment);
        if (!response.ok) {
          throw new Error(this.$t('fileDownloadError'));
        }

        const blob = await response.blob();
        const fileExtension = data.data.extension;
        const title = data.data.description;
        const fileType = mimeTypes[fileExtension] || 'application/octet-stream';

        const file = new File([blob], `${title}${fileExtension}`, { type: fileType });
        const files = [file];
  
        // Si estamos en un dispositivo de escritorio
        if (navigator.canShare && Device.desktop) {
          try {
            await navigator.share({
              files,
              title: title,
              text: '',
            });
          } catch (error) {
            this.reportMessage = '';
            this.$notifyDX(
              {
                message: error.message,
                width: 550,
              },
              'error',
              3000,
            );
          }
        } else if (Device.desktop && !navigator.canShare) {
          this.reportMessage = '';
          this.$notifyDX(
            {
              message: this.$t('fileShareNotSupported'),
              width: 550,
            },
            'error',
            3000,
          ); 
        } else {
          // Compartir en dispositivos móviles
          try {
            const reader = new FileReader();
            reader.onloadend = () => {
              const base64 = reader.result.split(',')[1];
              const path = `${cordova.file.externalRootDirectory}Download/`;
              this.savebase64AsFileAndShare(
                path,
                `${title}${fileExtension}`,
                base64,
                file.type,
                title,
              );
            };
            reader.readAsDataURL(blob);
          } catch (error) {
            this.reportMessage = '';
            this.$notifyDX(
              {
                message: this.$t('fileShareNotSupported'),
                width: 550,
              },
              'error',
              3000,
            ); 
          }
        }
        setTimeout(() => {
          this.reportMessage = this.$t('fileSharedSuccessfully');
        }, 3000);
      } catch (error) {
        this.reportMessage = ''
        this.$notifyDX(
          {
            message: error.message,
            width: 550,
          },
          'error',
          3000,
        );
      } finally {
        this.sharing = false;
        setTimeout(() => {
          this.reportMessage = '';
        }, 3000);
      }
    },
    ...mapActions('SearcherObservations', ['removeFile']),
  },
};
</script>
<style lang="scss" scoped>
@import './MultimediaData.styles.scss';
</style>
