<template>
  <div class="content-devx">
    <f7-block>
      <f7-row class="flexbox">
        <div v-if="editLandSize" class="chip-label">
          <DxForm>
            <DxSimpleItem
              :data-field="$t('ViewLocation_parcel_column_area')"
              :editor-options="{
                value: parseFloat(landSize.toFixed(2)),
                mode: 'number',
                placeholder: $t('ViewLocation_parcel_column_area'),
                showClearButton: true,
                onValueChanged: onValueChanged,
              }"
            >
            </DxSimpleItem>
          </DxForm>
        </div>
        <div v-else class="chip-label">
          {{ $t("Monitoring_point_area") }}: {{ parseFloat(landSize.toFixed(2)) }}
        </div>
        <div class="chip-label">
          {{ $t("Monitoring_point_longitude") }}: {{ parseFloat(lang).toFixed(6) }}
        </div>
        <div class="chip-label">
          {{ $t("Monitoring_point_latitude") }}: {{ parseFloat(lat).toFixed(6) }}
        </div>
      </f7-row>
    </f7-block>
  </div>
</template>

<script>
import {
  DxSimpleItem,
  DxForm,
} from 'devextreme-vue/form';

export default {
  name: 'ParcelInfo',
  components: {
    DxSimpleItem,
    DxForm 
  },
  props: {
    landSize: {
      type: Number,
      default: 0,
    },
    lang: {
      type: Number,
      default: 0,
    },
    lat: {
      type: Number,
      default: 0,
    },
    editLandSize: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onValueChanged(newValue) {
      // Emitimos el evento para pasarle el nuevo valor al padre
      this.$emit('update-land-size', parseFloat(newValue.value.toFixed(2)));
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./ParcelInfo.styles.scss";
</style>
