<template>
  <div>
    <DxPopup
      id="attributesPopup-popup"
      :title="$t('Configuration.ObservationsForms.attributesTitle')"
      :visible="showAttributePopup"
      :show-close-button="true"
      :on-hidden="onHidden"
      :close-on-outside-click="false"
      height="auto"
      :width="650"
      :position="{my: 'bottom', at: 'center'}"
    >
      <DxForm
        :read-only="readOnly"
      >
        <DxGroupItem>
          <DxSimpleItem
            :data-field="$t('Configuration.ObservationsForms.description')"
            :editor-options="{
              value: currentAttribute.description,
              placeholder: $t('Configuration.ObservationsForms.description'),
              showClearButton: true,
              onValueChanged: onValueChanged,
              width: '50%'
            }"
          >
            <DxRequiredRule :message="$t('Configuration.ObservationsForms.descriptionRequired')" />
          </DxSimpleItem>
          <DxSimpleItem
            :data-field="$t('Configuration.ObservationsForms.tipology')"
            editor-type="dxSelectBox"
            :editor-options="{
              items: inputTypes,
              value: currentAttribute.type,
              placeholder: $t('Configuration.ObservationsForms.tipology'),
              onValueChanged: onValueChanged,
              searchEnabled: true,
              searchTimeout: searchTimeoutOption,
              minSearchLength: minSearchLengthOption,
              showDataBeforeSearch: showDataBeforeSearchOption,
              displayExpr: displayTranslatedType,
              width: '50%'
            }"
          >
            <DxRequiredRule />
          </DxSimpleItem>
        </DxGroupItem>
        <DxGroupItem>
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.required')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: currentAttribute.required,
              onValueChanged: onValueChanged,
            }"
          />
        </DxGroupItem>
        <DxGroupItem :visible="currentAttribute.type === 'STRING'">
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.minLength')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentAttribute.min_length,
              showClearButton: true,
              onValueChanged: onValueChanged,
              width: '50%',
              min: 0,
            }"
          />
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.maxLength')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentAttribute.max_length,
              showClearButton: true,
              onValueChanged: onValueChanged,
              width: '50%',
              min: 0,
            }"
          />
        </DxGroupItem>
        <DxGroupItem :visible="currentAttribute.type === 'INT' || currentAttribute.type === 'FLOAT'">
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.minValue')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentAttribute.min_value,
              showClearButton: true,
              onValueChanged: onValueChanged,
              width: '50%',
              min: 0,
            }"
          />
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.maxValue')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentAttribute.max_value,
              showClearButton: true,
              onValueChanged: onValueChanged,
              width: '50%',
              min: 0,
            }"
          />
        </DxGroupItem>
        <DxGroupItem :visible="currentAttribute.type === 'FLOAT'">
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.decimalPlaces')"
            editor-type="dxNumberBox"
            :editor-options="{
              value: currentAttribute.decimal_places,
              showClearButton: true,
              onValueChanged: onValueChanged,
              width: '50%',
              max: 5,
              min: 1,
            }"
          />
        </DxGroupItem>
        <DxGroupItem :visible="currentAttribute.type === 'SELECT' || currentAttribute.type === 'LIST'">
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.choices')"
            :editor-options="{
              value: currentAttribute.choices.join(),
              placeholder: $t('Configuration.AttributesPopup.choices'),
              showClearButton: true,
              onValueChanged: onValueChanged,
            }"
            :help-text="$t('Configuration.ObservationsForms.choicesHelpText')"
          />
        </DxGroupItem>
        <DxGroupItem :visible="currentAttribute.type === 'BOOLEAN'">
          <DxSimpleItem
            :data-field="$t('Configuration.AttributesPopup.default')"
            editor-type="dxCheckBox"
            :editor-options="{
              value: currentAttribute.default,
              onValueChanged: onValueChanged,
            }"
          />
        </DxGroupItem>
      </DxForm>

      <f7-row>
        <f7-col />
        <f7-col>
          <f7-button
            v-if="!readOnly"
            class="dx-btn-success margin-bottom-block"
            raised
            type="success"
            styling-mode="contained"
            name="ButtonSave"
            @click="savePopup"
          >
            {{ $t('Button_save_text') }}
          </f7-button>
        </f7-col>
      </f7-row>
    </DxPopup>
  </div>
</template>

<script>

import { DxPopup } from 'devextreme-vue/popup';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'AttributesPopup',
  components: {
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    currentAttribute: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      searchTimeoutOption: 200,
      minSearchLengthOption: 0,
      showDataBeforeSearchOption: false,
      inputTypes: ['INT', 'FLOAT', 'BOOLEAN', 'STRING', 'DATE', 'DATETIME', 'SELECT', 'LIST'],
    };
  },
  computed: {
    ...mapState('Observations', ['showAttributePopup', 'readOnly']),
  },
  methods: {
    onHidden() {
      this.setShowAttributePopup(false);
      this.setReadOnly(false);
    },
    onValueChanged(e) {
      let attributeKey = '';
      switch (e.element.getElementsByTagName('input')[0].getAttribute('name')) {
        case this.$t('Configuration.ObservationsForms.field'):
          attributeKey = 'field';
          break;
        case this.$t('Configuration.ObservationsForms.description'):
          attributeKey = 'description';
          break;
        case this.$t('Configuration.ObservationsForms.tipology'):
          this.initDynamicValuesCurrentAttribute(e.value);
          attributeKey = 'type';
          break;
        case this.$t('Configuration.AttributesPopup.required'):
          attributeKey = 'required';
          break;
        case this.$t('Configuration.AttributesPopup.allowNull'):
          attributeKey = 'allow_null';
          break;
        case this.$t('Configuration.AttributesPopup.allowBlank'):
          attributeKey = 'allow_blank';
          break;
        case this.$t('Configuration.AttributesPopup.maxLength'):
          attributeKey = 'max_length';
          break;
        case this.$t('Configuration.AttributesPopup.minLength'):
          attributeKey = 'min_length';
          break;
        case this.$t('Configuration.AttributesPopup.maxValue'):
          attributeKey = 'max_value';
          break;
        case this.$t('Configuration.AttributesPopup.minValue'):
          attributeKey = 'min_value';
          break;
        case this.$t('Configuration.AttributesPopup.maxDigits'):
          attributeKey = 'max_digits';
          break;
        case this.$t('Configuration.AttributesPopup.decimalPlaces'):
          attributeKey = 'decimal_places';
          break;
        case this.$t('Configuration.AttributesPopup.choices'):
          attributeKey = 'choices';
          e.value = e.value.split(',');
          break;
        case this.$t('Configuration.AttributesPopup.default'):
          attributeKey = 'default';
          break;
        default:
          break;
      }
      this.updateCurrentAttribute({ attributeKey, value: e.value });
    },
    savePopup() {
      if (this.currentAttribute.description === '' || this.currentAttribute.type === '') {
        this.$notifyDX(
          {
            message: this.$t('Configuration.ObservationsForms.rowValidatingText'),
            width: 550,
          },
          'error',
          3000,
        );
      } else if ((this.currentAttribute.choices.filter((choice) => choice.indexOf(' ') >= 0)).length > 0) {
        this.$notifyDX(
          {
            message: this.$t('Configuration.ObservationsForms.validatingChoices'),
            width: 550,
          },
          'error',
          3000,
        );
      } else if ((this.currentAttribute.choices.filter((choice) => choice === '')).length > 0) {
        this.$notifyDX(
          {
            message: this.$t('Configuration.ObservationsForms.validatingChoices2'),
            width: 550,
          },
          'error',
          3000,
        );
      } else {
        if (this.currentAttribute.type === 'FLOAT' && this.currentAttribute.decimal_places === null) {
          this.currentAttribute.decimal_places = 2;
        }

        this.addAttribute();
        this.setShowAttributePopup(false);
      }
    },
    displayTranslatedType(type) {
      if (type === null) {
        return '';
      }
      return `${this.$t(`Configuration.AttributesPopup.type${type}`)}`;
    },
    ...mapActions('Observations', ['updateCurrentAttribute', 'addAttribute', 'initDynamicValuesCurrentAttribute', 'setShowAttributePopup', 'setReadOnly']),
  },
};
</script>
<style lang="scss" scoped>
@import './AttributesPopup.styles.scss';
</style>
