import helpers from '../../js/helpers';
import Api from '../../services/Api';

const tasksColors = {
  SIEMBRA: '#fbbf24', // Amarillo
  COSECHA: '#2dd4bf', // Verde menta
  FERTILIZACIÓN: '#db2777',  // Rosa
  'PREPARACIÓN DEL TERRENO': '#f43f5e', // Rojo
  A: '#9c27b0',                       // Púrpura
  'CONTROL DE MALAS HIERBAS': '#4caf50', // Verde
  'CONTROL DE PLAGAS Y ENFERMEDADES': '#2196f3', // Azul
  OTROS: '#ff9800',                   // Naranja
  PODA: '#795548',                    // Marrón
  RIEGO: '#00bcd4',                   // Cian
  'SIEMBRA-TRASPLANTE': '#8bc34a'     // Verde claro
};

export default {
  namespaced: true,
  state: {
    tasks: [],
    observations: [],
    selectedTasks: [],
    selectedObservations: []
  },
  actions: {
    resetData({ commit }) {
      commit('RESET_DATA');
    },
    setSelectedTasks({ commit }, tasks) {
      commit('SET_SELECTED_TASKS', tasks);
    },
    setSelectedObservations({ commit }, observations) {
      commit('SET_SELECTED_OBSERVATIONS', observations);
    },
    async fetchTasks({ commit }, {
      idCompany, idPlantation, activity, initDate, endDate,
    }) {
      try {
        const xhr = await Api.getTasksForCompanyFiltered(idCompany, idPlantation, activity, initDate, endDate);
        const tasks = JSON.parse(xhr.response).data;
        
        const color = tasksColors[activity];

        commit('SET_TASKS', tasks.map((task) => ({ ...task, color, selected: false })));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(helpers.getError(error));
      }
    },
    async fetchObservations({ commit }, {
      idCompany, idPlantation, category, initDate, endDate,
    }) {
      try {
        const xhr = await Api.getObservationsFilteredCategory(
          idCompany,
          idPlantation,
          category,
          initDate,
          endDate,
        );

        const observations = JSON.parse(xhr.response).results;

        // const color = getRandomHexColor();
        const color = '#65a30d';

        commit('SET_OBSERVATIONS', observations.map((observation) => ({ ...observation, color })));
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(helpers.getError(error));
      }
    },
  },
  mutations: {
    RESET_DATA(state) {
      state.tasks = [];
      state.observations = [];
    },
    SET_TASKS(state, tasks) {
      state.tasks.push(...tasks);
    },
    SET_OBSERVATIONS(state, observations) {
      state.observations.push(...observations);
    },
    SET_SELECTED_TASKS(state, tasks) {
      state.selectedTasks = tasks;
    },
    SET_SELECTED_OBSERVATIONS(state, observations) {
      state.selectedObservations = observations;
    },
  },
};
