import { render, staticRenderFns } from "./index.vue?vue&type=template&id=07a71c42&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./popupConfirmObservationExit.styles.scss?vue&type=style&index=0&id=07a71c42&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07a71c42",
  null
  
)

export default component.exports