<template>
  <f7-page>
    <navbar text="Informe de índice radiométrico" />
    <ReportNotify
      v-show="reportMessage.length > 0"
      :message="reportMessage"
    />
    <div v-if="step === 1">
      <transition
        :css="false"
        @leave="leave"
      >
        <ReportStep1
          :selected-dates="selectedDates"
          @change-selected-dates="changeSelectedDates"
        />
      </transition>
    </div>
    <div v-if="step === 2">
      <ReportStep2
        :selected-dates="selectedDates"
        :histogramData="histogram"
        @refresh-data="refreshData()"
        @commentaryChanged="handleCommentaryChange"
        @data-climates="handleClimateData"
      />
    </div>
    <div class="margin">
      <f7-row>
        <f7-col>
          <f7-button
            class="dx-btn-cancel"
            type="default"
            text-color="grey"
            styling-mode="outlined"
            name="ButtonCancel"
            @click="goBack()"
          >
            {{ $t('Button_go_to_back') }}
          </f7-button>
        </f7-col>
        <f7-col class="justify-content-end">
          <div v-if="step === 2">
            <f7-button
              class="dx-btn-success"
              style="width: 100%; margin: 0;"
              raised
              type="success"
              styling-mode="contained"
              :disabled="downloading || sharing"
              @click="shareFile()"
            >
              Compartir
            </f7-button>
          </div>
          <div style="padding-left: 0.4rem;">
            <f7-button
              class="dx-btn-success"
              style="width: 100%; margin: 0;"
              raised
              type="success"
              styling-mode="contained"
              :disabled="downloading || sharing"
              @click="nextStep()"
            >
              {{ getNextButtonText }}
            </f7-button>
          </div>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { Device } from 'framework7/framework7-lite.esm.bundle';
import { mapActions, mapState } from 'vuex';
import moment from 'moment';
import navbar from '../../../components/NavBar.vue';
import ReportStep1 from '../../../components/searcher/gis/reports/reportStep1.vue';
import ReportStep2 from '../../../components/searcher/gis/reports/reportStep2.vue';
import PDFMakerGis from '../../../js/pdf-maker-gis.js';
import Api from '../../../services/Api';
import ReportNotify from '../../../components/reportNotify/index.vue';
import EventBus from '../../../js/event-bus';

export default {
  name: 'ReportGis',
  components: {
    navbar,
    ReportStep1,
    ReportStep2,
    ReportNotify,
  },
  data() {
    return {
      step: 1,
      selectedDates: [],
      downloading: false,
      reportMessage: '',
      pdfmakergis: null,
      sharing: false,
      commentary: '',
      climatesData: [],
    };
  },
  computed: {
    // Datos para el parametro env del endpoint get_print para el dibujo del mapa en el pdf
    getEnvData(){
      return `min:${this.histogram[0].min};stddev:${this.histogram[0].stddev};mean:${this.histogram[0].mean};max:${this.histogram[0].max}`;
    },
    ...mapState('Plantation', ['currentPlantation']),
    ...mapState('Gis', ['currentIndex', 'cloudsValues', 'histogram']),
    ...mapState('authentication', ['user']),
    ...mapState('ReportGis', ['initDate', 'endDate']),
    ...mapState('TimeDimension', ['selectedObservations', 'selectedTasks']),
    getNextButtonText() {
      if (this.step === 1) {
        return this.$t('next');
      }

      return this.$t('nutritionDetail.report.download');
    },
  },
  methods: {
    ...mapActions('ReportGis', ['fetchTimelineIndexes']),
    ...mapActions('Gis', ['setHistogram']),
    reportInformation() {
      Api.report(
        {
          report: 'Informe de imágenes',
          contact_code: this.user?.user?.contact?.code || '',
          contact_name: this.user?.user?.contact?.name || '',
          contact_surname: this.user?.user?.contact?.surname || '',
          plantations: [this.currentPlantation.id],
          user: this.user.user.id,
        },
      );
    },
    handleCommentaryChange(commentary) {
      this.commentary = commentary;
    },
    handleClimateData(climate) {
      this.climatesData = climate;
    },
    goBack() {
      if (this.step === 2) {
        this.step--;
        this.downloading = false;
        return;
      }

      this.$f7.views.main.router.navigate('/searcher/gissearcher/', {
        reloadCurrent: true,
      });
    },
    nextStep() {
      if (this.step === 1) {
        this.step++;
        return;
      }

      if (this.initDate === '' || this.endDate === '') {
        this.$notifyDX(
          {
            message: this.$t('noDateRangeSelected'),
            width: 450,
          },
          'warning',
          2000,
        );
        return;
      }

      this.downloading = true;
      this.reportMessage = this.$t('generatingPDF');

      this.downloadReport();
    },
    async refreshData() {
      await this.fetchIndexes();
    },
    async fetchIndexes() {
      if (this.initDate === '' || this.endDate === '') return;

      const params = {
        location: this.currentPlantation.sectorId,
        initDate: moment(this.initDate).format('YYYY-MM-DD'),
        endDate: moment(this.endDate).format('YYYY-MM-DD'),
        companyId: this.currentPlantation.companyId,
        currentIndex: this.currentIndex,
      };

      try {
        await this.fetchTimelineIndexes(params);
        this.downloading = false;
      } catch (error) {
        this.downloading = true;
        this.$notifyDX(
          {
            message: error.detail || this.$t('downloadReportError'),
            width: 550,
          },
          'error',
          3000,
        );
      }
    },
   async changeSelectedDates(data) {
      this.selectedDates = data;
      // Actualizamos el histogram con la fecha elegida para actualizar el histograma con su nuevo env
      const histogram = await this.getFormattedHistogram(
        this.currentPlantation.sectorId,
        moment(this.selectedDates.length > 1 ? this.selectedDates[this.selectedDates.length - 1] : this.selectedDates[0]).format('YYYY-MM-DD'),
        this.currentPlantation.companyId,
        this.currentIndex,
    );
      // Lo guardamos en la store de histogram
      this.setHistogram(histogram);

      setTimeout(() => {
        EventBus.$emit('updateTimeDimensionGisTimeDimension');
      }, 100);
    },
    async fetchHistogram(location, date, company) {
      const xhr = await Api.getGeoServerTemperatureHistogram(
        location,
        date,
        company,
      );

      return xhr.json().then((res) => res.data);
    },
    // Funcion para formatear histogram para guardarlo en la store luego con la misma estructura de datos
    async getFormattedHistogram(sectorId, date, company, currentIndex) {
      const histogram = await this.fetchHistogram(
        sectorId,
        moment(date).format('YYYY-MM-DD'),
        company,
      );

      if (histogram.length === 0) return {};

      const histogramData = histogram.find(
        (item) => item.index === currentIndex.toLowerCase(),
      );

      return [{
        histogram: histogramData.histogram,
        iso_date: moment(this.selectedDates.length > 1 ? this.selectedDates[this.selectedDates.length - 1] : this.selectedDates[0]).format('YYYY-MM-DD'),
        max: histogramData?.max,
        mean: histogramData?.mean,
        min: histogramData?.min,
        stddev: histogramData?.stddev,
      }];
    },
    leave() {
      const timeDimension = document.getElementsByClassName(
        'timecontrol-play',
      )[0];
      if (timeDimension) {
        const timeDimensionClassList = timeDimension.classList;

        if (timeDimensionClassList.contains('pause')) {
          timeDimension.click();
        }
      }
    },
    getStatusCampaign(initDate, endDate) {
      const formatedInit = moment(initDate).format('YYYY-MM-DD');
      const formatedEnd = moment(endDate).format('YYYY-MM-DD');

      if (moment(this.actualDate).isBetween(formatedInit, formatedEnd, 'days', '[]')) {
        return this.$t('status_campania_active');
      } if (moment(formatedEnd).isBefore(this.actualDate)) {
        return this.$t('status_campania_end');
      }
      return this.$t('status_campania_pending');
    },
    async fetchImageAndStack(date) {
      const formattedDate = `${moment(date).format('YYYY-MM-DD')}`;
      try {
        const [imageXhr, stackXhr] = await Promise.all([
          Api.getGeoServerCallServices(
            this.currentPlantation.companyId,
            `${Api.getGeoServerPrintMap()}`,
            `?ws=${Api.getGeoServerWorkspace()}&resize=320&dimension=320&quality=25&layer=${this.currentPlantation.sectorId}&date=${formattedDate}&index=${this.currentIndex}&env=${this.getEnvData}`
          ),
          Api.getGeoServerCallServices(
            this.currentPlantation.companyId,
            `${Api.getGeoServerDiagramBar()}`,
            `?histogram=true&server=${Api.getBaseUrlGis()}&client=timacagro&location=${this.currentPlantation.sectorId}&init_date=${formattedDate}&index=${this.currentIndex.toUpperCase()}&histogram_select=horizontal`
          )
        ]);

        const [image, stack] = await Promise.all([
          this.imageToDataResponse(imageXhr),
          this.imageToDataResponse(stackXhr)
        ]);

        return { image, stack };
      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000
          );
        }, 0);
      }
    },
    async fetchClimateData() {
      const data = {
        initDate: moment(this.initDate).format('YYYY-MM-DDT00:00:00'),
        endDate: moment(this.endDate).format('YYYY-MM-DDT00:00:00'),
        grouped: 'DAY',
        formula: '',
        sectorId: this.currentPlantation.sectorId,
      };

      try {
        const imageXhr = await Api.getGeoServerCallServicesClimateData(
            this.currentPlantation.companyId,
            `${Api.getGeoServerClimateData()}`,
            `?location=${data.sectorId}&init_date=${data.initDate}&end_date=${data.endDate}&grouped=${data.grouped}&formula=${data.formula}`,
            this.climatesData
          )
        const imageDataUrl = await this.imageToDataResponse(imageXhr);

        return { image: imageDataUrl } ;
      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000
          );
        }, 0);
      }
    },
    async fetchIndexDates() {
      const initDate = moment(this.initDate).format('YYYY-MM-DD');
      const endDate = moment(this.endDate).format('YYYY-MM-DD');

      try {
        // Filtrar y transformar selectedTasks con la estructura que necesitamos
        const tasks = this.selectedTasks.map(task => ({
          date: task.init_date,
          title: task.activity,
        }));

        // Filtrar y transformar selectedObservations con la estructura que necesitamos
        const observations = this.selectedObservations.map(observation => ({
          date: observation.date,
          title: observation.scouting_point.name,
        }));

        // Construir el parámetro tasks_reports solo si hay datos
        let tasksReportsParam = '';
        const hasTasks = tasks.length > 0;
        const hasObservations = observations.length > 0;

        if (hasTasks || hasObservations) {
          const tasksReports = {
            sector_id: this.currentPlantation.sectorId,
            ...(hasTasks && { task: tasks }),  // Añade 'task' solo si hay tareas
            ...(hasObservations && { observation: observations })  // Añade 'observation' solo si hay observaciones
          };

          // Convertir tasksReports a un string JSON para pasarlo como parámetro en la URL
          tasksReportsParam = encodeURI(JSON.stringify(tasksReports));
        }

        // Construimos manualmente el enpoint
        let url = `?histogram=true&client=timacagro&location=${this.currentPlantation.sectorId}&init_date=${initDate}&end_date=${endDate}&index=${this.currentIndex.toUpperCase()}`;
        
        // Añadir tasks_reports a la URL si está definido
        if (tasksReportsParam) {
          url += `&tasks_reports=${tasksReportsParam}`;
        }

        // Llamada a la API con la URL construida
        const imageXhr = await Api.getGeoServerCallServices(
          this.currentPlantation.companyId,
          `${Api.getGeoServerIndexDates()}`,
          url
        );

        const imageDataUrl = await this.imageToDataResponse(imageXhr);

        return { image: imageDataUrl };
      } catch (error) {
        setTimeout(() => {
          this.$notifyDX(
            {
              message: this.$t(this.$helpers.getFilteredErrorMessage(`${error.message} wms`)),
              width: 550,
            },
            'warning',
            3000
          );
        }, 0);
      }
    },
    async fetchImagesForSelectedDates() {
      return Promise.all(
        this.selectedDates.map((date, index) => this.fetchImageAndStack(date, index))
      );
    },
    async generatePDF() {
      const AtcName = `${this.user.user.contact.name} ${this.user.user.contact.surname}`;
      // creamos constante para controlar si el comentario no esta vacio
      const commentaryIsNotEmpty = String(this.commentary).trim() !== '';

      this.pdfmakergis.insertReportTitle('INFORME DE IMÁGENES');
      this.pdfmakergis.insertTableWithoutBorders([
        [
          {
            title: this.$t('DxClients_client'),
            text: this.currentPlantation.company,
          },
          {
            title: this.$t('searchEngine_farm'),
            text: this.currentPlantation.explotation,
          },
          {
            title: this.$t('Parcel'),
            text: this.currentPlantation.parcel,
          },
        ],
        [
          {
            title: this.$t('Sector'),
            text: this.currentPlantation.sector,
          },
          {
            title: this.$t('Plantation'),
            text: this.currentPlantation.name,
          },
          {
            title: this.$t('nutritionDetail.report.area'),
            text: `${this.currentPlantation.area.toFixed(2)} ha`,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.cropType'),
            text: this.currentPlantation.species,
          },
          {
            title: this.$t('nutritionDetail.report.variety'),
            text: this.currentPlantation.variety,
          },
          {
            title: this.$t('nutritionDetail.report.date'),
            text: `${moment(this.currentPlantation.initDate).format('DD/MM/YYYY')} - ${moment(this.currentPlantation.endDate).format('DD/MM/YYYY')}`,
          },
        ],
        [
          {
            title: this.$t('nutritionDetail.report.clouds'),
            text: this.cloudsValues.join(', '),
          },
          {
            title: '',
            text: '',
          },
          {
            title: '',
            text: '',
          },
        ],
      ]);

      const images = await this.fetchImagesForSelectedDates();

      const dataImages = [];

      if (images.length % 3 !== 0) {
        images.push(
          ...new Array(3 - (images.length % 3)).fill([]),
        );
      }

      for (let i = 0; i < images.length; i += 3) {
        dataImages.push(images.slice(i, i + 3));
      }

      if (dataImages.length > 0) {
        await this.pdfmakergis.inserTableSatelliteImages([...dataImages]);
      }

      // Construimos el histograma de datos climaticos 
      const fetchChartPromise = this.climatesData.length > 0 ? this.fetchClimateData() : Promise.resolve(null);
      const [chart, indexDates] = await Promise.all([
        fetchChartPromise,
        this.fetchIndexDates()
      ]);

      if (chart) {
        await this.pdfmakergis.insertChart(chart);
      }
      await this.pdfmakergis.insertChart(indexDates);

      if (this.selectedObservations.length > 0) {
        const observations = await Promise.all(
          this.selectedObservations.map(async (item, index) => {
            const xhr = await Api.getObservationDetail(
              this.currentPlantation.companyId,
              item.id,
            );
            const observation = JSON.parse(xhr.response);

            const comment = [];

            observation.template_form.forEach((element) => {
              if (observation[element.field] !== null && observation[element.field]?.length > 0) {
                comment.push([element.description, observation[element.field]]);
              }
            });

            return {
              id: observation.id,
              title: `Observación ${index + 1}:`,
              date: observation.date,
              multimedia: await Promise.all(observation.multimedia.filter(
                (extension) => extension.extension === '.jpg' || extension.extension === '.png'
                || extension.extension === '.JPG' || extension.extension === '.PNG',
              ).map(async (image) => {
                image.base64 = await this.imageToDataUrl(image.attachment);
                return image;
              })),
              comment,
            };
          }),
        );
        this.pdfmakergis.insertObservations(observations);
      }

      this.pdfmakergis.insertTableBottomSignature([
        [
          ['Nombre del ATC', AtcName],
          ['Fecha', moment().format('DD/MM/YYYY')],
          ['Firmado', ''],
        ],
      ]);
      // Si no esta vacio metemos el comentario al descargar PDF
      if (commentaryIsNotEmpty) {
        this.pdfmakergis.insertTableBottomComments([
          [
            [this.commentary],
          ],
        ]);
      }
    },
    async downloadReport() {
      this.$fa.logEvent('buscador_gis_linea_temporal_descargar', {
        initDate: this.initDate,
        endDate: this.endDate,
        content_type: 'button',
        description: 'Evento ocurre en la vista de buscador/linea temporal y pulsamos el boton descargar informe',
      });
      this.pdfmakergis = new PDFMakerGis();

      await this.generatePDF();

      const random = moment().format('YYMMDDHHmmss');

      const title = `INFORME DE IMÁGENES (${random})`;

      this.reportMessage = this.$t('downloadingPDF');

      if (Device.desktop) {
        await this.pdfmakergis.buildAndDownload(title);
        this.reportMessage = '';
      } else {
        const pdfdata = this.pdfmakergis.buildForMobile();

        await new Promise((resolve) => {
          pdfdata.getBase64((base64) => {
            const path = `${cordova.file.externalRootDirectory}Download/`;
            const fileExtension = '.pdf';

            this.savebase64AsPDF(
              path,
              title + fileExtension,
              base64,
              'application/pdf',
            );

            resolve();
          });
        });
      }

      this.reportInformation();

      // this.reportMessage = '';
      this.downloading = false;
    },
    async shareFile() {
      this.$fa.logEvent('buscador_gis_linea_temporal_compartir', {
        initDate: this.initDate,
        endDate: this.endDate,
        content_type: 'button',
        description: 'Evento ocurre en la vista de buscador/linea temporal y pulsamos el boton compartir informe',
      });
      if (this.initDate === '' || this.endDate === '') {
        this.$notifyDX(
          {
            message: this.$t('noDateRangeSelected'),
            width: 450,
          },
          'warning',
          2000,
        );
        return;
      }
      this.sharing = true;

      this.reportMessage = this.$t('generatingPDFShare');

      this.pdfmakergis = new PDFMakerGis();

      await this.generatePDF();

      const random = moment().format('YYMMDDHHmmss');

      const title = `INFORME DE IMÁGENES (${random})`;

      this.$f7.dialog.confirm(this.$t('confirmSharePdf'), async () => {
        const buffer = await this.pdfmakergis.getPdfAsBuffer();

        const pdf = new File([buffer], `${title}.pdf`, { type: 'application/pdf' });
        const files = [pdf];

        if (navigator.canShare) {
          try {
            await navigator.share({
              files,
              title,
              text: '',
            });
            this.reportMessage = '';
          } catch (error) {
            this.reportMessage = '';
            this.$notifyDX(
              {
                message: error.message,
                width: 550,
              },
              'error',
              3000,
            );
          }
        } else {
          try {
            await new Promise((resolve) => {
              const pdfdata = this.pdfmakergis.buildForMobile();

              pdfdata.getBase64((base64) => {
                const path = `${cordova.file.externalRootDirectory}Download/`;
                const fileExtension = '.pdf';

                this.savebase64AsPDFAndShare(
                  path,
                  title + fileExtension,
                  base64,
                  'application/pdf',
                  title,
                );

                resolve();
              });
            });
          } catch (error) {
            this.reportMessage = '';
            this.$notifyDX(
              {
                message: error.message,
                width: 550,
              },
              'error',
              3000,
            );
          }
        }
      });

      // this.reportMessage = '';
      this.sharing = false;
    },
    async imageToDataUrl(path) {
      const blob = await fetch(path).then((res) => res.blob());
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    async imageToDataResponse(xhr) {
      const blob = await xhr.blob();
      const reader = new FileReader();

      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(blob);
      });
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    savebase64AsPDF(folderpath, filename, content, contentType) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              this.reportMessage = this.$t('FileDownloadedInDownloads');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
    savebase64AsPDFAndShare(folderpath, filename, content, contentType, message) {
      const DataBlob = this.b64toBlob(content, contentType);
      window.resolveLocalFileSystemURL(folderpath, (dir) => {
        dir.getFile(filename, { create: true }, (file) => {
          file.createWriter(
            (fileWriter) => {
              fileWriter.write(DataBlob);
              const fileName = folderpath + filename;

              window.plugins.socialsharing.shareWithOptions({
                message,
                files: [fileName],
              });

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
            () => {
              this.reportMessage = this.$t('SheetExport_sheet_export_error');

              setTimeout(() => {
                this.reportMessage = '';
              }, 500);
            },
          );
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './reportGis.styles.scss';
</style>