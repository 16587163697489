<template>
  <div
    class="content-devx"
  >
    <f7-block-title>
      {{ $t('nutritionDetail.report.nutritionPlan') }}
    </f7-block-title>

    <f7-block>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.client') }}: <b>{{ data.company_name }}</b>
        </f7-col>
        <f7-col />
        <f7-col>
          {{ $t('nutritionDetail.report.date') }}: <b>{{ $helpers.dateFormatting(explotationData.init_date) }} - {{ $helpers.dateFormatting(explotationData.end_date) }}</b>
        </f7-col>
      </f7-row>
      <f7-row>
        <f7-col>
          {{ $t('nutritionDetail.report.description') }}: <b>{{ data.description }}</b>
        </f7-col>
      </f7-row>
    </f7-block>
  </div>
</template>
<script>

export default {
  name: 'HeaderReportInfo',
  props: {
    explotationData: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
<style lang="scss" scoped>
@import './HeaderReportInfo.styles.scss';
</style>
